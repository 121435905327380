import { getPagedWaterRights } from "@/apis/waterright.api";
import defaultQueryConfig from "@/configs/defaultQueryConfig";
import CacheKeys from "@/types/CacheKeys";
import { keepPreviousData, useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { useSelector } from "react-redux";

type WaterRightPagedListProps = {
  waterRightIds?: any[] | null;
  pagination: {
    page: number;
    pageSize: number;
  };
  isActive: boolean | null;
  searchString?: string;
  permitTypes?: string[] | null;
};

export default function useWaterRightPagedList(props: WaterRightPagedListProps) {
  const { profile } = useSelector((state: any) => state.user);
  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const queryClient = useQueryClient();

  const {
    isFetched,
    isRefetching,
    data: waterRights,
    isFetching,
    isPlaceholderData,
  } = useQuery({
    queryKey: [
      CacheKeys.waterRights,
      CacheKeys.list,
      {
        profileId: profile?.id,
        companyId: selectedCompanyId,
        ...props,
      },
    ],
    queryFn: async () => {
      const response = await getPagedWaterRights({
        companyId: selectedCompanyId,
        ...props,
      });

      if (response.ok) {
        const data = await response.json();
        if (data.isSuccess) return data.value;

        throw new Error("Failed to fetch paged water rights");
      }
    },
    placeholderData: keepPreviousData,
    ...defaultQueryConfig,
    enabled: !!profile?.id,
  });

  const invalidateWaterRights = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [CacheKeys.waterRights, CacheKeys.list],
    });
  }, [queryClient]);

  const isLoading = !isFetched;

  return {
    isLoading,
    isFetching,
    isRefetching,
    isPlaceholderData,
    waterRights,
    invalidateWaterRights,
  };
}
