import { CloseOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { Button, Card, Divider, Form, Select, Space } from "antd";
import { useWatch } from "antd/es/form/Form";
import { constants } from "@/configs";
import dayjs from "dayjs";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getStocks } from "@/apis/stock.api";

import GeneratedStockConfigurationReport from "./GeneratedStockConfigurationReport";

const StockConfigurationReport: FC = () => {
  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const [form] = Form.useForm();

  const [loadingStock, setLoadingStock] = useState<boolean>(false);
  const [generatingReport, setGeneratingReport] = useState<boolean>(false);

  const [stock, setStock] = useState<any[]>([]);

  const [reportConfig, setReportConfig] = useState<any>(undefined);

  const stockIds: any[] = useWatch("stockIds", form);

  useEffect(() => {
    handleReset();
    // eslint-disable-next-line
  }, [selectedCompanyId]);

  useEffect(() => {
    if (selectedCompanyId) {
      refreshStock();
    }
    // eslint-disable-next-line
  }, [selectedCompanyId]);

  useEffect(() => {
    if (generatingReport) setTimeout(() => setGeneratingReport(false), 500);
    // eslint-disable-next-line
  }, [generatingReport]);

  const refreshStock = async () => {
    setLoadingStock(true);
    const response = await getStocks({ companyId: selectedCompanyId });
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setStock(data.value);
      }
    }
    setLoadingStock(false);
  };

  const handleGenerateReport = () => {
    setGeneratingReport(true);

    const tempStockIds: any[] = (stock ?? []).filter((stock: any) => (stockIds ?? []).includes(stock.id)).map((stock: any) => stock.id);

    setReportConfig({
      stockIds: tempStockIds,
      stock: stock
        .filter((stock: any) => tempStockIds?.includes(stock.id))
        .map((stock: any) => stock.name)
        .join(", "),
      generatedDate: dayjs().format(constants.dateTimeFormatWithSeconds),
    });
  };

  const handleReset = () => {
    form?.resetFields();
    setReportConfig(undefined);
  };

  const renderActionsButtons = () => {
    return (
      <Space>
        <Button loading={loadingStock || generatingReport} disabled={loadingStock || generatingReport} type="primary" icon={<ThunderboltOutlined />} onClick={handleGenerateReport}>
          Generate Report
        </Button>
        <Button loading={loadingStock || generatingReport} disabled={loadingStock || generatingReport} icon={<CloseOutlined />} onClick={handleReset}>
          Reset
        </Button>
      </Space>
    );
  };

  return (
    <>
      <Card title="Configuration" actions={[renderActionsButtons()]} bodyStyle={{ padding: 12, margin: 0 }}>
        <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} autoComplete="off" style={{ margin: 0 }}>
          <Form.Item label="Stock" name="stockIds" style={{ margin: 0, marginBottom: 10 }}>
            <Select
              allowClear
              mode="multiple"
              loading={loadingStock}
              placeholder="Select stock"
              showSearch
              optionFilterProp="label"
              maxTagCount={3}
              disabled={loadingStock || generatingReport}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <Space style={{ padding: "0 8px 4px" }}>
                    <Button
                      onClick={() =>
                        form.setFieldValue(
                          "stockIds",
                          stock.filter((item: any) => item !== undefined).map((stock) => stock.id)
                        )
                      }
                    >
                      Select All
                    </Button>
                    <Button danger onClick={() => form.setFieldValue("stockIds", [])}>
                      Clear List
                    </Button>
                  </Space>
                </>
              )}
            >
              {stock
                .filter((item: any) => item !== undefined)
                .map((stock: any) => {
                  return (
                    <Select.Option value={stock.id} key={stock.id} label={stock.name}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        {stock.name}
                      </div>
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Form>
      </Card>
      {generatingReport && (
        <Card title="Generating Report">
          <div style={{ textAlign: "center" }}>Loading...</div>
        </Card>
      )}
      {!generatingReport && reportConfig && <GeneratedStockConfigurationReport reportConfig={reportConfig} />}
    </>
  );
};

export default StockConfigurationReport;
