import { getCompanyTagsList } from "@/apis/company.api";
import { CompanyTagList, StatusTag, UsersTable } from "@/components";
import PrintPage from "@/components/PrintPage/PrintPage";
import { routes } from "@/configs";
import useCompanyPagedList from "@/queries/useCompanyPageQuery";
import useCustomNavigate from "@/services/useCustomNavigate";
import { formatPhoneNumber } from "@/services/utils";
import { useAppDispatch } from "@/stores";
import { addBreadcrumb } from "@/stores/breadcrumbs.store";
import { saveSelectedCompanyId } from "@/stores/company.store";
import { PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import { Button, Card, Descriptions, Divider, Input, Space, Switch, Table } from "antd";
import { FC, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import "./CompanyList.scss";

const CompanyList: FC = () => {
  const dispatch = useAppDispatch();

  const { selectedCompany } = useSelector((state: any) => state.company);

  const { navigate, updateQueryParams, queryParams } = useCustomNavigate();

  const [columns, setColumns] = useState<any[]>([]);
  const [searchStringValue, setSearchStringValue] = useState<string | undefined>("");
  const [searchString, setSearchString] = useState<string | undefined>("");
  const [isActive, setIsActive] = useState<string>("true");

  const [loadingCompanyTags, setLoadingCompanyTags] = useState<boolean>(false);
  const [companyTags, setCompanyTags] = useState<any>(undefined);

  const {
    companies: tempCompanies,
    isLoading: isCompaniesLoading,
    invalidateCompanies,
  } = useCompanyPagedList({
    isActive,
    pagination: {
      page: queryParams?.page ?? "1",
      pageSize: queryParams?.pageSize ?? "10",
    },
    searchString,
  });

  const companies: any[] = useMemo(() => {
    const data = tempCompanies?.list?.map((company: any) => {
      const tagsForCompany = companyTags?.find((companyTag: any) => companyTag.companyId === company.id)?.tags;

      return {
        ...company,
        tags: tagsForCompany !== null && tagsForCompany?.length > 0 ? tagsForCompany : [],
      };
    });

    return data;
  }, [tempCompanies, companyTags]);

  useEffect(() => {
    calculateColumns();
    // eslint-disable-next-line
  }, [companies, selectedCompany]);

  useEffect(() => {
    refreshCompanyTagsList();
    // eslint-disable-next-line
  }, [isActive]);

  const refreshCompanyTagsList = async () => {
    setLoadingCompanyTags(true);

    const response = await getCompanyTagsList();
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setCompanyTags(data.value);
      }
    }

    setLoadingCompanyTags(false);
  };

  const handleAddCompany = () => {
    dispatch(
      addBreadcrumb({
        type: `Company / Add`,
        url: `company/add`,
      })
    );
    navigate(routes.companyAdd);
  };

  const handleViewCompany = (record: any) => {
    dispatch(
      addBreadcrumb({
        type: `${record.name}`,
        url: `company/${record.id}/view`,
      })
    );
    navigate(routes.companyView, { id: record.id });
  };

  const handleSwitchToCompany = (record: any) => {
    dispatch(saveSelectedCompanyId(record.id));
  };

  const calculateColumns = () => {
    let tempColumns = [
      {
        title: "Company Name",
        dataIndex: "name",
        fixed: "left",
        render: (val: any, record: any) => (
          <>
            <Button style={{ paddingLeft: 0 }} type="link" onClick={() => handleViewCompany(record)}>
              {val}
            </Button>
            <CompanyTagList tags={record?.tags} />
          </>
        ),
      },
      {
        title: "Status",
        dataIndex: "active",
        render: (val: any) => <StatusTag status={val} />,
      },
      {
        title: "Actions",
        key: "action",
        width: 170,
        render: (value: any, record: any) => (
          <>
            <Button type="link" onClick={() => handleViewCompany(record)} style={{ paddingLeft: 0 }}>
              View
            </Button>
            {" | "}
            <Button type="link" disabled={selectedCompany?.companyId === record.id} onClick={() => handleSwitchToCompany(record)}>
              Switch To
            </Button>
          </>
        ),
      },
    ];

    setColumns(tempColumns);
  };

  const handleRefresh = () => {
    setSearchString("");
    setSearchStringValue("");
    updateQueryParams({ page: 1, pageSize: 10 });
    invalidateCompanies();
    refreshCompanyTagsList();
  };

  const handleOnChange = (value: boolean) => {
    if (!value) setIsActive("false");
    else setIsActive("true");
  };

  const renderOwnerData = (record: any) => {
    return (
      <div style={{ padding: 10, paddingTop: 0, paddingRight: 50 }}>
        <div style={{ paddingLeft: 50 }}>
          <Divider orientation="left">Owner Information</Divider>
          <Descriptions bordered size="small" layout="horizontal">
            <Descriptions.Item label="Name">
              {record.firstName} {record.lastName}
            </Descriptions.Item>
            <Descriptions.Item label="Phone Number">{record.phoneNumber ? formatPhoneNumber(record.phoneNumber) : "N/A"}</Descriptions.Item>
            <Descriptions.Item label="Email">{record.email}</Descriptions.Item>
          </Descriptions>
          <Divider orientation="left">Users</Divider>
        </div>
        <UsersTable companyId={record.id} applyPaddingOnSearch />
      </div>
    );
  };

  return (
    <Card
      className="companyList"
      id="companyList"
      title="Companies"
      extra={
        <>
          <Button icon={<PlusOutlined />} onClick={handleAddCompany} type="primary">
            Add Company
          </Button>
        </>
      }
    >
      <PrintPage
        content={
          <div>
            <Space direction="vertical" style={{ width: "100%" }}>
              <Space style={{ paddingBottom: 10 }}>
                <Input.Search
                  disabled={isCompaniesLoading}
                  placeholder="Search companies"
                  onSearch={(val) => {
                    setSearchString(val);
                    updateQueryParams({ page: 1, pageSize: 10 });
                  }}
                  onChange={(val) => setSearchStringValue(val.currentTarget.value)}
                  value={searchStringValue}
                  style={{ width: 400 }}
                  allowClear
                />
                <Button icon={<ReloadOutlined />} disabled={isCompaniesLoading} onClick={handleRefresh}>
                  Refresh
                </Button>
                <Switch defaultChecked onChange={handleOnChange} checkedChildren="Active Only" unCheckedChildren="All" />
              </Space>
              <Table
                pagination={{
                  pageSize: queryParams?.pageSize ? Number(queryParams?.pageSize) : 10,
                  current: queryParams?.page ? Number(queryParams?.page) : 1,
                  total: tempCompanies?.pagination?.totalItemCount,
                }}
                onChange={(pagination) => {
                  updateQueryParams({
                    page: pagination.current,
                    pageSize: pagination.pageSize,
                  });
                }}
                rowKey={(row: any) => row.id}
                expandable={{
                  expandedRowRender: (record: any) => renderOwnerData(record),
                }}
                loading={isCompaniesLoading}
                columns={columns}
                dataSource={
                  companies?.sort((a, b) =>
                    a?.name?.localeCompare(b.name, "en", {
                      numeric: true,
                      sensitivity: "base",
                    })
                  ) || []
                }
                size="small"
              />
            </Space>
          </div>
        }
      />
    </Card>
  );
};

export default CompanyList;
