import RadialGauge from "@/components/Gauges/RadialGauge";
import { constants, routes } from "@/configs";
import useListWellDashboardQuery from "@/queries/useListWellDashboardQuery";
import useCustomNavigate from "@/services/useCustomNavigate";
import { useAppDispatch } from "@/stores";
import { addBreadcrumb } from "@/stores/breadcrumbs.store";
import formatDateTime from "@/utils/formatDateTime";
import { ReloadOutlined } from "@ant-design/icons";
import { useIntersectionObserver } from "@uidotdev/usehooks";
import { Button, Card, Col, Empty, Input, Row, Skeleton, Space } from "antd";
import React, { useEffect, useState } from "react";

const gridStyle: React.CSSProperties = {
  textAlign: "center",
  cursor: "pointer",
  maxWidth: 330,
};

export default function GaugesDashboard() {
  const dispatch = useAppDispatch();
  const { navigate } = useCustomNavigate();

  const [searchString, setSearchString] = useState<string | undefined>("");
  const [searchStringValue, setSearchStringValue] = useState<string | undefined>("");

  const [downref, downentry] = useIntersectionObserver();

  const {
    dashboardWells: wells,
    fetchNextPage,
    invalidateDashboardWells,
    hasNextPage,
    isLoading,
    totalItemCount,
    isFetchingNextPage,
  } = useListWellDashboardQuery({
    searchString: searchString,
    sortOrder: {
      Name: "ASC",
    },
    pageIdentifier: "gaugesDashboard",
  });

  const handleRefresh = () => {
    setSearchString(undefined);
    setSearchStringValue(undefined);
    invalidateDashboardWells();
  };

  const handleOnClick = (record: any) => {
    dispatch(
      addBreadcrumb({
        type: `${record.name}`,
        url: `well/${record.id}/view`,
      })
    );
    navigate(routes.wellView, { id: record.id });
  };

  useEffect(() => {
    if (downentry?.isIntersecting && hasNextPage) {
      fetchNextPage();
    }
  }, [downentry?.isIntersecting, hasNextPage]);

  return (
    <>
      <Row>
        <Col span={24}>
          <Card
            title={
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                <span>Remaining Usage</span>
                <Space direction="horizontal">
                  <Space>
                    <Input.Search
                      disabled={isLoading}
                      placeholder="Search wells"
                      onSearch={(val) => {
                        setSearchString(val);
                      }}
                      onChange={(val) => setSearchStringValue(val.currentTarget.value)}
                      value={searchStringValue}
                      style={{ width: 300, fontWeight: "normal" }}
                      allowClear
                    />
                    <Button icon={<ReloadOutlined />} disabled={isLoading} onClick={handleRefresh}>
                      Refresh
                    </Button>
                  </Space>
                </Space>
                <span>Total: {totalItemCount ?? "-"}</span>
              </div>
            }
            bordered={false}
            loading={isLoading}
          >
            {!isLoading && wells?.length > 0 ? (
              wells.map((well: any, index, pages) => (
                <Card.Grid style={gridStyle} onClick={() => handleOnClick(well)} key={well.id}>
                  <RadialGauge
                    header={well.name}
                    companyId={well.companyId}
                    availableQuantity={well.availableQuantity}
                    usedQuantity={well.currentUsage}
                    remainingDays={well.remainingDays ?? "N/A"}
                    borderless
                    style={{ paddingBottom: 0 }}
                  />
                  <div style={{ opacity: 0.7, fontSize: 12, textAlign: "right", paddingTop: 10 }}>
                    Last Reading Date: {well.lastReadingDate ? formatDateTime(well.lastReadingDate, constants.dateTimeFormat) : "No Readings"}
                  </div>
                  {index === pages.length - 1 ? <div ref={downref} /> : null}
                </Card.Grid>
              ))
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Wells" />
            )}
            {isFetchingNextPage && (
              <Card.Grid>
                <Skeleton active />
              </Card.Grid>
            )}
          </Card>
        </Col>
      </Row>
    </>
  );
}
