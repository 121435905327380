import { Modal, Typography } from "antd";
import { RcFile } from "antd/es/upload";
import FileResizer from "react-image-file-resizer";
import { ExclamationCircleOutlined } from "@ant-design/icons";

export const determineMimeType = (extension: any) => {
  let mimeType = "";
  switch (extension) {
    case ".xlsx":
      mimeType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
      break;
    case ".xls":
      mimeType = "application/vnd.ms-excel";
      break;
    case ".doc":
      mimeType = "application/msword";
      break;
    case ".docx":
      mimeType = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
      break;
    case ".jpg":
    case ".jpeg":
      mimeType = "image/jpeg";
      break;
    case ".msg":
      mimeType = "application/vnd.ms-outlook";
      break;
    case ".png":
      mimeType = "image/png";
      break;
    case ".pdf":
    default:
      mimeType = "application/pdf";
      break;
  }
  return mimeType;
};

export const openAttachmentFromGeneratedFile = (file: any) => {
  //const split = file.fileName.split('.')
  //const ext = split[split.length - 1]
  const mimeType = file.mimeType;
  const tempFilename = `${file.fileName}`;
  const data = `data:${mimeType};base64,${escape(file.fileData)}`;
  const link = window.document.createElement("a");
  link.href = data;
  link.download = tempFilename;
  link.target = "_blank";
  link.click();
};

export const openFileFromGeneratedFile = (file: any) => {
  const split = file.fileName.split(".");
  const ext = split[split.length - 1];
  const mimeType = file.mimeType;
  const tempFilename = `${file.displayFileName}.${ext}`;
  const data = `data:${mimeType};base64,${escape(file.fileData)}`;
  const link = window.document.createElement("a");
  link.href = data;
  link.download = tempFilename;
  link.target = "_blank";
  link.click();
};

export const openReportFromGeneratedFile = (file: any) => {
  const mimeType = determineMimeType(file.extension);
  const tempFilename = file.filename || `file${file.extension}`;
  const data = `data:${mimeType};base64,${escape(file.data)}`;
  const link = window.document.createElement("a");
  link.href = data;
  link.download = tempFilename;
  link.target = "_blank";
  link.click();
};

export const getFileName = (file: any) => {
  const split = file.fileName.split(".");
  const ext = split[split.length - 1];
  const tempFilename = `${file.displayFileName}.${ext}`;
  return tempFilename;
};

export const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export const resizeImage = (file: RcFile, fileList: RcFile[]): Promise<any> => {
  return new Promise((resolve) => {
    FileResizer.imageFileResizer(
      file,
      1920,
      1080,
      "JPEG",
      90,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
    // }
  });
};

export const showNavigationWarning = (busyEditing: boolean, onOk: () => void) => {
  if (!busyEditing) return onOk();
  else
    Modal.confirm({
      icon: <ExclamationCircleOutlined />,
      title: "Warning",
      content: (
        <>
          <Typography style={{ marginBottom: 10 }}>All changes made will be lost if you navigate away from this form.</Typography>
          <Typography>Are you sure you want to continue?</Typography>
        </>
      ),
      okText: "Continue",
      onOk,
      cancelText: "Cancel",
      onCancel() {},
    });
};

export const convertUnits = (value: number, fromUnits: string, toUnits: string, devideBy?: number) => {
  let tempValue: number = value;

  if (fromUnits === toUnits) {
    if (devideBy !== undefined && devideBy !== null && devideBy !== 0) {
      return tempValue / devideBy;
    }
    return tempValue;
  }

  //Convert back to gallons
  switch (fromUnits) {
    case "acrefeet":
      tempValue = value * 325851.0;
      break;
    case "acreinches":
      tempValue = value * 27154.285714286;
      break;
    case "milliongallons":
      tempValue = value * 1000000;
      break;
  }

  //Convert to selected units
  switch (toUnits) {
    case "acrefeet":
      tempValue = tempValue / 325851.0;
      break;
    case "acreinches":
      tempValue = tempValue / 27154.285714286;
      break;
    case "milliongallons":
      tempValue = tempValue / 1000000;
      break;
  }

  if (devideBy !== undefined && devideBy !== null && devideBy !== 0) {
    tempValue = tempValue / devideBy;
  }

  return tempValue;
};

export function formatNumber(input: string) {
  return input.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function dateDiffInDays(date1: Date, date2: Date) {
  var Difference_In_Time = date2.getTime() - date1.getTime();
  var Difference_In_Days = Difference_In_Time / (1000.0 * 3600.0 * 24.0);
  return Difference_In_Days;
}

export function formatPhoneNumber(phoneNumberString: string) {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return match[1] + "-" + match[2] + "-" + match[3];
  } else return phoneNumberString;
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function isValidGUID(value: string) {
  if (value.length > 0) {
    if (!/^(\{){0,1}[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(\}){0,1}$/.test(value)) {
      return false;
    }
  }

  return true;
}

export function formatMeterUnits(meterUnits: string) {
  switch (meterUnits) {
    case "gallons":
      return "Gallons";
    case "milliongallons":
      return "MGPY";
    case "acrefeet":
      return "Acre Feet";
    case "acreinches":
      return "Acre Inches";
    case "gpm":
      return "Gallons Per Minute";
    default:
      return "";
  }
}

export function convertNumToMonth(num: number) {
  switch (num) {
    case 1:
      return "January";
    case 2:
      return "February";
    case 3:
      return "March";
    case 4:
      return "April";
    case 5:
      return "May";
    case 6:
      return "June";
    case 7:
      return "July";
    case 8:
      return "August";
    case 9:
      return "September";
    case 10:
      return "October";
    case 11:
      return "November";
    case 12:
      return "December";
    default:
      return "";
  }
}

export function randomIntFromInterval(min: number, max: number) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export const formatReadingValue = (record: Partial<{ reading: string | number; decimals: number }>) => {
  if (record?.reading === null || record?.reading === undefined) return "-";
  if (record?.decimals) return (Number(record.reading) / Math.pow(10, record.decimals)).toFixed(record.decimals);
  else return record?.reading;
};

export const determineReadingValue = (record: Partial<{ reading: string | number; decimals: number }>) => {
  if (record?.decimals) return Number(record.reading) / Math.pow(10, record.decimals);
  else return Number(record?.reading);
};

export const displayDecimals = (value: string | number | null | undefined, decimals: number | null | undefined) => {
  if (value === null || value === undefined) return "-";
  if (decimals) return Number(value).toFixed(decimals);
  else return value;
};
