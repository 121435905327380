import { get, post } from "@/services/request";

export const listBillingReport = (request: any) => {
  return post(`aggregator/report/billing`, request);
};

export const listBillingReportBreakdown = (request: any) => {
  return post(`aggregator/report/billing/breakdown`, request);
};

export const generateBillingReport = (request: any) => {
  return post(`aggregator/report/generate/billing`, request);
};

export const generateBillingBreakdownReport = (request: any) => {
  return post(`aggregator/report/generate/billing/breakdown`, request);
};

export const getCompanyTotalUsage = (companyId: any) => {
  return get(`aggregator/report/companytotalusage/${companyId}`);
};

export const getWaterRightTotalUsage = (request: any) => {
  return post(`aggregator/report/waterrighttotalusage`, request);
};

export const getWaterRightLastReadingDashboard = (request: any) => {
  return post(`aggregator/dashboard/waterright/lastreading`, request);
};

export const getRestrictionDashboard = (request: any) => {
  return post(`aggregator/dashboard/restriction`, request);
};

export const getWellDashboard = (request: any) => {
  return post(`aggregator/dashboard/well`, request);
};

export const getWellMapDashboardList = (request: any) => {
  return post(`aggregator/dashboard/well/map/list`, request);
};

export const getWellDetailsForMap = (wellId: any) => {
  return get(`aggregator/dashboard/well/retrieve/${wellId}`);
};

export const getWellsForDashboardMap = (request: any) => {
  return post(`aggregator/dashboard/map/wells/list`, request);
};

export const getWaterRightQuantityPerCompany = () => {
  return get(`aggregator/dashboard/analytics/waterquantity/company`);
};

export const getWaterAllocationPerPermitType = (request: any) => {
  return post(`aggregator/dashboard/analytics/waterallocation/permittype`, request);
};

export const getWaterAllocationPerPriorityType = (request: any) => {
  return post(`aggregator/dashboard/analytics/waterallocation/prioritytype`, request);
};

export const getCropAllocationPerAcres = (request: any) => {
  return post(`aggregator/dashboard/analytics/cropallocation`, request);
};

export const getCompanyTotalAllocation = (request: any) => {
  return post(`aggregator/dashboard/analytics/allocation/company`, request);
};

export const getCompanyTotalAllocationPerAcres = (request: any) => {
  return post(`aggregator/dashboard/analytics/allocation/company/acres`, request);
};

export const getCompanyAverageInchesPerAcre = (request: any) => {
  return post(`aggregator/dashboard/analytics/allocation/company/averageinchesperacre`, request);
};

export const getCompanyMonthlyUsage = (request: any) => {
  return post(`aggregator/dashboard/analytics/allocation/company/totalmonthlyusage`, request);
};
