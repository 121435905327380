import useCompanyPagedList from "@/queries/useCompanyPageQuery";
import useCustomNavigate from "@/services/useCustomNavigate";
import { ReloadOutlined } from "@ant-design/icons";
import { Button, Card, Input, Space, Table } from "antd";
import { FC, useEffect, useState } from "react";
import PlaceOfUseTable from "../PlaceOfUseTable/PlaceOfUseTable";
import PrintPage from "../PrintPage/PrintPage";

const CompanyPlaceOfUseTable: FC = () => {
  const { navigate, updateQueryParams, queryParams } = useCustomNavigate();

  const [columns, setColumns] = useState<any[]>([]);
  const [searchStringValue, setSearchStringValue] = useState<string | undefined>("");
  const [searchString, setSearchString] = useState<string | undefined>("");

  const {
    companies,
    isLoading: isCompaniesLoading,
    invalidateCompanies,
  } = useCompanyPagedList({
    isActive: "true",
    pagination: {
      page: queryParams?.page ?? "1",
      pageSize: queryParams?.pageSize ?? "10",
    },
    searchString,
  });

  useEffect(() => {
    calculateColumns();
    // eslint-disable-next-line
  }, [companies]);

  const calculateColumns = () => {
    let tempColumns = [
      {
        title: "Company Name",
        dataIndex: "name",
        fixed: "left",
        render: (val: any, record: any) => val,
      },
    ];

    setColumns(tempColumns);
  };

  const handleRefresh = () => {
    setSearchString(undefined);
    updateQueryParams({ page: 1, pageSize: 10 });
    invalidateCompanies();
  };

  const renderExpandData = (record: any) => {
    return <PlaceOfUseTable companyId={record.id} />;
  };

  return (
    <Card className="companyPlacesOfUseList" id="companyPlacesOfUseList" title="Companies">
      <PrintPage
        content={
          <div>
            <Space direction="vertical" style={{ width: "100%" }}>
              <Space style={{ paddingBottom: 10 }}>
                <Input.Search
                  disabled={isCompaniesLoading}
                  placeholder="Search companies"
                  onSearch={(val) => {
                    setSearchString(val);
                    updateQueryParams({ page: 1, pageSize: 10 });
                  }}
                  onChange={(val) => setSearchStringValue(val.currentTarget.value)}
                  value={searchStringValue}
                  style={{ width: 400 }}
                  allowClear
                />
                <Button icon={<ReloadOutlined />} disabled={isCompaniesLoading} onClick={handleRefresh}>
                  Refresh
                </Button>
              </Space>
              <Table
                pagination={{
                  pageSize: queryParams?.pageSize ? Number(queryParams?.pageSize) : 10,
                  current: queryParams?.page ? Number(queryParams?.page) : 1,
                  total: companies?.pagination?.totalItemCount,
                }}
                onChange={(pagination) => {
                  updateQueryParams({
                    page: pagination.current,
                    pageSize: pagination.pageSize,
                  });
                }}
                rowKey={(row: any) => row.id}
                expandable={{
                  expandedRowRender: (record: any, index: number, indent: number, expanded: boolean) => (expanded ? renderExpandData(record) : null),
                }}
                loading={isCompaniesLoading}
                columns={columns}
                dataSource={
                  companies?.list?.sort((a: any, b: any) =>
                    a?.name?.localeCompare(b.name, "en", {
                      numeric: true,
                      sensitivity: "base",
                    })
                  ) || []
                }
                size="small"
              />
            </Space>
          </div>
        }
      />
    </Card>
  );
};

export default CompanyPlaceOfUseTable;
