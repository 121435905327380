import { PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import { Button, Card, Input, Space, Switch, Table } from "antd";
import CompanyLabel from "@/components/Lookups/CompanyLabel";
import PrintPage from "@/components/PrintPage/PrintPage";
import StatusTag from "@/components/StatusTag/StatusTag";
import { routes } from "@/configs";
import { UserRole } from "@/dtos/user.dto";
import FieldView from "@/pages/FieldView/FieldView";
import { FC, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import useCustomNavigate from "@/services/useCustomNavigate";
import { RootState, useAppDispatch } from "@/stores";
import { addBreadcrumb } from "@/stores/breadcrumbs.store";
import useFieldPagedList from "@/queries/useFieldPageQuery";
import AddSelectCompanyModal, { AddSelectCompanyModalRef, EnitityName } from "../AddSelectCompanyModal/AddSelectCompanyModal";

import "./FieldTable.scss";
interface Props {
  style?: React.CSSProperties;
  showHeader: boolean;
  fieldIds?: any[];
  showAddField: boolean;
  trackTableState?: boolean;
  isChild?: boolean;
}

const FieldTable: FC<Props> = (props) => {
  const { style, showHeader, fieldIds, showAddField, trackTableState = false, isChild } = props;

  const dispatch = useAppDispatch();

  const { selectedCompanyId, selectedCompany } = useSelector((state: RootState) => state.company);

  const { navigate, updateQueryParams, queryParams } = useCustomNavigate();

  const [isActive, setIsActive] = useState<boolean | null>(true);
  const [columns, setColumns] = useState<any[]>([]);
  const [searchStringValue, setSearchStringValue] = useState<string | undefined>("");
  const [searchString, setSearchString] = useState<string | undefined>("");

  const [queryParamState, setQueryParamState] = useState<any>({ page: 1, pageSize: 10 });

  const {
    fields,
    isLoading: isFieldsLoading,
    invalidateFields,
  } = useFieldPagedList({
    fieldIds,
    isActive,
    pagination: {
      page: isChild ? queryParamState.page : queryParams?.page ?? "1",
      pageSize: isChild ? queryParamState.pageSize : queryParams?.pageSize ?? "10",
    },
    searchString,
  });

  useEffect(() => {
    calculateColumns();
    // eslint-disable-next-line
  }, [fields]);

  const calculateColumns = () => {
    let tempColumns = [
      {
        title: "Field Name",
        key: "name",
        dataIndex: "name",
        fixed: "left",
        width: 500,
        render: (val: any, record: any) => (
          <Button style={{ paddingLeft: 0 }} type="link" onClick={() => handleViewField(record)}>
            {val}
          </Button>
        ),
      },
      {
        title: "Status",
        key: "status",
        dataIndex: "status",
        render: (val: any, record: any) => <StatusTag status={val} />,
      },
      {
        title: "Actions",
        key: "action",
        width: 80,
        align: "center",
        render: (value: any, record: any) => (
          <>
            <Button style={{ paddingLeft: 0 }} type="link" onClick={() => handleViewField(record)}>
              View
            </Button>
          </>
        ),
      },
    ];

    if (!selectedCompanyId) {
      tempColumns.splice(2, 0, {
        title: "Company",
        key: "companyId",
        dataIndex: "companyId",
        render: (val: any, record: any) => <CompanyLabel companyId={val} />,
      });
    }

    setColumns(tempColumns);
  };

  const handleNavigateToAdd = () => {
    dispatch(
      addBreadcrumb({
        type: `Field / Add`,
        url: `field/add`,
      })
    );
    navigate(routes.fieldAdd);
  };

  const handleAddField = () => {
    if (selectedCompany?._companyId === "all") {
      addModalRef.current?.open();
      return;
    } else {
      handleNavigateToAdd();
    }
  };

  const handleViewField = (record: any) => {
    dispatch(
      addBreadcrumb({
        type: `${record.name}`,
        url: `field/${record.id}/view`,
      })
    );
    navigate(routes.fieldView, { id: record.id });
  };

  const handleRefresh = () => {
    setSearchString("");
    setSearchStringValue("");
    if (isChild) {
      setQueryParamState({ page: 1, pageSize: 10 });
    } else updateQueryParams({ page: 1, pageSize: 10 });
    invalidateFields();
  };

  const renderExpandData = (record: any) => {
    return <FieldView showHeader={false} overrideId={record.id} />;
  };

  const handleOnChange = (value: boolean) => {
    if (!value) setIsActive(null);
    else setIsActive(true);
  };

  const addModalRef = useRef<AddSelectCompanyModalRef>(null);

  const renderContent = () => {
    return (
      <div>
        <Space direction="vertical" style={{ width: "100%" }}>
          {showHeader && (
            <Space style={{ paddingBottom: 10 }}>
              <Input.Search
                disabled={isFieldsLoading}
                placeholder="Search fields"
                onSearch={(val) => {
                  setSearchString(val);
                  if (isChild) {
                    setQueryParamState({ page: 1, pageSize: 10 });
                  } else updateQueryParams({ page: 1, pageSize: 10 });
                }}
                onChange={(val) => setSearchStringValue(val.currentTarget.value)}
                value={searchStringValue}
                style={{ width: 400 }}
                allowClear
              />
              <Button icon={<ReloadOutlined />} disabled={isFieldsLoading} onClick={handleRefresh}>
                Refresh
              </Button>
              <Switch defaultChecked onChange={handleOnChange} checkedChildren="Active Only" unCheckedChildren="All" />
            </Space>
          )}
          <Table
            pagination={
              trackTableState
                ? {
                    pageSize: queryParams?.pageSize ? Number(queryParams?.pageSize) : 10,
                    current: queryParams?.page ? Number(queryParams?.page) : 1,
                    total: fields?.pagination?.totalItemCount,
                  }
                : undefined
            }
            rowKey={(row: any) => row.id}
            loading={isFieldsLoading}
            columns={columns}
            dataSource={fields?.list ?? []}
            size="small"
            expandable={{
              expandedRowRender: (record: any) => renderExpandData(record),
            }}
            onChange={(pagination) => {
              trackTableState &&
                updateQueryParams({
                  page: pagination.current,
                  pageSize: pagination.pageSize,
                });

              if (isChild) {
                setQueryParamState({
                  page: pagination.current,
                  pageSize: pagination.pageSize,
                });
              }
            }}
          />
        </Space>
      </div>
    );
  };

  return (
    <>
      <Card
        style={style}
        className="fieldList"
        id="fieldList"
        title="Fields"
        extra={
          showAddField && (selectedCompany?.userRole === UserRole.admin.label || selectedCompany?._companyId === "all") ? (
            <>
              <Button onClick={handleAddField} type="primary" icon={<PlusOutlined />}>
                Add Field
              </Button>
            </>
          ) : (
            <></>
          )
        }
      >
        {showAddField ? <PrintPage content={renderContent()} /> : renderContent()}
      </Card>
      <AddSelectCompanyModal entityName={EnitityName.Field} ref={addModalRef} onSubmit={handleNavigateToAdd} />
    </>
  );
};

export default FieldTable;
