export enum UserInviteStatus {
  Invited = 0,
  Accepted = 1,
}

export const UserRole: any = {
  admin: {
    label: "Administrator",
    description: "Can view, edit and update all company data and settings",
    value: "admin",
  },
  waterreader: {
    label: "Water Reader",
    description: "Can only view assigned water rights and record water meter readings for the water rights",
    value: "waterreader",
  },
  viewer: {
    label: "View Only",
    description: "Can view only assigned water rights",
    value: "viewer",
  },
};

export const IdentityRole: any = {
  MasterAdministrator: {
    label: "MasterAdministrator",
  },
};

//Testing tagging
