import { Card, ConfigProvider, Descriptions, Image, Space, Table, Typography } from "antd";
import { ColumnType } from "antd/es/table";
import { getWaterRightTotalUsage } from "@/apis/aggregator.api";
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title as TitleChart, Tooltip } from "chart.js";
import PrintPage from "@/components/PrintPage/PrintPage";
import UnitsConverter from "@/components/UnitsConverter/UnitsConverter";
import { FC, useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { convertUnits, formatMeterUnits } from "@/services/utils";

const { Title } = Typography;

interface Props {
  reportConfig: config;
}

type config = {
  years: number[];
  generatedDate: any;
  waterRightIds: any[];
  allWaterRightIds: any[];
  waterRights: string;
};

const customizeRenderEmpty = () => <div style={{ textAlign: "center" }}>No Data</div>;

const backgroundColor = ["rgb(128,128,128)", "rgba(2,3,129)"];

ChartJS.register(CategoryScale, LinearScale, BarElement, TitleChart, Tooltip, Legend);

const GeneratedWaterRightTotalUsageReport: FC<Props> = (props) => {
  const { reportConfig } = props;

  const { selectedCompanyId, selectedCompany } = useSelector((state: any) => state.company);

  const [loading, setLoading] = useState<boolean>(false);
  const [waterRightTotalUsage, setWaterRightTotalUsage] = useState<any[]>([]);

  useEffect(() => {
    if (reportConfig) retrieveWaterRightTotalUsage();
    // eslint-disable-next-line
  }, [reportConfig]);

  const retrieveWaterRightTotalUsage = async () => {
    setLoading(true);

    const response = await getWaterRightTotalUsage({
      waterRightIds: reportConfig.waterRightIds ?? reportConfig.allWaterRightIds,
    });
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        if (data.value?.length > 0) {
          let finalItems: any[] = [];
          const filteredValues = data.value.filter((usage: any) => usage.year >= reportConfig.years[0] && usage.year <= reportConfig.years[1]);

          const waterRightIds = reportConfig.waterRightIds ?? reportConfig.allWaterRightIds;

          waterRightIds.forEach((id) => {
            const waterRights = filteredValues.filter((usage: any) => usage?.waterRightId === id);

            if (waterRights.length > 0) finalItems.push(waterRights);
          });

          setWaterRightTotalUsage(finalItems);
        } else setWaterRightTotalUsage([]);
      }
    }

    setLoading(false);

    setLoading(false);
  };

  const generateCard = (waterRightUsage: any) => {
    const fileNumber = waterRightUsage[0]?.fileNumber;
    const pdiv = waterRightUsage[0]?.pdiv;
    const cin = waterRightUsage[0]?.cin;
    const meterUnits = formatMeterUnits(selectedCompany?.settings?.metric);
    let barData: any[] = [];

    const columns: ColumnType<any>[] = [
      {
        title: "Year",
        key: "year",
        dataIndex: "year",
        width: 300,
        render: (val: any) => val ?? "-",
      },
      {
        title: (
          <>
            Total Authorized Quantity
            <br />
            <small>Gallons</small>
            <br />
            <small>Acre Feet</small>
          </>
        ),
        key: "totalAvailableQuantity",
        dataIndex: "totalAvailableQuantity",
        align: "right",
        render: (val: any) => (
          <div>
            <UnitsConverter fromUnits="gallons" toUnits="gallons" value={val} overrideDecimalPlaces={2} /> <br />
            <UnitsConverter fromUnits="gallons" toUnits="acrefeet" value={val} overrideDecimalPlaces={2} />
          </div>
        ),
      },
      {
        title: (
          <>
            Total Usage
            <br />
            <small>Gallons</small>
            <br />
            <small>Acre Feet</small>
          </>
        ),
        key: "totalUsage",
        dataIndex: "totalUsage",
        align: "right",
        render: (val: any) => (
          <div>
            <UnitsConverter fromUnits="gallons" toUnits="gallons" value={val} overrideDecimalPlaces={2} /> <br />
            <UnitsConverter fromUnits="gallons" toUnits="acrefeet" value={val} overrideDecimalPlaces={2} />
          </div>
        ),
      },
      {
        title: "Percentage Remaining",
        key: "percentageRemaining",
        dataIndex: "percentageRemaining",
        width: 300,
        align: "right",
        render: (val: any, record: any) => <>{(val * 100).toFixed(2)} %</>,
      },
    ];

    const chartOptions: any = {
      plugins: {
        legend: {
          position: "bottom",
          padding: 20,
          align: "center",
        },
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
          title: {
            display: true,
            text: "Year",
          },
        },
        y: {
          stacked: true,
          title: {
            display: true,
            text: meterUnits,
            padding: 25,
          },
        },
      },
    };

    const labels = waterRightUsage?.map((usageForWaterRight: any) => usageForWaterRight?.year);
    const dataLabels = ["Total Authorized Quantity", "Total Usage"];

    for (let index = 0; index < 2; index++) {
      if (index === 0) {
        barData.push({
          label: dataLabels[index],
          data: waterRightUsage?.map((usage: any) => convertUnits(usage?.totalAvailableQuantity, "gallons", selectedCompany?.settings?.metric, undefined)),
          backgroundColor: backgroundColor[index],
          stack: "Stack 1",
        });
      }

      if (index === 1) {
        barData.push({
          label: dataLabels[index],
          data: waterRightUsage?.map((usage: any) => convertUnits(usage?.totalUsage, "gallons", selectedCompany?.settings?.metric, undefined)),
          backgroundColor: backgroundColor[index],
          stack: "Stack 0",
        });
      }
    }

    const dataSets = {
      labels: labels,
      datasets: barData,
    };

    return (
      <>
        <Card
          title={
            <>
              Water Right - {fileNumber} <br />
              <small>PDIV: {pdiv ?? "-"}</small> <br />
              <small>CIN: {cin ?? "-"}</small>
            </>
          }
        >
          <Bar
            style={{
              width: "100%",
              height: "100%",
              maxWidth: 1000,
              maxHeight: 800,
              marginLeft: "auto",
              marginRight: "auto",
            }}
            options={chartOptions}
            data={dataSets}
          />
          <ConfigProvider renderEmpty={customizeRenderEmpty}>
            <Table
              rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
              rowKey={(row: any) => row.companyId}
              loading={loading}
              columns={columns}
              dataSource={waterRightUsage ?? []}
              size="small"
              pagination={false}
            />
          </ConfigProvider>
        </Card>
        <div className="page-break" />
      </>
    );
  };

  return (
    <PrintPage
      content={
        <Card
          id="generatedSummaryUsageReport"
          className="remove-border-and-padding-on-print"
          title={
            <div>
              <Space size="middle">
                <Image
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                    textAlign: "center",
                    marginLeft: "auto",
                    marginRight: "auto",
                    alignContent: "center",
                    maxHeight: 200,
                    maxWidth: 200,
                    borderRadius: 10,
                    objectFit: "contain",
                  }}
                  src="/logo.png"
                  preview={false}
                />
                <Space direction="vertical" size="small" style={{ display: "flex" }}>
                  <Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>{`Water Right Total Usage Report`}</Title>
                  <Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>{`Generated On ${reportConfig.generatedDate}`}</Title>
                </Space>
              </Space>
            </div>
          }
          loading={loading}
        >
          {waterRightTotalUsage &&
            waterRightTotalUsage?.map((usage: any) => {
              return generateCard(usage);
            })}
          {reportConfig && (
            <Card title="Report Configuration" bodyStyle={{ padding: 10 }}>
              <Descriptions bordered size="small" column={1} labelStyle={{ width: 150 }} className="removeBoxShadow removeMargin">
                <Descriptions.Item label="Generated At">{reportConfig.generatedDate}</Descriptions.Item>
                <Descriptions.Item label="Year Range">
                  {reportConfig.years[0]} - {reportConfig.years[1]}
                </Descriptions.Item>
                <Descriptions.Item label="Water Rights">{reportConfig?.waterRights === "" ? "-" : reportConfig?.waterRights}</Descriptions.Item>
              </Descriptions>
            </Card>
          )}
        </Card>
      }
      isReport
    />
  );
};

export default GeneratedWaterRightTotalUsageReport;
