import { MinusOutlined, PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import { Button, Card, ConfigProvider, Input, Space, Table, Tag, message } from "antd";
import { addNotBillableEntity, updateNotBillableEntity } from "@/apis/identity.api";
import { getBillableEntities } from "@/apis/waterright.api";
import { FC, Ref, forwardRef, useEffect, useImperativeHandle, useMemo, useState } from "react";

export type AdminBillingExpandRenderRef = {
  handleRefresh: () => void;
};

export type AdminBillingExpandRenderProps = {
  record: any;
  lastRefreshed?: string;
};

const customizeRenderEmpty = () => <div style={{ textAlign: "center" }}>No Data</div>;

const AdminBillingExpandRender = (props: AdminBillingExpandRenderProps, ref: Ref<AdminBillingExpandRenderRef | undefined>) => {
  const { record, lastRefreshed } = props;

  const [loadingEntities, setLoadingEntities] = useState<boolean>(false);

  const [tempEnities, setEntities] = useState<any[]>([]);
  const [columns, setColumns] = useState<any[]>([]);

  const [searchString, setSearchString] = useState<string | undefined>(undefined);
  const [searchInput, setSearchInput] = useState<string | undefined>(undefined);

  const [expandAll, setExpandAll] = useState<boolean>(false);
  const [expandedEntities, setExpandedEntities] = useState<any>();

  const entities: any[] = useMemo(() => {
    let companiesForUser = tempEnities.filter((entity) => entity?.parentId === record.entityId);

    const filteredCompanyIds = new Set<string>();
    const filteredWaterRightIds = new Set<string>();
    const filteredWellIds = new Set<string>();

    const tempSearchString = searchString?.toLowerCase() ?? "";

    companiesForUser?.map((company: any) => {
      if (company.name.toLowerCase().includes(tempSearchString)) filteredCompanyIds.add(company.entityId);

      const waterRightsForCompany = tempEnities.filter((entity) => entity?.parentId === company.entityId);
      waterRightsForCompany.length > 0 ? (company.children = waterRightsForCompany) : (company.children = null);

      company.children?.map((waterRight: any) => {
        if (waterRight.name.toLowerCase().includes(tempSearchString)) {
          filteredCompanyIds.add(company.entityId);
          filteredWaterRightIds.add(waterRight.entityId);
        }

        const wellsForWaterRight = tempEnities.filter((entity) => entity?.parentId === waterRight.entityId);
        wellsForWaterRight.length > 0 ? (waterRight.children = wellsForWaterRight) : (waterRight.children = null);

        waterRight.children?.map((well: any) => {
          if (well.name.toLowerCase().includes(tempSearchString)) {
            filteredCompanyIds.add(company.entityId);
            filteredWaterRightIds.add(waterRight.entityId);
            filteredWellIds.add(well.entityId);
          }
        });
      });
    });

    if (searchString && searchString.length > 0) {
      companiesForUser = companiesForUser.filter((company) => filteredCompanyIds.has(company.entityId));

      if (filteredWellIds.size > 0) {
        companiesForUser.map((company) => {
          company.children = company.children?.filter((waterRight: { entityId: string }) => filteredWaterRightIds.has(waterRight.entityId));
          company.children?.map((waterRight: { children: any[] }) => {
            waterRight.children = waterRight.children?.filter((well: { entityId: string }) => filteredWellIds.has(well.entityId));
          });
        });
      }

      if (filteredWellIds.size === 0 && filteredWaterRightIds.size > 0) {
        companiesForUser.map((company) => {
          company.children = company.children?.filter((waterRight: { entityId: string }) => filteredWaterRightIds.has(waterRight.entityId));
        });
      }
    }

    return companiesForUser;
  }, [tempEnities, searchString]);

  useImperativeHandle(ref, () => ({
    handleRefresh,
  }));

  useEffect(() => {
    refreshChildEntities();
  }, [lastRefreshed]);

  useEffect(() => {
    calculateColumns();
  }, [entities]);

  const renderEntity = (entity: string) => {
    switch (entity) {
      case "waterright":
        return "Water Right";
      case "well":
        return "Well";
      case "company":
        return "Company";
      default:
        return "";
    }
  };

  const updateBillableEntity = async (record: any) => {
    const response = await updateNotBillableEntity({ id: record.entityId });
    if (response.ok) {
      message.success("Successfully marked entity as billable");
      handleRefresh();
    } else message.error("Failed to mark entity as billable");
  };

  const addNotBillableEntityEvent = async (record: any) => {
    const response = await addNotBillableEntity({
      entityId: record.entityId,
      entityType: record.entityType,
    });
    if (response.ok) {
      message.success("Successfully marked entity as not billable");
      handleRefresh();
    } else message.error("Failed to marked entity as not billable");
  };

  const refreshChildEntities = async () => {
    setLoadingEntities(true);

    const response = await getBillableEntities({ user: false });
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) setEntities(data.value);
    }

    setLoadingEntities(false);
  };

  const calculateColumns = () => {
    let childColumns: any[] = [
      {
        title: "Water Right / File Number / Name",
        key: "name",
        dataIndex: "name",
        render: (val: any, record: any) => val,
      },
      {
        title: "Type",
        key: "entityType",
        dataIndex: "entityType",
        render: (val: any, record: any) => renderEntity(val),
      },
      {
        title: "Billable",
        key: "billable",
        dataIndex: "billable",
        width: 50,
        render: (val: any, record: any) => (val === true ? <Tag color="green">Yes</Tag> : <Tag color="red">No</Tag>),
      },
      {
        title: "Actions",
        width: 75,
        render: (val: any, record: any) => (
          <Button style={{ paddingLeft: 0 }} type="link" onClick={() => (record.billable ? addNotBillableEntityEvent(record) : updateBillableEntity(record))}>
            {record.billable ? "Mark as NOT billable" : "Mark as billable"}
          </Button>
        ),
      },
    ];

    setColumns(childColumns);
  };

  const onSearch = (searchString: any) => {
    setSearchString(searchString);
  };

  const onSearchChange = (searchString: any) => {
    setSearchInput(searchString);
  };

  const handleRefresh = () => {
    setSearchString(undefined);
    refreshChildEntities();
  };

  const handleExpandAll = () => {
    const expandedEntities: string[] = [];

    entities?.map((company: any) => {
      expandedEntities.push(company.entityId);
      company.children?.map((waterRight: any) => {
        expandedEntities.push(waterRight.entityId);
      });
    });

    setExpandedEntities(expandedEntities);
    setExpandAll(true);
  };

  const handleCollapseAll = () => {
    setExpandedEntities([]);
    setExpandAll(false);
  };

  return (
    <Card title="Entities">
      <ConfigProvider renderEmpty={customizeRenderEmpty}>
        <Space direction="vertical" style={{ width: "100%" }}>
          <Space style={{ paddingBottom: 10 }}>
            <Input.Search
              disabled={loadingEntities}
              placeholder="Search entities"
              onSearch={(searchString) => {
                onSearch(searchString);
              }}
              onChange={(val) => onSearchChange(val.currentTarget.value)}
              value={searchInput}
              style={{ width: 400 }}
              allowClear
            />
            <Button disabled={loadingEntities} onClick={handleRefresh} icon={<ReloadOutlined />}>
              Refresh
            </Button>
            <Button disabled={loadingEntities || expandAll} onClick={handleExpandAll} type="text" icon={<PlusOutlined />}>
              Expand All
            </Button>
            <Button disabled={loadingEntities} onClick={handleCollapseAll} type="text" icon={<MinusOutlined />}>
              Collapse All
            </Button>
          </Space>
        </Space>
        <Table
          rowKey={(row: any) => row.entityId}
          columns={columns}
          dataSource={entities ?? []}
          size="small"
          pagination={false}
          loading={loadingEntities}
          expandable={{
            expandedRowKeys: expandedEntities,
            onExpand: (expanded, record) => {
              if (expanded) setExpandedEntities([...(expandedEntities ?? []), record.entityId]);
              else {
                setExpandedEntities((expandedEntities ?? []).filter((id: string) => id !== record.entityId));
                setExpandAll(false);
              }
            },
            onExpandedRowsChange: setExpandedEntities,
          }}
        />
      </ConfigProvider>
    </Card>
  );
};

export default forwardRef(AdminBillingExpandRender);
