import { CloseOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { Button, Card, Divider, Form, Select, Space, Switch, Tag } from "antd";
import { useWatch } from "antd/es/form/Form";
import { getFields } from "@/apis/field.api";
import { getGroupings } from "@/apis/grouping.api";
import { getRestrictions } from "@/apis/restriction.api";
import { getWaterRights } from "@/apis/waterright.api";
import { getWells } from "@/apis/well.api";
import { StatusTag, WaterRightSelector, WaterRightTag } from "@/components";
import { constants } from "@/configs";
import dayjs from "dayjs";
import type { CustomTagProps } from "rc-select/lib/BaseSelect";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import GeneratedAllConfigurationReport from "./GeneratedAllConfigurationReport";
import { getStocks } from "@/apis/stock.api";

const AllConfigurationReport: FC = () => {
  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const { lookups } = useSelector((state: any) => state.lookups);

  const [form] = Form.useForm();

  const [loadingStock, setLoadingStock] = useState<boolean>(false);
  const [loadingWaterRights, setLoadingWaterRights] = useState<boolean>(false);
  const [loadingRestrictions, setLoadingRestrictions] = useState<boolean>(false);
  const [loadingWells, setLoadingWells] = useState<boolean>(false);
  const [loadingFields, setLoadingFields] = useState<boolean>(false);
  const [loadingGroupings, setLoadingGroupings] = useState<boolean>(false);
  const [generatingReport, setGeneratingReport] = useState<boolean>(false);
  const [restrictionIsActive, setRestrictionIsActive] = useState<boolean | null>(true);
  const [wellIsActive, setWellIsActive] = useState<boolean | null>(true);
  const [fieldIsActive, setFieldIsActive] = useState<boolean | null>(true);

  const [stock, setStock] = useState<any[]>([]);
  const [waterRights, setWaterRights] = useState<any[]>([]);
  const [restrictions, setRestrictions] = useState<any[]>([]);
  const [wells, setWells] = useState<any[]>([]);
  const [fields, setFields] = useState<any[]>([]);
  const [groupings, setGroupings] = useState<any[]>([]);

  const [reportConfig, setReportConfig] = useState<any>(undefined);

  const wellIds: any[] = useWatch("wellIds", form);
  const stockIds: any[] = useWatch("stockIds", form);
  const groupingIds: any[] = useWatch("groupingIds", form);
  const fieldIds: any[] = useWatch("fieldIds", form);
  const waterRightIds: any[] = useWatch("waterRightIds", form);
  const restrictionIds: any[] = useWatch("restrictionIds", form);
  const permitTypes: any[] = useWatch("permitTypes", form);
  const priorityTypes: any[] = useWatch("priorityTypes", form);

  useEffect(() => {
    handleReset();
    // eslint-disable-next-line
  }, [selectedCompanyId]);

  useEffect(() => {
    if (selectedCompanyId) {
      refreshWaterRights();
      refreshGroupings();
      refreshStock();
    }
    // eslint-disable-next-line
  }, [selectedCompanyId]);

  useEffect(() => {
    if (selectedCompanyId) {
      refreshWells();
    }
    // eslint-disable-next-line
  }, [selectedCompanyId, wellIsActive]);

  useEffect(() => {
    if (selectedCompanyId) {
      refreshFields();
    }
    // eslint-disable-next-line
  }, [selectedCompanyId, fieldIsActive]);

  useEffect(() => {
    if (selectedCompanyId) {
      refreshRestrictions();
    }
    // eslint-disable-next-line
  }, [selectedCompanyId, restrictionIsActive]);

  useEffect(() => {
    if (generatingReport) setTimeout(() => setGeneratingReport(false), 500);
    // eslint-disable-next-line
  }, [generatingReport]);

  const refreshStock = async () => {
    setLoadingStock(true);
    const response = await getStocks({ companyId: selectedCompanyId });
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setStock(data.value);
      }
    }
    setLoadingStock(false);
  };

  const refreshFields = async () => {
    setLoadingFields(true);

    const request = { companyId: selectedCompanyId, isActive: fieldIsActive };

    const response = await getFields(request);
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setFields(data.value);
      }
    }
    setLoadingFields(false);
  };

  const refreshWells = async () => {
    setLoadingWells(true);

    const request = {
      isActive: wellIsActive,
      companyId: selectedCompanyId,
    };

    const response = await getWells(request);
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setWells(data.value);
      }
    }
    setLoadingWells(false);
  };

  const refreshGroupings = async () => {
    setLoadingGroupings(true);
    const response = await getGroupings(selectedCompanyId, null);
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setGroupings(data.value);
      }
    }
    setLoadingGroupings(false);
  };

  const refreshWaterRights = async () => {
    setLoadingWaterRights(true);
    const request = { companyId: selectedCompanyId };
    const response = await getWaterRights(request);
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) setWaterRights(data.value);
    }
    setLoadingWaterRights(false);
  };

  const refreshRestrictions = async () => {
    setLoadingRestrictions(true);
    const request = {
      companyId: selectedCompanyId,
      isActive: restrictionIsActive,
    };
    const response: any = await getRestrictions(request);
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) setRestrictions(data.value);
    }
    setLoadingRestrictions(false);
  };

  const handleGenerateReport = () => {
    setGeneratingReport(true);

    // Water Right Ids
    const restrictionWRIds = (restrictions ?? []).filter((restriction: any) => (restrictionIds ?? []).includes(restriction.id)).map((restriction: any) => restriction.waterRightIds ?? []);
    const tempWrIdsForStock = (stock ?? []).filter((stock) => (stockIds ?? []).includes(stock.id)).map((stock) => stock.waterRightIds ?? []);
    const tempWRsForPermitTypes = waterRights.filter((waterRight) => permitTypes && permitTypes.includes(waterRight.permitType)).map((waterRight) => waterRight.id);
    const tempWRsForPriorityTypes = waterRights.filter((waterRight) => priorityTypes && priorityTypes.includes(waterRight.priorityType)).map((waterRight) => waterRight.id);
    const tempWRIds = [...(waterRightIds ?? []), ...restrictionWRIds.flat(), ...tempWrIdsForStock.flat()];

    tempWRsForPermitTypes.forEach((tempWr) => {
      const wr = tempWRIds.find((waterRight) => waterRight === tempWr);
      if (!wr) tempWRIds.push(tempWr);
    });

    tempWRsForPriorityTypes.forEach((tempWr) => {
      const wr = tempWRIds.find((waterRight) => waterRight === tempWr);
      if (!wr) tempWRIds.push(tempWr);
    });

    // Well Ids
    const wellsForWaterRight = wells.filter((well) => tempWRIds.includes(well.waterRightId)).map((well) => well.id);
    const tempWells = [...(wellIds ?? []), ...wellsForWaterRight.flat()];
    const waterRightsFromWell = wells.filter((well) => tempWells.includes(well.id)).map((well) => well.waterRightId);
    waterRightsFromWell.forEach((wr) => {
      const wrToFind = tempWRIds.find((waterRight) => waterRight === wr);
      if (!wrToFind) tempWRIds.push(wr);
    });

    // Field ids
    const fieldIdsFromWell = wells.filter((well) => tempWells.includes(well.id)).map((well) => well.fieldId);
    const fieldsFromWell = fields.filter((field) => fieldIdsFromWell.includes(field.id)).map((field) => field.id);
    const tempFields = [...(fieldIds ?? []), ...fieldsFromWell.flat()];
    // WELL
    const wellsForField = wells.filter((well) => tempFields.includes(well.fieldId)).map((well) => well.id);
    wellsForField.forEach((well) => {
      const wellToFind = tempWells.find((tempWell) => tempWell === well);
      if (!wellToFind) tempWells.push(well);
    });
    // WR
    const waterRightWellForField = wells.filter((well) => tempFields.includes(well.fieldId)).map((well) => well.waterRightId);
    waterRightWellForField.forEach((wr) => {
      const wrToFind = tempWRIds.find((waterRight) => waterRight === wr);
      if (!wrToFind) tempWRIds.push(wr);
    });

    // Restriction Ids
    const tempRestrictionIds = (restrictions ?? []).filter((restriction: any) => (restriction.waterRightIds ?? []).some((x: any) => tempWRIds.includes(x))).map((restriction: any) => restriction.id);

    // Grouping Ids
    const groupingsFromWaterRights = (groupings ?? []).filter((grouping: any) => (grouping.waterRights ?? []).some((x: any) => waterRights.includes(x))).map((grouping: any) => grouping.id);
    const tempGroupings = [...(groupingIds ?? []), ...groupingsFromWaterRights.flat()];
    // WR
    const groupingWaterRights = groupings.filter((grouping) => grouping.waterRights.length > 0 && tempGroupings.includes(grouping.id)).map((grouping) => grouping.waterRights);
    groupingWaterRights.forEach((groupingWrs) => {
      groupingWrs.forEach((wr: any) => {
        const wrToFind = tempWRIds.find((waterRight) => waterRight === wr);
        if (!wrToFind) tempWRIds.push(wr);
      });
    });
    // Well
    const wellsForGrouping = wells.filter((well) => tempWRIds.includes(well.waterRightId)).map((well) => well.id);
    wellsForGrouping.forEach((well) => {
      const wellToFind = tempWells.find((tempWell) => tempWell === well);
      if (!wellToFind) tempWells.push(well);
    });
    // Field
    const fieldsForWellGrouping = wells.filter((well) => tempWells.includes(well.id)).map((well) => well.fieldId);
    fieldsForWellGrouping.forEach((fieldForGrouping) => {
      const fieldToFind = tempFields.find((field) => field === fieldForGrouping);
      if (!fieldToFind) tempFields.push(fieldForGrouping);
    });
    // Restrictions
    const tempGroupingRestrictionIds = (restrictions ?? [])
      .filter((restriction: any) => (restriction.waterRightIds ?? []).some((x: any) => tempWRIds.includes(x)))
      .map((restriction: any) => restriction.id);
    tempGroupingRestrictionIds.forEach((restriction) => {
      const restrictionToFind = tempRestrictionIds.find((tempRestriction) => tempRestriction === restriction);
      if (!restrictionToFind) tempRestrictionIds.push(restriction);
    });
    // Stock
    // Grouping Ids
    const stockFromWaterRights = (stock ?? []).filter((stock: any) => (stock.waterRightIds ?? []).some((x: any) => waterRights.includes(x))).map((stock: any) => stock.id);
    const tempStock = [...(stockIds ?? []), ...stockFromWaterRights.flat()];

    const permitTypeLookups = lookups?.find((lookup: any) => lookup.map === "permittype");
    const priorityTypeLookups = lookups?.find((lookup: any) => lookup.map === "prioritytype");

    setReportConfig({
      selectedGroupingNames: groupings
        .filter((grouping) => groupingIds?.includes(grouping.id))
        .map((grouping) => grouping.name)
        .join(", "),
      groupingIds: tempGroupings,
      groupings: groupings
        .filter((grouping: any) => tempGroupings?.includes(grouping.id))
        .map((grouping: any) => grouping.name)
        .join(", "),
      selectedFieldNames: fields
        .filter((field) => fieldIds?.includes(field.id))
        .map((field) => field.name)
        .join(", "),
      fieldIds: tempFields,
      fields: fields
        .filter((field: any) => tempFields?.includes(field.id))
        .map((field: any) => field.name)
        .join(", "),
      selectedWellNames: wells
        .filter((well) => wellIds?.includes(well.id))
        .map((well) => well.name)
        .join(", "),
      wellIds: tempWells,
      wells: wells
        .filter((well: any) => tempWells?.includes(well.id))
        .map((well: any) => well.name)
        .join(", "),
      selectedWaterRightNames: waterRights
        .filter((waterRight) => waterRightIds?.includes(waterRight.id))
        .map((waterRight) => waterRight.fileNumber)
        .join(", "),
      waterRightIds: tempWRIds,
      waterRights: waterRights
        .filter((waterRight: any) => tempWRIds?.includes(waterRight.id))
        .map((waterRight: any) => waterRight.fileNumber)
        .join(", "),
      selectedRestrictionNames: restrictions
        .filter((restriction) => restrictionIds?.includes(restriction.id))
        .map((restriction) => restriction.name)
        .join(", "),
      restrictionIds: tempRestrictionIds,
      restrictions: restrictions
        .filter((restriction: any) => tempRestrictionIds?.includes(restriction.id))
        .map((restriction: any) => restriction.name)
        .join(", "),
      stockIds: stockIds,
      stock: stock
        .filter((stock: any) => tempStock?.includes(stock.id))
        .map((stock: any) => stock.name)
        .join(", "),
      permitTypes: permitTypeLookups?.options
        ?.filter((option: any) => permitTypes?.includes(option.value))
        ?.sort((a: any, b: any) =>
          a?.name?.localeCompare(b?.name, "en", {
            numeric: true,
            sensitivity: "base",
          })
        )
        ?.map((option: any) => option.name)
        .join(", "),
      priorityTypes: priorityTypeLookups?.options
        ?.filter((option: any) => priorityTypes?.includes(option.value))
        ?.sort((a: any, b: any) =>
          a?.name?.localeCompare(b?.name, "en", {
            numeric: true,
            sensitivity: "base",
          })
        )
        ?.map((option: any) => option.name)
        .join(", "),
      generatedDate: dayjs().format(constants.dateTimeFormatWithSeconds),
    });
  };

  const handleReset = () => {
    form?.resetFields();
    setReportConfig(undefined);
  };

  const tagRender = (props: CustomTagProps) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag onMouseDown={onPreventMouseDown} closable={closable} onClose={onClose} style={{ marginRight: 3 }}>
        {wells?.find((well) => well?.id === value)?.name}
      </Tag>
    );
  };

  const handleRestrictionOnChange = (value: boolean) => {
    if (!value) setRestrictionIsActive(null);
    else setRestrictionIsActive(true);
  };

  const handleWellOnChange = (value: boolean) => {
    if (!value) setWellIsActive(null);
    else setWellIsActive(true);
  };

  const handleFieldOnChange = (value: boolean) => {
    if (!value) setFieldIsActive(null);
    else setFieldIsActive(true);
  };

  const renderActionsButtons = () => {
    return (
      <Space>
        <Button
          loading={loadingWaterRights || loadingRestrictions || generatingReport}
          disabled={loadingWaterRights || loadingRestrictions || generatingReport}
          type="primary"
          icon={<ThunderboltOutlined />}
          onClick={handleGenerateReport}
        >
          Generate Report
        </Button>
        <Button
          loading={loadingWaterRights || loadingRestrictions || generatingReport}
          disabled={loadingWaterRights || loadingRestrictions || generatingReport}
          icon={<CloseOutlined />}
          onClick={handleReset}
        >
          Reset
        </Button>
      </Space>
    );
  };

  return (
    <>
      <Card title="Configuration" actions={[renderActionsButtons()]} bodyStyle={{ padding: 12, margin: 0 }}>
        <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} autoComplete="off" style={{ margin: 0 }}>
          <Form.Item label="Groupings" name="groupingIds" style={{ margin: 0, marginBottom: 10 }}>
            <Select
              allowClear
              mode="multiple"
              loading={loadingGroupings}
              placeholder="Select groupings"
              showSearch
              optionFilterProp="label"
              maxTagCount={3}
              disabled={loadingGroupings || generatingReport}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <Space style={{ padding: "0 8px 4px" }}>
                    <Button
                      onClick={() =>
                        form.setFieldValue(
                          "groupingIds",
                          groupings.filter((item: any) => item !== undefined).map((grouping) => grouping.id)
                        )
                      }
                    >
                      Select All
                    </Button>
                    <Button danger onClick={() => form.setFieldValue("groupingIds", [])}>
                      Clear List
                    </Button>
                  </Space>
                </>
              )}
            >
              {groupings
                .filter((item: any) => item !== undefined)
                .map((grouping: any) => {
                  return (
                    <Select.Option value={grouping.id} key={grouping.id} label={grouping.name}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        {grouping.name}
                      </div>
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item label="Fields" name="fieldIds" style={{ margin: 0, marginBottom: 10 }}>
            <Select
              allowClear
              mode="multiple"
              loading={loadingFields}
              placeholder="Select fields"
              showSearch
              optionFilterProp="value"
              maxTagCount={3}
              disabled={loadingFields || generatingReport}
              options={fields?.map((field) => {
                return {
                  value: field?.id,
                  label: field?.name,
                };
              })}
              optionRender={(option) => {
                const field = fields?.find((field) => field?.id === option.value);

                return (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      {field.name} <StatusTag status={field.status} />
                    </div>
                  </>
                );
              }}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <Space style={{ padding: "0 8px 4px" }}>
                    <Button
                      onClick={() =>
                        form.setFieldValue(
                          "fieldIds",
                          fields.filter((item: any) => item !== undefined).map((field) => field.id)
                        )
                      }
                    >
                      Select All
                    </Button>
                    <Button danger onClick={() => form.setFieldValue("fieldIds", [])}>
                      Clear List
                    </Button>
                    <Switch defaultChecked onChange={handleFieldOnChange} checkedChildren="Active Only" unCheckedChildren="All" />
                  </Space>
                </>
              )}
            ></Select>
          </Form.Item>
          <Form.Item label="Wells" name="wellIds" style={{ margin: 0, marginBottom: 10 }}>
            <Select
              allowClear
              mode="multiple"
              loading={loadingWells}
              tagRender={tagRender}
              placeholder="Select wells"
              showSearch
              optionFilterProp="label"
              maxTagCount={3}
              disabled={generatingReport}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <Space style={{ padding: "0 8px 4px" }}>
                    <Button
                      onClick={() =>
                        form.setFieldValue(
                          "wellIds",
                          wells.filter((item: any) => item !== undefined).map((well) => well.id)
                        )
                      }
                    >
                      Select All
                    </Button>
                    <Button danger onClick={() => form.setFieldValue("wellIds", [])}>
                      Clear List
                    </Button>
                    <Switch defaultChecked onChange={handleWellOnChange} checkedChildren="Active Only" unCheckedChildren="All" />
                  </Space>
                </>
              )}
            >
              {wells
                .filter((item: any) => item !== undefined)
                .map((well: any) => {
                  return (
                    <Select.Option value={well.id} key={well.id} label={well.name}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        {well.name} <StatusTag status={well.status} />
                      </div>
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
          <WaterRightSelector style={{ margin: 0, marginBottom: 10 }} form={form} label={"Water Rights"} placeholder={"Select water rights"} propertyToSet={"waterRightIds"} />
          <Form.Item label="Restrictions" name="restrictionIds" style={{ margin: 0 }}>
            <Select
              allowClear
              mode="multiple"
              loading={loadingRestrictions}
              placeholder="Select restrictions"
              showSearch
              optionFilterProp="label"
              maxTagCount={3}
              disabled={generatingReport}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <Space style={{ padding: "0 8px 4px" }}>
                    <Button
                      onClick={() =>
                        form.setFieldValue(
                          "restrictionIds",
                          restrictions.filter((item: any) => item !== undefined).map((restriction) => restriction.id)
                        )
                      }
                    >
                      Select All
                    </Button>
                    <Button danger onClick={() => form.setFieldValue("restrictionIds", [])}>
                      Clear List
                    </Button>
                    <Switch defaultChecked onChange={handleRestrictionOnChange} checkedChildren="Active Only" unCheckedChildren="All" />
                  </Space>
                </>
              )}
            >
              {restrictions
                .filter((item: any) => item !== undefined)
                .map((restriction: any) => {
                  return (
                    <Select.Option value={restriction.id} key={restriction.id} label={restriction.name}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        {restriction.name} <StatusTag status={restriction.status} />
                      </div>
                      <div>
                        <WaterRightTag status={typeof restriction.type === "number" ? restriction.type : restriction.type.toUpperCase()} />
                      </div>
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item label="Stock" name="stockIds" style={{ margin: 0, marginTop: 10, marginBottom: 0 }}>
            <Select
              allowClear
              mode="multiple"
              loading={loadingStock}
              placeholder="Select stock"
              showSearch
              optionFilterProp="label"
              maxTagCount={3}
              disabled={loadingStock || generatingReport}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <Space style={{ padding: "0 8px 4px" }}>
                    <Button
                      onClick={() =>
                        form.setFieldValue(
                          "stockIds",
                          stock.filter((item: any) => item !== undefined).map((stock) => stock.id)
                        )
                      }
                    >
                      Select All
                    </Button>
                    <Button danger onClick={() => form.setFieldValue("stockIds", [])}>
                      Clear List
                    </Button>
                  </Space>
                </>
              )}
            >
              {stock
                .filter((item: any) => item !== undefined)
                .map((stock: any) => {
                  return (
                    <Select.Option value={stock.id} key={stock.id} label={stock.name}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        {stock.name}
                      </div>
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item label="Permit Type" name="permitTypes" style={{ margin: 0, paddingTop: 10 }}>
            <Select
              allowClear
              mode="multiple"
              loading={loadingWaterRights}
              placeholder="Select permit types"
              showSearch
              optionFilterProp="label"
              maxTagCount={3}
              disabled={loadingWaterRights || generatingReport}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <Space style={{ padding: "0 8px 4px" }}>
                    <Button
                      onClick={() =>
                        form.setFieldValue(
                          "permitTypes",
                          lookups.find((lookup: any) => lookup.map === "permittype").options.map((lookup: any) => lookup.value)
                        )
                      }
                    >
                      Select All
                    </Button>
                    <Button danger onClick={() => form.setFieldValue("permitTypes", [])}>
                      Clear List
                    </Button>
                  </Space>
                </>
              )}
            >
              {lookups
                .find((lookup: any) => lookup.map === "permittype")
                .options.map((lookup: any) => {
                  return (
                    <Select.Option value={lookup.value} key={lookup.id} label={lookup.name}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        {lookup.name}
                      </div>
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item label="Priority Type" name="priorityTypes" style={{ margin: 0, paddingTop: 10 }}>
            <Select
              allowClear
              mode="multiple"
              loading={loadingWaterRights}
              placeholder="Select the priority types"
              showSearch
              optionFilterProp="label"
              maxTagCount={3}
              disabled={loadingWaterRights || generatingReport}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <Space style={{ padding: "0 8px 4px" }}>
                    <Button
                      onClick={() =>
                        form.setFieldValue(
                          "priorityTypes",
                          lookups.find((lookup: any) => lookup.map === "prioritytype").options.map((lookup: any) => lookup.value)
                        )
                      }
                    >
                      Select All
                    </Button>
                    <Button danger onClick={() => form.setFieldValue("priorityTypes", [])}>
                      Clear List
                    </Button>
                  </Space>
                </>
              )}
            >
              {lookups
                .find((lookup: any) => lookup.map === "prioritytype")
                .options.map((lookup: any) => {
                  return (
                    <Select.Option value={lookup.value} key={lookup.id} label={lookup.name}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        {lookup.name}
                      </div>
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Form>
      </Card>
      {generatingReport && (
        <Card title="Generating Report">
          <div style={{ textAlign: "center" }}>Loading...</div>
        </Card>
      )}
      {!generatingReport && reportConfig && <GeneratedAllConfigurationReport reportConfig={reportConfig} />}
    </>
  );
};

export default AllConfigurationReport;
