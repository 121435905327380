import { useQuery } from "@tanstack/react-query";
import CacheKeys from "@/types/CacheKeys";
import { useSelector } from "react-redux";
import defaultQueryConfig from "@/configs/defaultQueryConfig";
import useOutstandingInvites from "./useOutstandingInvites";

const useActionsCounter = () => {
  const { profile } = useSelector((state: any) => state.user);
  // const { oustandingInvites, invalidateCompanyInvites, isFetched: companyInvitesFetched } = useOutstandingInvites();

  const { data, isFetching, isFetched } = useQuery({
    queryKey: [
      CacheKeys.actionsCounter,
      {
        profileId: profile?.id,
        // companyInvitesFetched,
        // invitesCount: oustandingInvites.invites?.length,
      },
    ],
    queryFn: async () => {
      let count = 0;
      if (!profile) return count;
      // if (!profile || !companyInvitesFetched) return count;

      if (!profile?.phoneNumber) count++;
      if (!profile?.phoneNumberConfirmed) count++;
      // if (oustandingInvites.invites?.length > 0) count++;
      return count;
    },
    ...defaultQueryConfig,
    enabled: !!profile?.id,
    initialData: 0,
  });

  return {
    actionsCount: data,
    isFetched,
    isFetching,
    // invalidateCompanyInvites,
  };
};

export default useActionsCounter;
