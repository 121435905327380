import { getPagedStocks } from "@/apis/stock.api";
import defaultQueryConfig from "@/configs/defaultQueryConfig";
import CacheKeys from "@/types/CacheKeys";
import { keepPreviousData, useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { useSelector } from "react-redux";

type StockPagedListProps = {
  pagination: {
    page: number;
    pageSize: number;
  };
  searchString?: string;
};

export default function useStockPageQuery(props: StockPagedListProps) {
  const { profile } = useSelector((state: any) => state.user);
  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const queryClient = useQueryClient();

  const {
    isFetched,
    isRefetching,
    data: stocks,
    isFetching,
    isPlaceholderData,
  } = useQuery({
    queryKey: [
      CacheKeys.stocks,
      CacheKeys.list,
      {
        profileId: profile?.id,
        companyId: selectedCompanyId,
        ...props,
      },
    ],
    queryFn: async () => {
      const response = await getPagedStocks({
        companyId: selectedCompanyId,
        ...props,
      });

      if (response.ok) {
        const data = await response.json();
        if (data.isSuccess) return data.value;

        throw new Error("Failed to fetch paged fields");
      }
    },
    placeholderData: keepPreviousData,
    ...defaultQueryConfig,
    enabled: !!profile?.id,
  });

  const invalidateStocks = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [CacheKeys.stocks, CacheKeys.list],
    });
  }, [queryClient]);

  const isLoading = !isFetched;

  return {
    isLoading,
    isFetching,
    isRefetching,
    isPlaceholderData,
    stocks,
    invalidateStocks,
  };
}
