import { getPagedCompanies } from "@/apis/company.api";
import defaultQueryConfig from "@/configs/defaultQueryConfig";
import CacheKeys from "@/types/CacheKeys";
import { keepPreviousData, useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { useSelector } from "react-redux";

type CompanyPagedListProps = {
  pagination: {
    page: number;
    pageSize: number;
  };
  isActive: string | null;
  searchString?: string;
};

export default function useCompanyPagedList(props: CompanyPagedListProps) {
  const { profile } = useSelector((state: any) => state.user);

  const queryClient = useQueryClient();

  const {
    isFetched,
    isRefetching,
    data: companies,
    isFetching,
    isPlaceholderData,
  } = useQuery({
    queryKey: [
      CacheKeys.companies,
      CacheKeys.list,
      {
        profileId: profile?.id,
        ...props,
      },
    ],
    queryFn: async () => {
      const response = await getPagedCompanies({
        ...props,
      });

      if (response.ok) {
        const data = await response.json();
        if (data.isSuccess) return data.value;

        throw new Error("Failed to fetch paged companies");
      }
    },
    placeholderData: keepPreviousData,
    ...defaultQueryConfig,
    enabled: !!profile?.id,
  });

  const invalidateCompanies = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [CacheKeys.companies, CacheKeys.list],
    });
  }, [queryClient]);

  const isLoading = !isFetched;

  return {
    isLoading,
    isFetching,
    isRefetching,
    isPlaceholderData,
    companies,
    invalidateCompanies,
  };
}
