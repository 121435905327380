import { LookupFilterModal, MapPermitTypeLookupHistory, RecentWells, StatusTag } from "@/components";
import WaterRightTagList from "@/components/WaterRightTagList/WaterRightTagList";
import { WellDetailsCard } from "@/components";
import { constants } from "@/configs";
import useListWellMapDashboard from "@/queries/useListWellMapDashboard";
import { ExpandOutlined, FilterOutlined, ReloadOutlined } from "@ant-design/icons";
import { useIntersectionObserver } from "@uidotdev/usehooks";
import { AutoComplete, Button, Card, Divider, Empty, Input, Popover, SelectProps, Space, Spin, Switch, Tag, Tooltip } from "antd";
import GoogleMapReact, { ChangeEventValue, Point } from "google-map-react";
import type { CustomTagProps } from "rc-select/lib/BaseSelect";
import { FC, useEffect, useRef, useState } from "react";
import { MdOutlineLocationOff } from "react-icons/md";
import useListWellsForDashboardMapQuery from "@/queries/useListWellsForDashboardMapQuery";
import useSupercluster from "use-supercluster";
import "./MapDashboard.scss";
import WaterRightLabel from "@/components/Lookups/WaterRightLabels/WaterRightLabel";
import FieldLabel from "@/components/Lookups/FieldLabel";
import CompanyLabel from "@/components/Lookups/CompanyLabel";

const HOVER_DISTANCE = 30;

const distanceToMouse = (pt: Point, mousePos: Point, markerProps?: any) => {
  const K_SCALE_NORMAL = 0.65;

  const K_MARKER_HEIGHT = 60;
  // marker is more tall at top, so calc distance to some point at marker top
  const K_MARKER_WEIGHT_PT = K_MARKER_HEIGHT * 0.7;
  // distance to markers depends on scale so hover on big markers is more probable
  const scale = markerProps.scale;
  const x = pt.x;
  const y = pt.y - K_MARKER_WEIGHT_PT * scale;

  const scaleNormalized = Math.min(scale / K_SCALE_NORMAL, 1);
  const K_MIN_DIST_MIN_KOEF = 0.6;

  const distKoef = 1 + scaleNormalized * (K_MIN_DIST_MIN_KOEF - 1);
  return distKoef * Math.sqrt((x - mousePos.x) * (x - mousePos.x) + (y - mousePos.y) * (y - mousePos.y));
};

const Polyline: React.FC<{ path: { lat: number; lng: number }[]; options: google.maps.PolylineOptions; mapRef: any }> = ({ path, options, mapRef }) => {
  const polylineRef = useRef<google.maps.Polyline | null>(null);

  useEffect(() => {
    if (polylineRef.current) {
      polylineRef.current.setMap(null);
    }
    polylineRef.current = new google.maps.Polyline({
      path,
      ...options,
    });
    polylineRef.current.setMap(mapRef.current);
    return () => {
      if (polylineRef.current) {
        polylineRef.current.setMap(null);
      }
    };
  }, [path, options]);

  return null;
};

const Marker = ({ children, isCluster, data }: any) =>
  isCluster ? (
    children
  ) : (
    <Popover
      trigger="hover"
      placement="top"
      title={data.name}
      content={
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start", lineHeight: "1.2em" }}>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start" }}>
            <div style={{ width: 70 }}>Company: </div>
            <CompanyLabel companyId={data.companyId} />
          </div>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start" }}>
            <div style={{ width: 70 }}>Field: </div>
            <FieldLabel fieldId={data.fieldId} />
          </div>
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "flex-start" }}>
            <div style={{ width: 70 }}>WR / FN: </div>
            <WaterRightLabel waterRightId={data.waterRightId} />
          </div>
        </div>
      }
    >
      {children}
    </Popover>
  );

const MapDashboard: FC = () => {
  const [selected, setSelected] = useState<any>();
  const [autocompleteSearchString, setAutoCompleteSearchString] = useState<string | undefined>("");
  const [searchString, setSearchString] = useState<string | undefined>("");
  const [open, setOpen] = useState<boolean>(false);

  const [isActive, setIsActive] = useState<boolean | undefined>(true);

  const [wellDownRef, wellDownEntry] = useIntersectionObserver();

  const [recentWells, setRecentWells] = useState<any[]>([]);

  const [permitTypeFilters, setPermitTypeFilters] = useState<string[]>([]);

  const [filterPermitTypeModalState, setFilterPermitTypeModalState] = useState<any>({
    open: false,
  });

  const [selectedCluster, setSelectedCluster] = useState<any>();

  const ref = useRef<any>(null);
  const mapRef = useRef<any>();
  const mapsRef = useRef<any>();

  const [bounds, setBounds] = useState<any>(null);
  const [zoom, setZoom] = useState(10);

  const {
    mapDashboardWells: wells,
    fetchNextPage: fetchNextWellPage,
    resetDashboardMapWells,
    hasNextPage: hasNextWellPage,
    isFetching,
    isFetched,
  } = useListWellMapDashboard({
    searchString: searchString,
    isActive: isActive,
  });

  const { wellsForDashboardMap: wellsToMap } = useListWellsForDashboardMapQuery({
    permitTypeFilters: permitTypeFilters?.length > 0 ? permitTypeFilters : undefined,
  });

  const { clusters, supercluster } = useSupercluster({
    points: wellsToMap,
    bounds,
    zoom,
    options: { radius: 75, maxZoom: 20 },
  });

  const onSelect = (value: string, option: any) => {
    setOpen(false);
    setSelected(option);
    setAutoCompleteSearchString(option.label);
    ref.current?.blur();
    if (option) {
      setRecentWells((current) => {
        const list = [...current];
        const values = list.filter((value) => value.value !== option.value);
        values.unshift(option);
        return values;
      });
    }
    const well = wellsToMap?.find((well) => well.properties.id === value);
    if (well) {
      mapRef.current?.setZoom(20);
      mapRef.current?.panTo({ lat: well.geometry.coordinates[1], lng: well.geometry.coordinates[0] });
    }
  };

  const tagRender = (props: CustomTagProps) => {
    const { value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag onMouseDown={onPreventMouseDown} closable={closable} onClose={onClose} style={{ marginRight: 3 }}>
        {wells?.find((well) => well?.id === value)?.name}
      </Tag>
    );
  };

  const onChange = (value: ChangeEventValue) => {
    const { zoom, bounds } = value;
    setZoom(zoom);
    setBounds([bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat]);
  };

  const centerMap = () => {
    if (wellsToMap?.length === 0) return;

    const bounds = new mapsRef.current.LatLngBounds();

    wellsToMap.forEach((well: any) => {
      const latLng = new mapsRef.current.LatLng(well.geometry.coordinates[1], well.geometry.coordinates[0]);
      bounds.extend(latLng);
    });
    mapRef.current.fitBounds(bounds, { top: 100, right: 100, left: 100, bottom: 100 });
  };

  const refreshSearch = () => {
    setSearchString("");

    setSelected(undefined);
    setAutoCompleteSearchString("");
    resetDashboardMapWells();
    setIsActive(true);
  };

  const refreshMap = () => {
    refreshSearch();
    setPermitTypeFilters([]);
  };

  const handleFilterPermitTypesClose = () => {
    setFilterPermitTypeModalState({
      open: false,
    });
  };

  const handleFilteredPermitTypesSuccess = (values: string[]) => {
    setPermitTypeFilters(values);

    handleFilterPermitTypesClose();
  };

  useEffect(() => {
    if (wellDownEntry?.isIntersecting && hasNextWellPage) {
      fetchNextWellPage();
    }
    // eslint-disable-next-line
  }, [wellDownEntry?.isIntersecting, hasNextWellPage]);

  // Calculate positions in a circle
  const calculatePositions = (center: any, leaves: any[]) => {
    const count = leaves.length;
    const radius = 0.00002 + count * 0.000005;

    const angleStep = (2 * Math.PI) / leaves.length;
    return leaves.map((marker: any, index: number) => {
      const angle = index * angleStep;
      const lat = center.lat + radius * Math.cos(angle);
      const lng = center.lng + radius * Math.sin(angle);
      return { ...marker, position: { lat, lng }, properties: marker.properties };
    });
  };

  return (
    <>
      <Card
        styles={{
          body: {
            padding: 10,
            margin: 0,
            height: "calc(100vh - 208px)",
          },
        }}
      >
        <div style={{ position: "relative" }}>
          <Space
            direction="vertical"
            style={{
              paddingBottom: 24,
              position: "absolute",
              left: "calc(50% - 250px)",
              top: 10,
              width: 500,
              zIndex: 999,
            }}
          >
            <AutoComplete
              style={{ width: 600 }}
              dropdownRender={(menu) => (
                <>
                  {isFetched && wells?.length === 0 ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Wells" /> : null}
                  {isFetched && wells?.length > 0 ? menu : null}
                  {isFetching ? (
                    <div style={{ padding: 24, display: "flex", flexDirection: "column", opacity: 0.5, flex: 1, justifyContent: "center", alignItems: "center", gap: 10, pointerEvents: "none" }}>
                      <Spin />
                      <div>Loading...</div>
                    </div>
                  ) : null}
                  <Divider style={{ margin: 0, marginTop: 2, marginBottom: 8 }} />
                  <Space style={{ padding: 0, paddingTop: 4, paddingLeft: 8, paddingBottom: 8 }}>
                    <Switch
                      defaultChecked
                      onChange={(val) => {
                        if (!val) setIsActive(undefined);
                        else setIsActive(true);
                      }}
                      checkedChildren="Active Only"
                      unCheckedChildren="All"
                    />
                    <Button
                      id="refresh"
                      onClick={(e: any) => {
                        refreshSearch();
                      }}
                    >
                      Refresh
                    </Button>
                  </Space>
                </>
              )}
              tagRender={tagRender}
              optionFilterProp="value"
              options={
                isFetched && wells.length > 0
                  ? wells?.map((well: any) => {
                      return {
                        value: well?.id,
                        label: well?.name,
                      };
                    })
                  : [
                      {
                        value: "loading",
                        label: "Loading...",
                      },
                    ]
              }
              optionRender={(option, { index }) => {
                if (option.value === "loading") return null;
                const well = wells?.find((well) => well.id === option?.value);
                if (!well) return null;

                return (
                  <div key={well.id}>
                    {index === (wells?.length ?? 0) - 1 ? <span ref={wellDownRef} /> : null}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        fontWeight: "bold",
                        paddingBottom: 5,
                      }}
                    >
                      {well?.name}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        flexDirection: "row",
                        paddingLeft: 0,
                        marginLeft: 0,
                      }}
                    >
                      <StatusTag style={{ margin: 2, marginLeft: 0 }} status={well?.status} />
                      <WaterRightTagList
                        internalTags={well?.internalTags}
                        externalTags={well?.externalTags}
                        style={{
                          marginLeft: -2,
                          marginRight: 50,
                          display: "flex",
                          flexDirection: "row",
                          flexWrap: "wrap",
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        fontSize: 12,
                      }}
                    >
                      <div style={{ paddingRight: 4, width: 60 }}>WR / FN:</div> {well?.waterRightFileNumber ?? "-"}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        fontSize: 12,
                      }}
                    >
                      <div style={{ paddingRight: 4, width: 60 }}>PDIV:</div> {well?.pdiv ?? "-"}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        fontSize: 12,
                      }}
                    >
                      <div style={{ paddingRight: 4, width: 60 }}>CIN:</div> {well?.cin ?? "-"}
                    </div>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        fontSize: 12,
                      }}
                    >
                      <div style={{ paddingRight: 4, width: 60 }}>Field:</div> {well?.fieldName ?? "-"}
                    </div>
                    {(well?.latitude === undefined || well?.latitude === null || well?.longitude === undefined || well?.longitude === null) && (
                      <div style={{ position: "absolute", top: 10, right: 10 }}>
                        <Tooltip title="No location available">
                          <MdOutlineLocationOff size={20} color="red" />
                        </Tooltip>
                      </div>
                    )}
                    {index < (wells?.length ?? 0) - 1 ? <Divider style={{ margin: 0, padding: 0, marginTop: 5 }} /> : null}
                  </div>
                );
              }}
              listHeight={isFetching ? 400 : 500}
              open={open}
              onFocus={() => setOpen(true)}
              id="autocomplete"
              value={autocompleteSearchString}
              onChange={setAutoCompleteSearchString}
              onSelect={onSelect}
            >
              <Input.Search
                ref={ref}
                allowClear
                id="search"
                disabled={isFetching}
                size="large"
                placeholder="Search wells"
                enterButton
                onSearch={(val) => {
                  if (val === "") refreshSearch();
                  setSearchString(val);
                }}
              />
            </AutoComplete>
          </Space>
          <Space
            direction="vertical"
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              zIndex: 999,
              backgroundColor: "rgba(255, 255, 255, 0.6)",
              padding: 5,
              borderBottomRightRadius: 5,
            }}
          >
            <Tooltip title="Filter" placement="right">
              <Button icon={<FilterOutlined />} size="large" type="primary" onClick={() => setFilterPermitTypeModalState({ open: true })} />
            </Tooltip>
            <Tooltip title="Center map around wells" placement="right">
              <Button icon={<ExpandOutlined />} size="large" type="primary" onClick={centerMap} />
            </Tooltip>
            <Tooltip title="Refresh map" placement="right">
              <Button icon={<ReloadOutlined />} size="large" type="primary" onClick={refreshMap} />
            </Tooltip>
          </Space>
        </div>
        {permitTypeFilters.length > 0 && (
          <div style={{ position: "absolute", left: 60, top: 10, paddingTop: 0, marginTop: 0, zIndex: 999 }}>
            <MapPermitTypeLookupHistory lookups={permitTypeFilters} />
          </div>
        )}
        {selected ? (
          <div style={{ position: "absolute", bottom: 10, left: 10, zIndex: 999 }}>
            <WellDetailsCard wellId={selected.value} />
          </div>
        ) : null}
        {recentWells.length > 0 ? (
          <div style={{ position: "absolute", top: 10, right: 10, zIndex: 999 }}>
            <RecentWells recentWells={recentWells} onClick={(well) => onSelect(well.value, well)} />
          </div>
        ) : null}
        <GoogleMapReact
          onGoogleApiLoaded={({ map, maps }) => {
            mapRef.current = map;
            mapsRef.current = maps;
          }}
          options={(map) => ({
            mapTypeId: map.MapTypeId.HYBRID,
            streetViewControl: false,
            fullscreenControl: false,
            zoomControl: false,
            minZoom: 3,
            maxZoom: 20,
          })}
          bootstrapURLKeys={{ key: constants.googleApiKey }}
          defaultCenter={{ lat: 47.116386, lng: -101.299591 }}
          defaultZoom={12}
          yesIWantToUseGoogleMapApiInternals
          onChange={onChange}
          onClick={() => setOpen(false)}
          hoverDistance={HOVER_DISTANCE}
          distanceToMouse={distanceToMouse}
        >
          {clusters.map((cluster) => {
            const [longitude, latitude] = cluster.geometry.coordinates;
            const { cluster: isCluster, point_count: pointCount } = cluster.properties;

            if (isCluster) {
              const currentZoom = mapRef.current.getZoom();

              if (cluster.id === selectedCluster?.clusterId && currentZoom === 20) {
                const center = { lat: latitude, lng: longitude };

                const positions = calculatePositions(center, selectedCluster.leaves);

                return positions.map((marker: any) => (
                  <Marker lat={marker.position.lat} lng={marker.position.lng} data={marker.properties}>
                    <div
                      className="cluster-marker"
                      style={{
                        cursor: "pointer",
                        backgroundColor: marker.properties.id === selected?.value ? constants.secondaryColor : undefined,
                        position: "absolute",
                        width: 10,
                        height: 10,
                        left: -10,
                        top: -10,
                      }}
                      onClick={() => {
                        onSelect(marker.properties.id, { value: marker.properties.id, label: marker.properties.name });
                      }}
                    >
                      1
                    </div>
                    <Polyline path={[center, marker.position]} options={{ geodesic: true, strokeColor: "black", strokeOpacity: 0.5, strokeWeight: 1 }} mapRef={mapRef} />
                  </Marker>
                ));
              }

              return (
                <Marker key={`cluster-${cluster.id}`} lat={latitude} lng={longitude} isCluster={isCluster}>
                  <div
                    className="cluster-marker"
                    style={{
                      cursor: "pointer",
                      backgroundColor: cluster.id === selectedCluster?.clusterId ? constants.secondaryColor : undefined,
                      position: "absolute",
                      width: `${10 + (pointCount / wellsToMap.length) * 20}px`,
                      height: `${10 + (pointCount / wellsToMap.length) * 20}px`,
                      left: -10,
                      top: -10,
                    }}
                    onClick={() => {
                      const expansionZoom = Math.min(supercluster.getClusterExpansionZoom(cluster.id), 20);
                      if (mapRef.current.getZoom() === expansionZoom) {
                        const leaves = supercluster.getLeaves(cluster.id, Infinity);
                        setSelectedCluster({ clusterId: cluster.id, leaves });
                      } else {
                        mapRef.current?.setZoom(expansionZoom);
                        mapRef.current?.panTo({ lat: latitude, lng: longitude });
                      }
                    }}
                  >
                    {pointCount}
                  </div>
                </Marker>
              );
            }

            return (
              <Marker key={`cluster-${cluster.properties.id}`} lat={latitude} lng={longitude} isCluster={isCluster} data={cluster.properties}>
                <div
                  className="cluster-marker"
                  style={{
                    width: `${10}px`,
                    height: `${10}px`,
                    cursor: "pointer",
                    backgroundColor: cluster.properties.id === selected?.value ? constants.secondaryColor : undefined,
                    position: "absolute",
                    left: -10,
                    top: -10,
                  }}
                  onClick={() => {
                    setSelectedCluster(undefined);
                    onSelect(cluster.properties.id, { value: cluster.properties.id, label: cluster.properties.name });
                  }}
                >
                  1
                </div>
              </Marker>
            );
          })}
        </GoogleMapReact>
      </Card>
      {filterPermitTypeModalState.open && (
        <LookupFilterModal permitTypes={permitTypeFilters} open={filterPermitTypeModalState.open} onSuccess={handleFilteredPermitTypesSuccess} onCancel={handleFilterPermitTypesClose} />
      )}
    </>
  );
};

export default MapDashboard;
