import { Button } from "antd";
import React, { CSSProperties, useRef } from "react";
import ReactToPrint from "react-to-print";

interface PrintPageProps {
  content: JSX.Element;
  isReport?: boolean;
  style?: CSSProperties;
}

export default function PrintPage(props: PrintPageProps) {
  const { content, isReport = false, style = {} } = props;
  const componentRef = useRef(null);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          marginTop: isReport ? 20 : 0,
          marginBottom: isReport ? 20 : 0,
          marginRight: 5,
        }}
      >
        <ReactToPrint trigger={() => <Button type="primary">{!isReport ? "Print" : "Print Report"}</Button>} content={() => componentRef.current} removeAfterPrint={true} />
      </div>
      <div style={style}>{React.cloneElement(content, { ...content.props, ref: componentRef })}</div>
    </div>
  );
}
