import { PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import { Button, Card, Input, Space, Table } from "antd";
import CompanyLabel from "@/components/Lookups/CompanyLabel";
import PrintPage from "@/components/PrintPage/PrintPage";
import { routes } from "@/configs";
import GroupingView from "@/pages/GroupingView/GroupingView";
import useGroupingPagedList from "@/queries/useGroupingPageQuery";
import { FC, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import useCustomNavigate from "@/services/useCustomNavigate";
import { useAppDispatch } from "@/stores";
import { addBreadcrumb } from "@/stores/breadcrumbs.store";
import AddSelectCompanyModal, { AddSelectCompanyModalRef, EnitityName } from "../AddSelectCompanyModal/AddSelectCompanyModal";

interface Props {
  style?: React.CSSProperties;
  trackTableState?: boolean;
}

const GroupingTable: FC<Props> = (props) => {
  const { style, trackTableState } = props;
  const dispatch = useAppDispatch();

  const { selectedCompanyId, selectedCompany } = useSelector((state: any) => state.company);

  const { navigate, updateQueryParams, queryParams } = useCustomNavigate();

  const [columns, setColumns] = useState<any[]>([]);
  const [searchString, setSearchString] = useState<string | undefined>(undefined);
  const [searchStringValue, setSearchStringValue] = useState<string | undefined>("");

  const {
    groupings,
    isLoading: isGroupingsLoading,
    invalidateGroupings,
  } = useGroupingPagedList({
    pagination: {
      page: queryParams?.page ?? "1",
      pageSize: queryParams?.pageSize ?? "10",
    },
    searchString,
  });

  useEffect(() => {
    calculateColumns();
    // eslint-disable-next-line
  }, [groupings]);

  const handleAddGrouping = () => {
    if (selectedCompany?._companyId === "all") {
      addModalRef.current?.open();
      return;
    } else {
      handleNavigateToAdd();
    }
  };

  const handleNavigateToAdd = () => {
    dispatch(
      addBreadcrumb({
        type: `Grouping / Add`,
        url: `grouping/add`,
      })
    );
    navigate(routes.groupingAdd);
  };

  const handleEditGrouping = (record: any) => {
    dispatch(
      addBreadcrumb({
        type: `${record?.name}`,
        url: `grouping/${record.id}/view`,
      })
    );
    navigate(routes.groupingView, { id: record.id });
  };

  const calculateColumns = () => {
    let tempColumns = [
      {
        title: "Grouping Name",
        key: "name",
        dataIndex: "name",
        fixed: "left",
        render: (val: any, record: any) => (
          <Button style={{ paddingLeft: 0 }} type="link" onClick={() => handleEditGrouping(record)}>
            {val}
          </Button>
        ),
      },
      {
        title: "Actions",
        key: "action",
        width: 250,
        align: "center",
        dataIndex: "",
        render: (value: any, record: any) => (
          <>
            <Button style={{ paddingLeft: 0 }} type="link" onClick={() => handleEditGrouping(record)}>
              View
            </Button>
          </>
        ),
      },
    ];

    if (!selectedCompanyId) {
      tempColumns.splice(1, 0, {
        title: "Company",
        key: "companyId",
        width: 250,
        align: "left",
        dataIndex: "companyId",
        render: (value: any, record: any) => <CompanyLabel companyId={value} />,
      });
    }

    setColumns(tempColumns);
  };

  const handleRefresh = () => {
    setSearchString("");
    setSearchStringValue("");
    updateQueryParams({ page: 1, pageSize: 10 });
    invalidateGroupings();
  };

  const renderExpandData = (record: any) => {
    return <GroupingView overrideId={record.id} showHeader={false} />;
  };

  const addModalRef = useRef<AddSelectCompanyModalRef>(null);

  return (
    <>
      <Card
        className="groupingTable"
        id="groupingTable"
        title="Groupings"
        extra={
          <>
            <Button icon={<PlusOutlined />} onClick={handleAddGrouping} type="primary">
              Add Grouping
            </Button>
          </>
        }
      >
        <PrintPage
          content={
            <div>
              <Space direction="vertical" style={{ width: "100%" }}>
                <Space style={{ paddingBottom: 10 }}>
                  <Input.Search
                    disabled={isGroupingsLoading}
                    placeholder="Search groupings"
                    onSearch={(val) => {
                      setSearchString(val);
                      updateQueryParams({ page: 1, pageSize: 10 });
                    }}
                    onChange={(val) => setSearchStringValue(val.currentTarget.value)}
                    value={searchStringValue}
                    style={{ width: 400 }}
                    allowClear
                  />
                  <Button disabled={isGroupingsLoading} onClick={handleRefresh} icon={<ReloadOutlined />}>
                    Refresh
                  </Button>
                </Space>

                <Table
                  pagination={
                    trackTableState
                      ? {
                          pageSize: queryParams?.pageSize ? Number(queryParams?.pageSize) : 10,
                          current: queryParams?.page ? Number(queryParams?.page) : 1,
                          total: groupings?.pagination?.totalItemCount,
                        }
                      : undefined
                  }
                  onChange={(pagination) => {
                    trackTableState &&
                      updateQueryParams({
                        page: pagination.current,
                        pageSize: pagination.pageSize,
                      });
                  }}
                  rowKey={(row: any) => row.id}
                  loading={isGroupingsLoading}
                  columns={columns}
                  dataSource={groupings?.list ?? []}
                  size="small"
                  expandable={{
                    expandedRowRender: (record: any) => renderExpandData(record),
                  }}
                />
              </Space>
            </div>
          }
        />
      </Card>
      <AddSelectCompanyModal entityName={EnitityName.Grouping} ref={addModalRef} onSubmit={handleNavigateToAdd} />
    </>
  );
};

export default GroupingTable;
