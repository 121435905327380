import { AndroidFilled, AppleFilled } from "@ant-design/icons";
import { Button, ConfigProvider, Popconfirm, Table, message } from "antd";
import { listUserDevice, removeDevice } from "@/apis/communication.api";
import { constants } from "@/configs";
import dayjs from "dayjs";
import { FC, useEffect, useState } from "react";

interface Props {
  userId: any;
  isProfile?: boolean;
}

const AdminDeviceTable: FC<Props> = (props: Props) => {
  const { userId, isProfile = false } = props;

  const [userDevices, setUserDevices] = useState<any[]>([]);

  const [loadingUserDevices, setLoadingUserDevices] = useState<boolean>(false);
  const [deleteDevice, setDeleteDevice] = useState<boolean>(false);

  const [userDevicesColumns, setUserDevicesColumns] = useState<any[]>([]);

  useEffect(() => {
    if (userId) refreshUserDevices();
  }, [userId]);

  useEffect(() => {
    calculateUserDevicesColumns();
  }, [userDevices]);

  const refreshUserDevices = async () => {
    setLoadingUserDevices(true);

    const request = { userIds: [userId] };
    const response = await listUserDevice(request);

    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setUserDevices(data.value);
      }
    }

    setLoadingUserDevices(false);
  };

  const handleRemoveDevice = async (record: any) => {
    setDeleteDevice(true);

    const response = await removeDevice({ id: record.id });
    if (response.ok) {
      message.success("Successfuly removed device from user");
      refreshUserDevices();
    } else {
      message.error("Removing of device for user failed");
    }

    setDeleteDevice(false);
  };

  const calculateUserDevicesColumns = () => {
    let tempColumns: any[] = [
      {
        title: "Platform",
        key: "platform",
        dataIndex: "platform",
        width: 20,
        align: "center",
        render: (val: any, record: any) => (val === "ios" ? <AppleFilled style={{ fontSize: 28 }} /> : val === "android" ? <AndroidFilled style={{ fontSize: 28, color: "#3DDC84" }} /> : "-"),
      },
      {
        title: "Device Brand",
        key: "deviceBrand",
        dataIndex: "deviceBrand",
        render: (val: any, record: any) => (val ? val.substring(0, 1).toUpperCase() + val.substring(1) : "-"),
      },
      {
        title: "Device Name",
        key: "deviceName",
        dataIndex: "deviceName",
        render: (val: any, record: any) => val,
      },
      {
        title: "Device Model",
        key: "deviceModel",
        dataIndex: "deviceModel",
        render: (val: any, record: any) => val,
      },
      {
        title: "Version",
        key: "version",
        dataIndex: "version",
        render: (val: any, record: any) => val ?? "-",
      },
      {
        title: "Last Updated",
        key: "lastUpdatedDate",
        dataIndex: "lastUpdatedDate",
        render: (val: any, record: any) => (val ? dayjs(val).format(constants.dateTimeFormat) : "-"),
      },
      {
        title: "Actions",
        key: "action",
        width: 100,
        render: (value: any, record: any) => (
          <>
            <Popconfirm title={"Remove device"} description="Are you sure you wish to remove this device from this user?" onConfirm={() => handleRemoveDevice(record)} okText="Yes" cancelText="No">
              <Button loading={deleteDevice} type="link" style={{ paddingLeft: 0 }}>
                Remove
              </Button>
            </Popconfirm>
          </>
        ),
      },
    ];

    if (isProfile) tempColumns.splice(3, 1);

    setUserDevicesColumns(tempColumns);
  };

  const customizeRenderEmpty = () => <div style={{ textAlign: "center" }}>No Data</div>;

  return (
    <ConfigProvider renderEmpty={customizeRenderEmpty}>
      <Table rowKey={(row: any) => row.id} loading={loadingUserDevices} columns={userDevicesColumns} dataSource={userDevices || []} size="small" pagination={false} />
    </ConfigProvider>
  );
};

export default AdminDeviceTable;
