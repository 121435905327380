import { CloseOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { Button, Card, Divider, Form, Select, Space } from "antd";
import { useWatch } from "antd/es/form/Form";
import { getGroupings } from "@/apis/grouping.api";
import { constants } from "@/configs";
import dayjs from "dayjs";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import GeneratedGroupingConfigurationReport from "./GeneratedGroupingConfigurationReport";

const GroupingConfigurationReport: FC = () => {
  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const [form] = Form.useForm();

  const [loadingGroupings, setLoadingGroupings] = useState<boolean>(false);
  const [generatingReport, setGeneratingReport] = useState<boolean>(false);

  const [groupings, setGroupings] = useState<any[]>([]);

  const [reportConfig, setReportConfig] = useState<any>(undefined);

  const groupingIds: any[] = useWatch("groupingIds", form);

  useEffect(() => {
    handleReset();
    // eslint-disable-next-line
  }, [selectedCompanyId]);

  useEffect(() => {
    if (selectedCompanyId) {
      refreshGroupings();
    }
    // eslint-disable-next-line
  }, [selectedCompanyId]);

  useEffect(() => {
    if (generatingReport) setTimeout(() => setGeneratingReport(false), 500);
    // eslint-disable-next-line
  }, [generatingReport]);

  const refreshGroupings = async () => {
    setLoadingGroupings(true);
    const response = await getGroupings(selectedCompanyId, null);
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setGroupings(data.value);
      }
    }
    setLoadingGroupings(false);
  };

  const handleGenerateReport = () => {
    setGeneratingReport(true);

    const tempGroupingsIds: any[] = (groupings ?? []).filter((grouping: any) => (groupingIds ?? []).includes(grouping.id)).map((grouping: any) => grouping.id);

    setReportConfig({
      groupingIds: tempGroupingsIds,
      groupings: groupings
        .filter((grouping: any) => tempGroupingsIds?.includes(grouping.id))
        .map((grouping: any) => grouping.name)
        .join(", "),
      generatedDate: dayjs().format(constants.dateTimeFormatWithSeconds),
    });
  };

  const handleReset = () => {
    form?.resetFields();
    setReportConfig(undefined);
  };

  const renderActionsButtons = () => {
    return (
      <Space>
        <Button loading={loadingGroupings || generatingReport} disabled={loadingGroupings || generatingReport} type="primary" icon={<ThunderboltOutlined />} onClick={handleGenerateReport}>
          Generate Report
        </Button>
        <Button loading={loadingGroupings || generatingReport} disabled={loadingGroupings || generatingReport} icon={<CloseOutlined />} onClick={handleReset}>
          Reset
        </Button>
      </Space>
    );
  };

  return (
    <>
      <Card title="Configuration" actions={[renderActionsButtons()]} bodyStyle={{ padding: 12, margin: 0 }}>
        <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} autoComplete="off" style={{ margin: 0 }}>
          <Form.Item label="Groupings" name="groupingIds" style={{ margin: 0, marginBottom: 10 }}>
            <Select
              allowClear
              mode="multiple"
              loading={loadingGroupings}
              placeholder="Select groupings"
              showSearch
              optionFilterProp="label"
              maxTagCount={3}
              disabled={loadingGroupings || generatingReport}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <Space style={{ padding: "0 8px 4px" }}>
                    <Button
                      onClick={() =>
                        form.setFieldValue(
                          "groupingIds",
                          groupings.filter((item: any) => item !== undefined).map((grouping) => grouping.id)
                        )
                      }
                    >
                      Select All
                    </Button>
                    <Button danger onClick={() => form.setFieldValue("groupingIds", [])}>
                      Clear List
                    </Button>
                  </Space>
                </>
              )}
            >
              {groupings
                .filter((item: any) => item !== undefined)
                .map((grouping: any) => {
                  return (
                    <Select.Option value={grouping.id} key={grouping.id} label={grouping.name}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        {grouping.name}
                      </div>
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Form>
      </Card>
      {generatingReport && (
        <Card title="Generating Report">
          <div style={{ textAlign: "center" }}>Loading...</div>
        </Card>
      )}
      {!generatingReport && reportConfig && <GeneratedGroupingConfigurationReport reportConfig={reportConfig} />}
    </>
  );
};

export default GroupingConfigurationReport;
