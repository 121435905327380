import { getPagedWells } from "@/apis/well.api";
import defaultQueryConfig from "@/configs/defaultQueryConfig";
import CacheKeys from "@/types/CacheKeys";
import { keepPreviousData, useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { useSelector } from "react-redux";

type WellPagedListProps = {
  fieldId?: string | null;
  waterRightId?: string | null;
  reductionWellIds?: string[] | null;
  pagination: {
    page: number;
    pageSize: number;
  };
  isActive: boolean | null;
  searchString?: string;
};

export default function useWellPagedList(props: WellPagedListProps) {
  const { profile } = useSelector((state: any) => state.user);
  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const queryClient = useQueryClient();

  const {
    isFetched,
    isRefetching,
    data: wells,
    isFetching,
    isPlaceholderData,
  } = useQuery({
    queryKey: [
      CacheKeys.wells,
      CacheKeys.list,
      {
        profileId: profile?.id,
        companyId: selectedCompanyId,
        ...props,
      },
    ],
    queryFn: async () => {
      const response = await getPagedWells({
        companyId: selectedCompanyId,
        ...props,
      });

      if (response.ok) {
        const data = await response.json();
        if (data.isSuccess) return data.value;

        throw new Error("Failed to fetch paged wells");
      }
    },
    placeholderData: keepPreviousData,
    ...defaultQueryConfig,
    enabled: !!profile?.id,
  });

  const invalidateWells = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [CacheKeys.wells, CacheKeys.list],
    });
  }, [queryClient]);

  const isLoading = !isFetched;

  return {
    isLoading,
    isFetching,
    isRefetching,
    isPlaceholderData,
    wells,
    invalidateWells,
  };
}
