import { CloseOutlined, InfoCircleOutlined, ThunderboltOutlined, WarningOutlined } from "@ant-design/icons";
import { Alert, Button, Card, Checkbox, DatePicker, Divider, Form, Input, InputNumber, Popconfirm, Select, Space, StepProps, Steps, Switch, Typography, message } from "antd";
import { useForm, useWatch } from "antd/es/form/Form";
import Tooltip, { TooltipPlacement } from "antd/es/tooltip";
import { addWaterRight, getPlaceOfUse, getWaterRight, getWaterRightSnapShot, getWaterRights, updateWaterRight, updateWaterRightSnapShot } from "@/apis/waterright.api";
import { LookupSelector, PlacesOfUseSelector } from "@/components";
import { constants, routes } from "@/configs";
import { FC, useEffect, useReducer, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import useCustomNavigate from "@/services/useCustomNavigate";
import { useAppDispatch } from "@/stores";
import { removeBreadcrumb, removeViewEditBreadcrumbs } from "@/stores/breadcrumbs.store";
import dayjs from "dayjs";
import { merge } from "lodash";
import DisableWarningsModalState from "./Components/DisableWarningsModal";
import customParseFormat from "dayjs/plugin/customParseFormat";

import "./WaterRightAddEdit.scss";
import scrollToTop from "@/services/scrollToTop";
import PlaceOfUse from "@/pages/PlaceOfUseAddEdit/PlaceOfUseAddEdit";
import useWaterRightLookups from "@/queries/useWaterRightLookups";
import useComapnyConfigLookup from "@/queries/useCompanyConfigLookup";

dayjs.extend(customParseFormat);

type PageConfigProperties = {
  priorityType: boolean;
  permitType: boolean;
  systemType: boolean;
  cropType: boolean;
  endGuns: boolean;
  energyType: boolean;
  owner: boolean;
  gmd: boolean;
  nrd: boolean;
  irrigationAcres: boolean;
  quantity: boolean;
  rate: boolean;
};

type PageConfig = {
  required: PageConfigProperties;
  disabled: PageConfigProperties;
  visible: PageConfigProperties;
};

const initialPageConfig: PageConfig = {
  required: {
    priorityType: true,
    permitType: true,
    systemType: true,
    cropType: false,
    endGuns: true,
    energyType: true,
    owner: true,
    gmd: true,
    nrd: true,
    irrigationAcres: true,
    quantity: true,
    rate: true,
  },
  disabled: {
    priorityType: false,
    permitType: false,
    systemType: false,
    cropType: false,
    endGuns: false,
    energyType: false,
    owner: false,
    gmd: false,
    nrd: false,
    irrigationAcres: false,
    quantity: false,
    rate: false,
  },
  visible: {
    priorityType: true,
    permitType: true,
    systemType: true,
    cropType: true,
    endGuns: true,
    energyType: true,
    owner: true,
    gmd: true,
    nrd: true,
    irrigationAcres: true,
    quantity: true,
    rate: true,
  },
};

const WaterRightAddEdit: FC = () => {
  const { selectedCompanyId } = useSelector((state: any) => state.company);
  const { profile } = useSelector((state: any) => state.user);

  const { id } = useParams<{ id: string }>();

  const dispatch = useAppDispatch();

  // const formRef = useRef<FormInstance>(null);

  const [form] = useForm();

  const { navigate } = useCustomNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [addAnother, setAddAnother] = useState<boolean>(false);
  const [waterRightData, setWaterRightData] = useState<any>(undefined);
  const [snapShotData, setSnapShotData] = useState<any>(undefined);
  // const [waterRights, setWaterRights] = useState<any[]>();
  const [selected, setSelected] = useState<string[]>([]);
  const [disableWarningsModalState, setDisableWarningsModalState] = useState<any>({ open: false, success: false });

  const fileNumber = useWatch("fileNumber", form);
  const subjectToMds = useWatch("subjectToMds", form);
  const authorizedQuantityUnits = useWatch("authorizedQuantityUnits", form);
  const usageQuantity = useWatch("usageQuantity", form);
  const usageQuantityUnits = useWatch("usageQuantityUnits", form);
  const authorizedIrrigationNotApplicable = useWatch("authorizedIrrigationNotApplicable", form);
  const authorizedQuantityNotApplicable = useWatch("authorizedQuantityNotApplicable", form);
  const authorizedRateNotApplicable = useWatch("authorizedRateNotApplicable", form);
  const disableWarnings = useWatch("disableWarnings", form);
  const state = useWatch("state", form);

  const [duplicateFound, setDuplicateFound] = useState<boolean>(false);

  const selectedYear: any = useWatch("year", form);

  const { waterRights } = useWaterRightLookups();

  const { companyConfig } = useComapnyConfigLookup(waterRightData?.companyId ?? selectedCompanyId);

  useEffect(() => {
    if (snapShotData !== null) {
      setWaterRightData((oldData: any) => {
        return {
          ...snapShotData,
          internalTags: snapShotData?.internalTags ? snapShotData.internalTags : oldData?.internalTags,
        };
      });
    }
  }, [snapShotData]);

  useEffect(() => {
    const currentYear = dayjs().year();

    if (id) {
      if (selectedYear !== null) {
        if (selectedYear?.year() < currentYear) {
          getWaterRightSnapShotData();
        } else if (selectedYear?.year() === currentYear) getWaterRightData();
      }
    } else {
      resetDefaults();
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [id, selectedYear, companyConfig]);

  const resetDefaults = () => {
    form?.setFieldsValue({
      state: "Kansas",
      townshipDirection: "S",
      rangeDirection: "W",
      permitType: "irrigation",
      authorizedQuantityUnits: companyConfig?.settings?.metric,
      status: true,
    });
  };

  useEffect(() => {
    if (fileNumber && waterRights) {
      if (
        (id && waterRights?.find((waterRight: any) => waterRight.fileNumber === fileNumber && waterRight.id !== id)) ||
        (!id && waterRights?.find((waterRight: any) => waterRight.fileNumber === fileNumber))
      )
        setDuplicateFound(true);
      else setDuplicateFound(false);
    } else setDuplicateFound(false);
    // eslint-disable-next-line
  }, [id, waterRights, fileNumber]);

  useEffect(() => {
    if (waterRightData) {
      form.setFieldsValue({
        ...waterRightData,
        externalTags: waterRightData.externalTags ?? undefined,
      });
      if (waterRightData?.disableWarnings) setDisableWarningsModalState({ open: false, success: true });
    }
    // eslint-disable-next-line
  }, [waterRightData]);

  useEffect(() => {
    if (usageQuantity === undefined || usageQuantity === null) form.setFieldValue("usageQuantityUnits", authorizedQuantityUnits);
    // eslint-disable-next-line
  }, [authorizedQuantityUnits, usageQuantity, usageQuantityUnits]);

  const getWaterRightData = async () => {
    setLoading(true);
    const response = await getWaterRight(id);
    if (response.ok) {
      const data = await response.json();
      setWaterRightData(data.value);
    }
    setLoading(false);
  };

  const getWaterRightSnapShotData = async () => {
    setLoading(true);

    const request = { year: selectedYear?.year(), id: id };
    const response = await getWaterRightSnapShot(request);
    if (response.ok) {
      const data = await response.json();

      if (data.value !== null) {
        const { year, ...rest } = data.value;
        setSnapShotData({
          year: dayjs(`${selectedYear?.year()}-12-31`),
          ...rest,
        });
      } else setSnapShotData(null);
    }
    setLoading(false);
  };

  const onFinish = async (values: any) => {
    setLoading(true);

    const data = {
      ...values,
      companyId: waterRightData?.companyId ?? selectedCompanyId,
      placesOfUse: selected,
    };

    if (id) {
      if (selectedYear.year() < dayjs().year()) {
        const { year, ...rest } = data;
        const snapshotResponse = await updateWaterRightSnapShot(snapShotData ? snapShotData.id : id, {
          year: selectedYear.year(),
          waterRightId: snapShotData ? snapShotData?.waterRightId : id,
          ...rest,
        });
        if (snapshotResponse.ok) {
          message.success("Successfully updated the water right snapshot");
          navigate(routes.waterRightList);
        } else {
          message.error("Failed to update the water right snapshot");
        }
      } else {
        const response = await updateWaterRight(id, data);
        if (response.ok) {
          message.success("Successfully updated the Water Right");
          navigate(routes.waterRightList);
        } else {
          message.error("Failed to update the Water Right");
        }
      }
    } else {
      const response = await addWaterRight({ ...data });
      if (response.ok) {
        message.success("Successfully added the Water Right");
        if (addAnother) {
          setWaterRightData(undefined);
          form.resetFields();
          resetDefaults();
          navigate(routes.waterRightAdd);
        } else navigate(routes.waterRightList);
      } else {
        message.error("Failed to add the Water Right");
      }
    }

    if (id) {
      dispatch(removeViewEditBreadcrumbs());
    } else if (!addAnother) dispatch(removeBreadcrumb());

    setLoading(false);
    setAddAnother(false);
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        onFinish(values);
      })
      .catch((errors) => {
        scrollToTop();
        message.error(`Please fill in the required fields`, 5);
      });
  };

  const handleAddAnother = () => {
    form
      .validateFields()
      .then((values) => {
        setAddAnother(true);
        form.submit();
        resetDefaults();
      })
      .catch((errors) => {
        scrollToTop();
        message.error(`Please fill in the required fields`, 5);
      });
  };

  const handleDisableWarningsClose = () => {
    form.setFieldValue("disableWarnings", false);
    setDisableWarningsModalState({ open: false, success: false });
  };

  const handleDisableWarningsSuccess = () => {
    form.setFieldsValue({
      disableWarnings: true,
      markedAsDisabledUserUserName: profile?.displayName,
      markedAsDisabledDate: dayjs().toISOString(),
    });
    setDisableWarningsModalState({ open: false, success: true });
  };

  const renderActionButtons = (placement: TooltipPlacement) => (
    <Space>
      {!id && (
        <>
          <Button loading={loading} type="primary" icon={<ThunderboltOutlined />} onClick={() => handleSubmit()}>
            Save
          </Button>
          <Button loading={loading} type="primary" icon={<ThunderboltOutlined />} onClick={handleAddAnother}>
            Save + Add Another
          </Button>
          <Button
            loading={loading}
            icon={<CloseOutlined />}
            onClick={() => {
              dispatch(removeBreadcrumb());
              navigate(routes.waterRightList);
            }}
          >
            Cancel
          </Button>
        </>
      )}
      {id && (
        <>
          <Button loading={loading} type="primary" icon={<ThunderboltOutlined />} onClick={() => handleSubmit()}>
            Save
          </Button>
          <Button
            loading={loading}
            icon={<CloseOutlined />}
            onClick={() => {
              dispatch(removeBreadcrumb());
              navigate(-1);
            }}
          >
            Cancel
          </Button>
        </>
      )}
    </Space>
  );

  const sectionRef = useRef<any>(null);
  const stateRef = useRef<any>(null);
  const rangeRef = useRef<any>(null);
  const rangeDirectionRef = useRef<any>(null);
  const townshipRef = useRef<any>(null);
  const townshipDirectionRef = useRef<any>(null);

  const [config, updateConfig] = useReducer((state: PageConfig, action: Partial<PageConfig>) => {
    return { ...state, ...action };
  }, initialPageConfig);

  return (
    <>
      <Card className="waterRightAddEdit" id="waterRightAddEdit" title={id ? "Edit Water Right" : "Add Water Right"} extra={renderActionButtons("bottomRight")} actions={[renderActionButtons("top")]}>
        <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} onFinish={onFinish} autoComplete="off">
          <Steps
            direction="vertical"
            items={[
              {
                title: "Year",
                status: "process",
                description: (
                  <Card>
                    <Form.Item
                      label="Year"
                      name="year"
                      rules={[
                        {
                          required: !id ? false : true,
                          message: "Please enter a year",
                        },
                      ]}
                      style={{ margin: 0, marginBottom: 10 }}
                      initialValue={dayjs()}
                    >
                      <DatePicker disabled={!id} picker="year" style={{ marginRight: 30 }} disabledDate={(d) => d.isAfter(`${dayjs().year()}-12-31`) || d.isBefore("2020-01-01")} allowClear={false} />
                    </Form.Item>
                    {selectedYear?.year() < dayjs().year() && waterRightData?.referenceYear !== waterRightData?.year?.year() && (
                      <Form.Item wrapperCol={{ span: 14, offset: 6 }}>
                        <Alert
                          style={{ marginTop: 15, marginBottom: 0 }}
                          type="info"
                          showIcon
                          description={`You are using the water right snapshot configuration for the year ${waterRightData?.referenceYear} to add a snapshot configuration for the selected year ${selectedYear?.year()}`}
                        />
                      </Form.Item>
                    )}
                    {selectedYear?.year() < dayjs().year() && snapShotData === null && (
                      <Form.Item wrapperCol={{ span: 14, offset: 6 }}>
                        <Alert
                          style={{ marginTop: 15, marginBottom: 0 }}
                          type="warning"
                          icon={<WarningOutlined />}
                          showIcon
                          description={`A water right snapshot configuration for the selected year ${selectedYear?.year()} does not exist`}
                        />
                      </Form.Item>
                    )}
                  </Card>
                ),
              },
              {
                title: "Details",
                status: "process",
                description: (
                  <Card>
                    <Form.Item
                      label="Water Right / File Number"
                      name="fileNumber"
                      rules={[
                        {
                          required: true,
                          message: "Please enter the water right / file number",
                        },
                      ]}
                    >
                      <Input placeholder="Enter the water right / file number" />
                    </Form.Item>
                    {duplicateFound && (
                      <Form.Item wrapperCol={{ span: 14, offset: 6 }}>
                        <Alert
                          description={`A Water Right already exist with this file number (${fileNumber}). You can setup multiple Water Rights with the same name/file number, to distinguish between each Water Right, you can use the tags feature below to tag the current Water Right with a meaningful label or if this is a mistake, navigate to the existing Water Right.`}
                          type="warning"
                          showIcon
                        />
                      </Form.Item>
                    )}
                    <Form.Item label="PDIV" name="pdiv">
                      <Input placeholder="Enter the PDIV number" />
                    </Form.Item>
                    <Form.Item label="CIN" name="cin">
                      <Input placeholder="Enter the CIN number" />
                    </Form.Item>
                    <Form.Item label="Status" name="status" valuePropName="checked">
                      <Switch checkedChildren="Active" unCheckedChildren="Inactive" />
                    </Form.Item>

                    <Form.Item label="Legal">
                      <Space>
                        <Typography style={{ width: 95 }}>Section / State</Typography>
                        <Input.Group compact>
                          <Form.Item name="section" noStyle>
                            <Input
                              style={{ width: 200 }}
                              ref={sectionRef}
                              placeholder="Enter the section"
                              onKeyDownCapture={(event) => {
                                if (event.key === "Tab") {
                                  event.preventDefault();
                                  townshipRef?.current?.focus();
                                }
                              }}
                            />
                          </Form.Item>
                          <Form.Item name="state" noStyle>
                            <LookupSelector
                              style={{ width: 200 }}
                              propertyToSet="state"
                              form={form}
                              setRef={(ref: any) => (stateRef.current = ref)}
                              placeholder="Select a state"
                              lookupType="states"
                              onKeyDownCapture={(event: any) => {
                                if (event.key === "Tab") {
                                  event.preventDefault();
                                  townshipDirectionRef?.current?.focus();
                                }
                              }}
                              showAction={["focus"]}
                            />
                          </Form.Item>
                        </Input.Group>
                      </Space>
                      <br />
                      <Space>
                        <Typography style={{ width: 95 }}>Township</Typography>
                        <Input.Group compact>
                          <Form.Item name="township" noStyle>
                            <Input
                              style={{ width: 200 }}
                              ref={townshipRef}
                              placeholder="Enter the township"
                              onKeyDownCapture={(event) => {
                                if (event.key === "Tab") {
                                  event.preventDefault();
                                  rangeRef?.current?.focus();
                                }
                              }}
                            />
                          </Form.Item>
                          <Form.Item name="townshipDirection" noStyle>
                            <Select
                              ref={townshipDirectionRef}
                              style={{ width: 200 }}
                              defaultValue="N"
                              onKeyDown={(event) => {
                                if (event.key === "Tab") {
                                  event.preventDefault();
                                  rangeDirectionRef?.current?.focus();
                                }
                              }}
                              showAction={["focus"]}
                            >
                              <Select.Option value="N">N</Select.Option>
                              <Select.Option value="S">S</Select.Option>
                            </Select>
                          </Form.Item>
                        </Input.Group>
                      </Space>
                      <br />
                      <Space>
                        <Typography style={{ width: 95 }}>Range</Typography>
                        <Input.Group compact>
                          <Form.Item name="range" noStyle>
                            <Input
                              ref={rangeRef}
                              style={{ width: 200 }}
                              placeholder="Enter the range"
                              onKeyDownCapture={(event) => {
                                if (event.key === "Tab") {
                                  event.preventDefault();
                                  stateRef?.current?.focus();
                                }
                              }}
                            />
                          </Form.Item>
                          <Form.Item name="rangeDirection" noStyle>
                            <Select ref={rangeDirectionRef} style={{ width: 200 }} defaultValue="E" showAction={["focus"]}>
                              <Select.Option value="E">E</Select.Option>
                              <Select.Option value="W">W</Select.Option>
                            </Select>
                          </Form.Item>
                        </Input.Group>
                      </Space>
                    </Form.Item>

                    {config.visible.priorityType && (
                      <Form.Item
                        label="Priority Type"
                        name="priorityType"
                        rules={[
                          {
                            required: config.required.priorityType,
                            message: "Please select the priority type",
                          },
                        ]}
                      >
                        <LookupSelector
                          locked={config.disabled.priorityType}
                          propertyToSet="priorityType"
                          configSelector="waterRightAddEdit"
                          onApplyConfig={updateConfig}
                          form={form}
                          placeholder="Select the priority type"
                          lookupType="priorityType"
                        />
                      </Form.Item>
                    )}

                    {config.visible.permitType && (
                      <Form.Item
                        label="Permit Type"
                        name="permitType"
                        rules={[
                          {
                            required: config.required.permitType,
                            message: "Please select the permit type",
                          },
                        ]}
                      >
                        <LookupSelector
                          locked={config.disabled.permitType}
                          configSelector="waterRightAddEdit"
                          onApplyConfig={updateConfig}
                          propertyToSet="permitType"
                          form={form}
                          placeholder="Select a permit type"
                          lookupType="permitType"
                        />
                      </Form.Item>
                    )}

                    {config.visible.systemType && (
                      <Form.Item
                        label="System Type"
                        name="systemType"
                        rules={[
                          {
                            required: config.required.systemType,
                            message: "Please select the system type",
                          },
                        ]}
                      >
                        <LookupSelector
                          locked={config.disabled.systemType}
                          propertyToSet="systemType"
                          configSelector="waterRightAddEdit"
                          onApplyConfig={updateConfig}
                          form={form}
                          placeholder="Select the system type"
                          lookupType="systemType"
                        />
                      </Form.Item>
                    )}

                    {config.visible.cropType && (
                      <Form.Item
                        label="Crop Type"
                        name="cropType"
                        rules={[
                          {
                            required: config.required.cropType,
                            message: "Please select the crop type",
                          },
                        ]}
                      >
                        <LookupSelector
                          locked={config.disabled.cropType}
                          configSelector="waterRightAddEdit"
                          onApplyConfig={updateConfig}
                          propertyToSet="cropType"
                          form={form}
                          placeholder="Select the crop type"
                          lookupType="cropType"
                        />
                      </Form.Item>
                    )}

                    {config.visible.endGuns && (
                      <Form.Item
                        label="End Guns"
                        name="endGuns"
                        rules={[
                          {
                            required: config.required.endGuns,
                            message: "Please enter the end guns",
                          },
                        ]}
                        initialValue={0}
                      >
                        <InputNumber disabled={config.disabled.endGuns} style={{ minWidth: 230 }} min={0} defaultValue={0} placeholder="Enter the end guns" />
                      </Form.Item>
                    )}

                    {config.visible.energyType && (
                      <Form.Item
                        label="Energy Type"
                        name="energyType"
                        rules={[
                          {
                            required: config.required.energyType,
                            message: "Please select the energy type",
                          },
                        ]}
                      >
                        <LookupSelector
                          locked={config.disabled.energyType}
                          propertyToSet="energyType"
                          configSelector="waterRightAddEdit"
                          onApplyConfig={updateConfig}
                          form={form}
                          placeholder="Select the energy type"
                          lookupType="energyType"
                        />
                      </Form.Item>
                    )}

                    {config.visible.owner && (
                      <Form.Item
                        label="Owner"
                        name="owner"
                        rules={[
                          {
                            required: config.required.owner,
                            message: "Please select the owner",
                          },
                        ]}
                      >
                        <LookupSelector
                          locked={config.disabled.owner}
                          propertyToSet="owner"
                          configSelector="waterRightAddEdit"
                          onApplyConfig={updateConfig}
                          form={form}
                          placeholder="Select the owner"
                          lookupType="owner"
                        />
                      </Form.Item>
                    )}

                    {state === "Kansas" && config.visible.gmd && (
                      <Form.Item
                        label="Ground Water Management District"
                        name="gmd"
                        rules={[
                          {
                            required: config.required.gmd,
                            message: "Please select the ground management district",
                          },
                        ]}
                      >
                        <LookupSelector
                          locked={config.disabled.gmd}
                          propertyToSet="gmd"
                          configSelector="waterRightAddEdit"
                          onApplyConfig={updateConfig}
                          form={form}
                          placeholder="Select the ground water management district"
                          lookupType="gmd"
                        />
                      </Form.Item>
                    )}

                    {state === "nebraska" && config.visible.nrd && (
                      <Form.Item
                        label="Natural Resources District"
                        name="nrd"
                        rules={[
                          {
                            required: config.required.nrd,
                            message: "Please select the natural resources district",
                          },
                        ]}
                      >
                        <LookupSelector
                          locked={config.disabled.nrd}
                          propertyToSet="nrd"
                          configSelector="waterRightAddEdit"
                          onApplyConfig={updateConfig}
                          form={form}
                          placeholder="Select the natural resources district"
                          lookupType="nrd"
                        />
                      </Form.Item>
                    )}

                    {(config.visible.irrigationAcres || config.visible.quantity || config.visible.rate) && (
                      <Form.Item
                        label="Authorized"
                        required={authorizedIrrigationNotApplicable === true && authorizedQuantityNotApplicable === true && authorizedRateNotApplicable === true ? false : true}
                      >
                        {config.visible.irrigationAcres && (
                          <>
                            <Space>
                              <Typography style={{ width: 95 }}>Irrigation Acres</Typography>
                              <Input.Group compact>
                                <Form.Item
                                  name="authorizedIrrigationAcres"
                                  rules={
                                    authorizedIrrigationNotApplicable
                                      ? undefined
                                      : [
                                          {
                                            required: config.required.irrigationAcres,
                                            message: "Please enter the authorized irrigation acres",
                                          },
                                        ]
                                  }
                                  noStyle
                                >
                                  <InputNumber
                                    disabled={authorizedIrrigationNotApplicable || config.disabled.irrigationAcres}
                                    style={{ minWidth: 415 }}
                                    min={0}
                                    placeholder="Enter the authroized irrigation acres"
                                  />
                                </Form.Item>
                              </Input.Group>
                              <Form.Item name="authorizedIrrigationNotApplicable" valuePropName="checked" noStyle>
                                <Checkbox>Not Applicable</Checkbox>
                              </Form.Item>
                            </Space>
                            <br />
                            {authorizedIrrigationNotApplicable && (
                              <>
                                <Form.Item noStyle>
                                  <Alert
                                    style={{ marginTop: 15, marginBottom: 0 }}
                                    showIcon
                                    icon={<WarningOutlined />}
                                    description={"By marking authorized irrigation acres as not applicable, no inches / acre and remaining days calculations will apply to this water right"}
                                    type="error"
                                  />
                                </Form.Item>
                                <br />
                              </>
                            )}
                          </>
                        )}
                        {config.visible.quantity && (
                          <>
                            <Space>
                              <Typography style={{ width: 95 }}>Quantity</Typography>
                              <Input.Group compact>
                                <Form.Item
                                  name="authorizedQuantity"
                                  rules={
                                    authorizedQuantityNotApplicable
                                      ? undefined
                                      : [
                                          {
                                            required: config.required.quantity,
                                            message: "Please enter the authorized quantity",
                                          },
                                        ]
                                  }
                                  noStyle
                                >
                                  <InputNumber disabled={authorizedQuantityNotApplicable || config.disabled.quantity} style={{ minWidth: 255 }} min={0} placeholder="Enter the authorized quantity" />
                                </Form.Item>
                                <Form.Item name="authorizedQuantityUnits" noStyle>
                                  <LookupSelector
                                    locked={authorizedQuantityNotApplicable || config.disabled.quantity}
                                    form={form}
                                    propertyToSet="authorizedQuantityUnits"
                                    placeholder="Select Units"
                                    lookupType="meterUnits"
                                    style={{ minWidth: 160 }}
                                    setDefault
                                  />
                                </Form.Item>
                              </Input.Group>
                              <Form.Item name="authorizedQuantityNotApplicable" valuePropName="checked" noStyle>
                                <Checkbox>Not Applicable</Checkbox>
                              </Form.Item>
                            </Space>
                            <br />
                            {authorizedQuantityNotApplicable && (
                              <>
                                <Form.Item noStyle>
                                  <Alert
                                    style={{ marginTop: 15, marginBottom: 0 }}
                                    showIcon
                                    icon={<WarningOutlined />}
                                    description={"By marking authorized quantity as not applicable, no warnings and no calculations besides used water metrics will apply to this water right"}
                                    type="error"
                                  />
                                </Form.Item>
                                <br />
                              </>
                            )}
                          </>
                        )}
                        {config.visible.rate && (
                          <>
                            <Space>
                              <Typography style={{ width: 95 }}>Rate</Typography>
                              <Input.Group compact>
                                <Form.Item
                                  name="authorizedRate"
                                  rules={
                                    authorizedRateNotApplicable
                                      ? undefined
                                      : [
                                          {
                                            required: config.required.rate,
                                            message: "Please enter the authorized rate",
                                          },
                                        ]
                                  }
                                  noStyle
                                >
                                  <InputNumber disabled={authorizedRateNotApplicable || config.disabled.rate} style={{ minWidth: 255 }} min={0} placeholder="Enter the authorized rate" />
                                </Form.Item>
                                <Form.Item name="authorizedRateUnits" noStyle>
                                  <LookupSelector
                                    form={form}
                                    propertyToSet="authorizedRateUnits"
                                    placeholder="Select Units"
                                    lookupType="meterUnits"
                                    style={{ minWidth: 160 }}
                                    locked
                                    constantValue={"gpm"}
                                  />
                                </Form.Item>
                              </Input.Group>
                              <Form.Item name="authorizedRateNotApplicable" valuePropName="checked" noStyle>
                                <Checkbox>Not Applicable</Checkbox>
                              </Form.Item>
                            </Space>
                            {authorizedRateNotApplicable && (
                              <>
                                <Form.Item noStyle>
                                  <Alert
                                    style={{ marginTop: 15, marginBottom: 0 }}
                                    showIcon
                                    icon={<WarningOutlined />}
                                    description={"By marking authorized rate as not applicable, no remaining days calculations will apply to this water right"}
                                    type="error"
                                  />
                                </Form.Item>
                                <br />
                              </>
                            )}
                          </>
                        )}
                      </Form.Item>
                    )}
                    <Form.Item
                      label={
                        <>
                          Subject To MDS{" "}
                          <Tooltip title="Minimum Desirable Streamflow">
                            <InfoCircleOutlined style={{ marginLeft: 5 }} />
                          </Tooltip>
                        </>
                      }
                      name="subjectToMds"
                      valuePropName="checked"
                    >
                      <Switch disabled={authorizedQuantityNotApplicable === true ? true : false} checkedChildren="Yes" unCheckedChildren="No" />
                    </Form.Item>
                    {subjectToMds && (
                      <Form.Item wrapperCol={{ span: 14, offset: 6 }}>
                        <Alert
                          showIcon
                          description={`Authorized Quantity reduced to 32%. New Authorized Quantity: ${form.getFieldValue("authorizedQuantity")} x 0.32 = ${(form.getFieldValue("authorizedQuantity") * 0.32).toFixed(2)}`}
                          type="warning"
                        />
                      </Form.Item>
                    )}
                    <Form.Item label="Disable Warnings" name="disableWarnings" valuePropName="checked">
                      <Switch onChange={(checked) => setDisableWarningsModalState({ open: checked })} checkedChildren="Yes" unCheckedChildren="No" />
                    </Form.Item>
                    {disableWarnings && disableWarningsModalState.success && (
                      <Form.Item wrapperCol={{ span: 14, offset: 6 }}>
                        <Alert
                          type="error"
                          showIcon
                          message="Warnings Disabled"
                          icon={<WarningOutlined />}
                          description={
                            <>
                              This water right has been marked as having warnings disabled. This means that the system will not send any warning notifications for this water right. <br />
                              To re-enable warnings, click the 'Edit' button above and uncheck the 'Disable Warnings' checkbox. <br />
                              <br />
                              Acknowledged By: {form.getFieldValue("markedAsDisabledUserUserName")} <br />
                              Date: {dayjs(form.getFieldValue("markedAsDisabledDate")).format(constants.dateTimeFormat)}
                            </>
                          }
                        />
                      </Form.Item>
                    )}
                  </Card>
                ),
              },
              config.visible.irrigationAcres || config.visible.quantity || config.visible.rate
                ? {
                    title: "Override Authorized Values for Calculations",
                    status: "process",
                    description: (
                      <Card>
                        <Form.Item wrapperCol={{ span: 14, offset: 6 }}>
                          <Alert
                            showIcon
                            description="The actual usage values below are optional and will override the authorized values in all calculations for remaining days for this water right."
                            type="warning"
                          />
                        </Form.Item>
                        <Form.Item label="Actual Usage">
                          {config.visible.irrigationAcres && (
                            <>
                              <Space>
                                <Typography style={{ width: 95 }}>Irrigation Acres</Typography>
                                <Input.Group compact>
                                  <Form.Item name="usageIrrigationAcres" noStyle>
                                    <InputNumber
                                      disabled={authorizedIrrigationNotApplicable || config.disabled.irrigationAcres}
                                      style={{ minWidth: 415 }}
                                      min={0}
                                      placeholder="Enter the usage irrigation acres (Optional)"
                                    />
                                  </Form.Item>
                                </Input.Group>
                                {authorizedIrrigationNotApplicable && <Typography style={{ width: 110 }}>(Not Applicable)</Typography>}
                              </Space>
                              <br />
                            </>
                          )}
                          {config.visible.quantity && (
                            <>
                              <Space>
                                <Typography style={{ width: 95 }}>Quantity</Typography>
                                <Input.Group compact>
                                  <Form.Item name="usageQuantity" noStyle>
                                    <InputNumber
                                      disabled={authorizedQuantityNotApplicable || config.disabled.quantity}
                                      style={{ minWidth: 255 }}
                                      min={0}
                                      placeholder="Enter the usage quantity (Optional)"
                                    />
                                  </Form.Item>
                                  <Form.Item name="usageQuantityUnits" noStyle>
                                    <LookupSelector
                                      locked={authorizedQuantityNotApplicable || usageQuantity === undefined || usageQuantity === null || config.disabled.quantity}
                                      form={form}
                                      propertyToSet="usageQuantityUnits"
                                      placeholder="Select Units"
                                      lookupType="meterUnits"
                                      style={{ minWidth: 160 }}
                                    />
                                  </Form.Item>
                                </Input.Group>
                                {authorizedQuantityNotApplicable && <Typography style={{ width: 110 }}>(Not Applicable)</Typography>}
                              </Space>
                              <br />
                            </>
                          )}
                          {config.visible.rate && (
                            <>
                              <Space>
                                <Typography style={{ width: 95 }}>Rate</Typography>
                                <Input.Group compact>
                                  <Form.Item name="usageRate" noStyle>
                                    <InputNumber disabled={authorizedRateNotApplicable || config.disabled.rate} style={{ minWidth: 255 }} min={0} placeholder="Enter the usage rate (Optional)" />
                                  </Form.Item>
                                  <Form.Item name="usageRateUnits" noStyle>
                                    <LookupSelector
                                      form={form}
                                      propertyToSet="usageRateUnits"
                                      placeholder="Select Units"
                                      lookupType="meterUnits"
                                      style={{ minWidth: 160 }}
                                      locked
                                      constantValue={"gpm"}
                                    />
                                  </Form.Item>
                                </Input.Group>
                                {authorizedRateNotApplicable && <Typography style={{ width: 110 }}>(Not Applicable)</Typography>}
                              </Space>
                            </>
                          )}
                        </Form.Item>
                      </Card>
                    ),
                  }
                : undefined,
              {
                title: "Tags for water right",
                status: "process",
                description: (
                  <Card>
                    <Form.Item label="Tags" name="externalTags">
                      <Select
                        mode="tags"
                        placeholder="Enter tags for the Water Right (Optional)"
                        loading={loading}
                        // disabled={loadingTags}
                      />
                    </Form.Item>
                  </Card>
                ),
              },
              {
                title: "Places of Use",
                status: "process",
                description: (
                  <Card>
                    <Form.Item label="Places of Use">
                      <PlacesOfUseSelector
                        reading={false}
                        selectedCompanyId={waterRightData?.companyId ?? selectedCompanyId}
                        waterRightId={id}
                        master={false}
                        waterRightPlacesOfUse={waterRightData?.placesOfUse}
                        updateSelectedValues={setSelected}
                      />
                    </Form.Item>
                  </Card>
                ),
              },
              {
                title: "Override Warning Quantities",
                status: "process",
                description: (
                  <Card>
                    <Form.Item wrapperCol={{ span: 14, offset: 6 }}>
                      <Alert
                        showIcon
                        description="Please leave this section blank if you do not want to override the default warning quantity in company settings for this water right."
                        type="warning"
                      />
                    </Form.Item>

                    <Form.Item label="Warning Quantity">
                      <Input.Group compact>
                        <Form.Item
                          name="warningQuantity"
                          //  rules={[{ required: true, message: 'Please input a default water right warning quantity' }]}
                          noStyle
                        >
                          <InputNumber min={0} style={{ minWidth: 320 }} placeholder="Enter an override warning quantity (Optional)" />
                        </Form.Item>
                        <Form.Item name="warningQuantityUnits" noStyle>
                          <LookupSelector form={form} propertyToSet="warningQuantityUnits" placeholder="Select Units" lookupType="meterUnits" style={{ minWidth: 120 }} />
                        </Form.Item>
                      </Input.Group>
                    </Form.Item>
                  </Card>
                ),
              },
            ]
              .filter((x) => x !== undefined)
              .map((x) => x as StepProps)}
          />
        </Form>
      </Card>
      {disableWarningsModalState.open && <DisableWarningsModalState open={disableWarningsModalState.open} onSuccess={handleDisableWarningsSuccess} onCancel={handleDisableWarningsClose} />}
    </>
  );
};

export default WaterRightAddEdit;
