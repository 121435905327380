import { Card } from "antd";
import { FC } from "react";

const AdminDashboard: FC = () => {
  return (
    <Card className="adminDashboard" id="adminDashboard" title="Dashboard" bordered={false}>
      Admin dashboard
    </Card>
  );
};

export default AdminDashboard;
