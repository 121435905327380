import { Card, ConfigProvider, Descriptions, Image, Space, Table } from "antd";
import Title from "antd/es/typography/Title";
import { getGroupings } from "@/apis/grouping.api";
import { getWaterRights } from "@/apis/waterright.api";
import PrintPage from "@/components/PrintPage/PrintPage";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import "./GeneratedSummaryReport.scss";

interface Props {
  reportConfig: any;
}

const customizeRenderEmpty = () => <div style={{ textAlign: "center" }}>No Data</div>;

export default function GeneratedGroupingConfigurationReport(props: Props) {
  const { reportConfig } = props;

  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const [loadingWaterRights, setLoadingWaterRights] = useState<boolean>(true);
  const [loadingGroupings, setLoadingGroupings] = useState<boolean>(true);
  const [generatingReport, setGeneratingReport] = useState<boolean>(false);

  const [waterRights, setWaterRights] = useState<any[]>([]);
  const [tempGroupings, setGroupings] = useState<any[]>([]);

  const [groupingColumns, setGroupingColumns] = useState<any[]>([]);

  const groupings: any[] = useMemo(() => {
    const data = tempGroupings.map((grouping: any) => {
      const waterRightsForGrouping = waterRights.filter((waterRight) => grouping.waterRights.includes(waterRight.id)).map((waterRight) => waterRight.fileNumber);

      return {
        ...grouping,
        waterRightNames: waterRightsForGrouping?.length > 0 ? waterRightsForGrouping : ["-"],
      };
    });
    return data;
  }, [waterRights, tempGroupings]);

  useEffect(() => {
    if (generatingReport) setTimeout(() => setGeneratingReport(false), 500);
    // eslint-disable-next-line
  }, [generatingReport]);

  useEffect(() => {
    if (selectedCompanyId) {
      refreshWaterRightsList();
      refreshGroupings();
    }
    // eslint-disable-next-line
  }, [selectedCompanyId]);

  useEffect(() => {
    calculateGroupingColumns();
    // eslint-disable-next-line
  }, [groupings, waterRights]);

  const refreshGroupings = async () => {
    setLoadingGroupings(true);
    const response = await getGroupings(selectedCompanyId, null);
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setGroupings(data.value);
      }
    }
    setLoadingGroupings(false);
  };

  const refreshWaterRightsList = async () => {
    setLoadingWaterRights(true);

    const request = {
      companyId: selectedCompanyId,
      isActive: undefined,
      waterRightIds: reportConfig.waterRightIds,
    };

    const response = await getWaterRights(request);
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setWaterRights(data.value);
      }
    }
    setLoadingWaterRights(false);
  };

  const calculateGroupingColumns = () => {
    let tempColumns = [
      {
        title: "#",
        width: 50,
        render: (val: any, record: any, index: any) => index + 1,
      },
      {
        title: "Grouping Name",
        key: "name",
        dataIndex: "name",
        fixed: "left",
        width: 300,
        render: (val: any, record: any) => val,
      },
      {
        title: "Water Rights",
        key: "waterRightNames",
        dataIndex: "waterRightNames",
        render: (val: any) => val && <div style={{ fontSize: 12, fontWeight: "normal", flex: 1 }}>{val.map((x: any) => x).join(", ")}</div>,
      },
    ];

    setGroupingColumns(tempColumns);
  };

  return (
    <PrintPage
      content={
        <Card
          id="generatedSummaryReport"
          className="remove-border-and-padding-on-print"
          title={
            <div>
              <Space size="middle">
                <Image
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                    textAlign: "center",
                    marginLeft: "auto",
                    marginRight: "auto",
                    alignContent: "center",
                    maxHeight: 200,
                    maxWidth: 200,
                    borderRadius: 10,
                    objectFit: "contain",
                  }}
                  src="/logo.png"
                  preview={false}
                />
                <Space direction="vertical" size="small" style={{ display: "flex" }}>
                  <Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>{`Grouping Configuration Report`}</Title>
                  <Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>{`Generated On ${reportConfig.generatedDate}`}</Title>
                </Space>
              </Space>
            </div>
          }
          loading={loadingWaterRights && loadingGroupings}
        >
          {
            <Card title="Groupings">
              <ConfigProvider renderEmpty={customizeRenderEmpty}>
                <Table
                  rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
                  rowKey={(row: any) => row.id}
                  loading={loadingGroupings || loadingWaterRights}
                  columns={groupingColumns}
                  dataSource={reportConfig?.groupingIds ? groupings.filter((grouping) => reportConfig?.groupingIds.includes(grouping.id)) : []}
                  size="small"
                  pagination={false}
                />
              </ConfigProvider>
            </Card>
          }
          <div className="page-break" />
          {reportConfig && (
            <Card title="Report Configuration" bodyStyle={{ padding: 10 }}>
              <Descriptions bordered size="small" column={1} labelStyle={{ width: 150 }} className="removeBoxShadow removeMargin">
                <Descriptions.Item label="Groupings">{reportConfig.groupings === "" ? "-" : reportConfig.groupings}</Descriptions.Item>
                <Descriptions.Item label="Generated At">{reportConfig.generatedDate}</Descriptions.Item>
              </Descriptions>
            </Card>
          )}
        </Card>
      }
      isReport
    />
  );
}
