import { getGrouping } from "@/apis/grouping.api";
import { AuditLogTable, WaterRightTable } from "@/components";
import { AuditLogType } from "@/components/AuditLogTable/AuditLogTable";
import PrintPage from "@/components/PrintPage/PrintPage";
import { routes } from "@/configs";
import useSelectedCompanyData from "@/queries/useSelectedCompanyData";
import useCustomNavigate from "@/services/useCustomNavigate";
import { useAppDispatch } from "@/stores";
import { addBreadcrumb, removeBreadcrumb, replaceUrlStateBreadcrumbType } from "@/stores/breadcrumbs.store";
import { EditOutlined, LeftOutlined } from "@ant-design/icons";
import { Button, Card, Descriptions, Tabs } from "antd";
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import "./GroupingView.scss";

interface GroupingViewProps {
  showHeader?: boolean;
  overrideId?: string;
}

const GroupingView: FC<GroupingViewProps> = (props) => {
  const { showHeader = true, overrideId = undefined } = props;

  const dispatch = useAppDispatch();

  const { navigate } = useCustomNavigate();
  const { id } = useParams<{ id: string }>();

  const pathUrl = window.location.pathname;

  const [groupingData, setGroupingData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [waterRights, setWaterRights] = useState<any[] | null>(null);

  useEffect(() => {
    refreshGroupingData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (pathUrl.includes("view") && groupingData) {
      dispatch(replaceUrlStateBreadcrumbType(groupingData?.name));
    }
  }, [pathUrl, groupingData]);

  const refreshGroupingData = async () => {
    setLoading(true);
    const response = await getGrouping(overrideId ?? id);
    if (response.ok) {
      const data = await response.json();
      setWaterRights(data.value.waterRights);
      setGroupingData(data.value);
    }
    setLoading(false);
  };

  const navigateEdit = () => {
    dispatch(
      addBreadcrumb({
        type: `Edit`,
        url: `grouping/${id}/edit`,
      })
    );
    navigate(routes.groupingEdit, { id: overrideId ?? id });
  };

  const navigateBack = () => {
    dispatch(removeBreadcrumb());
    navigate(-1);
  };

  const { canEdit } = useSelectedCompanyData(groupingData?.companyId);

  return (
    <Card
      className="groupingView"
      loading={loading}
      id="groupingView"
      title={showHeader ? `Grouping - ${loading ? "loading..." : groupingData?.name}` : undefined}
      extra={
        showHeader ? (
          <>
            <Button icon={<LeftOutlined />} style={{ marginRight: 5 }} onClick={navigateBack}>
              Back
            </Button>
            {canEdit && (
              <Button icon={<EditOutlined />} type="primary" onClick={navigateEdit}>
                Edit
              </Button>
            )}
          </>
        ) : undefined
      }
    >
      <PrintPage
        content={
          <div>
            <Descriptions style={{ paddingLeft: 0, width: 600 }} bordered size="small" labelStyle={{ width: 200 }} column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>
              <Descriptions.Item label="Name">{groupingData?.name}</Descriptions.Item>
            </Descriptions>
            <br />
            <Tabs
              defaultActiveKey="1"
              type="card"
              size="small"
              style={{ marginBottom: 32 }}
              items={[
                {
                  label: "Water Rights",
                  key: "waterRight",
                  children: <WaterRightTable isChild showAddWaterRight={false} showHeader waterRightIds={waterRights ?? []} />,
                },
                {
                  label: "Audit Log",
                  key: "auditLog",
                  children: <AuditLogTable entityId={groupingData?.id} entityType={AuditLogType.Grouping} />,
                },
              ]}
            />
          </div>
        }
      />
    </Card>
  );
};

export default GroupingView;
