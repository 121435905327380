import WaterRightTag from "@/components/WaterRightTag/WaterRightTag";
import { FC } from "react";

interface WaterRightTagListProps {
  internalTags?: any[];
  externalTags?: any[];
  style?: any;
  tagStyle?: any;
}

const WaterRightTagList: FC<WaterRightTagListProps> = (props) => {
  const { internalTags, externalTags, style, tagStyle } = props;
  return (
    <div style={style}>
      {(internalTags?.map((tag) => tag.label) ?? []).concat(externalTags ?? []).map((tag, index: number) => (
        <WaterRightTag style={tagStyle} status={tag} key={index} />
      ))}
    </div>
  );
};

export default WaterRightTagList;
