import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserRole } from "@/dtos/user.dto";
interface ICompanyState {
  selectedCompany: any | undefined;
  companies: string[];
  companyListData: any[];
  companyData: any | undefined;
  selectedCompanyId: string | undefined;
  reload: boolean;
  canSwitch: boolean;
  busyEditing: boolean;
  noFilterApplied: boolean;
}

const initialState: ICompanyState = {
  selectedCompanyId: undefined,
  selectedCompany: undefined,
  companies: [],
  companyListData: [],
  companyData: undefined,
  reload: false,
  canSwitch: true,
  busyEditing: false,
  noFilterApplied: false,
};

const companyStore = createSlice({
  name: "company",
  initialState,
  reducers: {
    saveSelectedCompanyId: (state: ICompanyState, action: PayloadAction<any>) => {
      state.selectedCompanyId = action.payload;
    },
    saveCompanyListData: (state: ICompanyState, action: PayloadAction<any>) => {
      state.companyListData = action.payload;
    },
    setReloadCompanies: (state: ICompanyState, action: PayloadAction<any>) => {
      state.reload = action.payload;
    },
    saveCompanies: (state: ICompanyState, action: PayloadAction<any>) => {
      state.companies = action.payload;
    },
    saveSelectedCompany: (state: ICompanyState, action: PayloadAction<any>) => {
      if (action.payload?._companyId === "all") {
        state.noFilterApplied = true;
        localStorage.removeItem("selectedCompanyId");
        state.selectedCompanyId = undefined;
        state.selectedCompany = {
          _companyId: "all",
        };
      } else {
        localStorage.setItem("selectedCompanyId", action.payload?.companyId);

        let userRole = UserRole[action.payload.userRole]?.label ?? "NONE";
        state.selectedCompany = { ...action.payload, userRole };
        state.noFilterApplied = false;
      }
    },
    saveCompany: (state: ICompanyState, action: PayloadAction<any>) => {
      state.companyData = action.payload;
    },
    clearCompanyListDataState: (state: ICompanyState) => {
      state.companyListData = [];
      state.companyData = undefined;
    },
    setCanSwitch: (state: ICompanyState, action: PayloadAction<any>) => {
      state.canSwitch = action.payload;
    },
    setBusyEditing: (state: ICompanyState, action: PayloadAction<any>) => {
      state.busyEditing = action.payload;
    },
  },
});

export const { saveCompanies, setReloadCompanies, saveSelectedCompany, saveCompany, saveSelectedCompanyId, saveCompanyListData, clearCompanyListDataState, setCanSwitch, setBusyEditing } =
  companyStore.actions;

export default companyStore.reducer;
