import { addWell, getWell, getWells, updateWell } from "@/apis/well.api";
import { LookupSelector, StatusTag } from "@/components";
import WaterRightTagList from "@/components/WaterRightTagList/WaterRightTagList";
import { constants, routes } from "@/configs";
import useFieldLookups from "@/queries/useFieldLookups";
import useWaterRightTagsQuery from "@/queries/useWaterRightTagsQuery";
import scrollToTop from "@/services/scrollToTop";
import useCustomNavigate from "@/services/useCustomNavigate";
import { getBase64, resizeImage } from "@/services/utils";
import { useAppDispatch } from "@/stores";
import { removeBreadcrumb, removeViewEditBreadcrumbs } from "@/stores/breadcrumbs.store";
import { clearWellState, saveWell } from "@/stores/well.store";
import { CloseOutlined, PlusOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { Alert, Button, Card, Col, Divider, Form, Image, Input, InputNumber, Row, Select, Space, Spin, Steps, Switch, Tag, Upload, UploadFile, message } from "antd";
import { useWatch } from "antd/es/form/Form";
import { TooltipPlacement } from "antd/es/tooltip";
import { RcFile } from "antd/es/upload";
import type { CustomTagProps } from "rc-select/lib/BaseSelect";
import { FC, useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import "./WellAddEdit.scss";

const WellAddEdit: FC = () => {
  const dispatch = useAppDispatch();
  const user = useAuth();

  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const { id } = useParams<{ id: string }>();

  const [form] = Form.useForm();

  const wellName = useWatch("name", form);
  const digital = useWatch("digital", form);
  const meterMaxValue: string = useWatch("meterMaxValue", form);
  const decimals: number = useWatch("decimals", form);

  const { navigate } = useCustomNavigate();

  const [wellData, setWellData] = useState<any>(undefined);
  const [addAnother, setAddAnother] = useState<boolean>(false);
  const [columns, setColumns] = useState<any[]>([]);
  const [wells, setWells] = useState<any[]>([]);
  const [reductionWell, setReductionWells] = useState<any[]>([]);
  const [fileList, setFileList] = useState<any[]>([]);
  const [uploading, setUploading] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(true);
  const [loadingReductionWells, setLoadingReductionWells] = useState<boolean>(true);

  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");

  const [isActive, setIsActive] = useState<boolean>(true);
  const [fieldIsActive, setFieldIsActive] = useState<boolean>(true);

  const { field, fields, isLoading: isFieldsLoading } = useFieldLookups(wellData?.fieldId, wellData?.companyId ?? selectedCompanyId, fieldIsActive);

  const { waterRightTag, waterRightTags, isLoading: isWaterRightTagsLoading } = useWaterRightTagsQuery(wellData?.waterRightId, wellData?.companyId ?? selectedCompanyId, isActive);

  useEffect(() => {
    if (!field) {
      setFieldIsActive(false);
    }
    // eslint-disable-next-line
  }, [field]);

  useEffect(() => {
    if (!waterRightTag) {
      setIsActive(false);
    }
    // eslint-disable-next-line
  }, [waterRightTag]);

  useEffect(() => {
    calculateColumns();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    form?.resetFields();
    return () => {
      dispatch(clearWellState());
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (wellData?.imageIds) {
      setFileList(
        wellData.imageIds.map((imageId: any, index: number) => {
          return {
            uid: imageId,
            name: "image.png",
            status: "done",
            url: `${constants.baseApiUrl}/well/image/${imageId}/download`,
            existingFile: true,
          };
        })
      );
    }
  }, [wellData]);

  useEffect(() => {
    if (id) getWellData();
    else {
      setLoading(false);
      resetDefaults();
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (selectedCompanyId || wellData?.companyId) {
      // refreshFieldsList();
      // refreshWaterRightTags();
      refreshWellsList();
    }
    // eslint-disable-next-line
  }, [wellData, selectedCompanyId]);

  const resetDefaults = () => {
    form.setFieldsValue({
      status: true,
    });
    scrollToTop();
  };

  useEffect(() => {
    if (wellData) {
      const { fieldId, waterRightId, meterMaxValue, meterMultiplier, ...rest } = wellData;
      if (!loading)
        form!.setFieldsValue({
          ...rest,
          meterMaxValue: meterMaxValue === 0 ? undefined : `${meterMaxValue}`,
          meterMultiplier: meterMultiplier === 0 ? undefined : meterMultiplier,
          fieldId: fieldId,
          waterRightId: waterRightId,
        });
    }
    // eslint-disable-next-line
  }, [wellData, loading]);

  const refreshWellsList = async (searchString: any = null) => {
    setLoadingReductionWells(true);
    const response = await getWells({
      companyId: wellData?.companyId ?? selectedCompanyId,
    });
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setWells(data.value);
      }
    }
    setLoadingReductionWells(false);
  };

  const getWellData = async () => {
    setLoading(true);

    const response = await getWell(id);

    if (response.ok) {
      const data = await response.json();

      if (data.value.reductionWells === null) data.value.reductionWells = [];

      setWellData(data.value);
    }

    setLoading(false);
  };

  const onFinish = async (values: any) => {
    setLoading(true);

    const { directionImages, ...rest } = values;
    const data = { ...wellData, ...rest, companyId: wellData?.companyId ?? selectedCompanyId };

    if (id) {
      const oldImageIds = fileList.filter((item: any) => item.existingFile).map((file: any) => file.uid);
      const newImageIds = fileList.filter((item: any) => !item.existingFile).map((file: any) => JSON.parse(file.xhr.response)?.value);

      const response = await updateWell(id, {
        ...data,
        decimals: !digital ? null : decimals,
        imageIds: [...oldImageIds, ...newImageIds],
      });

      if (response.ok) {
        dispatch(saveWell(undefined));
        message.success("Successfully updated the well / meter");
        navigate(routes.wellList);
      } else {
        message.error("Failed to update the well / meter");
      }
    } else {
      const response = await addWell({
        ...data,
        decimals: !digital ? null : decimals,
        imageIds: fileList.map((file: any) => JSON.parse(file.xhr.response)?.value),
      });

      if (response.ok) {
        message.success("Successfully added the well / meter");

        if (addAnother) {
          dispatch(clearWellState());
          form?.resetFields();
          setFileList([]);
          resetDefaults();
          navigate(routes.wellAdd);
        } else navigate(routes.wellList);
      } else {
        message.error("Failed to add the well / meter");
      }
    }

    if (id) {
      dispatch(removeViewEditBreadcrumbs());
    } else if (!addAnother) dispatch(removeBreadcrumb());

    setAddAnother(false);
    setLoading(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const handleAddAnother = () => {
    form
      .validateFields()
      .then((values) => {
        setAddAnother(true);
        form?.submit();
      })
      .catch((errors) => {
        scrollToTop();
        message.error(`Please fill in the required fields`, 5);
      });
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        onFinish(values);
      })
      .catch((errors) => {
        scrollToTop();
        message.error(`Please fill in the required fields`, 5);
      });
  };

  const calculateColumns = () => {
    let tempColumns = [
      {
        title: "Water Right / File Number",
        key: "fileNumber",
        dataIndex: "fileNumber",
        fixed: "left",
        render: (val: any, record: any) => val,
      },
    ];

    setColumns(tempColumns);
  };

  const renderActionButtons = (placement: TooltipPlacement) => (
    <Space>
      {!id && (
        <>
          <Button disabled={uploading} loading={loading} type="primary" icon={<ThunderboltOutlined />} onClick={() => handleSubmit()}>
            Save
          </Button>
          <Button disabled={uploading} loading={loading} type="primary" icon={<ThunderboltOutlined />} onClick={handleAddAnother}>
            Save + Add Another
          </Button>
        </>
      )}
      {id && (
        <Button disabled={uploading} loading={loading} type="primary" icon={<ThunderboltOutlined />} onClick={() => handleSubmit()}>
          Save
        </Button>
      )}
      <Button
        disabled={uploading}
        loading={loading}
        icon={<CloseOutlined />}
        onClick={() => {
          if (id) {
            dispatch(removeBreadcrumb());
            navigate(-1);
          } else {
            dispatch(removeBreadcrumb());
            navigate(routes.wellList);
          }
        }}
      >
        Cancel
      </Button>
    </Space>
  );

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  const tagRender = (props: CustomTagProps) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag onMouseDown={onPreventMouseDown} closable={closable} onClose={onClose} style={{ marginRight: 3 }}>
        {wells?.find((waterRight) => waterRight?.id === value)?.name}
      </Tag>
    );
  };

  const handleOnChange = (value: boolean) => {
    if (!value) setIsActive(false);
    else setIsActive(true);
  };

  const handleFieldOnChange = (value: boolean) => {
    if (!value) setFieldIsActive(false);
    else setFieldIsActive(true);
  };

  return (
    <Card className="wellAddEdit" id="wellAddEdit" title={id ? "Edit Well / Meter" : "Add Well / Meter"} extra={renderActionButtons("bottomRight")} actions={[renderActionButtons("top")]}>
      <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete="off">
        <Steps
          direction="vertical"
          items={[
            {
              title: "Well / Meter Details",
              status: "process",
              description: (
                <Card>
                  <Form.Item
                    label="Water Right"
                    name="waterRightId"
                    rules={[
                      {
                        required: true,
                        message: "Please select a water right",
                      },
                    ]}
                  >
                    <Select
                      suffixIcon={isWaterRightTagsLoading ? <Spin /> : undefined}
                      loading={isWaterRightTagsLoading}
                      disabled={isWaterRightTagsLoading}
                      showArrow={true}
                      notFoundContent={isWaterRightTagsLoading ? <Spin size="small" /> : null}
                      placeholder={isWaterRightTagsLoading ? "Loading..." : "Select a water right"}
                      showSearch
                      optionFilterProp="value"
                      filterOption={(input, option) => {
                        const searchVal = input?.toLowerCase();
                        let list: any[] = [];
                        waterRightTags.forEach((waterRight: any) => {
                          const fields = waterRight?.fields ?? [];
                          const wells = waterRight?.wells ?? [];

                          let match: boolean = false;

                          fields.forEach((field: string) => {
                            if (field && field.toLowerCase().includes(searchVal)) match = true;
                          });

                          wells.forEach((well: string) => {
                            if (well && well.toLowerCase().includes(searchVal)) match = true;
                          });

                          if (waterRight?.fileNumber?.toLowerCase()?.includes(searchVal)) match = true;

                          if (match) list.push(waterRight.id);
                        });
                        return list.includes(option?.value) ?? [];
                      }}
                      options={waterRightTags?.map((waterRight: any) => {
                        return {
                          value: waterRight.id,
                          label: waterRight?.fileNumber,
                        };
                      })}
                      optionRender={(option) => {
                        const waterRight = waterRightTags?.find((waterRight: any) => waterRight.id === option.value);

                        return (
                          <>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              {waterRight?.fileNumber}
                            </div>
                            <div
                              style={{
                                display: "inline-flex",
                                justifyContent: "space-between",
                                paddingLeft: 0,
                                marginLeft: 0,
                              }}
                            >
                              <StatusTag style={{ margin: 2, marginLeft: 0 }} status={waterRight?.status} />{" "}
                              <WaterRightTagList internalTags={waterRight?.internalTags} externalTags={waterRight?.externalTags} />
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                fontSize: 12,
                              }}
                            >
                              <b style={{ paddingRight: 4 }}>PDIV:</b> {waterRight?.pdiv ?? "-"}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                fontSize: 12,
                              }}
                            >
                              <b style={{ paddingRight: 4 }}>CIN:</b> {waterRight?.cin ?? "-"}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                fontSize: 12,
                              }}
                            >
                              <b style={{ paddingRight: 4 }}>Wells:</b> {waterRight?.wells?.join(", ")}
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                alignItems: "center",
                                fontSize: 12,
                              }}
                            >
                              <b style={{ paddingRight: 4 }}>Fields:</b> {waterRight?.fields?.join(", ")}
                            </div>
                            <Divider style={{ margin: 0, padding: 0 }} />
                          </>
                        );
                      }}
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          <Divider style={{ margin: "8px 0" }} />
                          <Space style={{ padding: "0 8px 4px" }}>
                            <Switch defaultChecked={waterRightTag?.status === "active" ? true : false} onChange={handleOnChange} checkedChildren="Active Only" unCheckedChildren="All" />
                          </Space>
                        </>
                      )}
                    ></Select>
                  </Form.Item>

                  <Form.Item
                    label="Field"
                    name="fieldId"
                    rules={[
                      {
                        required: true,
                        message: "Please select a field",
                      },
                    ]}
                  >
                    <Select
                      suffixIcon={isFieldsLoading ? <Spin /> : undefined}
                      loading={isFieldsLoading}
                      disabled={isFieldsLoading}
                      notFoundContent={isFieldsLoading ? <Spin size="small" /> : null}
                      placeholder={isFieldsLoading ? "Loading..." : "Select a field"}
                      showSearch
                      optionFilterProp="value"
                      filterOption={(input, option) => {
                        const searchVal = input?.toLowerCase();
                        let list: any[] = [];

                        fields?.forEach((field: any) => {
                          let match: boolean = false;

                          if (field?.name?.toLowerCase()?.includes(searchVal)) match = true;

                          if (match) list.push(field?.id);
                        });

                        return list.includes(option?.value) ?? [];
                      }}
                      options={fields
                        ?.sort((a: any, b: any) => a?.name?.localeCompare(b?.name, "en", { numeric: true, sensitivity: "base" }))
                        .map((field: any) => {
                          return {
                            value: field?.id,
                            label: field?.name,
                          };
                        })}
                      optionRender={(option) => {
                        const field = fields?.find((field: any) => field?.id === option.value);

                        return (
                          <>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              {field.name} <StatusTag status={field.status} />
                            </div>
                          </>
                        );
                      }}
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          <Divider style={{ margin: "8px 0" }} />
                          <Space style={{ padding: "0 8px 4px" }}>
                            <Switch defaultChecked={field?.status} onChange={handleFieldOnChange} checkedChildren="Active Only" unCheckedChildren="All" />
                          </Space>
                        </>
                      )}
                    ></Select>
                  </Form.Item>

                  <Form.Item label="Well Name" name="name" rules={[{ required: true, message: "Please enter a well name" }]}>
                    <Input placeholder="Enter a well name" />
                  </Form.Item>

                  <Form.Item label="Status" name="status" valuePropName="checked">
                    <Switch checkedChildren="Active" unCheckedChildren="Inactive" />
                  </Form.Item>
                </Card>
              ),
            },
            {
              title: "Meter Specifications",
              status: "process",
              description: (
                <Card>
                  <Form.Item wrapperCol={{ span: 14, offset: 6 }}>
                    <Alert showIcon description="Note: It is advised to complete this information when physically at the meter." type="info" />
                  </Form.Item>
                  <Form.Item label="Meter Serial Number" name="meterSerialNumber">
                    <Input placeholder="Enter the meter serial number" />
                  </Form.Item>
                  <Form.Item label="Meter Units" name="meterUnits">
                    <LookupSelector propertyToSet="meterUnits" form={form} placeholder="Select the meter units" lookupType="meterUnits" />
                  </Form.Item>

                  <Form.Item label="Meter Multiplier" name="meterMultiplier">
                    <LookupSelector propertyToSet="meterMultiplier" form={form} placeholder="Select the meter multiplier" lookupType="meterMultiplier" />
                  </Form.Item>

                  <Form.Item label="Meter Max Value (Rollover #)" name="meterMaxValue">
                    <LookupSelector propertyToSet="meterMaxValue" form={form} placeholder="Select the meter max value" lookupType="meterMaxValue" />
                  </Form.Item>

                  <Form.Item label="Is Digital Meter?" name="digital" valuePropName="checked">
                    <Switch checkedChildren="Yes" unCheckedChildren="No" />
                  </Form.Item>

                  {digital && (
                    <Form.Item label="Number of Decimal Places" name="decimals">
                      <InputNumber style={{ minWidth: 230 }} min={0} max={meterMaxValue?.length - 1} defaultValue={0} placeholder="Enter the number of decimal places" />
                    </Form.Item>
                  )}

                  {digital && meterMaxValue && decimals !== undefined && decimals > 0 && (
                    <Form.Item wrapperCol={{ span: 14, offset: 6 }}>
                      <Alert
                        type="info"
                        showIcon
                        description={
                          <>
                            The readings will display on the meter in the following format dependent on the type of digital meter you have. <br />
                            <br />
                            <Row>
                              <Col span={12}>
                                Meter Max Value = 99 999 999 <br />
                                Number of Decimal Places = 3 <br />
                                <br />
                                For example <br />
                                <br />
                                00000.112 <br />
                                0.112 <br />
                                <br />
                                50.456 <br />
                                00050.456 <br />
                                <br />
                                99999.999 <br />
                              </Col>
                              <Col span={12}>
                                When entering the reading please enter the values seen on the meter. <br />
                                <br />
                                For example: <br />
                                <br />
                                00000112
                                <br />
                                0112
                                <br />
                                <br />
                                50456
                                <br />
                                00050456
                                <br />
                                <br />
                                99999999
                              </Col>
                            </Row>
                          </>
                        }
                      />
                    </Form.Item>
                  )}

                  <Form.Item label="Latitude" name="latitude">
                    <InputNumber style={{ minWidth: 230 }} placeholder="Enter the latitude value" />
                  </Form.Item>

                  <Form.Item label="Longitude" name="longitude">
                    <InputNumber style={{ minWidth: 230 }} placeholder="Enter the longitude value" />
                  </Form.Item>
                </Card>
              ),
            },
            {
              title: "Deduction Wells",
              status: "process",
              description: (
                <Card>
                  <Form.Item
                    label="Deduction Well"
                    name="reductionWells"
                    getValueFromEvent={(val: any) => {
                      setReductionWells(wells.filter((well: any) => val.includes(well.id)));
                      return val;
                    }}
                  >
                    <Select tagRender={tagRender} maxTagCount={3} mode="multiple" loading={loadingReductionWells} placeholder="Select a deduction well" showSearch optionFilterProp="label">
                      {wells
                        .filter((item: any) => item !== undefined)
                        .map((well: any) => {
                          return (
                            <Select.Option value={well.id} key={well.id} label={well.name}>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                }}
                              >
                                {well.name} <StatusTag status={well.status} />
                              </div>
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                  {reductionWell.length > 0 && (
                    <Form.Item wrapperCol={{ span: 14, offset: 6 }}>
                      <Alert
                        showIcon
                        description={`You are subtracting meter${reductionWell.length === 1 ? "" : "s"} ${reductionWell.map((well) => well.name).join(", ")} from ${wellName ?? "this well/meter"}`}
                        type="info"
                      />
                    </Form.Item>
                  )}
                </Card>
              ),
            },
            {
              title: "Directions",
              status: "process",
              description: (
                <Card>
                  <Form.Item label="Notes" name="directionNotes">
                    <Input.TextArea rows={4} placeholder="Enter well direction notes" />
                  </Form.Item>

                  <Form.Item label="Images" name="directionImages">
                    <Upload
                      multiple={true}
                      action={`${constants.baseApiUrl}/well/image/upload`}
                      headers={{
                        Authorization: `Bearer ${user.user?.access_token}`,
                      }}
                      listType="picture-card"
                      fileList={fileList}
                      onChange={(info: any) => {
                        if (info.file.status === "uploading") {
                          setUploading(true);
                        }
                        if (info.file.status === "done") {
                          setUploading(false);
                        }
                        setFileList(info.fileList);
                      }}
                      beforeUpload={resizeImage}
                      onPreview={handlePreview}
                    >
                      <div>
                        <PlusOutlined />
                        <div style={{ marginTop: 8 }}>Upload</div>
                      </div>
                    </Upload>
                  </Form.Item>
                </Card>
              ),
            },
          ]}
        />
      </Form>
      <Image
        style={{ display: "none" }}
        preview={{
          visible: previewOpen,
          scaleStep: 0.5,
          src: previewImage,
          onVisibleChange: (value) => {
            setPreviewOpen(value);
          },
        }}
      />
    </Card>
  );
};

export default WellAddEdit;
