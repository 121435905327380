import { keepPreviousData, useQuery, useQueryClient } from "@tanstack/react-query";
import { getPagedFields } from "@/apis/field.api";
import { getPagedGroupings } from "@/apis/grouping.api";
import defaultQueryConfig from "@/configs/defaultQueryConfig";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import CacheKeys from "@/types/CacheKeys";

type GroupingPagedListProps = {
  pagination: {
    page: number;
    pageSize: number;
  };
  searchString?: string;
};

export default function useGroupingPagedList(props: GroupingPagedListProps) {
  const { profile } = useSelector((state: any) => state.user);
  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const queryClient = useQueryClient();

  const {
    isFetched,
    isRefetching,
    data: groupings,
    isFetching,
    isPlaceholderData,
  } = useQuery({
    queryKey: [
      CacheKeys.groupings,
      CacheKeys.list,
      {
        profileId: profile?.id,
        companyId: selectedCompanyId,
        ...props,
      },
    ],
    queryFn: async () => {
      const response = await getPagedGroupings({
        companyId: selectedCompanyId,
        ...props,
      });

      if (response.ok) {
        const data = await response.json();
        if (data.isSuccess) return data.value;

        throw new Error("Failed to fetch paged groupings");
      }
    },
    placeholderData: keepPreviousData,
    ...defaultQueryConfig,
    enabled: !!profile?.id,
  });

  const invalidateGroupings = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [CacheKeys.groupings, CacheKeys.list],
    });
  }, [queryClient]);

  const isLoading = !isFetched;

  return {
    isLoading,
    isFetching,
    isRefetching,
    isPlaceholderData,
    groupings,
    invalidateGroupings,
  };
}
