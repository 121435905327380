import { CloseOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { Button, Card, Divider, Form, Select, Space, Switch } from "antd";
import { useWatch } from "antd/es/form/Form";
import { getRestrictions } from "@/apis/restriction.api";
import { constants } from "@/configs";
import dayjs from "dayjs";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import GeneratedRestrictionConfigurationReport from "./GeneratedRestrictionConfigurationReport";
import { StatusTag, WaterRightTag } from "@/components";

const RestrictionConfigurationReport: FC = () => {
  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const [form] = Form.useForm();

  const [loadingRestrictions, setLoadingRestrictions] = useState<boolean>(false);
  const [generatingReport, setGeneratingReport] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean | null>(true);

  const [restrictions, setRestrictions] = useState<any[]>([]);

  const [reportConfig, setReportConfig] = useState<any>(undefined);

  const restrictionIds: any[] = useWatch("restrictionIds", form);

  useEffect(() => {
    handleReset();
    // eslint-disable-next-line
  }, [selectedCompanyId]);

  useEffect(() => {
    if (selectedCompanyId) {
      refreshRestrictions();
    }
    // eslint-disable-next-line
  }, [selectedCompanyId, isActive]);

  useEffect(() => {
    if (generatingReport) setTimeout(() => setGeneratingReport(false), 500);
    // eslint-disable-next-line
  }, [generatingReport]);

  const refreshRestrictions = async () => {
    setLoadingRestrictions(true);
    const request = { companyId: selectedCompanyId, isActive };
    const response: any = await getRestrictions(request);
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) setRestrictions(data.value);
    }
    setLoadingRestrictions(false);
  };

  const handleGenerateReport = () => {
    setGeneratingReport(true);

    const tempRestrictionIds: any[] = (restrictions ?? []).filter((restriction: any) => (restrictionIds ?? []).includes(restriction.id)).map((restriction: any) => restriction.id);

    setReportConfig({
      restrictionIds: tempRestrictionIds,
      restrictions: restrictions
        .filter((restriction: any) => tempRestrictionIds?.includes(restriction.id))
        .map((restriction: any) => restriction.name)
        .join(", "),
      generatedDate: dayjs().format(constants.dateTimeFormatWithSeconds),
    });
  };

  const handleReset = () => {
    form?.resetFields();
    setReportConfig(undefined);
  };

  const handleOnChange = (value: boolean) => {
    if (!value) setIsActive(null);
    else setIsActive(true);
  };

  const renderActionsButtons = () => {
    return (
      <Space>
        <Button loading={loadingRestrictions || generatingReport} disabled={loadingRestrictions || generatingReport} type="primary" icon={<ThunderboltOutlined />} onClick={handleGenerateReport}>
          Generate Report
        </Button>
        <Button loading={loadingRestrictions || generatingReport} disabled={loadingRestrictions || generatingReport} icon={<CloseOutlined />} onClick={handleReset}>
          Reset
        </Button>
      </Space>
    );
  };

  return (
    <>
      <Card title="Configuration" actions={[renderActionsButtons()]} bodyStyle={{ padding: 12, margin: 0 }}>
        <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} autoComplete="off" style={{ margin: 0 }}>
          <Form.Item label="Restrictions" name="restrictionIds" style={{ margin: 0 }}>
            <Select
              allowClear
              mode="multiple"
              loading={loadingRestrictions}
              placeholder="Select restrictions"
              showSearch
              optionFilterProp="label"
              maxTagCount={3}
              disabled={generatingReport}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <Space style={{ padding: "0 8px 4px" }}>
                    <Button
                      onClick={() =>
                        form.setFieldValue(
                          "restrictionIds",
                          restrictions.filter((item: any) => item !== undefined).map((restriction) => restriction.id)
                        )
                      }
                    >
                      Select All
                    </Button>
                    <Button danger onClick={() => form.setFieldValue("restrictionIds", [])}>
                      Clear List
                    </Button>
                    <Switch defaultChecked onChange={handleOnChange} checkedChildren="Active Only" unCheckedChildren="All" />
                  </Space>
                </>
              )}
            >
              {restrictions
                .filter((item: any) => item !== undefined)
                .map((restriction: any) => {
                  return (
                    <Select.Option value={restriction.id} key={restriction.id} label={restriction.name}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        {restriction.name} <StatusTag status={restriction.status} />
                      </div>
                      <div>
                        <WaterRightTag status={typeof restriction.type === "number" ? restriction.type : restriction.type.toUpperCase()} />
                      </div>
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Form>
      </Card>
      {generatingReport && (
        <Card title="Generating Report">
          <div style={{ textAlign: "center" }}>Loading...</div>
        </Card>
      )}
      {!generatingReport && reportConfig && <GeneratedRestrictionConfigurationReport reportConfig={reportConfig} />}
    </>
  );
};

export default RestrictionConfigurationReport;
