import { removePlaceOfUse, removeWaterRightPlaceOfUse } from "@/apis/waterright.api";
import PlaceOfUseAddModal from "@/components/PlaceOfUseAddModal/PlaceOfUseAddModal";
import useComapnyConfigLookup from "@/queries/useCompanyConfigLookup";
import usePlaceOfUsePagedList from "@/queries/usePlaceOfUsePageQuery";
import useSelectedCompanyData from "@/queries/useSelectedCompanyData";
import useCustomNavigate from "@/services/useCustomNavigate";
import { PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import { Button, Card, Input, Popconfirm, Space, Table, message } from "antd";
import { FC, useEffect, useMemo, useState } from "react";

interface Props {
  places?: any[];
  refreshWaterRightData?: () => void;
  waterRightId?: string;
  companyId: string;
  isChild?: boolean;
}

const PlaceOfUseTable: FC<Props> = (props) => {
  const { places, waterRightId = null, refreshWaterRightData, companyId, isChild } = props;

  const { navigate, updateQueryParams, queryParams } = useCustomNavigate();

  // const [placesOfUse, setPlacesOfUse] = useState<any>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [columns, setColumns] = useState<any[]>([]);
  const [placeOfUseModalState, setPlaceOfUseModalState] = useState<any>({
    open: false,
  });
  const [searchStringValue, setSearchStringValue] = useState<string | undefined>("");
  const [searchString, setSearchString] = useState<string | undefined>("");

  const { companyConfig } = useComapnyConfigLookup(companyId);

  const [queryParamState, setQueryParamState] = useState<any>({ page: 1, pageSize: 10 });

  const {
    placesOfUse: tempPlacesOfUse,
    isLoading: isPlacesOfUseLoading,
    invalidatePlacesOfUse,
  } = usePlaceOfUsePagedList({
    pagination: {
      page: isChild ? queryParamState.page : queryParams?.page ?? "1",
      pageSize: isChild ? queryParamState.pageSize : queryParams?.pageSize ?? "10",
    },
    // searchString,
    companyId: companyId ?? null,
  });

  const placesOfUse: any[] = useMemo(() => {
    const filteredPlacesOfUse: string[] = [];
    const placesOfUseToMap = places ? places : tempPlacesOfUse?.list[0]?.placesOfUse;

    // Handle if table tempPlacesOfUse or just places
    if (searchString) {
      placesOfUseToMap?.map((placeOfUse: string) => {
        if (placeOfUse.toLowerCase().includes(searchString.toLowerCase())) filteredPlacesOfUse.push(placeOfUse);
      });
    } else placesOfUseToMap?.map((placeOfUse: string) => filteredPlacesOfUse.push(placeOfUse));

    // const placesOfUseToMap = places ? places : tempPlacesOfUse?.list[0]?.placesOfUse;
    const data = filteredPlacesOfUse
      ?.sort((a: string, b: string) => a.localeCompare(b, "en", { numeric: true, sensitivity: "base" }))
      ?.map((place: any) => {
        return { name: place };
      });

    return data;
  }, [tempPlacesOfUse, places, searchString]);

  // useEffect(() => {
  //   console.log("company config", companyConfig);
  // }, [companyConfig])

  useEffect(() => {
    console.log("company id", companyId);
  }, [companyId]);

  useEffect(() => {
    calculateColumns();
  }, [tempPlacesOfUse, places]);

  const handleRemovePlaceOfUse = async (placeOfUse: string) => {
    setLoading(true);

    const response = waterRightId ? await removeWaterRightPlaceOfUse({ waterRightId, placeOfUse }) : await removePlaceOfUse({ companyId: companyId, placeOfUse });
    if (response.ok) {
      if (!waterRightId) {
        handleRefresh();
      } else refreshWaterRightData && refreshWaterRightData();
      message.success("Successfully removed place of use");
    } else message.error("Failed to remove place of use");

    setLoading(false);
  };

  // const refreshPlacesOfUse = async (searchString: any = null) => {
  //   setLoading(true);

  //   if (places) {
  //     const filteredPlacesOfUse: string[] = [];

  //     if (searchString) {
  //       places?.map((placeOfUse: string) => {
  //         if (placeOfUse.toLowerCase().includes(searchString.toLowerCase())) filteredPlacesOfUse.push(placeOfUse);
  //       });
  //     } else places?.map((placeOfUse: string) => filteredPlacesOfUse.push(placeOfUse));

  //     // refreshWaterRightData && refreshWaterRightData();
  //     setPlacesOfUse(
  //       filteredPlacesOfUse
  //         ?.sort((a: string, b: string) => a.localeCompare(b, "en", { numeric: true, sensitivity: "base" }))
  //         ?.map((place) => {
  //           return { name: place };
  //         })
  //     );
  //   } else {
  //     const response = await getPlaceOfUse(selectedCompanyId);
  //     if (response.ok) {
  //       const data = await response.json();
  //       const filteredPlacesOfUse: string[] = [];

  //       if (searchString) {
  //         data.value?.placesOfUse?.map((placeOfUse: string) => {
  //           if (placeOfUse.toLowerCase().includes(searchString.toLowerCase())) filteredPlacesOfUse.push(placeOfUse);
  //         });
  //       } else data.value?.placesOfUse?.map((placeOfUse: string) => filteredPlacesOfUse.push(placeOfUse));

  //       setPlacesOfUse(
  //         filteredPlacesOfUse?.length > 0
  //           ? filteredPlacesOfUse
  //               .sort((a: string, b: string) =>
  //                 a.localeCompare(b, "en", {
  //                   numeric: true,
  //                   sensitivity: "base",
  //                 })
  //               )
  //               ?.map((placeOfUse: any) => {
  //                 return { name: placeOfUse };
  //               })
  //           : []
  //       );
  //     }
  //   }

  //   setLoading(false);
  // };

  const calculateColumns = () => {
    let tempColumns: any[] = [
      {
        title: "Place of Use",
        key: "name",
        dataIndex: "name",
        render: (val: any, record: any) => val,
      },
    ];

    if (canEdit)
      tempColumns.push({
        title: "Actions",
        width: 80,
        render: (val: any, record: any) => (
          <Popconfirm
            title="Remove place of use"
            description={`Are you sure you want to remove this place of use${waterRightId ? " from this water right?" : "?"}`}
            okText="Yes"
            cancelText="No"
            onConfirm={() => handleRemovePlaceOfUse(record.name)}
          >
            <Button style={{ paddingLeft: 0 }} type="link">
              Remove
            </Button>
          </Popconfirm>
        ),
      });

    setColumns(tempColumns);
  };

  // const handleEditPlaceOfUse = () => {
  //     dispatch(addBreadcrumb({
  //         type: `Place Of Use / Add`,
  //         url: `placeofuse/add`
  //     }))
  //     navigate(routes.placeOfUseAdd, { id: selectedCompanyId });
  // }

  const handlePlaceOfUseClose = () => {
    setPlaceOfUseModalState({ open: false });
  };

  const handlePlaceOfUseSuccess = () => {
    message.success("Successfully added place of use");
    handleRefresh();
    setPlaceOfUseModalState({ open: false });
  };

  const handleRefresh = () => {
    setSearchString("");
    setSearchStringValue("");
    if (isChild) {
      setQueryParamState({ page: 1, pageSize: 10 });
    } else updateQueryParams({ page: 1, pageSize: 10 });
    invalidatePlacesOfUse();
  };

  const { canEdit } = useSelectedCompanyData(companyId);

  return (
    <Card
      className="placeOfUseList"
      id="placeOfUseList"
      title="Places of Use"
      extra={
        <Space>
          {canEdit && (
            <Button onClick={() => setPlaceOfUseModalState({ open: true })} type="primary" icon={<PlusOutlined />}>
              Add Place of Use
            </Button>
          )}
        </Space>
      }
    >
      <Space direction="vertical" style={{ width: "100%" }}>
        <Space style={{ paddingBottom: 10 }}>
          <Input.Search
            disabled={loading}
            placeholder="Search places of use"
            onSearch={(val) => {
              setSearchString(val);
              if (isChild) {
                setQueryParamState({ page: 1, pageSize: 10 });
              } else updateQueryParams({ page: 1, pageSize: 10 });
            }}
            onChange={(val) => setSearchStringValue(val.currentTarget.value)}
            value={searchStringValue}
            style={{ width: 400 }}
            allowClear
          />
          <Button icon={<ReloadOutlined />} disabled={loading} onClick={handleRefresh}>
            Refresh
          </Button>
        </Space>
      </Space>
      <Table loading={isPlacesOfUseLoading || loading} columns={columns} dataSource={placesOfUse ?? []} size="small" />
      {placeOfUseModalState.open && (
        <PlaceOfUseAddModal
          companyId={companyId}
          open={placeOfUseModalState.open}
          master={waterRightId ? false : true}
          // masterPlacesOfUse={!waterRightId ? (places ? places?.map((placeOfUse: any) => placeOfUse?.name) : tempPlacesOfUse?.list?.map((placeOfUse: any) => placeOfUse?.name)) : null}
          masterPlacesOfUse={!waterRightId ? placesOfUse?.map((placeOfUse: any) => placeOfUse?.name) : undefined}
          waterRightPlacesOfUse={waterRightId ? places : undefined}
          waterRightId={waterRightId ?? null}
          onCancel={handlePlaceOfUseClose}
          onSuccess={handlePlaceOfUseSuccess}
          refreshWaterRightData={refreshWaterRightData}
        />
      )}
    </Card>
  );
};

export default PlaceOfUseTable;
