import { WaterRightTable } from "@/components";
import { FC } from "react";

import "./WaterRightList.scss";

const WaterRightList: FC = () => {
  return <WaterRightTable showAddWaterRight showHeader trackTableState />;
};

export default WaterRightList;
