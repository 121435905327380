import { CloseOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { Button, Card, Divider, Form, Select, Space, Switch, Tag } from "antd";
import { useWatch } from "antd/es/form/Form";
import { getWells } from "@/apis/well.api";
import { StatusTag, WaterRightSelector } from "@/components";
import { constants } from "@/configs";
import dayjs from "dayjs";
import type { CustomTagProps } from "rc-select/lib/BaseSelect";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import GeneratedNotesReport from "./GeneratedNotesReport";

const NotesReport: FC = () => {
  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const [form] = Form.useForm();

  const [loadingWells, setLoadingWells] = useState<boolean>(false);
  const [generatingReport, setGeneratingReport] = useState<boolean>(false);

  const [reportConfig, setReportConfig] = useState<any>(undefined);
  const [waterRights, setWaterRights] = useState<any[]>([]);
  const [wells, setWells] = useState<any[]>([]);
  const [isActive, setIsActive] = useState<boolean | null>(true);

  const waterRightIds: any[] = useWatch("waterRightIds", form);
  const wellIds: any[] = useWatch("wellIds", form);

  useEffect(() => {
    handleReset();
    // eslint-disable-next-line
  }, [selectedCompanyId]);

  useEffect(() => {
    if (selectedCompanyId) {
      refreshWellsList();
    }
    // eslint-disable-next-line
  }, [selectedCompanyId, isActive]);

  useEffect(() => {
    if (generatingReport) setTimeout(() => setGeneratingReport(false), 500);
    // eslint-disable-next-line
  }, [generatingReport]);

  const refreshWellsList = async () => {
    setLoadingWells(true);

    const request = { companyId: selectedCompanyId, isActive };

    const response = await getWells(request);
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setWells(data.value);
      }
    }
    setLoadingWells(false);
  };

  const handleGenerateReport = () => {
    setGeneratingReport(true);

    const wellData: any[] = [];

    wellIds?.forEach((wellId) => {
      const wellName = wells.find((well) => well.id === wellId)?.name;
      wellData.push({ wellId, name: wellName });
    });

    const wellsReport = {
      wells: wellData,
    };

    const waterRightData: any[] = [];

    waterRightIds?.forEach((waterRightId) => {
      const waterRightFileNumber = waterRights.find((waterRight) => waterRight.id === waterRightId)?.fileNumber;
      waterRightData.push({ waterRightId, fileNumber: waterRightFileNumber });
    });

    const waterRightsReport = {
      waterRights: waterRightData,
    };

    setReportConfig({
      waterRights: waterRightsReport,
      wells: wellsReport,
      generatedDate: dayjs().format(constants.dateTimeFormatWithSeconds),
    });
  };

  const handleReset = () => {
    form?.resetFields();
    setReportConfig(undefined);
  };

  const renderActionsButtons = () => {
    return (
      <Space>
        <Button loading={generatingReport} disabled={generatingReport} type="primary" icon={<ThunderboltOutlined />} onClick={handleGenerateReport}>
          Generate Report
        </Button>
        <Button loading={generatingReport} disabled={generatingReport} icon={<CloseOutlined />} onClick={handleReset}>
          Reset
        </Button>
      </Space>
    );
  };

  const handleOnChange = (value: boolean) => {
    if (!value) setIsActive(null);
    else setIsActive(true);
  };

  const tagRender = (props: CustomTagProps) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag onMouseDown={onPreventMouseDown} closable={closable} onClose={onClose} style={{ marginRight: 3 }}>
        {wells?.find((well) => well?.id === value)?.name}
      </Tag>
    );
  };

  return (
    <>
      <Card title="Configuration" actions={[renderActionsButtons()]} bodyStyle={{ padding: 12, margin: 0 }}>
        <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} autoComplete="off" style={{ margin: 0 }}>
          <Form.Item label="Wells" name="wellIds" style={{ margin: 0 }}>
            <Select
              allowClear
              mode="multiple"
              loading={loadingWells}
              tagRender={tagRender}
              placeholder="Select wells"
              showSearch
              optionFilterProp="label"
              maxTagCount={3}
              disabled={generatingReport}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <Space style={{ padding: "0 8px 4px" }}>
                    <Button
                      onClick={() =>
                        form.setFieldValue(
                          "wellIds",
                          wells.filter((item: any) => item !== undefined).map((well) => well.id)
                        )
                      }
                    >
                      Select All
                    </Button>
                    <Button danger onClick={() => form.setFieldValue("wellIds", [])}>
                      Clear List
                    </Button>
                    <Switch defaultChecked onChange={handleOnChange} checkedChildren="Active Only" unCheckedChildren="All" />
                  </Space>
                </>
              )}
            >
              {wells
                .filter((item: any) => item !== undefined)
                .map((well: any) => {
                  return (
                    <Select.Option value={well.id} key={well.id} label={well.name}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        {well.name} <StatusTag status={well.status} />
                      </div>
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
          <WaterRightSelector style={{ marginTop: 10 }} form={form} label={"Water Rights"} placeholder={"Select water rights"} propertyToSet={"waterRightIds"} onLoad={setWaterRights} />
        </Form>
      </Card>
      {generatingReport && (
        <Card title="Generating Report">
          <div style={{ textAlign: "center" }}>Loading...</div>
        </Card>
      )}
      {!generatingReport && reportConfig && <GeneratedNotesReport reportConfig={reportConfig} />}
    </>
  );
};

export default NotesReport;
