import { Button, Card } from "antd";
import { generateSummaryReport } from "@/apis/waterright.api";
import { FC, useState } from "react";
import { useSelector } from "react-redux";
import { openReportFromGeneratedFile } from "@/services/utils";

const ExportWaterRightInformationReport: FC = () => {
  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const [generatingSummaryReport, setGeneratingSummaryReport] = useState<boolean>(false);

  const downloadSummaryReport = async () => {
    setGeneratingSummaryReport(true);

    const request = { companyId: selectedCompanyId };

    const response = await generateSummaryReport(request);
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        openReportFromGeneratedFile(data.value);
      }
    }

    setGeneratingSummaryReport(false);
  };
  return (
    <Card title="Water Right Information Export" bodyStyle={{ padding: 12, margin: 0 }}>
      <div
        style={{
          margin: "auto",
          width: "50%",
          textAlign: "center",
        }}
      >
        <Button loading={generatingSummaryReport} disabled={generatingSummaryReport} type="primary" onClick={(e) => downloadSummaryReport()}>
          Download
        </Button>
      </div>
    </Card>
  );
};

export default ExportWaterRightInformationReport;
