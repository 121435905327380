import { CloseOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { Button, Card, Divider, Form, Select, Space, Switch } from "antd";
import { useWatch } from "antd/es/form/Form";
import { getFields } from "@/apis/field.api";
import { constants } from "@/configs";
import dayjs from "dayjs";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { StatusTag } from "@/components";
import GeneratedFieldConfigurationReport from "./GeneratedFieldConfigurationReport";

const FieldConfigurationReport: FC = () => {
  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const [form] = Form.useForm();

  const [loadingFields, setLoadingFields] = useState<boolean>(false);
  const [generatingReport, setGeneratingReport] = useState<boolean>(false);
  const [fieldIsActive, setFieldIsActive] = useState<boolean | null>(true);

  const [fields, setFields] = useState<any[]>([]);

  const [reportConfig, setReportConfig] = useState<any>(undefined);

  const fieldIds: any[] = useWatch("fieldIds", form);

  useEffect(() => {
    handleReset();
    // eslint-disable-next-line
  }, [selectedCompanyId]);

  useEffect(() => {
    if (selectedCompanyId) {
      refreshFields();
    }
    // eslint-disable-next-line
  }, [selectedCompanyId, fieldIsActive]);

  useEffect(() => {
    if (generatingReport) setTimeout(() => setGeneratingReport(false), 500);
    // eslint-disable-next-line
  }, [generatingReport]);

  const refreshFields = async () => {
    setLoadingFields(true);

    const request = { companyId: selectedCompanyId, isActive: fieldIsActive };

    const response = await getFields(request);
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setFields(data.value);
      }
    }
    setLoadingFields(false);
  };

  const handleGenerateReport = () => {
    setGeneratingReport(true);

    const tempFieldIds: any[] = (fields ?? []).filter((field: any) => (fieldIds ?? []).includes(field.id)).map((field: any) => field.id);
    setReportConfig({
      fieldIds: tempFieldIds,
      fields: fields
        .filter((field: any) => tempFieldIds?.includes(field.id))
        .map((field: any) => field.name)
        .join(", "),
      generatedDate: dayjs().format(constants.dateTimeFormatWithSeconds),
    });
  };

  const handleReset = () => {
    form?.resetFields();
    setReportConfig(undefined);
  };

  const handleFieldOnChange = (value: boolean) => {
    if (!value) setFieldIsActive(null);
    else setFieldIsActive(true);
  };

  const renderActionsButtons = () => {
    return (
      <Space>
        <Button loading={loadingFields || generatingReport} disabled={loadingFields || generatingReport} type="primary" icon={<ThunderboltOutlined />} onClick={handleGenerateReport}>
          Generate Report
        </Button>
        <Button loading={loadingFields || generatingReport} disabled={loadingFields || generatingReport} icon={<CloseOutlined />} onClick={handleReset}>
          Reset
        </Button>
      </Space>
    );
  };

  return (
    <>
      <Card title="Configuration" actions={[renderActionsButtons()]} bodyStyle={{ padding: 12, margin: 0 }}>
        <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} autoComplete="off" style={{ margin: 0 }}>
          <Form.Item label="Fields" name="fieldIds" style={{ margin: 0, marginBottom: 10 }}>
            <Select
              allowClear
              mode="multiple"
              loading={loadingFields}
              placeholder="Select fields"
              showSearch
              optionFilterProp="value"
              maxTagCount={3}
              disabled={loadingFields || generatingReport}
              options={fields?.map((field) => {
                return {
                  value: field?.id,
                  label: field?.name,
                };
              })}
              optionRender={(option) => {
                const field = fields?.find((field) => field?.id === option.value);

                return (
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      {field.name} <StatusTag status={field.status} />
                    </div>
                  </>
                );
              }}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <Space style={{ padding: "0 8px 4px" }}>
                    <Button
                      onClick={() =>
                        form.setFieldValue(
                          "fieldIds",
                          fields.filter((item: any) => item !== undefined).map((field) => field.id)
                        )
                      }
                    >
                      Select All
                    </Button>
                    <Button danger onClick={() => form.setFieldValue("fieldIds", [])}>
                      Clear List
                    </Button>
                    <Switch defaultChecked onChange={handleFieldOnChange} checkedChildren="Active Only" unCheckedChildren="All" />
                  </Space>
                </>
              )}
            ></Select>
          </Form.Item>
        </Form>
      </Card>
      {generatingReport && (
        <Card title="Generating Report">
          <div style={{ textAlign: "center" }}>Loading...</div>
        </Card>
      )}
      {!generatingReport && reportConfig && <GeneratedFieldConfigurationReport reportConfig={reportConfig} />}
    </>
  );
};

export default FieldConfigurationReport;
