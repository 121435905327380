// import { ChartConfig, ChartContainer, ChartStyle, ChartTooltip, ChartTooltipContent } from "@/components/ui/chart";
// import useCompanyMonthlyUsageQuery from "@/queries/useCompanyMonthlyUsageQuery";
// import { convertNumToMonth } from "@/services/utils";
// import { Card } from "antd";
// import { FC, useMemo, useState } from "react";
// import { useSelector } from "react-redux";
// import { Bar, BarChart, CartesianGrid, XAxis, YAxis } from "recharts";

// const TotalCompanyWaterUsageByMonth: FC = () => {
//     const { selectedCompanyId } = useSelector((state: any) => state.company);

//     const { companyMonthlyUsages, companyMonthlyUsagesFetched, invalidateCompanyMonthlyUsages } = useCompanyMonthlyUsageQuery(selectedCompanyId);

//     const [chartConfig, setChartConfig] = useState<ChartConfig>({});

//     const id = "bar-company-total-monthly-usage";

//     // const chartData = [
//     //     { month: "January", desktop: 186 },
//     //     { month: "February", desktop: 305 },
//     //     { month: "March", desktop: 237 },
//     //     { month: "April", desktop: 73 },
//     //     { month: "May", desktop: 209 },
//     //     { month: "June", desktop: 214 },
//     //   ]
//     //   const chartConfig = {
//     //     desktop: {
//     //       label: "Desktop",
//     //       color: "hsl(var(--chart-1))",
//     //     },
//     //   } satisfies ChartConfig

//     // useEffect(() => {
//     //     if (lookups) {
//     //         const permitTypeLookups = lookups.find((lookup: any) => lookup.map === "permittype");
//     //         setPermitTypeLookups(
//     //             permitTypeLookups?.options?.map((permitType: any) => {
//     //                 return {
//     //                     name: permitType.code + " - " + permitType.name,
//     //                     value: permitType.value,
//     //                 };
//     //             })
//     //         );
//     //     }
//     // }, [lookups]);

//     const chartData: any[] = useMemo(() => {
//         const data: any[] = companyMonthlyUsages?.map((usage: any, index: number) => {
//             return {
//                 month: convertNumToMonth(usage?.month),
//                 usage: usage?.monthUsage,
//             };
//         });

//         let chartConfig: any = {};

//         setChartConfig({
//             month: {
//                 label: "Usage",
//                 color: "hsl(var(--chart-1))",
//             }
//         });

//         return data;
//     }, [companyMonthlyUsages]);

//     return (
//         <Card bordered={false} className="removeBoxShadow removeMargin">
//             <div style={{ textAlign: "left", width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap", paddingBottom: 25 }}>
//                 <div style={{ flex: 1, fontWeight: "semibold", fontSize: 16 }}>Permit Type</div>
//             </div>
//             <ChartStyle id={id} config={chartConfig} />
//             <ChartContainer id={id} config={chartConfig} className="mx-auto aspect-square w-full max-w-[300px]">
//                 <BarChart
//                     accessibilityLayer
//                     data={chartData}
//                     layout="vertical"
//                     margin={{
//                         left: -20,
//                     }}
//                 >
//                     <XAxis type="number" dataKey="usage" hide />
//                     <YAxis
//                         dataKey="month"
//                         type="category"
//                         tickLine={false}
//                         tickMargin={10}
//                         axisLine={false}
//                         tickFormatter={(value) => value.slice(0, 3)}
//                     />
//                     <ChartTooltip
//                         cursor={false}
//                         content={<ChartTooltipContent hideLabel />}
//                     />
//                     <Bar dataKey="usage" fill="var(--color-usage)" radius={5} />
//                 </BarChart>
//             </ChartContainer>
//         </Card>
//     );
// };

// export default TotalCompanyWaterUsageByMonth;

import { ChartConfig, ChartContainer, ChartStyle, ChartTooltip, ChartTooltipContent } from "@/components/ui/chart";
import useCompanyMonthlyUsageQuery from "@/queries/useCompanyMonthlyUsageQuery";
import { convertNumToMonth } from "@/services/utils";
import { Card } from "antd";
import { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { Bar, BarChart, CartesianGrid, LabelList, XAxis, YAxis } from "recharts";

const TotalCompanyWaterUsageByMonth: FC = () => {
  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const { companyMonthlyUsages, companyMonthlyUsagesFetched, invalidateCompanyMonthlyUsages } = useCompanyMonthlyUsageQuery(selectedCompanyId);

  const id = "bar-company-monthly-usage";

  // const chartData = [
  //     { month: "January", desktop: 186, mobile: 80 },
  //     { month: "February", desktop: 305, mobile: 200 },
  //     { month: "March", desktop: 237, mobile: 120 },
  //     { month: "April", desktop: 73, mobile: 190 },
  //     { month: "May", desktop: 209, mobile: 130 },
  //     { month: "June", desktop: 214, mobile: 140 },
  //     { month: "July", desktop: 214, mobile: 140 },
  //     { month: "August", desktop: 214, mobile: 140 },
  //     { month: "September", desktop: 214, mobile: 140 },
  //     { month: "October", desktop: 214, mobile: 140 },
  //     { month: "November", desktop: 214, mobile: 140 },
  //     { month: "December", desktop: 214, mobile: 140 },
  // ]

  // month = month
  // desktop = usage
  const chartConfig = {
    usage: {
      label: "Usage",
      color: "hsl(var(--chart-1))",
    },
    label: {
      color: "hsl(var(--background))",
    },
  } satisfies ChartConfig;

  const chartData: any[] = useMemo(() => {
    const data: any[] = companyMonthlyUsages?.map((usage: any, index: number) => {
      return {
        month: convertNumToMonth(usage?.month),
        usage: usage?.monthUsage,
      };
    });

    return data;
  }, [companyMonthlyUsages]);

  return (
    <Card bordered={false} className="removeBoxShadow removeMargin">
      <div style={{ textAlign: "left", width: "100%", display: "flex", flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap", paddingBottom: 25 }}>
        <div style={{ flex: 1, fontWeight: "semibold", fontSize: 16 }}>Total Water Used By Month</div>
      </div>
      <ChartStyle id={id} config={chartConfig} />
      <ChartContainer id={id} config={chartConfig} className="mx-auto aspect-square w-full max-w-[300px]">
        <BarChart
          accessibilityLayer
          data={chartData}
          layout="vertical"
          margin={{
            left: -20,
          }}
        >
          <CartesianGrid vertical={true} />
          <XAxis type="number" dataKey="usage" hide />
          <YAxis dataKey="month" type="category" tickLine={false} tickMargin={10} axisLine={false} tickFormatter={(value) => value.slice(0, 3)} />
          <ChartTooltip cursor={false} content={<ChartTooltipContent hideLabel />} />
          <Bar dataKey="usage" fill="var(--color-usage)" radius={5}>
            {/* <LabelList
                            dataKey="usage"
                            position="right"
                            offset={8}
                            className="fill-foreground"
                            fontSize={12}
                        /> */}
          </Bar>
        </BarChart>
      </ChartContainer>
    </Card>
  );
};

export default TotalCompanyWaterUsageByMonth;
