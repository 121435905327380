export default {
  notifications: "notifications",
  fields: "fields",
  wells: "wells",
  waterRights: "waterRights",
  restrictions: "restrictions",
  groupings: "groupings",
  companies: "companies",
  stocks: "stocks",
  placesOfUse: "placesOfUse",
  list: "list",
  lookup: "lookup",
  companiesLookup: "companiesLookup",
  fieldsLookup: "fieldsLookup",
  restrictionsLookup: "restrictionsLookup",
  wellsLookup: "wellsLookup",
  waterRightsLookup: "waterRightsLookup",
  dashboardCounts: "dashboardCounts",
  unreadNotificationCount: "unreadNotificationCount",
  calculation: "calculation",
  identity: "identity",
  waterRightLastReading: "waterRightLastReading",
  waterRightTags: "waterRightTags",
  restrictionDashbord: "restrictionDashbord",
  companyConfig: "companyConfig",
  waterRightLastReadingInfinite: "waterRightLastReadingInfinite",
  restrictionDashbordInfinite: "restrictionDashbordInfinite",
  wellDashboard: "wellDashboard",
  wellMapDashboard: "wellMapDashboard",
  oustandingInvites: "oustandingInvites",
  actionsCounter: "actionsCounter",
  details: "details",
  wellsForDashboardMap: "wellsForDashboardMap",
  waterRightQuantityPerCompany: "waterRightQuantityPerCompany",
  companyTotalAllocation: "companyTotalAllocation",
  companyAverageInchesPerAcre: "companyAverageInchesPerAcre",
  companyTotalAllocationByAcres: "companyTotalAllocationByAcres",
  waterAllocationPerPermitType: "waterAllocationPerPermitType",
  waterAllocationPerPriorityType: "waterAllocationPerPriorityType",
  cropAllocationByAcres: "cropAllocationByAcres",
  companyMonthlyUsage: "companyMonthlyUsage",
  outstandingCompanyCounts: "outstandingCompanyCounts",
} as const;
