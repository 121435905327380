import { syncRestrictionCalculations } from "@/apis/restriction.api";
import LookupFilter from "@/components/LookupFilter/LookupFilter";
import WaterRightPermitTypeLabel from "@/components/Lookups/WaterRightLabels/WaterRightPermitTypeLabel";
import RemainingDays from "@/components/RemainingDays/RemainingDays";
import RestrictionPopover from "@/components/RestrictionPopover/RestrictionPopover";
import UnitsConverter from "@/components/UnitsConverter/UnitsConverter";
import WaterRightTagList from "@/components/WaterRightTagList/WaterRightTagList";
import { routes } from "@/configs";
import useComapnyConfigLookup from "@/queries/useCompanyConfigLookup";
import useListWellDashboardQuery from "@/queries/useListWellDashboardQuery";
import useRestrictionLookups from "@/queries/useRestrictionLookups";
import useCustomNavigate from "@/services/useCustomNavigate";
import { useAppDispatch } from "@/stores";
import { addBreadcrumb } from "@/stores/breadcrumbs.store";
import { ReloadOutlined } from "@ant-design/icons";
import { useIntersectionObserver } from "@uidotdev/usehooks";
import { Button, Card, Input, Space, Table, TableProps } from "antd";
import { FilterValue, SorterResult } from "antd/es/table/interface";
import dayjs from "dayjs";
import { FC, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import LastReadingFrequency from "../LastReadingFrequency/LastReadingFrequency";
import CompanyLabel from "../Lookups/CompanyLabel";
import { WellView } from "@/pages";

const WellDashboardTable: FC = () => {
  const dispatch = useAppDispatch();

  const { navigate, updateQueryParams, queryParams } = useCustomNavigate();

  const { selectedCompanyId, selectedCompany } = useSelector((state: any) => state.company);
  const { lookups } = useSelector((state: any) => state.lookups);

  const [wellDownRef, wellDownEntry] = useIntersectionObserver();

  const [searchStringValue, setSearchStringValue] = useState<string | undefined>("");
  const [searchString, setSearchString] = useState<string | undefined>("");
  const [columns, setColumns] = useState<any[]>([]);
  const [permitTypes, setPermitTypes] = useState<any[]>([]);

  const [calculations, setCalculations] = useState<any[]>([]);

  const [loadingRestrictionCalculations, setLoadingRestrictionCalculations] = useState<boolean>(true);
  const [restrictionCalculations, setRestrictionCalculations] = useState<any[]>([]);

  const [filteredWellInfo, setFilteredWellInfo] = useState<Record<string, FilterValue | null>>({});
  const [sortedWellInfo, setSortedWellInfo] = useState<SorterResult<any>>({});

  const { companyConfigs, isLoading: isCompanyConfigsLoading } = useComapnyConfigLookup();

  const {
    dashboardWells: wells,
    fetchNextPage: fetchNextWellPage,
    invalidateDashboardWells,
    hasNextPage: hasNextWellPage,
    isLoading: isWellsLoading,
    totalItemCount: wellTotalCount,
    currentItemCount: wellCurrentCount,
  } = useListWellDashboardQuery({
    searchString: searchString,
    sortOrder:
      Object.keys(sortedWellInfo).length === 0
        ? {
            Name: "ASC",
          }
        : {
            Name: sortedWellInfo.field === "name" ? (sortedWellInfo.order === "ascend" ? "ASC" : "DESC") : undefined,
            FieldName: sortedWellInfo.field === "fieldName" ? (sortedWellInfo.order === "ascend" ? "ASC" : "DESC") : undefined,
            FileNumber: sortedWellInfo.field === "waterRightFileNumber" ? (sortedWellInfo.order === "ascend" ? "ASC" : "DESC") : undefined,
            PermitType: sortedWellInfo.field === "permitType" ? (sortedWellInfo.order === "ascend" ? "ASC" : "DESC") : undefined,
            LastReadingDate: sortedWellInfo.field === "lastReadingDate" ? (sortedWellInfo.order === "ascend" ? "ASC" : "DESC") : undefined,
            RemainingDays: sortedWellInfo.field === "remainingDays" ? (sortedWellInfo.order === "ascend" ? "ASC" : "DESC") : undefined,
          },
    permitTypeFilters: filteredWellInfo?.permitType === undefined ? undefined : filteredWellInfo?.permitType?.map((permitType: any) => permitType.toString()),
    pageIdentifier: "wellDashboardTable",
  });

  const { restrictions } = useRestrictionLookups();

  useEffect(() => {
    if (wellDownEntry?.isIntersecting && hasNextWellPage) {
      fetchNextWellPage();
    }
    // eslint-disable-next-line
  }, [wellDownEntry?.isIntersecting, hasNextWellPage]);

  // TODO: Refresh Calculations
  useEffect(() => {
    refreshRestrictionCalculations();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    calculateColumns();
    // eslint-disable-next-line
  }, [wells, restrictionCalculations, sortedWellInfo, filteredWellInfo]);

  const refreshRestrictionCalculations = async () => {
    setLoadingRestrictionCalculations(true);
    const response = await syncRestrictionCalculations({});
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setRestrictionCalculations(data.value);
      }
    }
    setLoadingRestrictionCalculations(false);
  };

  const handleViewWell = (record: any) => {
    dispatch(
      addBreadcrumb({
        type: `${record.name}`,
        url: `well/${record.id}/view`,
      })
    );
    navigate(routes.wellView, { id: record.id });
  };

  const handleViewField = (record: any) => {
    dispatch(
      addBreadcrumb({
        type: `${record.name}`,
        url: `field/${record.fieldId}/view`,
      })
    );
    navigate(routes.fieldView, { id: record.fieldId });
  };

  const handleViewWaterRight = (record: any) => {
    dispatch(
      addBreadcrumb({
        type: `${record.waterRightFileNumber}`,
        url: `waterright/${record.waterRightId}/view`,
      })
    );

    navigate(routes.waterRightView, { id: record.waterRightId });
  };

  const renderRestrictionSummaries = (record: any) => {
    const restrictionIds = restrictions
      .filter((restriction: any) => restriction?.waterRightIds?.includes(record.waterRightId) && restriction?.status === true)
      ?.map((restriction: any) => restriction.id);
    const existingCalculations = restrictionCalculations.filter((calculation: any) => restrictionIds.includes(calculation.id));

    if (existingCalculations.length === 0) return <>-</>;

    return existingCalculations.map((calculation: any, index: number, list: any[]) => (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          marginBottom: index < list.length - 1 ? 10 : undefined,
        }}
      >
        <RestrictionPopover
          companyId={record?.companyId}
          selectedCompany={selectedCompany}
          startDate={dayjs(calculation?.startDate)}
          endDate={dayjs(calculation?.endDate)}
          remainingYears={calculation?.remainingYears}
          calculation={calculation}
          isReport={false}
          type={calculation?.type}
          termQuantity={calculation?.termQuantity}
        />
        <div style={{ fontSize: "0.8em" }}>
          {selectedCompanyId && selectedCompany?.settings?.metric !== "acrefeet" && (
            <div>
              <UnitsConverter fromUnits="gallons" showUnitsLabel value={calculation.remainingYearlyAverage} />
            </div>
          )}
          <div>
            <UnitsConverter fromUnits="gallons" toUnits="acrefeet" showUnitsLabel value={calculation.remainingYearlyAverage} />
          </div>
          {calculation.inchesPerAcre !== null && (
            <div>
              <UnitsConverter fromUnits="inchesperacre" toUnits="inchesperacre" value={calculation.inchesPerAcre} postFix="Inches / Acre" />{" "}
            </div>
          )}
        </div>
      </div>
    ));
  };

  const calculateWaterRightTagsFilter = () => {
    const restrictionType = lookups.find((lookup: any) => lookup.map === "restrictiontype");
    if (restrictionType !== undefined) {
      const typeForRestriction = restrictionType.options.map((lookup: any) => {
        return { text: lookup.name, value: lookup.value };
      });

      return typeForRestriction;
    } else return null;
  };

  const filtersForWaterRight = useMemo(() => {
    const permitTypeLookups = [...(lookups?.find((lookup: any) => lookup?.map === "permittype")?.options ?? [])];

    const filteredPermitTypeLookups = permitTypeLookups
      ?.sort((a: any, b: any) => a?.name?.localeCompare(b?.name, "en", { numeric: true, sensitivity: "base" }))
      ?.map((lookup: any) => {
        return { text: lookup?.name, value: lookup?.value };
      });

    return filteredPermitTypeLookups;
  }, [lookups]);

  const percentageAvailable = (remainingUsage: number, availableQuantity: number) => {
    if (remainingUsage === undefined || availableQuantity === undefined || availableQuantity === 0) return undefined;
    if (remainingUsage < 0) return "0.00 %";
    return ((remainingUsage / availableQuantity) * 100).toFixed(2) + " %";
  };

  const calculateColumns = () => {
    let tempColumns = [
      {
        title: "Well Name",
        key: "name",
        dataIndex: "name",
        sorter: true,
        sortOrder: sortedWellInfo?.columnKey === "name" ? sortedWellInfo?.order : null,
        render: (val: any, record: any, index: any) => (
          <>
            <Button style={{ paddingLeft: 0 }} type="link" onClick={() => handleViewWell(record)}>
              {val}
            </Button>
            {!selectedCompanyId && (
              <div style={{ padding: 0, margin: 0, fontSize: 11 }}>
                (<CompanyLabel companyId={record?.companyId} />)
              </div>
            )}
            {index === wellCurrentCount - 1 ? <span ref={wellDownRef} /> : null}
          </>
        ),
      },
      {
        title: "Field Name",
        key: "fieldName",
        dataIndex: "fieldName",
        sorter: true,
        sortOrder: sortedWellInfo?.columnKey === "fieldName" ? sortedWellInfo?.order : null,
        render: (val: any, record: any) => (
          <Button style={{ paddingLeft: 0 }} type="link" onClick={() => handleViewField(record)}>
            {val}
          </Button>
        ),
      },
      {
        title: (
          <>
            Water Right /<br /> File Number
          </>
        ),
        key: "waterRightFileNumber",
        dataIndex: "waterRightFileNumber",
        // filters: calculateWaterRightTagsFilter(),
        // onFilter: (value: string, record: any) => {
        //     let found = false;
        //     if (record?.tags?.externalTags?.includes(value)) found = true;

        //     if (record?.tags?.internalTags && record?.tags?.internalTags?.length > 0) {
        //         record?.tags?.internalTags?.forEach((tag: any) => {
        //             if (tag?.label?.toLowerCase() === value) found = true;
        //         });
        //     }

        //     return found;
        // },
        sorter: true,
        sortOrder: sortedWellInfo?.columnKey === "waterRightFileNumber" ? sortedWellInfo?.order : null,
        render: (val: any, record: any) => (
          <>
            <Button style={{ paddingLeft: 0 }} type="link" onClick={() => handleViewWaterRight(record)}>
              {val}{" "}
              {/* {record?.disableWarnings ? (
                                <Popover
                                    content={
                                        <>
                                            <b>Warnings Disabled</b> <br />
                                            Acknowledged By: {record?.markedAsDisabledUserUserName} <br />
                                            Date: {dayjs(record?.markedAsDisabledDate ?? undefined).format(constants.dateTimeFormat)} <br />
                                        </>
                                    }
                                >
                                    <WarningOutlined style={{ color: "red" }} />
                                </Popover>
                            ) : undefined} */}
            </Button>
            <WaterRightTagList internalTags={record?.internalTags} externalTags={record?.externalTags} />
          </>
        ),
      },
      {
        title: (
          <>
            PDIV <br /> CIN
          </>
        ),
        key: "pdivcin",
        width: 100,
        render: (val: any, record: any, index: any) => (
          <>
            <div style={{ padding: 0, margin: 0, fontSize: 11 }}>{record?.pdiv ?? "-"}</div>
            <div style={{ padding: 0, margin: 0, fontSize: 11 }}>{record?.cin ?? "-"}</div>
          </>
        ),
      },
      {
        title: "Permit Type",
        key: "permitType",
        dataIndex: "permitType",
        filters: filtersForWaterRight,
        filteredValue: filteredWellInfo?.permitType || null,
        sorter: true,
        sortOrder: sortedWellInfo?.columnKey === "permitType" ? sortedWellInfo?.order : null,
        render: (val: any, record: any) => <WaterRightPermitTypeLabel waterRightId={record?.waterRightId} />,
      },
      {
        title: "Last Reading Date",
        key: "lastReadingDate",
        dataIndex: "lastReadingDate",
        sorter: true,
        sortOrder: sortedWellInfo?.columnKey === "lastReadingDate" ? sortedWellInfo?.order : null,
        render: (val: any, record: any) => {
          if (val) {
            return <LastReadingFrequency type={"meter"} date={val} meterReadingFrequency={companyConfigs?.find((c: any) => c?.id === record?.companyId)?.settings?.meterReadingFrequency} />;
          } else return "-";
        },
      },
      {
        title: "Estimated Pump Days Left / %",
        key: "remainingDays",
        dataIndex: "remainingDays",
        sorter: true,
        sortOrder: sortedWellInfo?.columnKey === "remainingDays" ? sortedWellInfo?.order : null,
        // sorter: (a: any, b: any) => a.termQuantity - b.termQuantity,
        render: (val: any, record: any) => (
          <>
            <RemainingDays remainingDays={val} /> <br />
            {percentageAvailable(record?.remainingUsage, record?.availableQuantity)}
          </>
        ),
      },
      {
        title: "Remaining Yearly Average",
        key: "yearlyAverage",
        dataIndex: "yearlyAverage",
        width: 200,
        render: (val: any, record: any) => renderRestrictionSummaries(record),
      },
    ];

    // if (!selectedCompanyId) {
    //     tempColumns.splice(!isDashBoard ? 9 : 8, 0, {
    //         title: "Company",
    //         key: "companyId",
    //         dataIndex: "companyId",
    //         render: (val: any, record: any) => <CompanyLabel companyId={val} isActive={false} />,
    //     });
    // }

    setColumns(tempColumns.filter((x) => x));
  };

  const handleRefresh = () => {
    setSearchString("");
    setSearchStringValue("");
    updateQueryParams({ page: 1, pageSize: 10 });
    setSortedWellInfo({});
    setFilteredWellInfo({});
    invalidateDashboardWells();
    refreshRestrictionCalculations();
  };

  const handleWellTableChange: TableProps<any>["onChange"] = (pagination, filters, sorter) => {
    updateQueryParams({
      wr_page: pagination.current,
      wr_pageSize: pagination.pageSize,
    });

    setSortedWellInfo(sorter as SorterResult<any>);
    setFilteredWellInfo(filters);
  };

  const renderExpandData = (record: any, expanded: boolean) => {
    return (
      <WellView
        expanded={expanded}
        // refreshWaterRightCalculations={refreshWaterRightCalculations}
        // refreshWaterRightViewCalculations={refreshWaterRightViewCalculations}
        // refreshWellCalculations={refreshWellCalculations}
        overrideId={record.id}
        showReadingsFirst
        showHeader={false}
      />
    );
  };

  return (
    <>
      <Card
        className="dashboardWellList"
        id="dashboardWellList"
        title={
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
            <span>Wells / Meters</span>
            <Space direction="horizontal">
              <Space>
                <Input.Search
                  disabled={isWellsLoading}
                  placeholder="Search wells"
                  onSearch={(val) => {
                    setSearchString(val);
                    updateQueryParams({ w_page: 1, w_pageSize: 10 });
                  }}
                  onChange={(val) => setSearchStringValue(val.currentTarget.value)}
                  value={searchStringValue}
                  style={{ width: 400, fontWeight: "normal" }}
                  allowClear
                />
                <Button icon={<ReloadOutlined />} disabled={isWellsLoading} onClick={handleRefresh}>
                  Refresh
                </Button>
                {/* <LookupFilter
                                disabled={isWellsLoading}
                                onSelect={(values) => {
                                    setPermitTypes(values);
                                }}
                                lookupType={"permittype"}
                                value={permitTypes}
                                onClear={() => setPermitTypes([])}
                                onSelectAll={() => setPermitTypes(lookups?.find((lookup: any) => lookup.map === "permittype")?.options?.map((options: any) => options.value))}
                            /> */}
              </Space>
            </Space>
            <span>Total: {wellTotalCount ?? "-"}</span>
          </div>
        }
        bordered={false}
        styles={{ body: { padding: 0, borderTop: "#d9d9d9 1px solid", height: 470 } }}
      >
        <Table
          className="customScrollBarStyle"
          rowKey={(row: any) => row.id}
          expandable={{
            expandedRowRender: (record: any, index: number, indent: number, expanded: boolean) => (expanded ? renderExpandData(record, expanded) : null),
          }}
          loading={isWellsLoading}
          columns={columns}
          dataSource={wells ?? []}
          size="small"
          onChange={handleWellTableChange}
          pagination={false}
          scroll={{ y: 400, x: 600, scrollToFirstRowOnChange: true }}
          style={{ minHeight: 400 }}
        />
      </Card>
    </>
  );
};

export default WellDashboardTable;
