import { getPagedPlaceOfUse, getPagedWaterRights } from "@/apis/waterright.api";
import defaultQueryConfig from "@/configs/defaultQueryConfig";
import CacheKeys from "@/types/CacheKeys";
import { keepPreviousData, useQuery, useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { useSelector } from "react-redux";

type PlaceOfUsePagedListProps = {
  pagination: {
    page: number;
    pageSize: number;
  };
  searchString?: string;
  companyId?: string | null;
};

export default function usePlaceOfUsePagedList(props: PlaceOfUsePagedListProps) {
  const { profile } = useSelector((state: any) => state.user);
  // const { selectedCompanyId } = useSelector((state: any) => state.company);

  const queryClient = useQueryClient();

  const {
    isFetched,
    isRefetching,
    data: placesOfUse,
    isFetching,
    isPlaceholderData,
  } = useQuery({
    queryKey: [
      CacheKeys.placesOfUse,
      CacheKeys.list,
      {
        profileId: profile?.id,
        ...props,
      },
    ],
    queryFn: async () => {
      const response = await getPagedPlaceOfUse({
        ...props,
      });

      if (response.ok) {
        const data = await response.json();
        if (data.isSuccess) return data.value;

        throw new Error("Failed to fetch paged places of use");
      }
    },
    placeholderData: keepPreviousData,
    ...defaultQueryConfig,
    enabled: !!profile?.id,
  });

  const invalidatePlacesOfUse = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [CacheKeys.placesOfUse, CacheKeys.list],
    });
  }, [queryClient]);

  const isLoading = !isFetched;

  return {
    isLoading,
    isFetching,
    isRefetching,
    isPlaceholderData,
    placesOfUse,
    invalidatePlacesOfUse,
  };
}
