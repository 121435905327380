import useActionsCounter from "@/queries/useActionsCounter";
import useOutstandingCompanyCounts from "@/queries/useOutstandingCompanyCounts";
import useCustomNavigate from "@/services/useCustomNavigate";
import { DashboardOutlined, EnvironmentOutlined, FileSearchOutlined, LineChartOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { Badge, Button, Card, Tabs, Tooltip } from "antd";
import ButtonGroup from "antd/es/button/button-group";
import { FC, useMemo } from "react";
import ActionsDashboard from "./components/ActionsDashboard";
import GaugesDashboard from "./components/GaugesDashboard";
import MapDashboard from "./components/MapDashboard";
import OperationsDashboard from "./components/OperationsDashboard";
import AnalyticsDashboard from "./components/AnalyticsDashboard";

const Dashboard: FC = () => {
  const { searchParams, updateQueryParams } = useCustomNavigate();

  const { actionsCount } = useActionsCounter();
  const { count: outstandingCompanyCounts } = useOutstandingCompanyCounts();

  const tabs: {
    label: string;
    key: string;
    children: JSX.Element;
    icon: JSX.Element;
    hidden?: boolean;
    tooltip?: string;
    hiddenTooltip?: string;
    badgeCount?: number | (() => number);
  }[] = [
    // {
    //   label: "Analytics",
    //   key: "1",
    //   children: <AnalyticsDashboard />,
    //   icon: <LineChartOutlined />,
    //   tooltip: "An analytics overview of the company data",
    // },
    {
      label: "Operations",
      key: "2",
      children: <OperationsDashboard />,
      icon: <FileSearchOutlined />,
      tooltip: "An operational overview of the company data",
    },
    {
      label: "Gauges",
      key: "3",
      children: <GaugesDashboard />,
      icon: <DashboardOutlined />,
      tooltip: "A visual overview of the company data",
    },
    {
      label: "Map",
      key: "4",
      children: <MapDashboard />,
      icon: <EnvironmentOutlined />,
      tooltip: "A visual map of all wells",
    },
    {
      label: "Actions",
      key: "5",
      children: <ActionsDashboard />,
      icon: <ThunderboltOutlined />,
      badgeCount: actionsCount + (outstandingCompanyCounts === undefined ? 0 : outstandingCompanyCounts),
    },
  ];

  const tab = useMemo(() => searchParams?.get("tab") ?? "2", [searchParams]);

  return (
    <Card
      bordered={false}
      style={{ background: "transparent" }}
      className="removeBoxShadow removeMargin"
      styles={{
        body: {
          padding: 0,
          marginRight: 8,
        },
      }}
    >
      {tab && (
        <Tabs
          activeKey={tab}
          centered
          tabPosition={"top"}
          items={tabs}
          tabBarStyle={{ marginBottom: 5 }}
          renderTabBar={(props) => (
            <Card
              styles={{
                body: {
                  padding: 5,
                  marginRight: 8,
                },
              }}
            >
              <ButtonGroup>
                {tabs.map((tab, index) => (
                  <Tooltip key={tab.key} title={tab.hidden ? tab.hiddenTooltip : tab.tooltip}>
                    <Badge count={typeof tab.badgeCount === "number" ? tab.badgeCount : tab.badgeCount?.()} style={{ zIndex: 99 }} offset={[0, 5]} color="red">
                      <Button key={tab.key} type={props.activeKey === tab.key ? "primary" : "default"} icon={tab.icon} onClick={() => updateQueryParams({ tab: tab.key })} disabled={tab.hidden}>
                        {tab.label}
                      </Button>
                    </Badge>
                  </Tooltip>
                ))}
              </ButtonGroup>
            </Card>
          )}
        />
      )}
    </Card>
  );
};

export default Dashboard;
