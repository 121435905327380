import { FC, useState, useEffect } from "react";
import { constants } from "@/configs";
import { Button, Card, Table } from "antd";
import { ReloadOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { getWellAuditLogs } from "@/apis/well.api";
import { getWaterRightAuditLogs } from "@/apis/waterright.api";
import { getFieldAuditLogs } from "@/apis/field.api";
import { getCompanyAuditLogs } from "@/apis/company.api";
import { getGroupingAuditLogs } from "@/apis/grouping.api";
import { getRestrictionAuditLogs } from "@/apis/restriction.api";

export enum AuditLogType {
  Well,
  WellReading,
  WaterRight,
  Restriction,
  Field,
  Company,
  User,
  Grouping,
  WellAdjustmentTransfer,
  WaterRightAdjustmentTransfer,
}

interface Props {
  entityId?: string;
  entityType?: AuditLogType;
  refresh?: boolean;
}

const AuditLogTable: FC<Props> = (props) => {
  const { entityType, entityId, refresh = false } = props;
  const [auditLogs, setAuditLogs] = useState<any[]>([]);
  const [auditColumns, setAuditColumns] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    refreshAuditLogs();
  }, []);

  useEffect(() => {
    if (refresh === true) refreshAuditLogs();
  }, [refresh]);

  useEffect(() => {
    calculateAuditLogColumns();
  }, [auditLogs]);

  const calculateAuditLogColumns = () => {
    let tempColumns = [
      {
        title: "Modified Date",
        key: "date",
        dataIndex: "date",
        width: 150,
        render: (val: any, record: any) => dayjs(val).format(constants.dateTimeFormat),
      },
      {
        title: "Modified By",
        key: "displayName",
        dataIndex: "userName",
        width: 200,
        render: (val: any, record: any) => val,
      },
      {
        title: "Action",
        key: "action",
        dataIndex: "action",
        width: 150,
        render: (val: any, record: any) => val,
      },
      {
        title: "Summary",
        render: (val: any, record: any) => {
          if (record?.action === "Updated") {
            if (record?.changes?.length === 0) return <>-</>;
            return record?.changes?.map((change: any) => {
              return (
                <>
                  {`- Updated [${change?.changedVariable}] from [${change?.originalValue}] to [${change?.newValue}]`}
                  <br />
                </>
              );
            });
          } else return <>-</>;
        },
      },
    ];

    setAuditColumns(tempColumns);
  };

  const refreshAuditLogs = async () => {
    setLoading(true);
    let response: Response | undefined = undefined;
    if (entityId) {
      switch (entityType) {
        case AuditLogType.WellReading:
        case AuditLogType.Well:
          response = await getWellAuditLogs(entityId);
          break;
        case AuditLogType.WaterRight:
          response = await getWaterRightAuditLogs(entityId);
          break;
        case AuditLogType.Field:
          response = await getFieldAuditLogs(entityId);
          break;
        case AuditLogType.User:
        case AuditLogType.Company:
          response = await getCompanyAuditLogs(entityId);
          break;
        case AuditLogType.Grouping:
          response = await getGroupingAuditLogs(entityId);
          break;
        case AuditLogType.WellAdjustmentTransfer:
          response = await getWellAuditLogs(entityId);
          break;
        case AuditLogType.WaterRightAdjustmentTransfer:
          response = await getWaterRightAuditLogs(entityId);
          break;
        case AuditLogType.Restriction:
          response = await getRestrictionAuditLogs(entityId);
          break;
      }

      if (response !== undefined && response.ok) {
        var data = await response.json();
        if (data.isSuccess) {
          setAuditLogs(data.value);
        }
      }
    }
    setLoading(false);
  };

  return (
    <Card
      title={`Audit Logs`}
      extra={
        <>
          <Button icon={<ReloadOutlined />} disabled={loading} onClick={refreshAuditLogs}>
            Refresh
          </Button>
        </>
      }
    >
      <Table rowKey={(row: any) => row.id} loading={loading} columns={auditColumns} dataSource={auditLogs} size="small" />
    </Card>
  );
};

export default AuditLogTable;
