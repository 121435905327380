import { CloseOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { Button, Card, DatePicker, Divider, Form, Select, Space, Switch, Tag } from "antd";
import { useWatch } from "antd/es/form/Form";
import { getWaterRights } from "@/apis/waterright.api";
import { getWells } from "@/apis/well.api";
import { StatusTag, WaterRightSelector } from "@/components";
import { constants } from "@/configs";
import dayjs from "dayjs";
import type { CustomTagProps } from "rc-select/lib/BaseSelect";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import GeneratedLastReadingReport from "./GeneratedLastReadingReport";

const LastReadingReport: FC = () => {
  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const [form] = Form.useForm();

  const [loadingWaterRights, setLoadingWaterRights] = useState<boolean>(false);
  const [loadingWells, setLoadingWells] = useState<boolean>(false);
  const [generatingReport, setGeneratingReport] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean | null>(true);

  const [waterRights, setWaterRights] = useState<any[]>([]);
  const [wells, setWells] = useState<any[]>([]);

  const [year, setYear] = useState<any>(null);

  const [reportConfig, setReportConfig] = useState<any>(undefined);

  const wellIds: any[] = useWatch("wellIds", form);
  const waterRightIds: any[] = useWatch("waterRightIds", form);
  const date: any = useWatch("date", form);

  useEffect(() => {
    handleReset();
    // eslint-disable-next-line
  }, [selectedCompanyId]);

  useEffect(() => {
    if (date === null) setYear(null);
    else setYear(dayjs(date)?.year());
    // eslint-disable-next-line
  }, [date]);

  useEffect(() => {
    if (selectedCompanyId) {
      refreshWaterRights();
      refreshWells();
    }
    // eslint-disable-next-line
  }, [selectedCompanyId, isActive]);

  useEffect(() => {
    if (generatingReport) setTimeout(() => setGeneratingReport(false), 500);
    // eslint-disable-next-line
  }, [generatingReport]);

  const refreshWells = async () => {
    setLoadingWells(true);

    const request = {
      isActive: isActive,
      companyId: selectedCompanyId,
    };

    const response = await getWells(request);
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setWells(data.value);
      }
    }
    setLoadingWells(false);
  };

  const refreshWaterRights = async () => {
    setLoadingWaterRights(true);
    const request = { companyId: selectedCompanyId };
    const response = await getWaterRights(request);
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) setWaterRights(data.value);
    }
    setLoadingWaterRights(false);
  };

  const handleGenerateReport = () => {
    setGeneratingReport(true);

    const tempWells = [...(wellIds ?? [])];
    const tempWRIds = [...(waterRightIds ?? [])];

    const waterRightsForWell = wells.filter((well) => tempWRIds.includes(well.waterRightId)).map((well) => well.id);
    waterRightsForWell.forEach((well) => {
      const w = tempWells.find((w) => well === w);
      if (!w) tempWells.push(well);
    });

    const wellsForWaterRights = wells.filter((well) => tempWells.includes(well.id)).map((well) => well.waterRightId);
    wellsForWaterRights.forEach((waterRightId) => {
      const waterRight = tempWRIds.find((w) => w === waterRightId);
      if (!waterRight) tempWRIds.push(waterRightId);
    });

    const orderWells = wells
      ?.filter((well) => wellIds?.includes(well.id))
      .map((well) => well.name)
      .sort((a, b) => a.localeCompare(b, "en", { numeric: true, sensitivity: "base" }));
    const orderWaterRights = waterRights
      ?.filter((waterRight) => waterRightIds?.includes(waterRight.id))
      .map((waterRight) => waterRight.fileNumber)
      .sort((a, b) => a.localeCompare(b, "en", { numeric: true, sensitivity: "base" }));

    setReportConfig({
      selectedWellNames: orderWells?.join(", "),
      selectedWaterRightNames: orderWaterRights?.join(", "),
      wellIds: tempWells,
      waterRightIds: tempWRIds,
      generatedDate: dayjs().format(constants.dateTimeFormatWithSeconds),
      year,
    });
  };

  const handleReset = () => {
    form?.resetFields();
    setReportConfig(undefined);
  };

  const tagRender = (props: CustomTagProps) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag onMouseDown={onPreventMouseDown} closable={closable} onClose={onClose} style={{ marginRight: 3 }}>
        {wells?.find((well) => well?.id === value)?.name}
      </Tag>
    );
  };

  const handleOnChange = (value: boolean) => {
    if (!value) setIsActive(null);
    else setIsActive(true);
  };

  const renderActionsButtons = () => {
    return (
      <Space>
        <Button loading={loadingWaterRights || generatingReport} disabled={loadingWaterRights || generatingReport} type="primary" icon={<ThunderboltOutlined />} onClick={handleGenerateReport}>
          Generate Report
        </Button>
        <Button loading={loadingWaterRights || generatingReport} disabled={loadingWaterRights || generatingReport} icon={<CloseOutlined />} onClick={handleReset}>
          Reset
        </Button>
      </Space>
    );
  };

  return (
    <>
      <Card title="Configuration" actions={[renderActionsButtons()]} bodyStyle={{ padding: 12, margin: 0 }}>
        <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} autoComplete="off" style={{ margin: 0 }}>
          <Form.Item label="Year" name="date" style={{ margin: 0, marginBottom: 10 }} initialValue={null}>
            <DatePicker
              picker="year"
              style={{ marginRight: 30 }}
              placeholder="All Readings"
              allowClear={true}
              renderExtraFooter={() => (
                <div style={{ marginLeft: 5, paddingTop: 5, paddingBottom: 5 }}>
                  <Button
                    type="primary"
                    onClick={() => {
                      form.setFieldValue("date", null);
                    }}
                  >
                    Clear
                  </Button>
                </div>
              )}
            />
          </Form.Item>
          <Form.Item label="Wells" name="wellIds" style={{ margin: 0, marginBottom: 10 }}>
            <Select
              allowClear
              mode="multiple"
              loading={loadingWells}
              placeholder="Select wells"
              showSearch
              optionFilterProp="label"
              maxTagCount={3}
              tagRender={tagRender}
              disabled={generatingReport}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <Space style={{ padding: "0 8px 4px" }}>
                    <Button
                      onClick={() =>
                        form.setFieldValue(
                          "wellIds",
                          wells.filter((item: any) => item !== undefined).map((well) => well.id)
                        )
                      }
                    >
                      Select All
                    </Button>
                    <Button danger onClick={() => form.setFieldValue("wellIds", [])}>
                      Clear List
                    </Button>
                    <Switch defaultChecked onChange={handleOnChange} checkedChildren="Active Only" unCheckedChildren="All" />
                  </Space>
                </>
              )}
            >
              {wells
                .filter((item: any) => item !== undefined)
                .map((well: any) => {
                  return (
                    <Select.Option value={well.id} key={well.id} label={well.name}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        {well.name} <StatusTag status={well.status} />
                      </div>
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
          <WaterRightSelector style={{ margin: 0 }} form={form} loading={loadingWaterRights} label={"Water Rights"} placeholder={"Select water rights"} propertyToSet={"waterRightIds"} />
        </Form>
      </Card>
      {generatingReport && (
        <Card title="Generating Report">
          <div style={{ textAlign: "center" }}>Loading...</div>
        </Card>
      )}
      {!generatingReport && reportConfig && <GeneratedLastReadingReport reportConfig={reportConfig} />}
    </>
  );
};

export default LastReadingReport;
