import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getCompaniesLookup } from "@/apis/company.api";
import { useCallback, useEffect, useState } from "react";
import CacheKeys from "@/types/CacheKeys";
import { isEqual } from "lodash";
import defaultQueryConfig from "@/configs/defaultQueryConfig";
import { useSelector } from "react-redux";

export default function useCompanyLookups(companyId?: string, canEdit?: boolean, isActive?: boolean) {
  const { profile } = useSelector((state: any) => state.user);

  const queryClient = useQueryClient();
  const {
    data: companies,
    isFetching,
    isRefetching,
    isFetched,
    isError,
    isSuccess,
  } = useQuery({
    queryKey: [
      CacheKeys.companies,
      CacheKeys.lookup,
      {
        profileId: profile?.id,
        canEdit,
        isActive,
      },
    ],
    queryFn: async () => {
      const response = await getCompaniesLookup({
        canEdit,
        isActive,
      });

      if (response.ok) {
        const data = await response.json();
        if (data.isSuccess) return data.value;
      }

      throw new Error("Failed to fetch companies");
    },
    gcTime: Infinity,
    staleTime: Infinity,
    ...defaultQueryConfig,
    enabled: !!profile?.id,
  });

  const invalidateCompanies = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: [CacheKeys.companies, CacheKeys.lookup],
    });
  }, [queryClient]);

  const [company, setCompany] = useState<any>(null);

  useEffect(() => {
    if (isFetched && !isError && !isFetching && !isRefetching && isSuccess && companyId && companies) {
      const tempCompany = companies.find((c: any) => c.id === companyId);
      if (tempCompany) {
        if (!isEqual(company, tempCompany)) setCompany(tempCompany);
      } else {
        invalidateCompanies();
      }
    }
  }, [isError, isFetched, isFetching, isRefetching, companyId, companies, invalidateCompanies, isSuccess, company]);

  const isLoading = isFetching || isRefetching;

  return { company, companies, isFetched, isLoading, invalidateCompanies };
}
