import { FC } from "react";
import { FieldTable } from "@/components";

import "./FieldList.scss";

const FieldList: FC = () => {
  return <FieldTable showAddField showHeader trackTableState />;
};

export default FieldList;
