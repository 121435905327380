import { InfoCircleOutlined } from "@ant-design/icons";
import { Card, ConfigProvider, Descriptions, Image, Popover, Space, Table, TableProps } from "antd";
import Title from "antd/es/typography/Title";
import { getCompany } from "@/apis/company.api";
import { getFields } from "@/apis/field.api";
import { getWaterRights } from "@/apis/waterright.api";
import { getRecentWellReading, getWaterRightsForWell, getWells } from "@/apis/well.api";
import { LastReadingFrequency } from "@/components";
import PrintPage from "@/components/PrintPage/PrintPage";
import { orderBy } from "lodash";
import moment from "moment";
import { FC, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import "./GeneratedLastReadingReport.scss";
import { formatReadingValue } from "@/services/utils";

interface Props {
  reportConfig: any;
}

const customizeRenderEmpty = () => <div style={{ textAlign: "center" }}>No Data</div>;

const GeneratedLastReadingReport: FC<Props> = (props) => {
  const { reportConfig } = props;

  const { selectedCompanyId, selectedCompany } = useSelector((state: any) => state.company);

  const [tempWells, setWells] = useState<any[]>([]);
  const [recentWellReadings, setRecentWellReadings] = useState<any[]>([]);
  const [tempWaterRights, setWaterRights] = useState<any[]>([]);
  const [fields, setFields] = useState<any[]>([]);
  const [companyData, setCompanyData] = useState<any>(undefined);
  const [recentWaterRightWellReadings, setRecentWaterRightWellReadings] = useState<any>({ wells: [], wellReadings: [] });

  const [loadingWells, setLoadingWells] = useState<boolean>(false);
  const [loadingFields, setLoadingFields] = useState<boolean>(false);
  const [loadingWaterRights, setLoadingWaterRights] = useState<boolean>(false);
  const [loadingRecentWellReadings, setLoadingRecentWellReadings] = useState<boolean>(false);
  const [loadingCompany, setLoadingCompany] = useState<boolean>(false);
  const [generatingReport, setGeneratingReport] = useState<boolean>(false);

  const [wellColumns, setWellColumns] = useState<any[]>([]);
  const [waterRightColumns, setWaterRightColumns] = useState<any[]>([]);

  const wells: any[] = useMemo(() => {
    const data = tempWells.map((well: any) => {
      // Add recent well readings and field and waterright names
      const recentWellReadingsForWell = recentWellReadings?.find((reading) => reading.wellId === well.id);
      const field = fields.find((field) => well.fieldId === field.id)?.name;
      const waterRight = tempWaterRights.find((waterRight) => well.waterRightId === waterRight.id)?.fileNumber;

      return {
        ...well,
        wellReading: recentWellReadingsForWell,
        field,
        waterRight,
      };
    });

    const orderedList = data.sort((a: any, b: any) => {
      let result = a?.field?.localeCompare(b?.field, "en", {
        numeric: true,
        sensitivity: "base",
      });
      if (result === 0) {
        result = a?.waterRight?.localeCompare(b?.waterRight, "en", {
          numeric: true,
          sensitivity: "base",
        });
        if (result === 0)
          result = a?.name?.localeCompare(b?.name, "en", {
            numeric: true,
            sensitivity: "base",
          });
      }
      return result;
    });

    return orderedList;
  }, [tempWells, recentWellReadings, tempWaterRights]);

  const waterRights: any[] = useMemo(() => {
    const data = tempWaterRights.map((waterRight) => {
      const wellsForWaterRight = wells.filter((well) => well.waterRightId === waterRight.id);
      const fieldsForWaterRight = wellsForWaterRight.map((well) => well.fieldId);
      const tempWells = wellsForWaterRight.map((well) => well.name);
      const tempFields = fields.filter((field) => fieldsForWaterRight.includes(field.id)).map((field) => field.name);

      const wellIdsForWR = wellsForWaterRight.map((well) => well.id);
      const recentWellReadingsForWaterRight = recentWaterRightWellReadings?.wellReadings.filter((reading: any) => wellIdsForWR.includes(reading?.wellId));
      const orderedList = orderBy(recentWellReadingsForWaterRight, ["date"], ["asc"]);
      const lastReadingDate = orderedList?.[0]?.date;

      return {
        ...waterRight,
        wells:
          tempWells?.length > 0
            ? tempWells.sort((a, b) =>
                a.localeCompare(b, "en", {
                  numeric: true,
                  sensitivity: "base",
                })
              )
            : ["-"],
        fields:
          tempFields.length > 0
            ? tempFields.sort((a, b) =>
                a.localeCompare(b, "en", {
                  numeric: true,
                  sensitivity: "base",
                })
              )
            : ["-"],
        lastReadingDate,
      };
    });

    const orderedList = data.sort((a, b) =>
      a.fileNumber.localeCompare(b.fileNumber, "en", {
        numeric: true,
        sensitivity: "base",
      })
    );

    return orderedList;
  }, [tempWells, fields, recentWellReadings, tempWaterRights, recentWaterRightWellReadings]);

  useEffect(() => {
    calculateWaterRightColumnsColumns();
    // eslint-disable-next-line
  }, [waterRights, companyData]);

  useEffect(() => {
    refreshWells();
    refreshWaterRightsList();
    refreshFields();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    refreshCompanyData();
    // eslint-disable-next-line
  }, [selectedCompanyId]);

  useEffect(() => {
    if (tempWells?.length > 0) refreshRecentWellReadings();
    // eslint-disable-next-line
  }, [tempWells]);

  useEffect(() => {
    getLastReadDateForWaterRight();
    // eslint-disable-next-line
  }, [tempWaterRights]);

  useEffect(() => {
    if (generatingReport) setTimeout(() => setGeneratingReport(false), 500);
    // eslint-disable-next-line
  }, [generatingReport]);

  useEffect(() => {
    calculateWellColumns();
    // eslint-disable-next-line
  }, [wells, companyData]);

  const getLastReadDateForWaterRight = async () => {
    // const waterRightIds = waterRights.map((waterRight => waterRight.id));

    const request = {
      waterRightIds: reportConfig?.waterRightIds,
    };

    const response = await getWaterRightsForWell(request);
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        // Get Well Readings for id
        const wellIds = data.value.map((well: any) => well.id);

        const wellReadingRequest = {
          wellIds: wellIds,
          year: reportConfig?.year,
        };

        if (wellIds?.length > 0) {
          const wellReadingResponse = await getRecentWellReading(wellReadingRequest);
          if (wellReadingResponse.ok) {
            const readingData = await wellReadingResponse.json();
            if (readingData.isSuccess) {
              data.value.forEach((well: any) => {
                well.waterRightId = waterRights.find((waterRight: any) => well.waterRightId === waterRight.id);
              });

              setRecentWaterRightWellReadings({
                wells: data.value,
                wellReadings: readingData.value,
              });
            }
          }
        }
      }
    }
  };

  const refreshCompanyData = async () => {
    setLoadingCompany(true);

    const response = await getCompany(selectedCompanyId);

    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setCompanyData(data.value);
      }
    }

    setLoadingCompany(false);
  };

  const refreshWaterRightsList = async () => {
    setLoadingWaterRights(true);

    const request = {
      companyId: selectedCompanyId,
      isActive: undefined,
      // waterRightIds: reportConfig.waterRightIds
    };

    const response = await getWaterRights(request);
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setWaterRights(data.value);
      }
    }
    setLoadingWaterRights(false);
  };

  const refreshFields = async () => {
    setLoadingFields(true);
    if (selectedCompanyId) {
      const request = { companyId: selectedCompanyId };
      const response = await getFields(request);

      if (response.ok) {
        const data = await response.json();
        if (data.isSuccess) {
          setFields(data.value);
        }
      }
    }
    setLoadingFields(false);
  };

  const refreshRecentWellReadings = async () => {
    setLoadingRecentWellReadings(true);

    const request = {
      wellIds: tempWells?.map((well) => well?.id),
      year: reportConfig?.year,
    };

    const response = await getRecentWellReading(request);
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setRecentWellReadings(data.value);
      }
    }

    setLoadingRecentWellReadings(true);
  };

  const refreshWells = async () => {
    setLoadingWells(true);
    if (selectedCompanyId) {
      const request = { companyId: selectedCompanyId };
      const response = await getWells(request);

      if (response.ok) {
        const data = await response.json();
        if (data.isSuccess) {
          setWells(data.value);
        }
      }
    }
    setLoadingWells(false);
  };

  const calculateWaterRightColumnsColumns = () => {
    let tempColumns = [
      {
        title: "#",
        width: 50,
        render: (val: any, record: any, index: any) => index + 1,
      },
      {
        title: (
          <>
            Water Right /<br /> File Number
          </>
        ),
        key: "fileNumber",
        dataIndex: "fileNumber",
        sorter: {
          compare: (a: any, b: any) =>
            a.fileNumber.localeCompare(b.fileNumber, "en", {
              numeric: true,
              sensitivity: "base",
            }),
          multiple: 3,
        },
        render: (val: any, record: any) => val,
      },
      {
        title: "Fields",
        key: "fields",
        dataIndex: "fields",
        sorter: {
          compare: (a: any, b: any) => a.fields?.length - b.fields?.length,
          multiple: 2,
        },
        render: (val: any, record: any) => {
          if (loadingFields) return "Loading...";
          else {
            return record?.fields
              ? record?.fields
                  ?.sort((a: any, b: any) =>
                    a?.localeCompare(b, "en", {
                      numeric: true,
                      sensitivity: "base",
                    })
                  )
                  ?.join(", ")
              : "-";
          }
        },
      },
      {
        title: "Wells",
        key: "wells",
        dataIndex: "wells",
        sorter: {
          compare: (a: any, b: any) => a.wells?.length - b.wells?.length,
          multiple: 1,
        },
        render: (val: any, record: any) => {
          if (loadingWells) return "Loading...";
          else {
            return record?.wells
              ? record?.wells
                  ?.sort((a: any, b: any) =>
                    a?.localeCompare(b, "en", {
                      numeric: true,
                      sensitivity: "base",
                    })
                  )
                  ?.join(", ")
              : "-";
          }
        },
      },
      {
        title: "Last Reading Date",
        key: "lastReadingDate",
        dataIndex: "lastReadingDate",
        width: 170,
        sorter: (a: any, b: any) => moment(a.lastReadingDate).unix() - moment(b.lastReadingDate).unix(),
        render: (val: any) => {
          if (val) {
            return <LastReadingFrequency isReport type={"water right"} date={val} meterReadingFrequency={companyData?.settings?.meterReadingFrequency} />;
          } else return "-";
        },
      },
    ];

    setWaterRightColumns(tempColumns);
  };

  const calculateWellColumns = () => {
    let tempColumns = [
      {
        title: "#",
        width: 50,
        render: (val: any, record: any, index: any) => index + 1,
      },
      {
        title: "Field Name",
        key: "fieldName",
        dataIndex: "field",
        sorter: (a: any, b: any) =>
          a?.field?.localeCompare(b?.field, "en", {
            numeric: true,
            sensitivity: "base",
          }),
        render: (val: any, record: any) => val,
      },
      {
        title: (
          <>
            Water Right /<br /> File Number
          </>
        ),
        key: "waterRightFileNumber",
        dataIndex: "waterRight",
        sorter: (a: any, b: any) =>
          a?.waterRight?.localeCompare(b?.waterRight, "en", {
            numeric: true,
            sensitivity: "base",
          }),
        render: (val: any, record: any) => <>{val}</>,
      },
      {
        title: "Well Name",
        key: "name",
        dataIndex: "name",
        sorter: (a: any, b: any) =>
          a?.name?.localeCompare(b?.name, "en", {
            numeric: true,
            sensitivity: "base",
          }),
        render: (val: any, record: any) => val,
      },
      {
        title: "Last Reading Value",
        key: "wellReading",
        dataIndex: "wellReading",
        sorter: (a: any, b: any) => a?.wellReading?.reading?.localeCompare(b?.wellReading?.reading, "en", { numeric: true, sensitivity: "base" }),
        render: (val: any, record: any) => (record?.wellReading?.reading ? formatReadingValue(record?.wellReading) : "No readings caputed"),
        //     {
        //     if (record?.wellReading?.reading) {
        //         return <>{record?.wellReading?.reading}</>;
        //     } else return 'No Readings Captured'
        // }
      },
      {
        title: "Last Reading Date",
        key: "wellDate",
        dataIndex: "wellReading",
        sorter: (a: any, b: any) => moment(a.wellReading?.date).unix() - moment(b.wellReading?.date).unix(),
        render: (val: any, record: any) => {
          if (record?.wellReading?.date) {
            return <LastReadingFrequency isReport type="well" date={record?.wellReading?.date} meterReadingFrequency={companyData?.settings?.meterReadingFrequency} />;
          } else return "No Readings Captured";
        },
      },
    ];

    setWellColumns(tempColumns.filter((x) => x));
  };

  return (
    <PrintPage
      content={
        <Card
          id="generatedLastReadingReport"
          className="remove-border-and-padding-on-print"
          title={
            <div>
              <Space size="middle">
                <Image
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                    textAlign: "center",
                    marginLeft: "auto",
                    marginRight: "auto",
                    alignContent: "center",
                    maxHeight: 200,
                    maxWidth: 200,
                    borderRadius: 10,
                    objectFit: "contain",
                  }}
                  src="/logo.png"
                  preview={false}
                />
                <Space direction="vertical" size="small" style={{ display: "flex" }}>
                  <Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>{`Last Reading Report`}</Title>
                  <Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>{`Generated On ${reportConfig.generatedDate}`}</Title>
                </Space>
              </Space>
            </div>
          }
        >
          {
            <Card title="Wells Last Reading">
              <ConfigProvider renderEmpty={customizeRenderEmpty}>
                <Table
                  rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
                  rowKey={(row: any) => row.id}
                  dataSource={reportConfig?.wellIds ? wells.filter((well) => reportConfig?.wellIds?.includes(well.id)) : []}
                  columns={wellColumns}
                  loading={loadingWells || loadingFields || loadingWaterRights}
                  size="small"
                  pagination={false}
                />
              </ConfigProvider>
            </Card>
          }
          <div className="page-break" />
          {
            <Card title="Water Rights Last Reading">
              <ConfigProvider renderEmpty={customizeRenderEmpty}>
                <Table
                  rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
                  rowKey={(row: any) => row.id}
                  dataSource={reportConfig?.waterRightIds ? waterRights.filter((waterRight) => reportConfig?.waterRightIds?.includes(waterRight.id)) : []}
                  columns={waterRightColumns}
                  loading={loadingWells || loadingFields || loadingWaterRights}
                  size="small"
                  pagination={false}
                />
              </ConfigProvider>
            </Card>
          }
          <div className="page-break" />
          {reportConfig && (
            <Card title="Report Configuration" bodyStyle={{ padding: 10 }}>
              <Descriptions bordered size="small" column={1} labelStyle={{ width: 150 }} className="removeBoxShadow removeMargin">
                <Descriptions.Item label="Wells">{reportConfig?.selectedWellNames === "" ? "-" : reportConfig.selectedWellNames}</Descriptions.Item>
                <Descriptions.Item label="Water Rights">{reportConfig?.selectedWaterRightNames === "" ? "-" : reportConfig.selectedWaterRightNames}</Descriptions.Item>
                <Descriptions.Item label="Generated At">{reportConfig.generatedDate}</Descriptions.Item>
              </Descriptions>
            </Card>
          )}
        </Card>
      }
      isReport
    />
  );
};

export default GeneratedLastReadingReport;
