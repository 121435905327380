import { getField } from "@/apis/field.api";
import { getWaterRight, getWaterRightCalculation } from "@/apis/waterright.api";
import { getWell } from "@/apis/well.api";
import { getWellReadings } from "@/apis/wellreading.api";
import { AuditLogTable, LookupLabel, NotesTable, RestrictionTable, StatusTag, WellTable } from "@/components";
import { AuditLogType } from "@/components/AuditLogTable/AuditLogTable";
import PrintPage from "@/components/PrintPage/PrintPage";
import RemainingDays from "@/components/RemainingDays/RemainingDays";
import UnitsConverter from "@/components/UnitsConverter/UnitsConverter";
import WellReadingsTable from "@/components/WellReadingsTable/WellReadingsTable";
import { constants, routes } from "@/configs";
import useSelectedCompanyData from "@/queries/useSelectedCompanyData";
import useCustomNavigate from "@/services/useCustomNavigate";
import { convertUnits, dateDiffInDays, determineReadingValue, displayDecimals, formatNumber, formatReadingValue } from "@/services/utils";
import { useAppDispatch } from "@/stores";
import { addBreadcrumb, removeBreadcrumb, replaceUrlStateBreadcrumbType } from "@/stores/breadcrumbs.store";
import { EditOutlined, LeftOutlined } from "@ant-design/icons";
import { Button, Card, Col, Descriptions, Image, Row, Tabs, Tooltip } from "antd";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import GoogleMapReact from "google-map-react";
import { orderBy } from "lodash";
import { FC, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import "./WellView.scss";
import RadialGauge from "@/components/Gauges/RadialGauge";
import useComapnyConfigLookup from "@/queries/useCompanyConfigLookup";

dayjs.extend(utc);
dayjs.extend(timezone);

interface WellViewProps {
  refreshWellCalculations?: () => void;
  refreshWaterRightCalculations?: () => void;
  refreshWaterRightViewCalculations?: () => void;
  showHeader?: boolean;
  overrideId?: string;
  showReadingsFirst?: boolean;
  expanded?: boolean;
}

const coloredMarker = (color: string, maps: any) => {
  return {
    url: `/images/marker_${color}.png`,
    fillColor: color,
    fillOpacity: 1,
    strokeWeight: 0,
    rotation: 0,
    scale: 2,
    anchor: new maps.Point(15, 30),
  };
};

const WellView: FC<WellViewProps> = (props: WellViewProps) => {
  const { expanded = true, refreshWellCalculations, refreshWaterRightViewCalculations, refreshWaterRightCalculations, showHeader = true, overrideId = undefined, showReadingsFirst = false } = props;

  const dispatch = useAppDispatch();

  const { navigate } = useCustomNavigate();

  const { id } = useParams<{ id: string }>();

  const pathUrl = window.location.pathname;

  const [wellData, setWellData] = useState<any>(undefined);

  const [fieldName, setFieldName] = useState<string | undefined>(undefined);
  const [waterRight, setWaterRight] = useState<any>(undefined);

  const [loading, setLoading] = useState<boolean>(true);
  const [loadingReadings, setLoadingReadings] = useState<boolean>(false);

  const [calculation, setCalculation] = useState<any>(undefined);
  const [loadingCalculation, setLoadingCalculation] = useState<boolean>(false);

  const [lastMeterReading, setLastMeterReading] = useState<any>(null);
  const [secondLastMeterReading, setSecondLastMeterReading] = useState<any>(null);
  const [currentYearLastReading, setCurrentYearLastReading] = useState<any>(null);
  const [previousYearPreviousReading, setPreviousYearPreviousReading] = useState<any>(null);

  const { companyConfig } = useComapnyConfigLookup(wellData?.companyId);

  const refreshWellViewCalculations = useCallback(() => {
    handleRefresh();
  }, []);

  useEffect(() => {
    if (pathUrl.includes("view") && wellData) {
      dispatch(replaceUrlStateBreadcrumbType(wellData?.name));
    }
  }, [pathUrl, wellData]);

  useEffect(() => {
    getWellData();
  }, []);

  useEffect(() => {
    if (wellData?.id) {
      getWaterRightData(wellData.waterRightId);
      getFieldData(wellData.fieldId);
    }
  }, [wellData]);

  useEffect(() => {
    if (companyConfig) refreshWellReadings();
  }, [companyConfig]);

  useEffect(() => {
    if (waterRight?.id) {
      refreshCalculation();
    }
  }, [waterRight?.id]);

  useEffect(() => {
    if (expanded && wellData) handleExpandRefresh();
  }, [expanded, wellData]);

  const refreshWellReadings = async () => {
    setLoadingReadings(true);
    const response = await getWellReadings({ wellId: overrideId ?? id });
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess && data.value) {
        let readings = orderBy(
          data.value.filter((x: any) => x.reading != null),
          "date",
          "desc"
        );

        const timezone = companyConfig?.settings?.timezone;
        if (timezone) {
          readings = readings.map((reading: any) => {
            const year = dayjs.utc(reading.date).tz(timezone).year();
            return { ...reading, _year: year };
          });
        }

        /**
         * So to summarize: Lets just change the text:
         * Last Reading in 2024: (Last reading in 2024 - if no readings - show "No readings recorded")
         * Beginning reading in 2024: (Last reading of 2023 - if no readings use first reading of 2024, if no readings show "No readings recorded"
         */
        const currentYear = dayjs().year();
        const previousYear = dayjs().year() - 1;
        const readingsForCurrentYear = readings.filter((x: any) => x._year === currentYear);
        const readingsForPreviousYear = readings.filter((x: any) => x._year === previousYear);

        if (readings.length > 0) setLastMeterReading(readings[0]);
        if (readings.length > 1) setSecondLastMeterReading(readings[1]);
        setCurrentYearLastReading(readings[0]);
        if (readingsForPreviousYear.length > 0) setPreviousYearPreviousReading(readingsForPreviousYear[0]);
        else if (readingsForCurrentYear.length > 0) setPreviousYearPreviousReading(readingsForCurrentYear[readingsForCurrentYear.length - 1]);
      }
    }
    setLoadingReadings(false);
  };

  const refreshCalculation = async () => {
    setLoadingCalculation(true);
    if (waterRight?.id) {
      const response = await getWaterRightCalculation(waterRight?.id, dayjs().year());
      if (response.ok) {
        const data = await response.json();
        if (data.isSuccess) {
          setCalculation(data.value?.[0]);
        }
      }
    }
    setLoadingCalculation(false);
  };

  const getWaterRightData = async (id: any) => {
    const response = await getWaterRight(id);
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setWaterRight(data.value);
      }
    }
  };

  const getWellData = async () => {
    setLoading(true);
    const response = await getWell(overrideId ?? id);

    if (response.ok) {
      const data = await response.json();
      setWellData(data.value);
    }
    setLoading(false);
  };

  const navigateEdit = () => {
    dispatch(
      addBreadcrumb({
        type: `Edit`,
        url: `well/${id}/view`,
      })
    );
    navigate(routes.wellEdit, { id: id });
  };

  const navigateBack = () => {
    dispatch(removeBreadcrumb());
    navigate(-1);
  };

  const handleRefresh = () => {
    // refreshWellReadings();
    // refreshCalculation();
    handleExpandRefresh();
  };

  const handleExpandRefresh = () => {
    refreshWellReadings();
    refreshCalculation();
    getWaterRightData(wellData.waterRightId);
    getFieldData(wellData.fieldId);
  };

  const handleViewField = (record: any) => {
    dispatch(
      addBreadcrumb({
        type: `${fieldName}`,
        url: `field/${record}/view`,
      })
    );
    navigate(routes.fieldView, { id: record });
  };

  const handleViewWaterRight = (record: any) => {
    dispatch(
      addBreadcrumb({
        type: `${waterRight?.fileNumber}`,
        url: `waterright/${record}/view`,
      })
    );
    navigate(routes.waterRightView, { id: record });
  };

  const getFieldData = async (id: any) => {
    const response = await getField(id);

    if (response.ok) {
      const data = await response.json();

      setFieldName(data.value.name);
    }
  };

  const getMapOptions = (map: any, maps: any) => {
    const marker = new maps.Marker({
      position: new maps.LatLng(wellData?.latitude, wellData?.longitude),
      icon: coloredMarker("red", maps),
      label: { text: wellData?.name, className: "googleMarkerLabelPrimary" },
      map,
    });
    marker.setVisible(true);
  };

  const determineValue = (id: string, type: string, postfix: any = undefined) => {
    if (calculation) {
      if (type === "remainingUsage" && calculation.quantityNotApplicable === true) return "N/A";
      else if (type === "remainingDays" && (calculation.quantityNotApplicable === true || calculation.rateNotApplicable === true)) return "N/A";
      return calculation[type];
    } else return undefined;
  };

  const renderTabs = (wellData: any) => {
    let tabs: any[] = [
      {
        label: "Details / Directions",
        key: "details",
        children: (
          <Row gutter={[16, 16]}>
            <Col>
              <Descriptions bordered size="small" column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }} labelStyle={{ width: 165 }} style={{ width: "100%", height: "100%" }}>
                <Descriptions.Item label="Meter Max Value (Rollover #)">
                  <LookupLabel lookupType="metermaxvalue" value={wellData?.meterMaxValue} />
                </Descriptions.Item>
                <Descriptions.Item label="Digital Meter">
                  <StatusTag status={wellData?.digital ? "YES" : "No"} />
                </Descriptions.Item>
                <Descriptions.Item label="Decimals">{wellData?.decimals ?? "N/A"}</Descriptions.Item>
                <Descriptions.Item label="Meter Multiplier">{wellData?.meterMultiplier !== 0 ? wellData?.meterMultiplier : "-"}</Descriptions.Item>
                <Descriptions.Item label="Meter Units">
                  <LookupLabel lookupType="meterunits" value={wellData?.meterUnits} />
                </Descriptions.Item>
                {/* <Descriptions.Item label="Retired"><StatusTag status={wellData?.retired ? 'Yes' : 'No'} color={wellData?.retired ? 'red' : 'blue'} /></Descriptions.Item> */}
                <Descriptions.Item label="Status">
                  <StatusTag status={wellData?.status} />
                </Descriptions.Item>
                <Descriptions.Item label="Meter Serial Number">{wellData?.meterSerialNumber ? wellData?.meterSerialNumber : "-"}</Descriptions.Item>
                <Descriptions.Item label="Latitude, Longitude">{wellData?.latitude && wellData?.longitude ? `${wellData.latitude}, ${wellData?.longitude}` : "-"}</Descriptions.Item>
              </Descriptions>
            </Col>

            {wellData?.latitude && wellData?.longitude ? (
              <>
                <Col>
                  <Descriptions bordered size="small" column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }} labelStyle={{ width: 150 }} style={{ width: "100%", height: "100%" }}>
                    <Descriptions.Item label="Location Map">
                      <div style={{ height: 250, width: 250 }}>
                        <GoogleMapReact
                          onGoogleApiLoaded={({ map, maps }) => getMapOptions(map, maps)}
                          options={(map) => ({
                            mapTypeId: map.MapTypeId.HYBRID,
                          })}
                          bootstrapURLKeys={{ key: constants.googleApiKey }}
                          defaultCenter={{
                            lat: wellData?.latitude,
                            lng: wellData?.longitude,
                          }}
                          defaultZoom={16}
                          //yesIWantToUseGoogleMapApiInternals={true}
                        >
                          {/* <Marker
                      anchor={{ lat: wellData?.latitude, lng: wellData?.longitude }}
                      index={0}
                      // anchor={x: 10, y: 15 }
                      lat={wellData?.latitude}
                      lng={wellData?.longitude}
                      text="My Marker"
                    /> */}
                        </GoogleMapReact>
                      </div>
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
              </>
            ) : undefined}
            <Col>
              <Descriptions bordered size="small" column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }} labelStyle={{ width: 150 }} style={{ width: "100%", height: "100%" }}>
                <Descriptions.Item label="Direction Notes">{wellData?.directionNotes ?? "-"}</Descriptions.Item>
                <Descriptions.Item label="Direction Images">
                  {wellData?.imageIds?.length > 0 ? (
                    <Image.PreviewGroup>
                      {wellData?.imageIds.map((imageId: any, index: number) => (
                        <Image
                          key={imageId}
                          style={{
                            backgroundColor: "gray",
                            textAlign: "center",
                            marginLeft: "auto",
                            marginRight: "auto",
                            alignContent: "center",
                            objectFit: "contain",
                            maxHeight: 200,
                            maxWidth: 200,
                          }}
                          src={`${constants.baseApiUrl}/well/image/${imageId}/download?maxWidth=200&maxHeight=200`}
                          preview={{
                            src: `${constants.baseApiUrl}/well/image/${imageId}/download`,
                          }}
                        />
                      ))}
                    </Image.PreviewGroup>
                  ) : (
                    "-"
                  )}
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
        ),
      },
      {
        label: "Deduction Wells",
        key: "reductionWells",
        children: <WellTable isChild addWell={false} header reductionWellIds={wellData?.reductionWells ?? []} />,
      },
      {
        label: "General Notes",
        key: "notes",
        children: <NotesTable wellId={wellData?.id} isReport={false} />,
      },
      // {
      //   label: 'Adjustments',
      //   key: 'adjustments',
      //   children: <AdjustmentTable wellId={overrideId ?? id} />
      // },
      {
        label: "Restrictions",
        key: "restrictions",
        children: <RestrictionTable isChild waterRightId={waterRight?.id} showAddRestriction={false} showHeader={false} />,
      },
      {
        label: "Directions",
        key: "directions",
        children: (
          <Card className="removeBoxShadow" id="directions" title="Directions">
            <Descriptions bordered size="small" labelStyle={{ width: 100 }} column={1}>
              <Descriptions.Item label="Notes">{wellData?.directionNotes ?? "-"}</Descriptions.Item>
              <Descriptions.Item label="Images">
                {wellData?.imageIds?.length > 0 ? (
                  <Image.PreviewGroup>
                    {wellData?.imageIds.map((imageId: any, index: number) => (
                      <Image
                        key={imageId}
                        style={{
                          textAlign: "center",
                          marginLeft: "auto",
                          marginRight: "auto",
                          alignContent: "center",
                          paddingRight: 5,
                        }}
                        width={200}
                        src={`${constants.baseApiUrl}/well/image/${imageId}/download`}
                      />
                    ))}
                  </Image.PreviewGroup>
                ) : (
                  "-"
                )}
              </Descriptions.Item>
            </Descriptions>
          </Card>
        ),
      },
    ];

    tabs.splice(showReadingsFirst ? 0 : 3, 0, {
      label: "Readings",
      key: "readings",
      children: (
        <WellReadingsTable
          refreshWellCalculations={refreshWellCalculations}
          refreshWaterRightViewCalculations={refreshWaterRightViewCalculations}
          refreshWellViewCalculations={refreshWellViewCalculations}
          refreshWaterRightCalculations={refreshWaterRightCalculations}
          wellId={wellData?.id}
          waterRightId={waterRight?.id}
          canEdit={canEdit}
        />
      ),
    });

    if (canEdit)
      tabs.push({
        label: "Audit Log",
        key: "auditLog",
        children: <AuditLogTable entityId={wellData?.id} entityType={AuditLogType.Well} />,
      });

    return tabs;
  };

  const calculateGallonsBetweenMeterReadings = (reading1: any, reading2: any) => {
    if (!reading1 || !reading2 || reading1?.newMeter) return 0;

    let diff = determineReadingValue(reading1) - determineReadingValue(reading2);
    if (diff < 0) {
      diff = Number(reading2?.meterMaxValue ? reading2?.meterMaxValue : reading1?.meterMaxValue) - Number(reading2.reading) + Number(reading1?.reading);
    }
    const multipliedValue = diff * Number(reading2?.meterMultiplier ? reading2.meterMultiplier : reading1?.meterMultiplier);
    const convertedToGallons = convertUnits(multipliedValue, reading2?.meterUnits ? reading2?.meterUnits : reading1?.meterUnits, "gallons");
    return convertedToGallons;
  };

  const calculateAverageGPM = (gallons: number, last: Date, secondLast: Date) => {
    if (!last || !secondLast) return "Not enough readings";
    const minutesBetweenDates = dateDiffInDays(new Date(secondLast), new Date(last)) * 24 * 60;
    return formatNumber((gallons / minutesBetweenDates).toFixed(2));
  };

  const renderContent = () => {
    return (
      <div>
        <Row gutter={[0, 10]}>
          <Col>
            <Descriptions
              bordered
              size="small"
              column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
              labelStyle={{ width: 235 }}
              style={{ width: 460, height: "100%" }}
              title={
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "center",
                    padding: 5,
                  }}
                >
                  Summary
                </div>
              }
              className="removeDescriptionHeaderPadding"
            >
              <Descriptions.Item label="Well Name">{wellData?.name}</Descriptions.Item>
              <Descriptions.Item label="Field Name">
                {fieldName ? (
                  <Tooltip title="Go to the Field">
                    <Button style={{ padding: 0, margin: 0, height: 24 }} type="link" onClick={() => handleViewField(wellData?.fieldId)}>
                      {fieldName}
                    </Button>
                  </Tooltip>
                ) : (
                  ""
                )}
              </Descriptions.Item>
              <Descriptions.Item label="Water Right / File Number">
                {waterRight ? (
                  <Tooltip title="Go to the Water Right">
                    <Button style={{ padding: 0, margin: 0, height: 24 }} type="link" onClick={() => handleViewWaterRight(wellData?.waterRightId)}>
                      {waterRight?.fileNumber}
                    </Button>
                  </Tooltip>
                ) : (
                  ""
                )}
              </Descriptions.Item>
              {/* <br /> */}
              <Descriptions.Item label={"Last Meter Reading"}>
                {currentYearLastReading ? (
                  <>
                    Reading: {formatReadingValue(currentYearLastReading)} <br />
                    Date: {currentYearLastReading?.date ? dayjs(currentYearLastReading?.date).format(constants.dateTimeFormat) : "-"} <br />
                  </>
                ) : (
                  "No readings recorded"
                )}
              </Descriptions.Item>
              <Descriptions.Item label={"Beginning Meter Reading (" + dayjs().format("YYYY") + ")"}>
                {previousYearPreviousReading ? (
                  <>
                    Reading: {formatReadingValue(previousYearPreviousReading)} <br />
                    Date: {previousYearPreviousReading?.date ? dayjs(previousYearPreviousReading?.date).format(constants.dateTimeFormat) : "-"} <br />
                  </>
                ) : (
                  "No readings recorded"
                )}
              </Descriptions.Item>
            </Descriptions>
          </Col>
          <Col>
            <Descriptions
              style={{ paddingLeft: 0, height: "100%" }}
              bordered
              size="small"
              labelStyle={{ width: 150 }}
              column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
              title={
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "center",
                    padding: 5,
                  }}
                >
                  Water Right Information
                </div>
              }
            >
              <Descriptions.Item label="Authorized">
                Irrigation Acres: {waterRight?.authorizedIrrigationNotApplicable === true ? "N/A" : `${waterRight?.authorizedIrrigationAcres} Acres`}
                <br />
                Quantity:{" "}
                {waterRight?.authorizedQuantityNotApplicable === true ? (
                  "N/A"
                ) : (
                  <>
                    {waterRight?.authorizedQuantity} <LookupLabel lookupType="meterUnits" value={waterRight?.authorizedQuantityUnits} />
                  </>
                )}{" "}
                <br />
                Rate: {waterRight?.authorizedRateNotApplicable === true ? "N/A" : `${waterRight?.authorizedRate} GPM`}
              </Descriptions.Item>
              <Descriptions.Item label="Usage">
                Irrigation Acres:{" "}
                {waterRight?.authorizedIrrigationNotApplicable === true
                  ? "N/A"
                  : waterRight?.usageIrrigationAcres
                    ? `${waterRight?.usageIrrigationAcres} Acres`
                    : `${waterRight?.authorizedIrrigationAcres} Acres`}
                <br />
                Quantity:{" "}
                {waterRight?.authorizedQuantityNotApplicable === true ? (
                  "N/A"
                ) : waterRight?.usageQuantity ? (
                  <>
                    {waterRight?.usageQuantity} <LookupLabel lookupType="meterUnits" value={waterRight?.usageQuantityUnits} />
                  </>
                ) : (
                  <>
                    {waterRight?.authorizedQuantity} <LookupLabel lookupType="meterUnits" value={waterRight?.authorizedQuantityUnits} />
                  </>
                )}
                <br />
                Rate: {waterRight?.authorizedRateNotApplicable ? "N/A" : waterRight?.usageRate ? `${waterRight?.usageRate} GPM` : `${waterRight?.authorizedRate} GPM`}
              </Descriptions.Item>
              {determineValue(overrideId ?? (id as string), "penaltyQuantity") > 0 && (
                <Descriptions.Item label="Penalty">
                  <UnitsConverter
                    fromUnits="gallons"
                    toUnits={companyConfig?.settings?.metric ?? "acrefeet"}
                    value={determineValue(overrideId ?? (id as string), "penaltyQuantity")}
                    showUnitsLabel
                    placeholder="Loading..."
                  />
                </Descriptions.Item>
              )}
              {waterRight?.subjectToMds && (
                <Descriptions.Item label="MDS">
                  Irrigation Acres:{" "}
                  {waterRight?.authorizedIrrigationNotApplicable === true
                    ? "N/A"
                    : waterRight?.usageIrrigationAcres
                      ? `${waterRight?.usageIrrigationAcres} Acres`
                      : `${waterRight?.authorizedIrrigationAcres} Acres`}
                  <br />
                  Quantity:{" "}
                  {waterRight?.authorizedQuantityNotApplicable === true ? (
                    "N/A"
                  ) : (
                    <>
                      <UnitsConverter
                        value={determineValue(overrideId ?? (id as string), "availableQuantity") + determineValue(overrideId ?? (id as string), "transferQuantity")}
                        fromUnits={"gallons"}
                        toUnits={companyConfig?.settings?.metric ?? "acrefeet"}
                        showUnitsLabel
                      />
                    </>
                  )}
                  <br />
                  Rate: {waterRight?.authorizedRateNotApplicable ? "N/A" : waterRight?.usageRate ? `${waterRight?.usageRate} GPM` : `${waterRight?.authorizedRate} GPM`}
                </Descriptions.Item>
              )}
              {calculation && (
                <>
                  <Descriptions.Item label="Transferred">
                    <UnitsConverter
                      fromUnits="gallons"
                      toUnits={companyConfig?.settings?.metric ?? "acrefeet"}
                      value={determineValue(overrideId ?? (id as string), "transferQuantity")}
                      showUnitsLabel
                      placeholder="Loading..."
                    />
                  </Descriptions.Item>
                  <Descriptions.Item label="Used">
                    <UnitsConverter
                      fromUnits="gallons"
                      toUnits={companyConfig?.settings?.metric ?? "acrefeet"}
                      value={determineValue(overrideId ?? (id as string), "currentUsage")}
                      showUnitsLabel
                      placeholder="Loading..."
                    />
                  </Descriptions.Item>
                </>
              )}
            </Descriptions>
          </Col>
          <Col>
            <Descriptions
              bordered
              size="small"
              column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
              labelStyle={{ width: 170 }}
              style={{ height: "100%" }}
              title={
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "center",
                    padding: 5,
                  }}
                >
                  Remaining Usage
                </div>
              }
              className="removeDescriptionHeaderPadding"
            >
              <Descriptions.Item label="Final Meter Reading">
                {calculation?.finalMeterOutcome ? "N/A - " + calculation?.finalMeterOutcome : displayDecimals(calculation?.finalMeterReading, calculation?.decimals)}
              </Descriptions.Item>
              <Descriptions.Item label="Est. Remaining">
                <RemainingDays remainingDays={determineValue(waterRight?.id, "remainingDays")} />
              </Descriptions.Item>
              <Descriptions.Item label="Gallons">
                <UnitsConverter value={determineValue(waterRight?.id, "remainingUsage")} fromUnits="gallons" toUnits="gallons" />
              </Descriptions.Item>
              {companyConfig?.settings?.metric === "gallons" ? undefined : (
                <Descriptions.Item label={<LookupLabel lookupType="meterUnits" value={companyConfig?.settings?.metric ?? "acrefeet"} />}>
                  <UnitsConverter value={determineValue(waterRight?.id, "remainingUsage")} fromUnits="gallons" toUnits={companyConfig?.settings?.metric ?? "acrefeet"} />
                </Descriptions.Item>
              )}
              {!waterRight?.authorizedIrrigationNotApplicable && (
                <Descriptions.Item label="Inches / Acre">
                  <UnitsConverter
                    value={determineValue(waterRight?.id, "remainingUsage")}
                    fromUnits="gallons"
                    toUnits="acreinches"
                    devideBy={waterRight?.usageIrrigationAcres ?? waterRight?.authorizedIrrigationAcres}
                  />
                </Descriptions.Item>
              )}
            </Descriptions>
          </Col>
          <Col>
            <Descriptions
              bordered
              size="small"
              column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
              labelStyle={{ width: 200 }}
              style={{ height: "100%" }}
              title={
                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "center",
                    padding: 5,
                  }}
                >
                  Last Usage Reading
                </div>
              }
              className="removeDescriptionHeaderPadding"
            >
              <Descriptions.Item label="Meter Reading">{formatReadingValue(lastMeterReading)}</Descriptions.Item>
              <Descriptions.Item label="Gallons">
                {<UnitsConverter value={calculateGallonsBetweenMeterReadings(lastMeterReading, secondLastMeterReading)} fromUnits="gallons" toUnits="gallons" />}
              </Descriptions.Item>
              <Descriptions.Item label="Acre Feet">
                <UnitsConverter value={calculateGallonsBetweenMeterReadings(lastMeterReading, secondLastMeterReading)} fromUnits="gallons" toUnits="acrefeet" />
              </Descriptions.Item>
              <Descriptions.Item label="Inches / Acre">
                {
                  <UnitsConverter
                    value={
                      waterRight?.authorizedIrrigationNotApplicable === true || waterRight?.authorizedQuantityNotApplicable === true
                        ? "N/A"
                        : calculateGallonsBetweenMeterReadings(lastMeterReading, secondLastMeterReading)
                    }
                    fromUnits="gallons"
                    toUnits="acreinches"
                    devideBy={waterRight?.usageIrrigationAcres ?? waterRight?.authorizedIrrigationAcres}
                  />
                }
              </Descriptions.Item>
              <Descriptions.Item label="Average GPM">
                {calculateAverageGPM(calculateGallonsBetweenMeterReadings(lastMeterReading, secondLastMeterReading), lastMeterReading?.date, secondLastMeterReading?.date)}
              </Descriptions.Item>
              <Descriptions.Item label="Reading Date">{lastMeterReading?.date ? dayjs(lastMeterReading?.date).format(constants.dateTimeFormat) : "No readings"}</Descriptions.Item>
              <Descriptions.Item label="Time Between Readings">
                {secondLastMeterReading?.date ? (
                  <RemainingDays daysLeftOverride={9999999} remainingDays={dateDiffInDays(new Date(secondLastMeterReading?.date), new Date(lastMeterReading?.date))} />
                ) : (
                  "Not enough readings"
                )}
              </Descriptions.Item>
            </Descriptions>
          </Col>
          {calculation && (
            <Col>
              <RadialGauge
                usedQuantity={determineValue(overrideId ?? (id as string), "currentUsage")}
                availableQuantity={determineValue(overrideId ?? (id as string), "availableQuantity")}
                remainingDays={determineValue(overrideId ?? (id as string), "remainingDays")}
                style={{ paddingLeft: 14, width: 320 }}
                companyId={wellData?.companyId}
              />
              {/* <QuantityGauge
              warningQuantity={10}
              warningQuantityUnits={'acrefeet'}
              availableQuantity={determineValue(overrideId ?? id as string, 'availableQuantity')}
              usedQuantity={determineValue(overrideId ?? id as string, 'currentUsage')}
            /> */}
            </Col>
          )}
        </Row>
        <br />
        <Tabs defaultActiveKey="1" type="card" size="small" style={{ marginBottom: 32 }} items={renderTabs(wellData)} />
      </div>
    );
  };

  const { canEdit } = useSelectedCompanyData(wellData?.companyId);

  return (
    <Card
      className="wellView"
      loading={loading}
      id="wellView"
      title={showHeader ? `Well / Meter - ${loading ? "loading..." : wellData?.name}` : undefined}
      extra={
        showHeader ? (
          <>
            <Button icon={<LeftOutlined />} style={{ marginRight: 5 }} onClick={navigateBack}>
              Back
            </Button>
            {canEdit && (
              <Button icon={<EditOutlined />} type="primary" onClick={navigateEdit}>
                Edit
              </Button>
            )}
          </>
        ) : undefined
      }
    >
      {showHeader ? <PrintPage content={renderContent()} /> : renderContent()}
    </Card>
  );
};

export default WellView;
