import { get, post, put } from "@/services/request";

export const getGroupings = (companyId: string, searchString: any) => {
  return get(`farm/list?companyId=${companyId}&${searchString ? "searchString=" + searchString : ""}`);
};

export const getGrouping = (id: any) => {
  return get(`farm/${id}`);
};

export const addGrouping = (request: any) => {
  return post("farm", request);
};

export const updateGrouping = (id: any, request: any) => {
  return put(`farm/update/${id}`, request);
};

export const getGroupingHealth = (removeAuth: boolean) => {
  return get("farm/health", removeAuth);
};

export const countGroupings = (companyId?: string) => {
  const queryParams = companyId ? `?companyId=${companyId}` : "";

  return get(`farm/count` + queryParams);
};

export const getGroupingAuditLogs = (id: string) => {
  return get(`farm/auditlog/${id}/list`);
};

export const getPagedGroupings = (request: any) => {
  return post(`farm/paged/list`, request);
};
