import { JoinedItems, LastReadingFrequency, LookupLabel, RestrictionPopover, WellDashboardTable } from "@/components";
import Stats from "@/components/Dashboard/Stats/Stats";
import CompanyLabel from "@/components/Lookups/CompanyLabel";
import UnitsConverter from "@/components/UnitsConverter/UnitsConverter";
import { routes } from "@/configs";
import useComapnyConfigLookup from "@/queries/useCompanyConfigLookup";
import useFieldLookups from "@/queries/useFieldLookups";
import useWaterRightLastReadingInfiniteQuery from "@/queries/useListWaterRightLastReadingInfiniteQuery";
import useRestrictionDashboardInfiniteQuery from "@/queries/useRestrictionDashboardInfiniteQuery";
import useWellLookups from "@/queries/useWellLookups";
import useCustomNavigate from "@/services/useCustomNavigate";
import { useAppDispatch } from "@/stores";
import { addBreadcrumb } from "@/stores/breadcrumbs.store";
import { ReloadOutlined } from "@ant-design/icons";
import { useIntersectionObserver } from "@uidotdev/usehooks";
import { Button, Card, Col, Input, Row, Space, Table, TableProps } from "antd";
import { FilterValue, SorterResult } from "antd/es/table/interface";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const OperationsDashboard = () => {
  const dispatch = useAppDispatch();

  const { selectedCompany, selectedCompanyId } = useSelector((state: any) => state.company);
  const { lookups } = useSelector((state: any) => state.lookups);

  const { navigate, updateQueryParams, queryParams } = useCustomNavigate();

  const [waterRightDownref, waterRightDownentry] = useIntersectionObserver();
  const [restrictionDownref, restrictionDownentry] = useIntersectionObserver();

  const [waterRightColumns, setWaterRightColumns] = useState<any[]>([]);
  const [restrictionColumns, setRestrictionColumns] = useState<any[]>([]);

  const [waterRightSearchString, setWaterRightSearchString] = useState<string | undefined>("");
  const [waterRightSearchStringValue, setWaterRightSearchStringValue] = useState<string | undefined>("");

  const [restrictionSearchString, setRestrictionSearchString] = useState<string | undefined>("");
  const [restrictionSearchStringValue, setRestrictionSearchStringValue] = useState<string | undefined>("");

  const [filteredWaterRightInfo, setFilteredWaterRightInfo] = useState<Record<string, FilterValue | null>>({});
  const [sortedWaterRightInfo, setSortedWaterRightInfo] = useState<SorterResult<any>>({});

  const [filteredRestrictionInfo, setFilteredRestrictionInfo] = useState<Record<string, FilterValue | null>>({});
  const [sortedRestrictionInfo, setSortedRestrictionInfo] = useState<SorterResult<any>>({});

  const { fields, isLoading: isFieldsLoading } = useFieldLookups(undefined, selectedCompanyId ?? null, false);
  const { wells, isLoading: isWellsLoading, invalidateWells } = useWellLookups(undefined, selectedCompanyId ?? null, false);
  const { companyConfigs, isLoading: isCompanyConfigsLoading } = useComapnyConfigLookup();

  const {
    waterRightLastReadingInfinite: waterRightLastReadings,
    fetchNextPage: fetchNextWaterRightPage,
    invalidatelastReadingWaterRights,
    hasNextPage: hasNextWaterRightPage,
    isLoading: isWaterRightLastReadingInfiniteLoading,
    totalItemCount: waterRightTotalCount,
    currentItemCount: waterRightCurrentCount,
  } = useWaterRightLastReadingInfiniteQuery({
    searchString: waterRightSearchString,
    sortOrder:
      Object.keys(sortedWaterRightInfo).length === 0
        ? {
            FileNumber: "ASC",
          }
        : {
            FileNumber: sortedWaterRightInfo.field === "fileNumber" ? (sortedWaterRightInfo.order === "ascend" ? "ASC" : "DESC") : undefined,
            Fields: sortedWaterRightInfo.field === "fields" ? (sortedWaterRightInfo.order === "ascend" ? "ASC" : "DESC") : undefined,
            Wells: sortedWaterRightInfo.field === "wells" ? (sortedWaterRightInfo.order === "ascend" ? "ASC" : "DESC") : undefined,
            LastReadingDate: sortedWaterRightInfo.field === "lastReadingDate" ? (sortedWaterRightInfo.order === "ascend" ? "ASC" : "DESC") : undefined,
          },
    wellFilters: filteredWaterRightInfo?.wells === undefined ? undefined : filteredWaterRightInfo?.wells?.map((well: any) => well.toString()),
    fieldFilters: filteredWaterRightInfo?.fields === undefined ? undefined : filteredWaterRightInfo?.fields?.map((field: any) => field.toString()),
  });

  const {
    restrictions,
    fetchNextPage: fetchNextRestrictionPage,
    invalidateDashboardRestrictions,
    hasNextPage: hasNextRestrictionPage,
    isLoading: isRestrictionsLoading,
    totalItemCount: restrictionTotalCount,
    currentItemCount: restrictionCurrentCount,
  } = useRestrictionDashboardInfiniteQuery({
    searchString: restrictionSearchString,
    sortOrder:
      Object.keys(sortedRestrictionInfo).length === 0
        ? {
            Name: "ASC",
          }
        : {
            Name: sortedRestrictionInfo.field === "name" ? (sortedRestrictionInfo.order === "ascend" ? "ASC" : "DESC") : undefined,
            Type: sortedRestrictionInfo.field === "type" ? (sortedRestrictionInfo.order === "ascend" ? "ASC" : "DESC") : undefined,
            RemainingUsage: sortedRestrictionInfo.field === "remainingQuantity" ? (sortedRestrictionInfo.order === "ascend" ? "ASC" : "DESC") : undefined,
            CurrentYearRemaining: sortedRestrictionInfo.field === "currentYearRemaining" ? (sortedRestrictionInfo.order === "ascend" ? "ASC" : "DESC") : undefined,
          },
    typeFilters: filteredRestrictionInfo?.type === undefined ? undefined : filteredRestrictionInfo?.type?.map((type: any) => type.toString()),
  });

  useEffect(() => {
    if (waterRightDownentry?.isIntersecting && hasNextWaterRightPage) {
      fetchNextWaterRightPage();
    }
  }, [waterRightDownentry?.isIntersecting, hasNextWaterRightPage]);

  useEffect(() => {
    if (restrictionDownentry?.isIntersecting && hasNextRestrictionPage) {
      fetchNextRestrictionPage();
    }
  }, [restrictionDownentry?.isIntersecting, hasNextRestrictionPage]);

  // useEffect(() => {
  //   if (isRestrictionsFetched && !queryParams?.r_page && !queryParams?.r_pageSize) {
  //     updateQueryParams({ r_page: 1, r_pageSize: 10 });
  //   }
  // }, [isRestrictionsFetched]);

  useEffect(() => {
    calculateWaterRightColumnsColumns();
    // eslint-disable-next-line
  }, [waterRightLastReadings, companyConfigs, filteredWaterRightInfo]);

  useEffect(() => {
    calculateRestrictionColumns();
    // eslint-disable-next-line
  }, [restrictions, lookups, sortedRestrictionInfo, filteredRestrictionInfo]);

  const handleWaterRightRefresh = () => {
    setWaterRightSearchString("");
    setWaterRightSearchStringValue("");
    setSortedWaterRightInfo({});
    setFilteredWaterRightInfo({});
    updateQueryParams({ wr_page: 1, wr_pageSize: 10 });
    invalidatelastReadingWaterRights();
  };

  const handleRefresh = () => {
    setRestrictionSearchString("");
    setRestrictionSearchStringValue("");
    setSortedRestrictionInfo({});
    setFilteredRestrictionInfo({});
    updateQueryParams({ r_page: 1, r_pageSize: 10 });
    invalidateDashboardRestrictions();
  };

  const handleViewWaterRight = (record: any) => {
    dispatch(
      addBreadcrumb({
        type: `${record.fileNumber}`,
        url: `waterright/${record.waterRightId}/view`,
      })
    );
    navigate(routes.waterRightView, { id: record.waterRightId });
  };

  const calculateFieldFiltersForWaterRight = () => {
    const fieldsForWr = fields
      ?.sort((a: any, b: any) => a?.name?.localeCompare(b?.name, "en", { numeric: true, sensitivity: "base" }))
      ?.map((field: any) => {
        return { text: field.name, value: field.name };
      });

    return fieldsForWr;
  };

  const calculateWellFiltersForWaterRight = () => {
    const wellsForWr = wells
      ?.sort((a: any, b: any) => a?.name?.localeCompare(b?.name, "en", { numeric: true, sensitivity: "base" }))
      ?.map((well: any) => {
        return { text: well.name, value: well.name };
      });

    return wellsForWr;
  };

  const handleWaterRightTableChange: TableProps<any>["onChange"] = (pagination, filters, sorter) => {
    updateQueryParams({
      wr_page: pagination.current,
      wr_pageSize: pagination.pageSize,
    });

    setSortedWaterRightInfo(sorter as SorterResult<any>);
    setFilteredWaterRightInfo(filters);
  };

  const handleRestrictionTableChange: TableProps<any>["onChange"] = (pagination, filters, sorter) => {
    updateQueryParams({
      r_page: pagination.current,
      r_pageSize: pagination.pageSize,
    });

    setSortedRestrictionInfo(sorter as SorterResult<any>);
    setFilteredRestrictionInfo(filters);
  };

  const calculateWaterRightColumnsColumns = () => {
    let tempColumns: any = [
      {
        title: (
          <>
            Water Right /<br /> File Number
          </>
        ),
        key: "fileNumber",
        dataIndex: "fileNumber",
        sorter: true,
        sortOrder: sortedWaterRightInfo?.columnKey === "fileNumber" ? sortedWaterRightInfo?.order : null,
        render: (val: any, record: any, index: any) => (
          <>
            <Button style={{ padding: 0, margin: 0, textWrap: "wrap", lineHeight: "1.2em", textAlign: "left", height: 18 }} type="link">
              {val}
            </Button>
            {!selectedCompanyId && (
              <div style={{ padding: 0, margin: 0, fontSize: 11 }}>
                (<CompanyLabel companyId={record?.companyId} />)
              </div>
            )}
            {index === waterRightCurrentCount - 1 ? <span ref={waterRightDownref} /> : null}
          </>
        ),
      },
      {
        title: (
          <>
            PDIV <br /> CIN
          </>
        ),
        key: "pdivcin",
        width: 100,
        render: (val: any, record: any, index: any) => (
          <>
            <div style={{ padding: 0, margin: 0, fontSize: 11 }}>{record?.pdiv ?? "-"}</div>
            <div style={{ padding: 0, margin: 0, fontSize: 11 }}>{record?.cin ?? "-"}</div>
          </>
        ),
      },
      {
        title: "Fields",
        key: "fields",
        dataIndex: "fields",
        filters: calculateFieldFiltersForWaterRight(),
        // onFilter: (value: string, record: any) => record.fields?.includes(value),
        filteredValue: filteredWaterRightInfo?.fields || null,
        sorter: true,
        sortOrder: sortedWaterRightInfo?.columnKey === "fields" ? sortedWaterRightInfo?.order : null,
        render: (val: any, record: any) =>
          isWaterRightLastReadingInfiniteLoading ? "Loading..." : <JoinedItems items={record?.fields?.map((field: any) => field)} maxLength={30} title={"Fields for Water Right"} />,
      },
      {
        title: "Wells",
        key: "wells",
        dataIndex: "wells",
        filters: calculateWellFiltersForWaterRight(),
        // onFilter: (value: string, record: any) => record.wells?.includes(value),
        filteredValue: filteredWaterRightInfo?.wells || null,
        sorter: true,
        sortOrder: sortedWaterRightInfo?.columnKey === "wells" ? sortedWaterRightInfo?.order : null,
        render: (val: any, record: any) =>
          isWaterRightLastReadingInfiniteLoading ? "Loading..." : <JoinedItems items={record?.wells?.map((well: any) => well)} maxLength={30} title={"Wells for Water Right"} />,
      },
      {
        title: (
          <>
            Last <br /> Reading Date
          </>
        ),
        width: 130,
        key: "lastReadingDate",
        dataIndex: "lastReadingDate",
        sorter: true,
        sortOrder: sortedWaterRightInfo?.columnKey === "lastReadingDate" ? sortedWaterRightInfo?.order : null,
        render: (val: any, record: any) => (
          <LastReadingFrequency type={"water right"} date={val} meterReadingFrequency={companyConfigs?.find((c: any) => c?.id === record?.companyId)?.settings?.meterReadingFrequency} includeNewLine />
        ),
      },
    ];

    setWaterRightColumns(tempColumns);
  };

  const handleViewRestriction = (record: any) => {
    dispatch(
      addBreadcrumb({
        type: `${record.name}`,
        url: `restriction/${record.id}/view`,
      })
    );
    navigate(routes.restrictionView, { id: record.id });
  };

  const calculateTypeFiltersForRestriction = () => {
    const restrictionType = lookups.find((lookup: any) => lookup.map === "restrictiontype");
    if (restrictionType !== undefined) {
      const typeForRestriction = restrictionType.options.map((lookup: any) => {
        return { text: lookup.name, value: lookup.value };
      });

      return typeForRestriction;
    } else return null;
  };

  const calculateRestrictionColumns = () => {
    let tempColumns = [
      {
        title: <>Name</>,
        key: "name",
        dataIndex: "name",
        fixed: "left",
        sorter: true,
        sortOrder: sortedRestrictionInfo?.columnKey === "name" ? sortedRestrictionInfo?.order : null,
        render: (val: any, record: any, index: any) => (
          <>
            <Button style={{ padding: 0, margin: 0, textWrap: "wrap", lineHeight: "1.2em", textAlign: "left", height: 18 }} type="link">
              {val}
            </Button>
            {!selectedCompanyId && (
              <div style={{ padding: 0, margin: 0, fontSize: 11 }}>
                (<CompanyLabel companyId={record?.companyId} />)
              </div>
            )}
            {index === restrictionCurrentCount - 1 ? <span ref={restrictionDownref} /> : null}
          </>
        ),
      },
      {
        title: "Type",
        key: "type",
        dataIndex: "type",
        width: 130,
        filters: calculateTypeFiltersForRestriction(),
        filteredValue: filteredRestrictionInfo?.type ?? null,
        sorter: true,
        sortOrder: sortedRestrictionInfo?.columnKey === "type" ? sortedRestrictionInfo?.order : null,
        render: (val: string, record: any) => {
          if (!record?.myfa) return <LookupLabel lookupType="restrictiontype" value={val} />;
          else
            return (
              <>
                <LookupLabel lookupType="restrictiontype" value={val} /> - MYFA
              </>
            );
        },
      },
      {
        title: (
          <>
            Remaining Quantity
            <br />
            <small>
              (
              <LookupLabel lookupType="meterUnits" value={selectedCompany?.settings?.metric ?? "acrefeet"} />)
            </small>
          </>
        ),
        width: 130,
        key: "remainingQuantity",
        dataIndex: "remainingQuantity",
        sorter: true,
        sortOrder: sortedRestrictionInfo?.columnKey === "remainingQuantity" ? sortedRestrictionInfo?.order : null,
        render: (val: any, record: any) => <UnitsConverter fromUnits="gallons" toUnits={selectedCompany?.settings?.metric ?? "acrefeet"} value={record?.remainingUsage} />,
      },
      {
        title: (
          <>
            Current Year Remaining
            <br />
            <small>(Acre Feet)</small>
          </>
        ),
        width: 130,
        key: "currentYearRemaining",
        dataIndex: "currentYearRemaining",
        sorter: true,
        sortOrder: sortedRestrictionInfo?.columnKey === "currentYearRemaining" ? sortedRestrictionInfo?.order : null,
        render: (val: any, record: any) => <UnitsConverter fromUnits="gallons" toUnits="acrefeet" value={val} />,
      },
      {
        title: "Remaining Yearly Average",
        key: "yearlyAverage",
        dataIndex: "yearlyAverage",
        render: (val: any, record: any) => (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <RestrictionPopover
              companyId={record?.companyId}
              selectedCompany={selectedCompany}
              startDate={dayjs(record?.startDate)}
              endDate={dayjs(record?.endDate)}
              remainingYears={record?.remainingYears}
              calculation={record}
              restriction={record}
              handleRefresh={handleRefresh}
              restrictionTable
            />
            <div style={{ fontSize: "0.8em" }}>
              <div>
                <UnitsConverter fromUnits="gallons" toUnits={selectedCompany?.settings?.metric ?? "acrefeet"} showUnitsLabel value={record?.remainingYearlyAverage} />
              </div>
              {record?.inchesPerAcre !== null && (
                <div>
                  <UnitsConverter fromUnits="inchesperacre" toUnits="inchesperacre" value={record?.inchesPerAcre} postFix="Inches / Acre" />
                </div>
              )}
            </div>
          </div>
        ),
      },
    ];

    setRestrictionColumns(tempColumns);
  };

  return (
    <>
      <Row>
        <Stats companyId={selectedCompany?.companyId} renderCompanyCount />
      </Row>
      <Row>
        <Col span={24}>
          <WellDashboardTable />
        </Col>
        <Col span={12}>
          <Card
            title={
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                <span>Water Rights</span>
                <Space direction="horizontal">
                  <Space>
                    <Input.Search
                      disabled={isWaterRightLastReadingInfiniteLoading}
                      placeholder="Search water rights"
                      onSearch={(val) => {
                        setWaterRightSearchString(val);
                        updateQueryParams({ wr_page: 1, wr_pageSize: 10 });
                      }}
                      onChange={(val) => setWaterRightSearchStringValue(val.currentTarget.value)}
                      value={waterRightSearchStringValue}
                      style={{ width: 300, fontWeight: "normal" }}
                      allowClear
                    />
                    <Button icon={<ReloadOutlined />} disabled={isWaterRightLastReadingInfiniteLoading} onClick={handleWaterRightRefresh}>
                      Refresh
                    </Button>
                  </Space>
                </Space>
                <span>Total: {waterRightTotalCount ?? "-"}</span>
              </div>
            }
            bordered={false}
            styles={{ body: { padding: 0, borderTop: "#d9d9d9 1px solid", height: 570 } }}
          >
            <Table
              className="customScrollBarStyle"
              rowKey={(row: any) => row.id}
              loading={isWaterRightLastReadingInfiniteLoading}
              columns={waterRightColumns}
              dataSource={waterRightLastReadings ?? []}
              size="small"
              onChange={handleWaterRightTableChange}
              pagination={false}
              scroll={{ y: 500, x: 600, scrollToFirstRowOnChange: true }}
              style={{ minHeight: 500 }}
              onRow={(record) => {
                return {
                  style: { cursor: "pointer" },
                  onClick: () => handleViewWaterRight(record),
                };
              }}
            />
          </Card>
        </Col>
        <Col span={12}>
          <Card
            title={
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                <span>Restrictions</span>
                <Space direction="horizontal">
                  <Space>
                    <Input.Search
                      disabled={isRestrictionsLoading}
                      placeholder="Search restrictions"
                      onSearch={(val) => {
                        setRestrictionSearchString(val);
                        updateQueryParams({ r_page: 1, r_pageSize: 10 });
                      }}
                      onChange={(val) => setRestrictionSearchStringValue(val.currentTarget.value)}
                      value={restrictionSearchStringValue}
                      style={{ width: 300, fontWeight: "normal" }}
                      allowClear
                    />
                    <Button icon={<ReloadOutlined />} disabled={isRestrictionsLoading} onClick={handleRefresh}>
                      Refresh
                    </Button>
                  </Space>
                </Space>
                <span>Total: {restrictionTotalCount ?? "-"}</span>
              </div>
            }
            bordered={false}
            styles={{ body: { padding: 0, borderTop: "#d9d9d9 1px solid", height: 570 } }}
          >
            <Table
              className="customScrollBarStyle"
              rowKey={(row: any) => row.id}
              loading={isRestrictionsLoading}
              columns={restrictionColumns}
              dataSource={restrictions || []}
              size="small"
              onChange={handleRestrictionTableChange}
              pagination={false}
              scroll={{ y: 478, x: 600, scrollToFirstRowOnChange: true }}
              style={{ minHeight: 500 }}
              onRow={(record) => {
                return {
                  style: { cursor: "pointer" },
                  onClick: () => handleViewRestriction(record),
                };
              }}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default OperationsDashboard;
