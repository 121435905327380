import { Card, ConfigProvider, Descriptions, Image, Space, Table } from "antd";
import Title from "antd/es/typography/Title";
import { getFields } from "@/apis/field.api";
import { getWaterRights } from "@/apis/waterright.api";
import { getWells } from "@/apis/well.api";
import { LookupLabel, StatusTag } from "@/components";
import PrintPage from "@/components/PrintPage/PrintPage";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import "./GeneratedSummaryReport.scss";

interface Props {
  reportConfig: any;
}

const customizeRenderEmpty = () => <div style={{ textAlign: "center" }}>No Data</div>;

export default function GeneratedWellConfigurationReport(props: Props) {
  const { reportConfig } = props;

  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const [loadingWaterRights, setLoadingWaterRights] = useState<boolean>(true);
  const [loadingWells, setLoadingWells] = useState<boolean>(true);
  const [loadingFields, setLoadingFields] = useState<boolean>(true);
  const [generatingReport, setGeneratingReport] = useState<boolean>(false);

  const [waterRights, setWaterRights] = useState<any[]>([]);
  const [tempWells, setWells] = useState<any[]>([]);
  const [fields, setFields] = useState<any[]>([]);

  const [wellColumns, setWellColumns] = useState<any[]>([]);

  const wells: any[] = useMemo(() => {
    const data = tempWells.map((well: any) => {
      const waterRightForWell = waterRights.find((waterRight) => waterRight.id === well.waterRightId);
      const fieldForWaterRight = fields.find((field) => field.id === well.fieldId)?.name;
      const reductionWells = well.reductionWells ?? [];
      const reductionWellsForWell = tempWells.filter((tempWell) => reductionWells.includes(tempWell.id)).map((tempWell) => tempWell.name);

      return {
        ...well,
        waterRight: waterRightForWell,
        fieldName: fieldForWaterRight ? fieldForWaterRight : ["-"],
        reductionWellNames: reductionWellsForWell.length > 0 ? reductionWellsForWell : ["-"],
      };
    });

    return data;
  }, [tempWells, waterRights, fields]);

  useEffect(() => {
    if (generatingReport) setTimeout(() => setGeneratingReport(false), 500);
    // eslint-disable-next-line
  }, [generatingReport]);

  useEffect(() => {
    if (selectedCompanyId) {
      refreshWaterRightsList();
      refreshWells();
      refreshFields();
    }
    // eslint-disable-next-line
  }, [selectedCompanyId]);

  useEffect(() => {
    calculateWellColumns();
    // eslint-disable-next-line
  }, [wells]);

  const refreshWaterRightsList = async () => {
    setLoadingWaterRights(true);

    const request = {
      companyId: selectedCompanyId,
      isActive: undefined,
    };

    const response = await getWaterRights(request);
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setWaterRights(data.value);
      }
    }
    setLoadingWaterRights(false);
  };

  const refreshWells = async () => {
    setLoadingWells(true);
    if (selectedCompanyId) {
      const request = { companyId: selectedCompanyId };
      const response = await getWells(request);

      if (response.ok) {
        const data = await response.json();
        if (data.isSuccess) {
          setWells(data.value);
        }
      }
    }
    setLoadingWells(false);
  };

  const refreshFields = async () => {
    setLoadingFields(true);
    if (selectedCompanyId) {
      const request = { companyId: selectedCompanyId };
      const response = await getFields(request);

      if (response.ok) {
        const data = await response.json();
        if (data.isSuccess) {
          setFields(data.value);
        }
      }
    }
    setLoadingFields(false);
  };

  const calculateWellColumns = () => {
    let tempColumns = [
      {
        title: "#",
        width: 50,
        render: (val: any, record: any, index: any) => index + 1,
      },
      {
        title: "Well Name",
        key: "name",
        dataIndex: "name",
        fixed: "left",
        render: (val: any, record: any) => val,
      },
      {
        title: "Field Name",
        key: "fieldName",
        dataIndex: "fieldName",
        render: (val: any, record: any) => val,
      },
      {
        title: (
          <>
            Water Right /<br /> File Number
          </>
        ),
        key: "waterRight",
        dataIndex: "waterRight",
        render: (val: any, record: any) => (
          <>
            <div style={{ paddingLeft: 5 }}>{record?.waterRight?.fileNumber}</div>
            <div style={{ paddingLeft: 5 }}>PDIV: {record?.waterRight?.pdiv ? record.waterRight.pdiv : "-"}</div>
            <div style={{ paddingLeft: 5 }}>CIN: {record?.waterRight?.cin ? record.waterRight.cin : "-"}</div>
          </>
        ),
      },
      {
        title: "Status",
        key: "status",
        dataIndex: "status",
        render: (val: any, record: any) => <StatusTag status={val} />,
      },
      {
        title: (
          <>
            Meter <br />
            Max <br />
            Value
          </>
        ),
        key: "meterMaxValue",
        dataIndex: "meterMaxValue",
        align: "right",
        render: (val: any, record: any) => <LookupLabel lookupType="metermaxvalue" value={record?.meterMaxValue} />,
      },
      {
        title: (
          <>
            Meter <br />
            Multiplier
          </>
        ),
        key: "meterMultiplier",
        dataIndex: "meterMultiplier",
        align: "right",
        render: (val: any, record: any) => val,
      },
      {
        title: (
          <>
            Is <br />
            Digital <br />
            Meter
          </>
        ),
        key: "digital",
        dataIndex: "digital",
        render: (val: any, record: any) => <StatusTag status={val === true ? "YES" : "No"} />,
      },
      {
        title: (
          <>
            Number <br />
            of <br />
            Decimals
          </>
        ),
        key: "decimals",
        dataIndex: "decimals",
        render: (val: any, record: any) => (val ? val : "N/A"),
      },
      {
        title: (
          <>
            Meter <br />
            Units
          </>
        ),
        key: "meterUnits",
        dataIndex: "meterUnits",
        render: (val: any, record: any) => <LookupLabel lookupType="meterunits" value={record?.meterUnits} />,
      },
      {
        title: (
          <>
            Meter <br />
            Serial <br />
            Number
          </>
        ),
        key: "meterSerialNumber",
        dataIndex: "meterSerialNumber",
        render: (val: any, record: any) => (val ? val : "-"),
      },
      {
        title: (
          <>
            Latitude <br />
            Longitude
          </>
        ),
        // align: 'right',
        render: (val: any, record: any) => {
          if (record?.latitude && record?.longitude) {
            return (
              <>
                {record.latitude},
                <br />
                {record.longitude}
              </>
            );
          } else return "-";
        },
      },
    ];

    setWellColumns(tempColumns.filter((x) => x));
  };

  const renderWellExpandData = (record: any) => {
    return (
      <div style={{ padding: 5 }}>
        {record?.reductionWellNames?.length > 0 && (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                fontSize: 12,
                width: 160,
                fontWeight: "bold",
                padding: 0,
              }}
            >
              Deduction Wells / Meters:{" "}
            </div>
            <div style={{ fontSize: 12, fontWeight: "normal", flex: 1 }}>{record?.reductionWellNames?.map((x: any) => x).join(", ")}</div>
          </div>
        )}
      </div>
    );
  };

  return (
    <PrintPage
      content={
        <Card
          id="generatedWellSummaryReport"
          className="remove-border-and-padding-on-print"
          title={
            <div>
              <Space size="middle">
                <Image
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                    textAlign: "center",
                    marginLeft: "auto",
                    marginRight: "auto",
                    alignContent: "center",
                    maxHeight: 200,
                    maxWidth: 200,
                    borderRadius: 10,
                    objectFit: "contain",
                  }}
                  src="/logo.png"
                  preview={false}
                />
                <Space direction="vertical" size="small" style={{ display: "flex" }}>
                  <Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>{`Well Configuration Report`}</Title>
                  <Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>{`Generated On ${reportConfig.generatedDate}`}</Title>
                </Space>
              </Space>
            </div>
          }
          loading={loadingWells && loadingWaterRights && loadingFields}
        >
          {
            <Card title="Wells / Meters">
              <ConfigProvider renderEmpty={customizeRenderEmpty}>
                <Table
                  rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
                  rowKey={(row: any) => row.id}
                  loading={loadingWells}
                  columns={wellColumns}
                  dataSource={reportConfig?.wellIds ? wells.filter((well) => reportConfig?.wellIds.includes(well.id)) : []}
                  size="small"
                  pagination={false}
                  expandable={{
                    showExpandColumn: false,
                    expandedRowKeys: reportConfig?.wellIds
                      ? wells.filter((well) => reportConfig?.wellIds.includes(well.id) && well.reductionWellNames?.length > 0 && well.reductionWellNames[0] !== "-").map((well) => well.id)
                      : [],
                    expandedRowClassName: (record: any, index: number) => (index % 2 === 0 ? "table-row-light" : "table-row-dark"),
                    expandedRowRender: (record: any) => renderWellExpandData(record),
                  }}
                />
              </ConfigProvider>
            </Card>
          }
          <div className="page-break" />
          {reportConfig && (
            <Card title="Report Configuration" bodyStyle={{ padding: 10 }}>
              <Descriptions bordered size="small" column={1} labelStyle={{ width: 150 }} className="removeBoxShadow removeMargin">
                <Descriptions.Item label="Wells">{reportConfig.wells === "" ? "-" : reportConfig.wells}</Descriptions.Item>
                <Descriptions.Item label="Generated At">{reportConfig.generatedDate}</Descriptions.Item>
              </Descriptions>
            </Card>
          )}
        </Card>
      }
      isReport
    />
  );
}
