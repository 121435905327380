import { constants } from "@/configs";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import dayjs from "dayjs";
import { FC, useMemo } from "react";

interface Props {
  type: string;
  date: any;
  meterReadingFrequency: string;
  isReport?: boolean;
  includeNewLine?: boolean;
  placeholder?: string;
}

const LastReadingFrequency: FC<Props> = (props) => {
  const { type, date, meterReadingFrequency, isReport = false, includeNewLine, placeholder = "-" } = props;

  const renderDateTime = () => {
    if (includeNewLine)
      return (
        <div style={{ display: "flex", flexDirection: "column", gap: 2, justifyContent: "flex-start", alignItems: "flex-start" }}>
          <div>{dayjs(date).format(constants.dateFormat)}</div>
          <div>{dayjs(date).format(constants.timeFormatWithSeconds)}</div>
        </div>
      );
    return <span>{dayjs(date).format(constants.dateTimeFormat)}</span>;
  };

  const modifiedDate = useMemo(() => {
    switch (meterReadingFrequency) {
      case "daily":
        return dayjs(date).add(1, "day");
      case "weekly":
        return dayjs(date).add(1, "week");
      case "bi-weekly":
        return dayjs(date).add(2, "weeks");
      case "monthly":
        return dayjs(date).add(1, "month");
      default:
        return dayjs(date);
    }
  }, [meterReadingFrequency, date]);

  const now = dayjs();

  if (!date) return <>{placeholder}</>;

  if (isReport) return renderDateTime();

  return (
    <>
      {meterReadingFrequency === "none" ? (
        renderDateTime()
      ) : modifiedDate < now ? (
        <Popover content={`This ${type}  is due for collection`}>
          <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
            <span style={{ color: modifiedDate < now ? "red" : "black", lineHeight: "1.1em" }}>{renderDateTime()}</span>
            <InfoCircleOutlined style={{ color: modifiedDate < now ? "red" : "black" }} />
          </div>
        </Popover>
      ) : (
        renderDateTime()
      )}
    </>
  );
};

export default LastReadingFrequency;
