import { ReloadOutlined } from "@ant-design/icons";
import { Button, Card, Space, Table, message } from "antd";
import { getWaterRightNotes } from "@/apis/waterright.api";
import { getWellNotes } from "@/apis/well.api";
import NoteAddEditModal from "@/components/NoteAddEditModal/NoteAddEditModal";
import { constants } from "@/configs";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

interface NotesTableProps {
  wellId?: string;
  waterRightId?: string;
  title?: string;
  isReport?: boolean;
}

export default function NotesTable(props: NotesTableProps) {
  const { wellId, waterRightId, title, isReport = false } = props;

  const [loading, setLoading] = useState<boolean>(true);
  const [notes, setNoteData] = useState<any[]>([]);
  const [noteModalState, setNoteModalState] = useState<any>({ open: false });
  const [columns, setColumns] = useState<any[]>([]);

  useEffect(() => {
    refreshNotes();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    calculateNoteColumns();
    // eslint-disable-next-line
  }, [notes]);

  const calculateNoteColumns = () => {
    let tempColumns: any[] = [
      {
        title: "Created At",
        key: "createdAt",
        dataIndex: "createdAt",
        width: 150,
        render: (val: any, record: any) => dayjs(val).format(constants.dateTimeFormat),
      },
      {
        title: "Created By",
        key: "createdByUserName",
        dataIndex: "createdByUserName",
        width: 150,
        render: (val: any, record: any) => val,
      },
      {
        title: "Note",
        key: "note",
        dataIndex: "note",
        render: (val: any, record: any) => (
          <pre
            style={{
              width: "100%",
              margin: 0,
              fontFamily: "inherit",
              display: "block",
              wordBreak: "break-all",
              overflowWrap: "anywhere",
            }}
          >
            {val}
          </pre>
        ),
      },
    ];

    if (isReport !== undefined && isReport == true)
      tempColumns.unshift({
        title: "#",
        width: 50,
        render: (val: any, record: any, index: any) => index + 1,
      });

    setColumns(tempColumns);
  };

  const refreshNotes = async () => {
    setLoading(true);
    let response: Response | undefined = undefined;
    if (wellId) response = await getWellNotes({ wellId: wellId });
    if (waterRightId) response = await getWaterRightNotes({ waterRightId: waterRightId });

    if (response !== undefined && response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setNoteData(data.value);
      }
    }
    setLoading(false);
  };

  const handleCancel = () => {
    setNoteModalState({
      ...noteModalState,
      open: false,
    });
  };

  const handleSuccess = () => {
    handleCancel();
    message.success("Added note successfully");
    refreshNotes();
  };

  return (
    <>
      {isReport ? (
        <Card
          id="notesTable"
          title={title ? title : `General Notes`}
          extra={
            !title ? (
              <>
                <Space direction="horizontal" style={{ width: "100%" }}>
                  <Button icon={<ReloadOutlined />} disabled={loading} loading={loading} onClick={() => refreshNotes()}>
                    Refresh
                  </Button>
                  <Button disabled={loading} type="primary" onClick={() => setNoteModalState({ ...noteModalState, open: true })}>
                    Add Note
                  </Button>
                </Space>
              </>
            ) : undefined
          }
        >
          <Table
            rowClassName={(record, index) => (isReport === true ? (index % 2 === 0 ? "table-row-light" : "table-row-dark") : "")}
            loading={loading}
            columns={columns}
            dataSource={notes}
            size="small"
            pagination={isReport ? false : undefined}
          />
          {noteModalState.open && <NoteAddEditModal waterRightId={waterRightId} wellId={wellId} onSuccess={handleSuccess} open={noteModalState.open} onCancel={handleCancel} />}
        </Card>
      ) : (
        <Card
          id="notesTable"
          title={title ? title : `General Notes`}
          extra={
            !title ? (
              <>
                <Space direction="horizontal" style={{ width: "100%" }}>
                  <Button disabled={loading} icon={<ReloadOutlined />} loading={loading} onClick={() => refreshNotes()}>
                    Refresh
                  </Button>
                  <Button disabled={loading} type="primary" onClick={() => setNoteModalState({ ...noteModalState, open: true })}>
                    Add Note
                  </Button>
                </Space>
              </>
            ) : undefined
          }
        >
          <Table loading={loading} columns={columns} dataSource={notes} size="small" pagination={isReport ? false : undefined} />
          {noteModalState.open && <NoteAddEditModal waterRightId={waterRightId} wellId={wellId} onSuccess={handleSuccess} open={noteModalState.open} onCancel={handleCancel} />}
        </Card>
      )}
    </>
  );
}
