import { Card, Descriptions, Empty, Image, Space, Typography, Table, ConfigProvider } from "antd";
import { getCompanyTotalUsage } from "@/apis/aggregator.api";
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title as TitleChart, Tooltip } from "chart.js";
import PrintPage from "@/components/PrintPage/PrintPage";
import UnitsConverter from "@/components/UnitsConverter/UnitsConverter";
import { FC, useEffect, useMemo, useState } from "react";
import { Bar } from "react-chartjs-2";
import { useSelector } from "react-redux";
import { convertUnits, formatMeterUnits } from "@/services/utils";

const { Title } = Typography;

interface Props {
  reportConfig: config;
}

type config = {
  companyId: any;
  years: number[];
  generatedDate: any;
};

const backgroundColor = ["rgb(128,128,128)", "rgba(2,3,129)"];

ChartJS.register(CategoryScale, LinearScale, BarElement, TitleChart, Tooltip, Legend);

const customizeRenderEmpty = () => <div style={{ textAlign: "center" }}>No Data</div>;

const GeneratedCompanyTotalUsageReport: FC<Props> = (props) => {
  const { reportConfig } = props;

  const { selectedCompany } = useSelector((state: any) => state.company);

  const [loading, setLoading] = useState<boolean>(false);
  const [companyTotalUsage, setCompanyTotalUsage] = useState<any[]>([]);
  const [columns, setColumns] = useState<any[]>([]);
  const [chartOptions, setChartOptions] = useState<any>({
    plugins: {
      legend: {
        position: "bottom",
        padding: 20,
        align: "center",
      },
    },
    responsive: true,
    scales: {
      x: {
        title: {
          display: true,
          text: "Year",
        },
      },
      y: {
        title: {
          display: true,
          text: "Gallons",
        },
      },
    },
  });

  const stackedBarData: any | undefined = useMemo(() => {
    const meterUnits = formatMeterUnits(selectedCompany?.settings?.metric);

    setChartOptions((prevValue: any) => {
      return {
        ...prevValue,
        scales: {
          x: {
            stacked: true,
            title: {
              display: true,
              text: "Year",
            },
          },
          y: {
            stacked: true,
            title: {
              display: true,
              text: meterUnits,
              padding: 25,
            },
          },
        },
      };
    });

    const labels = companyTotalUsage?.map((usage: any) => usage?.year);
    const dataLabels = ["Total Authorized Quantity", "Total Usage"];
    let barData: any[] = [];

    for (let index = 0; index < 2; index++) {
      if (index === 0) {
        barData.push({
          label: dataLabels[index],
          data: companyTotalUsage?.map((usage: any) => convertUnits(usage?.totalAvailableQuantity, "gallons", selectedCompany?.settings?.metric, undefined)),
          backgroundColor: backgroundColor[index],
          stack: "Stack 1",
        });
      }

      if (index === 1) {
        barData.push({
          label: dataLabels[index],
          data: companyTotalUsage?.map((usage: any) => convertUnits(usage?.totalUsage, "gallons", selectedCompany?.settings?.metric, undefined)),
          backgroundColor: backgroundColor[index],
          stack: "Stack 0",
        });
      }
    }

    const dataSets = {
      labels: labels,
      datasets: barData,
    };

    return dataSets;
  }, [companyTotalUsage, selectedCompany]);

  useEffect(() => {
    if (reportConfig) retrieveCompanyTotalUsage();
    // eslint-disable-next-line
  }, [reportConfig]);

  useEffect(() => {
    calculateColumns();
    // eslint-disable-next-line
  }, [companyTotalUsage]);

  const calculateColumns = () => {
    let tempColumns = [
      {
        title: "Year",
        key: "year",
        dataIndex: "year",
        width: 300,
        render: (val: any) => val ?? "-",
      },
      {
        title: (
          <>
            Total Authorized Quantity
            <br />
            <small>Gallons</small>
            <br />
            <small>Acre Feet</small>
          </>
        ),
        key: "totalAvailableQuantity",
        dataIndex: "totalAvailableQuantity",
        align: "right",
        render: (val: any) => (
          <div>
            <UnitsConverter fromUnits="gallons" toUnits="gallons" value={val} overrideDecimalPlaces={2} /> <br />
            <UnitsConverter fromUnits="gallons" toUnits="acrefeet" value={val} overrideDecimalPlaces={2} />
          </div>
        ),
      },
      {
        title: (
          <>
            Total Usage
            <br />
            <small>Gallons</small>
            <br />
            <small>Acre Feet</small>
          </>
        ),
        key: "totalUsage",
        dataIndex: "totalUsage",
        align: "right",
        render: (val: any) => (
          <div>
            <UnitsConverter fromUnits="gallons" toUnits="gallons" value={val} overrideDecimalPlaces={2} /> <br />
            <UnitsConverter fromUnits="gallons" toUnits="acrefeet" value={val} overrideDecimalPlaces={2} />
          </div>
        ),
      },
      {
        title: "Percentage Remaining",
        width: 300,
        align: "right",
        render: (val: any, record: any) => {
          const percentage = calculatePercentage(record?.totalUsage, record?.totalAvailableQuantity);
          if (percentage === 0) return <>0.00 %</>;
          else return <>{percentage} %</>;
        },
      },
    ];

    setColumns(tempColumns);
  };

  const calculatePercentage = (totalUsage: number, totalAvailableQuantity: number) => {
    if (totalUsage > totalAvailableQuantity) return 0;
    else return ((1 - totalUsage / totalAvailableQuantity) * 100).toFixed(2);
  };

  const retrieveCompanyTotalUsage = async () => {
    setLoading(true);

    const response = await getCompanyTotalUsage(reportConfig.companyId);
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        if (data.value?.length > 0) {
          const filteredYears = data.value.filter((usage: any) => usage.year >= reportConfig.years[0] && usage.year <= reportConfig.years[1]);

          setCompanyTotalUsage(filteredYears);
        } else setCompanyTotalUsage([]);
      }
    }

    setLoading(false);
  };

  return (
    <PrintPage
      content={
        <Card
          id="generatedSummaryUsageReport"
          className="remove-border-and-padding-on-print"
          title={
            <div>
              <Space size="middle">
                <Image
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                    textAlign: "center",
                    marginLeft: "auto",
                    marginRight: "auto",
                    alignContent: "center",
                    maxHeight: 200,
                    maxWidth: 200,
                    borderRadius: 10,
                    objectFit: "contain",
                  }}
                  src="/logo.png"
                  preview={false}
                />
                <Space direction="vertical" size="small" style={{ display: "flex" }}>
                  <Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>{`Company Total Usage Report`}</Title>
                  <Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>{`Generated On ${reportConfig.generatedDate}`}</Title>
                </Space>
              </Space>
            </div>
          }
          loading={loading}
        >
          {stackedBarData ? (
            <>
              <Card title={`${selectedCompany?.companyName} - Company Total Usage`}>
                <Bar
                  style={{
                    width: "100%",
                    height: "100%",
                    maxWidth: 1000,
                    maxHeight: 800,
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                  options={chartOptions}
                  data={stackedBarData}
                />
                <ConfigProvider renderEmpty={customizeRenderEmpty}>
                  <Table
                    rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
                    rowKey={(row: any) => row.companyId}
                    loading={loading}
                    columns={columns}
                    dataSource={companyTotalUsage ?? []}
                    size="small"
                    pagination={false}
                  />
                </ConfigProvider>
              </Card>
              {/* <div className='page-break' /> */}
            </>
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
          {reportConfig && (
            <Card title="Report Configuration" bodyStyle={{ padding: 10 }}>
              <Descriptions bordered size="small" column={1} labelStyle={{ width: 150 }} className="removeBoxShadow removeMargin">
                <Descriptions.Item label="Generated At">{reportConfig.generatedDate}</Descriptions.Item>
                <Descriptions.Item label="Year Range">
                  {reportConfig.years[0]} - {reportConfig.years[1]}
                </Descriptions.Item>
              </Descriptions>
            </Card>
          )}
        </Card>
      }
      isReport
    />
  );
};

export default GeneratedCompanyTotalUsageReport;
