import { Card, ConfigProvider, Descriptions, Image, Space, Table } from "antd";
import Title from "antd/es/typography/Title";
import { getRestrictions, syncRestrictionCalculations } from "@/apis/restriction.api";
import { getWaterRights } from "@/apis/waterright.api";
import { getWells } from "@/apis/well.api";
import { LookupLabel } from "@/components";
import PrintPage from "@/components/PrintPage/PrintPage";
import { constants } from "@/configs";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import "./GeneratedSummaryReport.scss";

interface Props {
  reportConfig: any;
}

const customizeRenderEmpty = () => <div style={{ textAlign: "center" }}>No Data</div>;

export default function GeneratedRestrictionConfigurationReport(props: Props) {
  const { reportConfig } = props;

  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const [loadingWaterRights, setLoadingWaterRights] = useState<boolean>(true);
  const [loadingRestrictions, setLoadingRestrictions] = useState<boolean>(true);
  const [loadingWells, setLoadingWells] = useState<boolean>(true);
  const [loadingRestrictionCalculations, setLoadingRestrictionCalculations] = useState<boolean>(false);
  const [generatingReport, setGeneratingReport] = useState<boolean>(false);

  const [waterRights, setWaterRights] = useState<any[]>([]);
  const [wells, setWells] = useState<any[]>([]);
  const [tempRestrictions, setRestrictions] = useState<any[]>([]);
  const [restrictionCalculations, setRestrictionCalculations] = useState<any[]>([]);

  const [restrictionColumns, setRestrictionColumns] = useState<any[]>([]);

  const restrictions: any[] = useMemo(() => {
    const data = tempRestrictions.map((restriction: any) => {
      const waterRightsForRestriction = waterRights.filter((waterRight) => restriction.waterRightIds.includes(waterRight.id)).map((waterRight) => waterRight.fileNumber);
      const waterRightIds = restriction.waterRightIds;
      const wellsForRestriction = wells.filter((well) => waterRightIds.includes(well.waterRightId)).map((well) => well.name);

      return {
        ...restriction,
        waterRights: waterRightsForRestriction?.length > 0 ? waterRightsForRestriction : ["-"],
        wells: wellsForRestriction?.length > 0 ? wellsForRestriction : ["-"],
      };
    });
    return data;
  }, [waterRights, wells, tempRestrictions]);

  useEffect(() => {
    if (generatingReport) setTimeout(() => setGeneratingReport(false), 500);
    // eslint-disable-next-line
  }, [generatingReport]);

  useEffect(() => {
    if (selectedCompanyId) {
      refreshWaterRightsList();
      refreshWells();
      refreshRestrictions();
      refreshRestrictionCalculations();
    }
    // eslint-disable-next-line
  }, [selectedCompanyId]);

  useEffect(() => {
    calculateRestrictionColumns();
    // eslint-disable-next-line
  }, [restrictions, restrictionCalculations]);

  const refreshRestrictionCalculations = async () => {
    setLoadingRestrictionCalculations(true);
    const response = await syncRestrictionCalculations({});
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setRestrictionCalculations(data.value);
      }
    }
    setLoadingRestrictionCalculations(false);
  };

  const refreshRestrictions = async () => {
    setLoadingRestrictions(true);

    const request = { companyId: selectedCompanyId };

    const response = await getRestrictions(request);
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setRestrictions(data.value);
      }
    }
    setLoadingRestrictions(false);
  };

  const refreshWaterRightsList = async () => {
    setLoadingWaterRights(true);

    const request = {
      companyId: selectedCompanyId,
      isActive: undefined,
    };

    const response = await getWaterRights(request);
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setWaterRights(data.value);
      }
    }
    setLoadingWaterRights(false);
  };

  const refreshWells = async () => {
    setLoadingWells(true);
    if (selectedCompanyId) {
      const request = { companyId: selectedCompanyId };
      const response = await getWells(request);

      if (response.ok) {
        const data = await response.json();
        if (data.isSuccess) {
          setWells(data.value);
        }
      }
    }
    setLoadingWells(false);
  };

  const determineRestrictionValue = (id: string, type: string, postfix: any = undefined) => {
    const existingCalculation = restrictionCalculations.find((calculation) => calculation.id === id);
    if (existingCalculation) {
      return existingCalculation[type];
    } else return undefined;
  };

  const calculateRestrictionColumns = () => {
    let tempColumns = [
      {
        title: "#",
        width: 50,
        render: (val: any, record: any, index: any) => index + 1,
      },
      {
        title: "Restriction Name",
        key: "name",
        dataIndex: "name",
        fixed: "left",
        render: (val: any, record: any) => val,
      },
      {
        title: "Type",
        key: "type",
        dataIndex: "type",
        render: (val: string, record: any) => {
          if (!record?.myfa) return <LookupLabel lookupType="restrictiontype" value={val} />;
          else
            return (
              <>
                <LookupLabel lookupType="restrictiontype" value={val} /> - MYFA
              </>
            );
        },
      },
      {
        title: "Start Date",
        key: "startDate",
        dataIndex: "startDate",
        render: (val: any, record: any) => dayjs(record.startDate).format(constants.dateFormat),
      },
      {
        title: "End Date",
        key: "endDate",
        dataIndex: "endDate",
        render: (val: any, record: any) => dayjs(record.endDate).format(constants.dateFormat),
      },
      {
        title: "Years",
        render: (val: any, record: any) => determineRestrictionValue(record?.id, "years"),
      },
      {
        title: (
          <>
            Term Quantity <small>(Acre Feet)</small>
          </>
        ),
        key: "termQuantity",
        dataIndex: "termQuantity",
        align: "right",
        width: 300,
        render: (val: any, record: any) => val,
      },
    ];

    setRestrictionColumns(tempColumns);
  };

  const renderRestrictionExpandData = (record: any) => {
    return (
      <div style={{ padding: 5 }}>
        {record?.waterRights?.length > 0 && (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                fontSize: 12,
                width: 100,
                fontWeight: "bold",
                padding: 0,
              }}
            >
              Water Rights:{" "}
            </div>
            <div style={{ fontSize: 12, fontWeight: "normal", flex: 1 }}>{record?.waterRights?.map((x: any) => x).join(", ")}</div>
          </div>
        )}
        {record?.wells?.length > 0 && (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div
              style={{
                fontSize: 12,
                width: 100,
                fontWeight: "bold",
                padding: 0,
              }}
            >
              Wells / Meters:{" "}
            </div>
            <div style={{ fontSize: 12, fontWeight: "normal", flex: 1 }}>{record?.wells?.map((x: any) => x).join(", ")}</div>
          </div>
        )}
      </div>
    );
  };

  return (
    <PrintPage
      content={
        <Card
          id="generatedRestrictionSummaryReport"
          className="remove-border-and-padding-on-print"
          title={
            <div>
              <Space size="middle">
                <Image
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                    textAlign: "center",
                    marginLeft: "auto",
                    marginRight: "auto",
                    alignContent: "center",
                    maxHeight: 200,
                    maxWidth: 200,
                    borderRadius: 10,
                    objectFit: "contain",
                  }}
                  src="/logo.png"
                  preview={false}
                />
                <Space direction="vertical" size="small" style={{ display: "flex" }}>
                  <Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>{`Restriction Configuration Report`}</Title>
                  <Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>{`Generated On ${reportConfig.generatedDate}`}</Title>
                </Space>
              </Space>
            </div>
          }
          // title={`Summary Report on ${reportConfig.generatedDate}`}
          loading={loadingWells && loadingWaterRights && loadingRestrictions}
        >
          {
            <Card title="Restrictions">
              <ConfigProvider renderEmpty={customizeRenderEmpty}>
                <Table
                  rowClassName={(record, index) => (index % 2 === 0 ? "table-row-light" : "table-row-dark")}
                  rowKey={(row: any) => row.id}
                  loading={loadingRestrictions}
                  columns={restrictionColumns}
                  dataSource={reportConfig?.restrictionIds ? restrictions.filter((restriction) => reportConfig?.restrictionIds.includes(restriction.id)) : []}
                  size="small"
                  pagination={false}
                  expandable={{
                    showExpandColumn: false,
                    defaultExpandAllRows: true,
                    expandedRowClassName: (record: any, index: number) => (index % 2 === 0 ? "table-row-light" : "table-row-dark"),
                    expandedRowRender: (record: any) => renderRestrictionExpandData(record),
                  }}
                />
              </ConfigProvider>
            </Card>
          }
          <div className="page-break" />
          {reportConfig && (
            <Card title="Report Configuration" bodyStyle={{ padding: 10 }}>
              <Descriptions bordered size="small" column={1} labelStyle={{ width: 150 }} className="removeBoxShadow removeMargin">
                <Descriptions.Item label="Restrictions">{reportConfig.restrictions === "" ? "-" : reportConfig.restrictions}</Descriptions.Item>
                <Descriptions.Item label="Generated At">{reportConfig.generatedDate}</Descriptions.Item>
              </Descriptions>
            </Card>
          )}
        </Card>
      }
      isReport
    />
  );
}
