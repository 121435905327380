import { RegistrationModal } from "@/components";
import { routes } from "@/configs";
import { BaseLayout, BlankContentLayout, BlankContentLayoutWithoutBackground, SideMenuContentLayout, MobileLayout } from "@/layouts";
import {
  AboutUs,
  AcceptCompanyInvite,
  CompanyAddEdit,
  CompanyList,
  CompanyView,
  ContactUs,
  Dashboard,
  Error,
  EthicsSustainability,
  Faq,
  FieldAddEdit,
  FieldList,
  FieldView,
  GroupingAddEdit,
  GroupingList,
  GroupingView,
  Home,
  Lookups,
  MissionVision,
  NotificationList,
  PhotoGallery,
  PlaceOfUseAddEdit,
  PlaceOfUselist,
  PrivacyPolicy,
  ReportList,
  RestrictionAddEdit,
  RestrictionList,
  RestrictionView,
  StockAddEdit,
  StockList,
  StockView,
  TermsConditions,
  UserAddEdit,
  WaterRightAddEdit,
  WaterRightList,
  WaterRightView,
  WellAddEdit,
  WellList,
  WellView,
  UserList,
} from "@/pages";

import {
  AdminBilling,
  AdminContactUsList,
  AdminDashboard,
  AdminFaqAddEdit,
  AdminReports,
  AdminSupportQueryList,
  AdminSupportQueryView,
  AdminUsers,
  AdminCommunicationAddEdit,
  AdminCommunicationView,
  AdminCommunications,
  AdminContactUsView,
  AdminFaqList,
} from "@/pages/Admin";

import { createBrowserRouter } from "react-router-dom";

const router = createBrowserRouter([
  {
    element: <BaseLayout />,
    errorElement: <Error />,
    children: [
      {
        element: <BlankContentLayoutWithoutBackground />,
        children: [
          {
            path: routes.home.path,
            element: <Home />,
          },
          {
            path: routes.contactUs.path,
            element: <ContactUs />,
          },
          {
            path: routes.aboutUs.path,
            element: <AboutUs />,
          },
          {
            path: routes.faqs.path,
            element: <Faq />,
          },
          {
            path: routes.photoGallery.path,
            element: <PhotoGallery />,
          },
          {
            path: routes.missionVision.path,
            element: <MissionVision />,
          },
          {
            path: routes.ethicsSustainability.path,
            element: <EthicsSustainability />,
          },
        ],
      },
      {
        element: <BlankContentLayout />,
        children: [
          {
            path: routes.privacy.path,
            element: <PrivacyPolicy />,
          },
          {
            path: routes.termsOfUse.path,
            element: <TermsConditions />,
          },
          {
            path: routes.acceptCompanyInvite.path,
            element: <AcceptCompanyInvite />,
          },
        ],
      },
      {
        element: <SideMenuContentLayout />,
        children: [
          {
            path: routes.dashboard.path,
            element: <Dashboard />,
          },
          {
            path: routes.notification.path,
            element: <NotificationList />,
          },
          {
            path: routes.companyList.path,
            element: <CompanyList />,
          },
          {
            path: routes.companyAdd.path,
            element: <CompanyAddEdit />,
          },
          {
            path: routes.companyEdit.path,
            element: <CompanyAddEdit />,
          },
          {
            path: routes.companyView.path,
            element: <CompanyView />,
          },
          {
            path: routes.lookups.path,
            element: <Lookups />,
          },
          {
            path: routes.fieldList.path,
            element: <FieldList />,
          },
          {
            path: routes.fieldAdd.path,
            element: <FieldAddEdit />,
          },
          {
            path: routes.fieldEdit.path,
            element: <FieldAddEdit />,
          },
          {
            path: routes.fieldView.path,
            element: <FieldView />,
          },
          {
            path: routes.groupingList.path,
            element: <GroupingList />,
          },
          {
            path: routes.stockList.path,
            element: <StockList />,
          },
          {
            path: routes.stockAdd.path,
            element: <StockAddEdit />,
          },
          {
            path: routes.stockEdit.path,
            element: <StockAddEdit />,
          },
          {
            path: routes.stockView.path,
            element: <StockView />,
          },
          {
            path: routes.groupingAdd.path,
            element: <GroupingAddEdit />,
          },
          {
            path: routes.groupingEdit.path,
            element: <GroupingAddEdit />,
          },
          {
            path: routes.groupingView.path,
            element: <GroupingView />,
          },
          {
            path: routes.wellList.path,
            element: <WellList />,
          },
          {
            path: routes.wellAdd.path,
            element: <WellAddEdit />,
          },
          {
            path: routes.wellEdit.path,
            element: <WellAddEdit />,
          },
          {
            path: routes.wellView.path,
            element: <WellView />,
          },
          {
            path: routes.waterRightList.path,
            element: <WaterRightList />,
          },
          {
            path: routes.waterRightAdd.path,
            element: <WaterRightAddEdit />,
          },
          {
            path: routes.waterRightEdit.path,
            element: <WaterRightAddEdit />,
          },
          {
            path: routes.waterRightView.path,
            element: <WaterRightView />,
          },
          {
            path: routes.userList.path,
            element: <UserList />,
          },
          {
            path: routes.userAdd.path,
            element: <UserAddEdit />,
          },
          {
            path: routes.userEdit.path,
            element: <UserAddEdit />,
          },
          {
            path: routes.reportList.path,
            element: <ReportList />,
          },
          {
            path: routes.restrictionList.path,
            element: <RestrictionList />,
          },
          {
            path: routes.restrictionAdd.path,
            element: <RestrictionAddEdit />,
          },
          {
            path: routes.restrictionEdit.path,
            element: <RestrictionAddEdit />,
          },
          {
            path: routes.restrictionView.path,
            element: <RestrictionView />,
          },
          {
            path: routes.placeOfUseList.path,
            element: <PlaceOfUselist />,
          },
          {
            path: routes.placeOfUseAdd.path,
            element: <PlaceOfUseAddEdit />,
          },
          {
            path: routes.placeOfUseEdit.path,
            element: <PlaceOfUseAddEdit />,
          },
          {
            path: routes.adminDashboard.path,
            element: <AdminDashboard />,
          },
          {
            path: routes.adminReports.path,
            element: <AdminReports />,
          },
          {
            path: routes.adminUsers.path,
            element: <AdminUsers />,
          },
          {
            path: routes.adminCommunication.path,
            element: <AdminCommunications />,
          },
          {
            path: routes.adminCommunicationAdd.path,
            element: <AdminCommunicationAddEdit />,
          },
          {
            path: routes.adminCommunicationView.path,
            element: <AdminCommunicationView />,
          },
          {
            path: routes.adminCommunicationEdit.path,
            element: <AdminCommunicationAddEdit />,
          },
          {
            path: routes.adminFaqsList.path,
            element: <AdminFaqList />,
          },
          {
            path: routes.adminFaqsAdd.path,
            element: <AdminFaqAddEdit />,
          },
          {
            path: routes.adminFaqsEdit.path,
            element: <AdminFaqAddEdit />,
          },
          {
            path: routes.adminSupportQueryList.path,
            element: <AdminSupportQueryList />,
          },
          {
            path: routes.adminSupportQueryView.path,
            element: <AdminSupportQueryView />,
          },
          {
            path: routes.adminContactUsList.path,
            element: <AdminContactUsList />,
          },
          {
            path: routes.adminContactUsView.path,
            element: <AdminContactUsView />,
          },
          {
            path: routes.adminBilling.path,
            element: <AdminBilling />,
          },
        ],
      },
    ],
  },
  {
    element: <MobileLayout />,
    errorElement: <Error />,
    children: [
      {
        element: <BlankContentLayout />,
        children: [
          {
            path: routes.mobile.privacy.path,
            element: <PrivacyPolicy />,
          },
          {
            path: routes.mobile.termsOfUse.path,
            element: <TermsConditions />,
          },
          {
            path: routes.mobile.register.path,
            element: <RegistrationModal mobile />,
          },
        ],
      },
    ],
  },
]);

export default router;
