import { Alert, Card, Descriptions, Image, Space, Table } from "antd";
import Title from "antd/es/typography/Title";
import { getFields } from "@/apis/field.api";
import { getStockUsageData } from "@/apis/stock.api";
import { getWaterRightSnapsotList, getWaterRightUsagePerMonth, syncWaterRightCalculation } from "@/apis/waterright.api";
import { getWells } from "@/apis/well.api";
import { LookupLabel } from "@/components";
import PrintPage from "@/components/PrintPage/PrintPage";
import RemainingDays from "@/components/RemainingDays/RemainingDays";
import UnitsConverter from "@/components/UnitsConverter/UnitsConverter";
import dayjs from "dayjs";
import { FC, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { convertNumToMonth } from "@/services/utils";
import { getDaysInMonthForYear } from "@/utils/getDaysInMonthForYear";

import "./GeneratedFeedLotReport.scss";

interface Props {
  reportConfig: any;
}

const GeneratedFeedLotReport: FC<Props> = (props) => {
  const { reportConfig } = props;

  const { selectedCompanyId, selectedCompany } = useSelector((state: any) => state.company);

  const [generatingReport, setGeneratingReport] = useState<boolean>(false);
  const [tempStockUsage, setStockUsageData] = useState<any[]>([]);
  const [waterRightCalculations, setWaterRightCalculations] = useState<any[]>([]);
  const [fields, setFields] = useState<any[]>([]);
  const [wells, setWells] = useState<any[]>([]);
  const [snapshots, setSnapshots] = useState<any[]>([]);
  const [waterRightUsage, setWaterRightUsage] = useState<any[]>([]);

  const [columns, setColumns] = useState<any[]>([]);

  const [loadingStockUsageData, setLoadingStockUsageData] = useState<boolean>(false);
  const [loadingWaterRightUsage, setLoadingWaterRightUsage] = useState<boolean>(false);
  const [loadingWaterRightCalculations, setLoadingWaterRightCalculations] = useState<boolean>(false);
  const [loadingWells, setLoadingWells] = useState<boolean>(false);
  const [loadingFields, setLoadingFields] = useState<boolean>(false);
  const [loadingSnapshots, setLoadingSnapshots] = useState<boolean>(false);

  const currentYear = dayjs().year();

  const stockUsageData: any[] = useMemo(() => {
    const data: any[] = tempStockUsage.map((stockUsage: any) => {
      const wellsForWaterRight = wells?.filter((well) => well?.waterRightId === stockUsage?.waterRightId);
      const fieldsForWell = wellsForWaterRight?.map((well) => well.fieldId);
      const fieldsForStock = fields?.filter((field) => fieldsForWell?.includes(field.id));

      let decimals = null;
      wellsForWaterRight.forEach((well) => {
        if (well?.decimals) decimals = well?.decimals;
      });

      if (waterRightUsage?.length > 0) {
        let originalStockUsageMontlyData = stockUsage?.monthlyUsage;
        const waterRightUsageForStock = waterRightUsage.filter((wrUsage) => wrUsage?.returnWaterRightId === stockUsage?.waterRightId && wrUsage?.returnYear === reportConfig?.year);

        const stockMonthUsage = stockUsage?.monthlyUsage?.find((monthlyUsage: any) => monthlyUsage?.year === reportConfig?.year)?.monthlyUsage;
        const stockMonthUsageIndex: number = stockUsage?.monthlyUsage?.findIndex((monthlyUsage: any) => monthlyUsage?.year === reportConfig?.year);

        let monthsUsage = [];

        const year = reportConfig.year;

        const daysInMonth = getDaysInMonthForYear(year, selectedCompany?.settings?.timezone);

        if (stockMonthUsageIndex !== -1) {
          for (let index = 1; index <= 12; index++) {
            const waterRightUsageForMonth = waterRightUsageForStock?.find((wrUsage: any) => wrUsage?.returnMonth === index);
            const stockUsageForMonth = stockMonthUsage?.find((stockUsage: any) => stockUsage?.month === index);

            if (waterRightUsageForMonth || stockUsageForMonth)
              monthsUsage.push({
                year: reportConfig?.year,
                month: index,
                headCount: stockUsageForMonth?.headCount ?? null,
                monthUsage: waterRightUsageForMonth?.returnUsage,
                daysInMonth: daysInMonth[index - 1],
              });
          }

          originalStockUsageMontlyData[stockMonthUsageIndex].monthlyUsage = monthsUsage;

          return {
            ...stockUsage,
            monthlyUsage: originalStockUsageMontlyData,
            wells: wellsForWaterRight,
            fields: fieldsForStock?.map((field: any) => field?.name),
          };
        } else
          return {
            ...stockUsage,
            wells: wellsForWaterRight,
            fields: fieldsForStock?.map((field: any) => field?.name),
          };
      } else
        return {
          ...stockUsage,
          wells: wellsForWaterRight,
          fields: fieldsForStock?.map((field: any) => field?.name),
        };
    });

    return data;
  }, [tempStockUsage, waterRightUsage, wells, fields]);

  useEffect(() => {
    if (reportConfig.stocks?.stocks?.length > 0) setTimeout(() => setGeneratingReport(false), 500);
    // eslint-disable-next-line
  }, [reportConfig]);

  useEffect(() => {
    if (selectedCompanyId) {
      refreshStockUsageData();
      refreshWaterRightCalculations();
      refreshWells();
      refreshFields();
    }
    // eslint-disable-next-line
  }, [selectedCompanyId, reportConfig]);

  useEffect(() => {
    if (reportConfig?.year < currentYear) refreshSnapshots();
    if (tempStockUsage?.length > 0 && reportConfig.interpolate) refreshWaterRightUsage();
  }, [tempStockUsage, reportConfig]);

  useEffect(() => {
    calculateColumns();
    // eslint-disable-next-line
  }, [stockUsageData, waterRightCalculations, waterRightUsage]);

  const refreshWaterRightUsage = async () => {
    setLoadingWaterRightUsage(true);

    const waterRightIds = stockUsageData.map((stock) => stock.waterRightId);

    const request = { waterRightIds, year: reportConfig?.year };
    const response = await getWaterRightUsagePerMonth(request);

    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setWaterRightUsage(data.value);
      }
    }

    setLoadingWaterRightUsage(false);
  };

  const refreshSnapshots = async () => {
    setLoadingSnapshots(true);

    const waterRightIds = stockUsageData.map((stock) => stock.waterRightId);

    const request = {
      companyId: selectedCompanyId,
      year: reportConfig?.year,
      waterRightIds,
    };
    const response = await getWaterRightSnapsotList(request);

    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setSnapshots(data.value);
      }
    }

    setLoadingSnapshots(false);
  };

  const refreshWaterRightCalculations = async () => {
    setLoadingWaterRightCalculations(true);
    const response = await syncWaterRightCalculation({});
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setWaterRightCalculations(data.value);
      }
    }
    setLoadingWaterRightCalculations(false);
  };

  const refreshWells = async () => {
    setLoadingWells(true);
    if (selectedCompanyId) {
      const request = { companyId: selectedCompanyId };
      const response = await getWells(request);

      if (response.ok) {
        const data = await response.json();
        if (data.isSuccess) {
          setWells(data.value);
        }
      }
    }
    setLoadingWells(false);
  };

  const refreshFields = async () => {
    setLoadingFields(true);
    if (selectedCompanyId) {
      const request = { companyId: selectedCompanyId };
      const response = await getFields(request);

      if (response.ok) {
        const data = await response.json();
        if (data.isSuccess) {
          setFields(data.value);
        }
      }
    }
    setLoadingFields(false);
  };

  const refreshStockUsageData = async () => {
    setLoadingStockUsageData(true);

    const request = {
      companyId: selectedCompanyId,
      stockIds: reportConfig.stocks?.stocks?.map((stock: any) => stock.stockId),
    };

    const response = await getStockUsageData(request);
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        setStockUsageData(data.value);
      }
    }
    setLoadingStockUsageData(false);
  };

  const calculateWaterRightAvailablePercentage = (record: any) => {
    return ((determineValue(record?.waterRightId, "remainingUsage") / determineValue(record?.waterRightId, "availableQuantity")) * 100).toFixed(2);
  };

  const determineValue = (
    id: string,
    type: string,
    postfix: any = undefined,
    irrigationAcresNotApplicable: boolean | undefined = undefined,
    quantityNotApplicable: boolean | undefined = undefined,
    rateNotApplicable: boolean | undefined = undefined
  ) => {
    const existingCalculation = waterRightCalculations?.find((calculation) => calculation.waterRightId === id && reportConfig?.year === calculation.year);
    if (existingCalculation) {
      if (type === "remainingUsage" && existingCalculation.quantityNotApplicable === true) return "N/A";
      else if (type === "remainingUsage" && existingCalculation.quantityNotApplicable === true) return "N/A";
      else if (type === "remainingDays" && (existingCalculation.quantityNotApplicable === true || existingCalculation.rateNotApplicable === true)) return "N/A";
      return existingCalculation[type];
    } else {
      if (type === "remainingUsage" && quantityNotApplicable === true) return "N/A";
      else if (type === "remainingDays" && (quantityNotApplicable === true || rateNotApplicable === true)) return "N/A";
      else return undefined;
    }
  };

  const calculateColumns = () => {
    let tempColumns: any[] = [
      {
        title: "#",
        width: 50,
        render: (val: any, record: any, index: any) => (index <= 1 ? "" : index - 1),
      },
      {
        title: (
          <>
            Water Right /<br /> File Number
          </>
        ),
        key: "fileNumber",
        dataIndex: "fileNumber",
        render: (val: any, record: any) => val,
      },
      {
        title: <>Fields</>,
        key: "fields",
        dataIndex: "fields",
        render: (val: any, record: any) => (val?.length > 0 ? val?.join(", ") : "-"),
      },
      {
        title: <>Wells</>,
        key: "wells",
        dataIndex: "wells",
        render: (val: any, record: any) => (record?.wells ? record?.wells?.map((well: any) => well?.name)?.join(", ") : "-"),
      },
      {
        title: (
          <>
            Remaining Usage
            <br />
            <small>(Acre Feet)</small>
          </>
        ),
        key: "remainingUsage",
        dataIndex: "remainingUsage",
        align: "center",
        render: (val: any, record: any) => (
          <>
            <UnitsConverter
              fromUnits="gallons"
              toUnits="acrefeet"
              value={determineValue(
                record.waterRightId,
                "remainingUsage",
                null,
                record?.authorizedIrrigationNotApplicable === true ? true : undefined,
                record?.authorizedQuantityNotApplicable === true ? true : undefined,
                record?.authorizedRateNotApplicable === true ? true : undefined
              )}
            />
          </>
        ),
      },
      {
        title: (
          <>
            Remaining
            <br />
            <small>Days</small>
            <br />
            <small>%</small>
          </>
        ),
        key: "remainingDays",
        dataIndex: "remainingDays",
        align: "center",
        render: (val: any, record: any) => {
          const percentage = calculateWaterRightAvailablePercentage(record);

          if (record.fileNumber === "Head Count" || record.fileNumber === "Days In Month") return <>-</>;
          else if (determineValue(record.waterRightId, "remainingDays") === undefined)
            return (
              <>
                - <br /> 0.00%
              </>
            );
          else if (determineValue(record.waterRightId, "remainingDays") === "N/A") return "N/A";
          else
            return (
              <>
                <RemainingDays remainingDays={determineValue(record.waterRightId, "remainingDays")} /> <br /> {+percentage > 0 ? `${percentage}%` : percentage === "N/A" ? "N/A" : "0.00%"}
              </>
            );
        },
      },
      {
        title: (
          <>
            Usage
            <br />
            <small>January</small>
            <br />
            <small>
              (
              <LookupLabel lookupType="meterUnits" value={selectedCompany?.settings?.metric} />)
            </small>
            <br />
            {selectedCompany?.settings?.metric !== "gallons" && (
              <small>
                (<LookupLabel lookupType="meterUnits" value={"gallons"} />)
              </small>
            )}
          </>
        ),
        key: "januaryUsage",
        dataIndex: "januaryUsage",
        align: "right",
        render: (val: any, record: any, index: number) =>
          index <= 1 ? (
            val
          ) : (
            <>
              <UnitsConverter value={val} fromUnits="gallons" />
              <br />
              {selectedCompany?.settings?.metric !== "gallons" && <UnitsConverter value={val} fromUnits="gallons" toUnits="gallons" />}
            </>
          ),
      },
      {
        title: (
          <>
            Usage
            <br />
            <small>February</small>
            <br />
            <small>
              (
              <LookupLabel lookupType="meterUnits" value={selectedCompany?.settings?.metric} />)
            </small>
            <br />
            {selectedCompany?.settings?.metric !== "gallons" && (
              <small>
                (<LookupLabel lookupType="meterUnits" value={"gallons"} />)
              </small>
            )}
          </>
        ),
        key: "februaryUsage",
        dataIndex: "februaryUsage",
        align: "right",
        render: (val: any, record: any, index: number) =>
          index <= 1 ? (
            val
          ) : (
            <>
              <UnitsConverter value={val} fromUnits="gallons" />
              <br />
              {selectedCompany?.settings?.metric !== "gallons" && <UnitsConverter value={val} fromUnits="gallons" toUnits="gallons" />}
            </>
          ),
      },
      {
        title: (
          <>
            Usage
            <br />
            <small>March</small>
            <br />
            <small>
              (
              <LookupLabel lookupType="meterUnits" value={selectedCompany?.settings?.metric} />)
            </small>
            <br />
            {selectedCompany?.settings?.metric !== "gallons" && (
              <small>
                (<LookupLabel lookupType="meterUnits" value={"gallons"} />)
              </small>
            )}
          </>
        ),
        key: "marchUsage",
        dataIndex: "marchUsage",
        align: "right",
        render: (val: any, record: any, index: number) =>
          index <= 1 ? (
            val
          ) : (
            <>
              <UnitsConverter value={val} fromUnits="gallons" />
              <br />
              {selectedCompany?.settings?.metric !== "gallons" && <UnitsConverter value={val} fromUnits="gallons" toUnits="gallons" />}
            </>
          ),
      },
      {
        title: (
          <>
            Usage
            <br />
            <small>April</small>
            <br />
            <small>
              (
              <LookupLabel lookupType="meterUnits" value={selectedCompany?.settings?.metric} />)
            </small>
            <br />
            {selectedCompany?.settings?.metric !== "gallons" && (
              <small>
                (<LookupLabel lookupType="meterUnits" value={"gallons"} />)
              </small>
            )}
          </>
        ),
        key: "aprilUsage",
        dataIndex: "aprilUsage",
        align: "right",
        render: (val: any, record: any, index: number) =>
          index <= 1 ? (
            val
          ) : (
            <>
              <UnitsConverter value={val} fromUnits="gallons" />
              <br />
              {selectedCompany?.settings?.metric !== "gallons" && <UnitsConverter value={val} fromUnits="gallons" toUnits="gallons" />}
            </>
          ),
      },
      {
        title: (
          <>
            Usage
            <br />
            <small>May</small>
            <br />
            <small>
              (
              <LookupLabel lookupType="meterUnits" value={selectedCompany?.settings?.metric} />)
            </small>
            <br />
            {selectedCompany?.settings?.metric !== "gallons" && (
              <small>
                (<LookupLabel lookupType="meterUnits" value={"gallons"} />)
              </small>
            )}
          </>
        ),
        key: "mayUsage",
        dataIndex: "mayUsage",
        align: "right",
        render: (val: any, record: any, index: number) =>
          index <= 1 ? (
            val
          ) : (
            <>
              <UnitsConverter value={val} fromUnits="gallons" />
              <br />
              {selectedCompany?.settings?.metric !== "gallons" && <UnitsConverter value={val} fromUnits="gallons" toUnits="gallons" />}
            </>
          ),
      },
      {
        title: (
          <>
            Usage
            <br />
            <small>June</small>
            <br />
            <small>
              (
              <LookupLabel lookupType="meterUnits" value={selectedCompany?.settings?.metric} />)
            </small>
            <br />
            {selectedCompany?.settings?.metric !== "gallons" && (
              <small>
                (<LookupLabel lookupType="meterUnits" value={"gallons"} />)
              </small>
            )}
          </>
        ),
        key: "juneUsage",
        dataIndex: "juneUsage",
        align: "right",
        render: (val: any, record: any, index: number) =>
          index <= 1 ? (
            val
          ) : (
            <>
              <UnitsConverter value={val} fromUnits="gallons" />
              <br />
              {selectedCompany?.settings?.metric !== "gallons" && <UnitsConverter value={val} fromUnits="gallons" toUnits="gallons" />}
            </>
          ),
      },
      {
        title: (
          <>
            Usage
            <br />
            <small>July</small>
            <br />
            <small>
              (
              <LookupLabel lookupType="meterUnits" value={selectedCompany?.settings?.metric} />)
            </small>
            <br />
            {selectedCompany?.settings?.metric !== "gallons" && (
              <small>
                (<LookupLabel lookupType="meterUnits" value={"gallons"} />)
              </small>
            )}
          </>
        ),
        key: "julyUsage",
        dataIndex: "julyUsage",
        align: "right",
        render: (val: any, record: any, index: number) =>
          index <= 1 ? (
            val
          ) : (
            <>
              <UnitsConverter value={val} fromUnits="gallons" />
              <br />
              {selectedCompany?.settings?.metric !== "gallons" && <UnitsConverter value={val} fromUnits="gallons" toUnits="gallons" />}
            </>
          ),
      },
      {
        title: (
          <>
            Usage
            <br />
            <small>August</small>
            <br />
            <small>
              (
              <LookupLabel lookupType="meterUnits" value={selectedCompany?.settings?.metric} />)
            </small>
            <br />
            {selectedCompany?.settings?.metric !== "gallons" && (
              <small>
                (<LookupLabel lookupType="meterUnits" value={"gallons"} />)
              </small>
            )}
          </>
        ),
        key: "augustUsage",
        dataIndex: "augustUsage",
        align: "right",
        render: (val: any, record: any, index: number) =>
          index <= 1 ? (
            val
          ) : (
            <>
              <UnitsConverter value={val} fromUnits="gallons" />
              <br />
              {selectedCompany?.settings?.metric !== "gallons" && <UnitsConverter value={val} fromUnits="gallons" toUnits="gallons" />}
            </>
          ),
      },
      {
        title: (
          <>
            Usage
            <br />
            <small>September</small>
            <br />
            <small>
              (
              <LookupLabel lookupType="meterUnits" value={selectedCompany?.settings?.metric} />)
            </small>
            <br />
            {selectedCompany?.settings?.metric !== "gallons" && (
              <small>
                (<LookupLabel lookupType="meterUnits" value={"gallons"} />)
              </small>
            )}
          </>
        ),
        key: "septemberUsage",
        dataIndex: "septemberUsage",
        align: "right",
        render: (val: any, record: any, index: number) =>
          index <= 1 ? (
            val
          ) : (
            <>
              <UnitsConverter value={val} fromUnits="gallons" />
              <br />
              {selectedCompany?.settings?.metric !== "gallons" && <UnitsConverter value={val} fromUnits="gallons" toUnits="gallons" />}
            </>
          ),
      },
      {
        title: (
          <>
            Usage
            <br />
            <small>October</small>
            <br />
            <small>
              (
              <LookupLabel lookupType="meterUnits" value={selectedCompany?.settings?.metric} />)
            </small>
            <br />
            {selectedCompany?.settings?.metric !== "gallons" && (
              <small>
                (<LookupLabel lookupType="meterUnits" value={"gallons"} />)
              </small>
            )}
          </>
        ),
        key: "octoberUsage",
        dataIndex: "octoberUsage",
        align: "right",
        render: (val: any, record: any, index: number) =>
          index <= 1 ? (
            val
          ) : (
            <>
              <UnitsConverter value={val} fromUnits="gallons" />
              <br />
              {selectedCompany?.settings?.metric !== "gallons" && <UnitsConverter value={val} fromUnits="gallons" toUnits="gallons" />}
            </>
          ),
      },
      {
        title: (
          <>
            Usage
            <br />
            <small>November</small>
            <br />
            <small>
              (
              <LookupLabel lookupType="meterUnits" value={selectedCompany?.settings?.metric} />)
            </small>
            <br />
            {selectedCompany?.settings?.metric !== "gallons" && (
              <small>
                (<LookupLabel lookupType="meterUnits" value={"gallons"} />)
              </small>
            )}
          </>
        ),
        key: "novemberUsage",
        dataIndex: "novemberUsage",
        align: "right",
        render: (val: any, record: any, index: number) =>
          index <= 1 ? (
            val
          ) : (
            <>
              <UnitsConverter value={val} fromUnits="gallons" />
              <br />
              {selectedCompany?.settings?.metric !== "gallons" && <UnitsConverter value={val} fromUnits="gallons" toUnits="gallons" />}
            </>
          ),
      },
      {
        title: (
          <>
            Usage
            <br />
            <small>December</small>
            <br />
            <small>
              (
              <LookupLabel lookupType="meterUnits" value={selectedCompany?.settings?.metric} />)
            </small>
            <br />
            {selectedCompany?.settings?.metric !== "gallons" && (
              <small>
                (<LookupLabel lookupType="meterUnits" value={"gallons"} />)
              </small>
            )}
          </>
        ),
        key: "decemberUsage",
        dataIndex: "decemberUsage",
        align: "right",
        render: (val: any, record: any, index: number) =>
          index <= 1 ? (
            val
          ) : (
            <>
              <UnitsConverter value={val} fromUnits="gallons" />
              <br />
              {selectedCompany?.settings?.metric !== "gallons" && <UnitsConverter value={val} fromUnits="gallons" toUnits="gallons" />}
            </>
          ),
      },
      {
        title: (
          <b>
            Total
            <br />
            <small>
              (
              <LookupLabel lookupType="meterUnits" value={selectedCompany?.settings?.metric} />)
            </small>
            <br />
            {selectedCompany?.settings?.metric !== "gallons" && (
              <small>
                (<LookupLabel lookupType="meterUnits" value={"gallons"} />)
              </small>
            )}
          </b>
        ),
        key: "total",
        dataIndex: "total",
        align: "right",
        render: (val: any, record: any, index: number) =>
          index <= 1 ? (
            val
          ) : (
            <b>
              <UnitsConverter value={val} fromUnits="gallons" />
              <br />
              {selectedCompany?.settings?.metric !== "gallons" && <UnitsConverter value={val} fromUnits="gallons" toUnits="gallons" />}
            </b>
          ),
      },
    ];

    setColumns(tempColumns);
  };

  return (
    <PrintPage
      content={
        <Card
          id="generatedFeedLotReport"
          className="remove-border-and-padding-on-print"
          title={
            <div>
              <Space size="middle">
                <Image
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                    textAlign: "center",
                    marginLeft: "auto",
                    marginRight: "auto",
                    alignContent: "center",
                    maxHeight: 200,
                    maxWidth: 200,
                    borderRadius: 10,
                    objectFit: "contain",
                  }}
                  src="/logo.png"
                  preview={false}
                />
                <Space direction="vertical" size="small" style={{ display: "flex" }}>
                  <Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>{`Detailed Stock Report`}</Title>
                  <Title level={5} style={{ marginTop: 0, marginBottom: 0 }}>{`Generated On ${reportConfig.generatedDate}`}</Title>
                </Space>
              </Space>
            </div>
          }
          loading={generatingReport}
        >
          {reportConfig && reportConfig.stocks?.stocks.length > 0 && wells?.length > 0 && fields?.length > 0 ? (
            reportConfig.stocks.stocks.map((stockData: any) => {
              const usageData = stockUsageData.filter((stock) => stock.stockId === stockData.stockId);

              const headCountDataLabels: any = {
                fileNumber: "Head Count",
                januaryUsage: "-",
                februaryUsage: "-",
                marchUsage: "-",
                aprilUsage: "-",
                mayUsage: "-",
                juneUsage: "-",
                julyUsage: "-",
                augustUsage: "-",
                septemberUsage: "-",
                octoberUsage: "-",
                novemberUsage: "-",
                decemberUsage: "-",
                total: 0,
              };
              const headCountDataValues: any = {
                januaryUsage: 0,
                februaryUsage: 0,
                marchUsage: 0,
                aprilUsage: 0,
                mayUsage: 0,
                juneUsage: 0,
                julyUsage: 0,
                augustUsage: 0,
                septemberUsage: 0,
                octoberUsage: 0,
                novemberUsage: 0,
                decemberUsage: 0,
                total: 0,
              };

              const daysInMonthDataLabels: any = {
                fileNumber: "Days In Month",
                januaryUsage: "-",
                februaryUsage: "-",
                marchUsage: "-",
                aprilUsage: "-",
                mayUsage: "-",
                juneUsage: "-",
                julyUsage: "-",
                augustUsage: "-",
                septemberUsage: "-",
                octoberUsage: "-",
                novemberUsage: "-",
                decemberUsage: "-",
                total: 0,
              };
              const daysInMonthDataValues: any = {
                fileNumber: "Days In Month",
                januaryUsage: 0,
                februaryUsage: 0,
                marchUsage: 0,
                aprilUsage: 0,
                mayUsage: 0,
                juneUsage: 0,
                julyUsage: 0,
                augustUsage: 0,
                septemberUsage: 0,
                octoberUsage: 0,
                novemberUsage: 0,
                decemberUsage: 0,
              };
              const totals: any = {
                januaryUsage: 0,
                februaryUsage: 0,
                marchUsage: 0,
                aprilUsage: 0,
                mayUsage: 0,
                juneUsage: 0,
                julyUsage: 0,
                augustUsage: 0,
                septemberUsage: 0,
                octoberUsage: 0,
                novemberUsage: 0,
                decemberUsage: 0,
                total: 0,
                daysInMonth: 0,
                headCount: 0,
              };

              const daysInMonths = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
              const headCounts = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
              const configuredMonths = [false, false, false, false, false, false, false, false, false, false, false, false];

              const data = usageData?.map(({ monthlyUsage, ...rest }: any) => {
                const previousYearUsage = monthlyUsage?.find((monthlyUsage: any) => monthlyUsage.year === reportConfig.year - 1);
                const currentYearUsage = monthlyUsage?.find((monthlyUsage: any) => monthlyUsage.year === reportConfig.year);

                const tempData: any = {
                  ...rest,
                  total: 0,
                  headCounts: 0,
                  daysInMonths: 0,
                };

                currentYearUsage?.monthlyUsage?.forEach((usage: any) => {
                  tempData[`${convertNumToMonth(usage.month).toLowerCase()}Usage`] = usage.monthUsage;
                  totals[`${convertNumToMonth(usage.month).toLowerCase()}Usage`] += usage.monthUsage;
                  totals.total += usage.monthUsage;
                  headCountDataValues[`${convertNumToMonth(usage.month).toLowerCase()}Usage`] = usage.headCount
                    ? Math.floor(usage.headCount)
                    : currentYearUsage.averageHeadCount
                      ? Math.floor(currentYearUsage.averageHeadCount)
                      : 0;
                  headCountDataLabels[`${convertNumToMonth(usage.month).toLowerCase()}Usage`] = usage.headCount
                    ? Math.floor(usage.headCount)
                    : currentYearUsage.averageHeadCount
                      ? "* " + Math.floor(currentYearUsage.averageHeadCount)
                      : "-";
                  daysInMonthDataValues[`${convertNumToMonth(usage.month).toLowerCase()}Usage`] = usage.daysInMonth ?? 0;
                  daysInMonthDataLabels[`${convertNumToMonth(usage.month).toLowerCase()}Usage`] = usage.daysInMonth ?? "-";
                  tempData.total += usage.monthUsage;
                  tempData.daysInMonths += usage.daysInMonth ?? 0;
                  tempData.headCounts += usage.headCount ?? currentYearUsage.averageHeadCount ?? 0;
                  daysInMonths[usage.month] = usage.daysInMonth ?? 0;
                  headCounts[usage.month] = usage.headCount ?? currentYearUsage.averageHeadCount ?? 0;
                  configuredMonths[usage.month] = true;
                });

                return tempData;
              });

              totals.headCount = headCounts.reduce((a, b) => a + b, 0);
              totals.daysInMonth = daysInMonths.reduce((a, b) => a + b, 0);
              let configuredMonthsCount = configuredMonths.filter((configured) => configured).length;
              if (configuredMonthsCount === 0) configuredMonthsCount = 1;
              headCountDataLabels.total = `** ${Math.round(headCounts.reduce((a, b) => a + b, 0) / (configuredMonthsCount ?? 1))}`;
              daysInMonthDataLabels.total = daysInMonths.reduce((a, b) => a + b, 0);

              return (
                <>
                  <Card title={stockData.name}>
                    {data ? (
                      <>
                        <Table
                          scroll={{ x: "max-content" }}
                          loading={loadingStockUsageData}
                          columns={columns}
                          dataSource={[headCountDataLabels, daysInMonthDataLabels, ...data] ?? []}
                          size="small"
                          pagination={false}
                          footer={() => (
                            <>
                              {reportConfig.interpolate && (
                                <>
                                  <b>Note: </b>Continuous usage is assumed. Therefore, water usage will be spread equally across the time between reading dates to give a more accurate breakdown of
                                  water usage.
                                  <br />
                                </>
                              )}
                              {!reportConfig.interpolate && (
                                <>
                                  <b>Note: </b>Allocated usage is selected. Therefore, water usage is allocated in the month it was recorded or allocated in.
                                  <br />
                                </>
                              )}
                              * No value configured, thus the average head count between all configured months are used. <br />
                              ** Average head count between all configured months. <br />
                              *** Calculated as the total usage for the year divided by the average head count for the year divided by the total number of days in the year. <br />
                            </>
                          )}
                          summary={(pageData) => {
                            return (
                              <>
                                <Table.Summary.Row>
                                  <Table.Summary.Cell index={0} colSpan={5} align="right"></Table.Summary.Cell>
                                  <Table.Summary.Cell index={6} align="right">
                                    <b>
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          alignItems: "center",
                                          justifyContent: "flex-end",
                                          gap: 20,
                                        }}
                                      >
                                        <div>
                                          <b>Total</b>
                                        </div>
                                        <div>
                                          <small>
                                            (
                                            <LookupLabel lookupType="meterUnits" value={selectedCompany?.settings?.metric} />)
                                          </small>
                                          {selectedCompany?.settings?.metric !== "gallons" && (
                                            <>
                                              <br />
                                              <small>
                                                (
                                                <LookupLabel lookupType="meterUnits" value={"gallons"} />)
                                              </small>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </b>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell index={7} align="right">
                                    <b>
                                      <UnitsConverter value={totals.januaryUsage} fromUnits="gallons" />
                                      {selectedCompany?.settings?.metric !== "gallons" && (
                                        <>
                                          <br />
                                          <UnitsConverter value={totals.januaryUsage} fromUnits="gallons" toUnits="gallons" />
                                        </>
                                      )}
                                    </b>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell index={8} align="right">
                                    <b>
                                      <UnitsConverter value={totals.februaryUsage} fromUnits="gallons" />
                                      {selectedCompany?.settings?.metric !== "gallons" && (
                                        <>
                                          <br />
                                          <UnitsConverter value={totals.februaryUsage} fromUnits="gallons" toUnits="gallons" />
                                        </>
                                      )}
                                    </b>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell index={9} align="right">
                                    <b>
                                      <UnitsConverter value={totals.marchUsage} fromUnits="gallons" />
                                      {selectedCompany?.settings?.metric !== "gallons" && (
                                        <>
                                          <br />
                                          <UnitsConverter value={totals.marchUsage} fromUnits="gallons" toUnits="gallons" />
                                        </>
                                      )}
                                    </b>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell index={10} align="right">
                                    <b>
                                      <UnitsConverter value={totals.aprilUsage} fromUnits="gallons" />
                                      {selectedCompany?.settings?.metric !== "gallons" && (
                                        <>
                                          <br />
                                          <UnitsConverter value={totals.aprilUsage} fromUnits="gallons" toUnits="gallons" />
                                        </>
                                      )}
                                    </b>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell index={11} align="right">
                                    <b>
                                      <UnitsConverter value={totals.mayUsage} fromUnits="gallons" />
                                      {selectedCompany?.settings?.metric !== "gallons" && (
                                        <>
                                          <br />
                                          <UnitsConverter value={totals.mayUsage} fromUnits="gallons" toUnits="gallons" />
                                        </>
                                      )}
                                    </b>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell index={12} align="right">
                                    <b>
                                      <UnitsConverter value={totals.juneUsage} fromUnits="gallons" />
                                      {selectedCompany?.settings?.metric !== "gallons" && (
                                        <>
                                          <br />
                                          <UnitsConverter value={totals.juneUsage} fromUnits="gallons" toUnits="gallons" />
                                        </>
                                      )}
                                    </b>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell index={13} align="right">
                                    <b>
                                      <UnitsConverter value={totals.julyUsage} fromUnits="gallons" />
                                      {selectedCompany?.settings?.metric !== "gallons" && (
                                        <>
                                          <br />
                                          <UnitsConverter value={totals.julyUsage} fromUnits="gallons" toUnits="gallons" />
                                        </>
                                      )}
                                    </b>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell index={14} align="right">
                                    <b>
                                      <UnitsConverter value={totals.augustUsage} fromUnits="gallons" />
                                      {selectedCompany?.settings?.metric !== "gallons" && (
                                        <>
                                          <br />
                                          <UnitsConverter value={totals.augustUsage} fromUnits="gallons" toUnits="gallons" />
                                        </>
                                      )}
                                    </b>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell index={15} align="right">
                                    <b>
                                      <UnitsConverter value={totals.septemberUsage} fromUnits="gallons" />
                                      {selectedCompany?.settings?.metric !== "gallons" && (
                                        <>
                                          <br />
                                          <UnitsConverter value={totals.septemberUsage} fromUnits="gallons" toUnits="gallons" />
                                        </>
                                      )}
                                    </b>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell index={16} align="right">
                                    <b>
                                      <UnitsConverter value={totals.octoberUsage} fromUnits="gallons" />
                                      {selectedCompany?.settings?.metric !== "gallons" && (
                                        <>
                                          <br />
                                          <UnitsConverter value={totals.octoberUsage} fromUnits="gallons" toUnits="gallons" />
                                        </>
                                      )}
                                    </b>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell index={17} align="right">
                                    <b>
                                      <UnitsConverter value={totals.novemberUsage} fromUnits="gallons" />
                                      {selectedCompany?.settings?.metric !== "gallons" && (
                                        <>
                                          <br />
                                          <UnitsConverter value={totals.novemberUsage} fromUnits="gallons" toUnits="gallons" />
                                        </>
                                      )}
                                    </b>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell index={18} align="right">
                                    <b>
                                      <UnitsConverter value={totals.decemberUsage} fromUnits="gallons" />
                                      {selectedCompany?.settings?.metric !== "gallons" && (
                                        <>
                                          <br />
                                          <UnitsConverter value={totals.decemberUsage} fromUnits="gallons" toUnits="gallons" />
                                        </>
                                      )}
                                    </b>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell index={19} align="right">
                                    <b>
                                      <UnitsConverter value={totals.total} fromUnits="gallons" />
                                      {selectedCompany?.settings?.metric !== "gallons" && (
                                        <>
                                          <br />
                                          <UnitsConverter value={totals.total} fromUnits="gallons" toUnits="gallons" />
                                        </>
                                      )}
                                    </b>
                                  </Table.Summary.Cell>
                                </Table.Summary.Row>
                                <Table.Summary.Row>
                                  <Table.Summary.Cell index={0} colSpan={6} align="right">
                                    <b>Gallons / Head / Day</b>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell index={7} align="right">
                                    <b>
                                      <UnitsConverter
                                        value={totals.januaryUsage}
                                        fromUnits="gallons"
                                        toUnits="gallons"
                                        devideBy={headCountDataValues.januaryUsage * daysInMonthDataValues.januaryUsage}
                                        overrideDecimalPlaces={2}
                                      />
                                    </b>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell index={8} align="right">
                                    <b>
                                      <UnitsConverter
                                        value={totals.februaryUsage}
                                        fromUnits="gallons"
                                        toUnits="gallons"
                                        devideBy={headCountDataValues.februaryUsage * daysInMonthDataValues.februaryUsage}
                                        overrideDecimalPlaces={2}
                                      />
                                    </b>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell index={9} align="right">
                                    <b>
                                      <UnitsConverter
                                        value={totals.marchUsage}
                                        fromUnits="gallons"
                                        toUnits="gallons"
                                        devideBy={headCountDataValues.marchUsage * daysInMonthDataValues.marchUsage}
                                        overrideDecimalPlaces={2}
                                      />
                                    </b>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell index={10} align="right">
                                    <b>
                                      <UnitsConverter
                                        value={totals.aprilUsage}
                                        fromUnits="gallons"
                                        toUnits="gallons"
                                        devideBy={headCountDataValues.aprilUsage * daysInMonthDataValues.aprilUsage}
                                        overrideDecimalPlaces={2}
                                      />
                                    </b>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell index={11} align="right">
                                    <b>
                                      <UnitsConverter
                                        value={totals.mayUsage}
                                        fromUnits="gallons"
                                        toUnits="gallons"
                                        devideBy={headCountDataValues.mayUsage * daysInMonthDataValues.mayUsage}
                                        overrideDecimalPlaces={2}
                                      />
                                    </b>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell index={12} align="right">
                                    <b>
                                      <UnitsConverter
                                        value={totals.juneUsage}
                                        fromUnits="gallons"
                                        toUnits="gallons"
                                        devideBy={headCountDataValues.juneUsage * daysInMonthDataValues.juneUsage}
                                        overrideDecimalPlaces={2}
                                      />
                                    </b>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell index={13} align="right">
                                    <b>
                                      <UnitsConverter
                                        value={totals.julyUsage}
                                        fromUnits="gallons"
                                        toUnits="gallons"
                                        devideBy={headCountDataValues.julyUsage * daysInMonthDataValues.julyUsage}
                                        overrideDecimalPlaces={2}
                                      />
                                    </b>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell index={14} align="right">
                                    <b>
                                      <UnitsConverter
                                        value={totals.augustUsage}
                                        fromUnits="gallons"
                                        toUnits="gallons"
                                        devideBy={headCountDataValues.augustUsage * daysInMonthDataValues.augustUsage}
                                        overrideDecimalPlaces={2}
                                      />
                                    </b>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell index={15} align="right">
                                    <b>
                                      <UnitsConverter
                                        value={totals.septemberUsage}
                                        fromUnits="gallons"
                                        toUnits="gallons"
                                        devideBy={headCountDataValues.septemberUsage * daysInMonthDataValues.septemberUsage}
                                        overrideDecimalPlaces={2}
                                      />
                                    </b>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell index={16} align="right">
                                    <b>
                                      <UnitsConverter
                                        value={totals.octoberUsage}
                                        fromUnits="gallons"
                                        toUnits="gallons"
                                        devideBy={headCountDataValues.octoberUsage * daysInMonthDataValues.octoberUsage}
                                        overrideDecimalPlaces={2}
                                      />
                                    </b>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell index={17} align="right">
                                    <b>
                                      <UnitsConverter
                                        value={totals.novemberUsage}
                                        fromUnits="gallons"
                                        toUnits="gallons"
                                        devideBy={headCountDataValues.novemberUsage * daysInMonthDataValues.novemberUsage}
                                        overrideDecimalPlaces={2}
                                      />
                                    </b>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell index={18} align="right">
                                    <b>
                                      <UnitsConverter
                                        value={totals.decemberUsage}
                                        fromUnits="gallons"
                                        toUnits="gallons"
                                        devideBy={headCountDataValues.decemberUsage * daysInMonthDataValues.decemberUsage}
                                        overrideDecimalPlaces={2}
                                      />
                                    </b>
                                  </Table.Summary.Cell>
                                  <Table.Summary.Cell index={19} align="right">
                                    <b>
                                      ***{" "}
                                      <UnitsConverter
                                        value={totals.total}
                                        fromUnits="gallons"
                                        toUnits="gallons"
                                        devideBy={(totals.headCount === 0 ? 1 : Math.round(totals.headCount / configuredMonthsCount)) * (totals.daysInMonth === 0 ? 1 : totals.daysInMonth)}
                                        overrideDecimalPlaces={2}
                                      />
                                    </b>
                                  </Table.Summary.Cell>
                                </Table.Summary.Row>
                              </>
                            );
                          }}
                        />
                      </>
                    ) : (
                      <Alert message="No usage data found for this stock collection." type="warning" />
                    )}
                  </Card>
                  <div className="page-break" />
                </>
              );
            })
          ) : (
            <Alert message="No stock in config." type="warning" />
          )}

          {reportConfig && (
            <Card title="Report Configuration" bodyStyle={{ padding: 10 }}>
              <Descriptions bordered size="small" column={1} labelStyle={{ width: 150 }} className="removeBoxShadow removeMargin">
                <Descriptions.Item label="Year">{reportConfig.year}</Descriptions.Item>
                <Descriptions.Item label="Stock">{reportConfig?.stocks?.stocks?.length > 0 ? reportConfig.stocks.stocks.map((stock: any) => stock.name).join(", ") : "-"}</Descriptions.Item>
                <Descriptions.Item label="Assume continuous usage">{reportConfig.interpolate ? "Yes" : "No"}</Descriptions.Item>
                <Descriptions.Item label="Generated At">{reportConfig.generatedDate}</Descriptions.Item>
              </Descriptions>
            </Card>
          )}
        </Card>
      }
      isReport
    />
  );
};

export default GeneratedFeedLotReport;
