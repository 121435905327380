import CompanyLabel from "@/components/Lookups/CompanyLabel";
import PrintPage from "@/components/PrintPage/PrintPage";
import { routes } from "@/configs";
import { UserRole } from "@/dtos/user.dto";
import { StockView } from "@/pages";
import useStockPagedList from "@/queries/useStockPageQuery";
import useCustomNavigate from "@/services/useCustomNavigate";
import { useAppDispatch } from "@/stores";
import { addBreadcrumb } from "@/stores/breadcrumbs.store";
import { PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import { Button, Card, Input, Space, Table } from "antd";
import { FC, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import AddSelectCompanyModal, { AddSelectCompanyModalRef, EnitityName } from "../AddSelectCompanyModal/AddSelectCompanyModal";

const StockTable: FC = () => {
  const dispatch = useAppDispatch();

  const { selectedCompanyId, selectedCompany } = useSelector((state: any) => state.company);

  const { navigate, updateQueryParams, queryParams } = useCustomNavigate();

  const [columns, setColumns] = useState<any[]>([]);
  const [searchStringValue, setSearchStringValue] = useState<string | undefined>("");
  const [searchString, setSearchString] = useState<string | undefined>("");

  const {
    stocks,
    isLoading: isStocksLoading,
    invalidateStocks,
  } = useStockPagedList({
    pagination: {
      page: queryParams?.page ?? "1",
      pageSize: queryParams?.pageSize ?? "10",
    },
    searchString,
  });

  useEffect(() => {
    calculateColumns();
    // eslint-disable-next-line
  }, [stocks]);

  // useEffect(() => {
  //   if (selectedCompanyId) handleRefresh();
  //   else handlePagedRefresh();

  //   // eslint-disable-next-line
  // }, [selectedCompanyId]);

  const handleRefresh = () => {
    setSearchString("");
    setSearchStringValue("");
    updateQueryParams({ page: 1, pageSize: 10 });
    invalidateStocks();
  };

  const handleAddStock = () => {
    if (selectedCompany?._companyId === "all") {
      addModalRef.current?.open();
      return;
    } else {
      handleNavigateToAdd();
    }
  };

  const handleNavigateToAdd = () => {
    dispatch(
      addBreadcrumb({
        type: `Stock / Add`,
        url: `stock/add`,
      })
    );
    navigate(routes.stockAdd);
  };

  const handleEditStock = (record: any) => {
    dispatch(
      addBreadcrumb({
        type: `${record?.name}`,
        url: `stock/${record.id}/view`,
      })
    );
    navigate(routes.stockView, { id: record.id });
  };

  const calculateColumns = () => {
    let tempColumns = [
      {
        title: "Stock Name",
        key: "name",
        dataIndex: "name",
        fixed: "left",
        render: (val: any, record: any) => (
          <Button style={{ paddingLeft: 0 }} type="link" onClick={() => handleEditStock(record)}>
            {val}
          </Button>
        ),
      },
      {
        title: "Actions",
        key: "action",
        width: 250,
        align: "center",
        dataIndex: "",
        render: (value: any, record: any) => (
          <>
            <Button style={{ paddingLeft: 0 }} type="link" onClick={() => handleEditStock(record)}>
              View
            </Button>
          </>
        ),
      },
    ];

    if (!selectedCompanyId) {
      tempColumns.splice(1, 0, {
        title: "Company",
        key: "companyId",
        width: 250,
        align: "left",
        dataIndex: "companyId",
        render: (value: any, record: any) => <CompanyLabel companyId={value} />,
      });
    }

    setColumns(tempColumns);
  };

  const renderExpandData = (record: any) => {
    return <StockView overrideId={record.id} showHeader={false} />;
  };

  const addModalRef = useRef<AddSelectCompanyModalRef>(null);

  const renderContent = () => {
    return (
      <div>
        <Space direction="vertical" style={{ width: "100%" }}>
          <Space style={{ paddingBottom: 10 }}>
            <Input.Search
              disabled={isStocksLoading}
              placeholder="Search stock"
              onSearch={(val) => {
                setSearchString(val);
                updateQueryParams({ page: 1, pageSize: 10 });
              }}
              onChange={(val) => setSearchStringValue(val.currentTarget.value)}
              value={searchStringValue}
              style={{ width: 400 }}
              allowClear
            />
            <Button icon={<ReloadOutlined />} disabled={isStocksLoading} onClick={handleRefresh}>
              Refresh
            </Button>
          </Space>
          <Table
            rowKey={(row: any) => row.id}
            expandable={{
              expandedRowRender: (record: any) => renderExpandData(record),
            }}
            loading={isStocksLoading}
            columns={columns}
            dataSource={stocks?.list}
            size="small"
            onChange={(pagination) => {
              updateQueryParams({
                page: pagination.current,
                pageSize: pagination.pageSize,
              });
            }}
            pagination={{
              pageSize: queryParams?.pageSize ? Number(queryParams?.pageSize) : 10,
              current: queryParams?.page ? Number(queryParams?.page) : 1,
              total: stocks?.pagination?.totalItemCount,
            }}
          />
        </Space>
      </div>
    );
  };

  return (
    <>
      <Card
        className="stockList"
        id="stockList"
        title="Stock"
        extra={
          selectedCompany?.userRole === UserRole.admin.label || selectedCompany?._companyId === "all" ? (
            <>
              <Button icon={<PlusOutlined />} type="primary" onClick={handleAddStock}>
                Add Stock
              </Button>
            </>
          ) : (
            <></>
          )
        }
      >
        <PrintPage content={renderContent()} />
      </Card>
      <AddSelectCompanyModal entityName={EnitityName.Stock} ref={addModalRef} onSubmit={handleNavigateToAdd} />
    </>
  );
};

export default StockTable;
