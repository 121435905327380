import { InfoCircleOutlined, PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import { Badge, Button, Card, DatePicker, Descriptions, Divider, Image, Popconfirm, Popover, Space, Switch, Table, Tag, Typography, message } from "antd";
import { archiveWellReading, getWellReadings, validateWellReading } from "@/apis/wellreading.api";
import AuditLogTable, { AuditLogType } from "@/components/AuditLogTable/AuditLogTable";
import ReadingsAddEditModal from "@/components/ReadingsAddEditModal/ReadingsAddEditModal";
import { constants } from "@/configs";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

import { UserRole } from "@/dtos/user.dto";
import { useSelector } from "react-redux";
import { formatReadingValue } from "@/services/utils";
import "./WellReadingsTable.scss";
import JoinedItems from "../JoinedItems/JoinedItems";

interface WellReadingsTableProps {
  refreshWellCalculations?: () => void;
  refreshWellViewCalculations?: () => void;
  refreshWaterRightViewCalculations?: () => void;
  refreshWaterRightCalculations?: () => void;
  wellId: string;
  waterRightId: string;
  isReport?: boolean;
  canEdit: boolean;
}

export default function WellReadingsTable(props: WellReadingsTableProps) {
  const { refreshWellCalculations, refreshWellViewCalculations, refreshWaterRightViewCalculations, refreshWaterRightCalculations, wellId, waterRightId, isReport = false, canEdit } = props;

  const { selectedCompany } = useSelector((state: any) => state.company);

  const [loading, setLoading] = useState<boolean>(true);
  const [wellReadingModalState, setWellReadingModalState] = useState<any>({
    open: false,
  });
  const [readingColumns, setReadingColumns] = useState<any[]>([]);
  const [readings, setReadings] = useState<any[]>([]);

  const [date, setDate] = useState<any>(dayjs());
  const [showArchived, setShowArchived] = useState<boolean>(false);

  useEffect(() => {
    refreshWellReadings();
  }, [wellId, date, showArchived]);

  useEffect(() => {
    calculateReadingColumns();
  }, [readings]);

  const renderAdditionalNotesList = (additionalNotes: any[]) =>
    additionalNotes.map((note: any, index: number) => (
      <pre
        style={{
          width: "100%",
          margin: 0,
          marginTop: 5,
          fontFamily: "inherit",
          display: "block",
          wordBreak: "break-word",
          overflowWrap: "anywhere",
        }}
      >
        <small>
          <div style={{ display: "flex", paddingBottom: 5, paddingTop: 5 }}>
            <div style={{ width: 120 }}>
              {note.displayName}
              <br />
              <span style={{ fontSize: 10 }}>{dayjs(note.createdAt).format(constants.dateTimeFormat)}</span>
            </div>
            <div style={{ flex: 1 }}>{note.note}</div>
          </div>
        </small>
        <Divider style={{ padding: 0, margin: 0, marginTop: 5 }} />
      </pre>
    ));

  const renderAdditionalNotes = (record: any) =>
    record.additionalNotes?.length > 0 ? (
      <Popover
        content={
          <div
            style={{
              maxWidth: 500,
              maxHeight: 400,
              overflow: "auto",
              padding: 10,
            }}
          >
            <b>Additional Notes</b>
            {renderAdditionalNotesList(record.additionalNotes)}
          </div>
        }
      >
        <InfoCircleOutlined style={{ paddingLeft: 10 }} />
      </Popover>
    ) : (
      <></>
    );

  const calculateReadingColumns = () => {
    let tempColumns: any[] = [
      {
        title: "Date",
        key: "date",
        dataIndex: "date",
        fixed: "left",
        width: 150,
        render: (val: any, record: any) => dayjs(val).format(constants.dateTimeFormat),
      },
      {
        title: "Reading",
        key: "reading",
        dataIndex: "reading",
        width: 155,
        render: (val: any, record: any) => (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {formatReadingValue(record)}{" "}
            {record?.imageIds?.length > 0 ? (
              <Image.PreviewGroup items={record?.imageIds?.length > 0 ? record.imageIds.map((imageId: any) => `${constants.baseApiUrl}/well/image/${imageId}/download`) : []}>
                {record?.imageIds?.slice(0, 1).map((imageId: any, index: number) =>
                  record?.imageIds?.length > 1 ? (
                    <Badge offset={[-5, 3]} count={record?.imageIds?.length} color="grey">
                      <Image
                        key={imageId}
                        style={{
                          textAlign: "center",
                          marginLeft: "auto",
                          marginRight: "auto",
                          alignContent: "center",
                          paddingRight: 5,
                          width: 50,
                          height: 50,
                          objectFit: "contain",
                        }}
                        src={`${constants.baseApiUrl}/well/image/${imageId}/download?maxWidth=50&maxHeight=50`}
                        preview={{
                          src: `${constants.baseApiUrl}/well/image/${imageId}/download`,
                        }}
                      />
                    </Badge>
                  ) : (
                    <Image
                      key={imageId}
                      style={{
                        textAlign: "center",
                        marginLeft: "auto",
                        marginRight: "auto",
                        alignContent: "center",
                        paddingRight: 5,
                        width: 50,
                        height: 50,
                        objectFit: "contain",
                      }}
                      src={`${constants.baseApiUrl}/well/image/${imageId}/download?maxWidth=50&maxHeight=50`}
                      preview={{
                        src: `${constants.baseApiUrl}/well/image/${imageId}/download`,
                      }}
                    />
                  )
                )}
              </Image.PreviewGroup>
            ) : (
              ""
            )}
          </div>
        ),
      },
      {
        title: "New Meter",
        key: "newMeter",
        dataIndex: "newMeter",
        width: 100,
        align: "center",
        render: (val: any, record: any) => {
          if (record.type === "Not Collected") return <></>;
          if (record.newMeter === true) return <Tag color="green">YES</Tag>;
          else return <Tag color="blue">NO</Tag>;
        },
      },
      {
        title: "Type",
        key: "type",
        dataIndex: "type",
        width: 120,
        render: (val: any, record: any) => val,
      },
      {
        title: "User",
        key: "displayName",
        dataIndex: "displayName",
        width: 200,
        render: (val: any, record: any) => val,
      },
      {
        title: "Notes",
        key: "notes",
        dataIndex: "notes",
        render: (val: any, record: any) => (
          <div style={{ display: "flex" }}>
            <div>
              {record?.endReading && (
                <>
                  End meter reading for ({record?._year})<br />
                </>
              )}
              {record?.beginningReading && (
                <>
                  Beginning meter reading for ({record?._year})<br />
                </>
              )}
              {record?.beginEndReading && (
                <>
                  Beginning meter reading for ({record?._year + 1}) <br />
                  End meter reading for ({record?._year}) <br />
                </>
              )}
              {val && (
                <>
                  {val}
                  <br />
                </>
              )}
              {!val && (
                // !record?.endReading && !record.beginningReading && !record.beginEndReading &&
                <>-</>
              )}
            </div>
            <div style={{ flex: 1, alignContent: "center", textAlign: "right" }}>{renderAdditionalNotes(record)}</div>
          </div>
        ),
      },
      {
        title: "Places of Use",
        key: "placesOfUse",
        dataIndex: "placesOfUse",
        width: 250,
        render: (val: any, record: any) => (val ? <JoinedItems items={val} maxLength={20} title={"Places of Use"} /> : "-"),
      },
      showArchived && {
        title: "Archived",
        key: "validated",
        width: 100,
        align: "center",
        render: (val: any, record: any) => {
          return record.archived ? <Tag color="green">YES</Tag> : <Tag color="blue">NO</Tag>;
        },
      },
      {
        title: "Validated",
        key: "validated",
        width: 100,
        align: "center",
        render: (val: any, record: any) => {
          return record.type === "Not Collected" ? <></> : record.validatedDate ? <Tag color="green">YES</Tag> : <Tag color="blue">NO</Tag>;
        },
      },
    ];

    if (canEdit)
      tempColumns.push({
        title: "Actions",
        key: "action",
        width: 250,
        render: (value: any, record: any) =>
          record.type === "Not Collected" ? (
            <>
              <Popconfirm
                title="Archive the well reading"
                description="Are you sure you want to archive this well reading?"
                onConfirm={() =>
                  handleArchiveWellReading({
                    id: record.id,
                    archived: record?.archived ? true : null,
                  })
                }
                okText="Yes"
                cancelText="No"
              >
                <Button style={{ paddingLeft: 0 }} type="link">
                  {record.archived ? "Unarchive" : "Archive"}
                </Button>
              </Popconfirm>
            </>
          ) : (
            <>
              <Popconfirm
                title="Validate the well reading"
                description="Are you sure you want to validate this well reading?"
                onConfirm={() => handleValidateWellReading(record.id)}
                okText="Yes"
                cancelText="No"
              >
                {!record.validatedDate && (
                  <Button style={{ paddingLeft: 0, paddingRight: 10 }} type="link">
                    Validate
                  </Button>
                )}
              </Popconfirm>
              {!record.validatedDate && ` | `}
              <Button style={{ paddingLeft: 10 }} type="link" onClick={() => handleEditReading(record)}>
                Edit
              </Button>
            </>
          ),
      });

    setReadingColumns(tempColumns.filter((x) => x));
  };

  const handleValidateWellReading = async (id: any) => {
    const response = await validateWellReading(id);

    if (response.ok) {
      message.success("Validated reading successfully");
      refreshWellReadings();
    } else {
      message.error("Failed to validate reading");
    }
  };

  const handleArchiveWellReading = async (request: any) => {
    const response = await archiveWellReading(request);

    if (response.ok) {
      message.success("Archived reading successfully");
      refreshWellReadings();
    } else message.error("Failed to archive reading");
  };

  const refreshWellReadings = async () => {
    setLoading(true);
    const response = await getWellReadings({
      wellId: wellId,
      year: date?.year(),
      showArchived,
      includePreviousYearFinalReading: true,
    });
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess && data.value.length > 0) {
        const timezone = selectedCompany?.settings?.timezone;
        let orderReadings = data.value;

        const newReadings: any[] = [];

        if (timezone) {
          orderReadings = orderReadings.map((reading: any) => {
            const year = dayjs.utc(reading.date).tz(timezone).year();
            newReadings.push({ ...reading, _year: year });
            // return { ...reading, _year: year };
          });
        } else
          orderReadings.map((reading: any) => {
            const year = dayjs(reading.date).year();
            newReadings.push({ ...reading, _year: year });
            // return { ...reading, _year: year };
          });

        const filteredList = newReadings.filter((reading: any) => reading.type !== "Not Collected");
        const notCollectedList = newReadings.filter((reading: any) => reading.type === "Not Collected");

        const finalReadings: any[] = [];

        if (filteredList?.length > 0) {
          let startYear = filteredList[0]._year;
          if (date === null) {
            for (let index = 0; index < filteredList.length; index++) {
              const element = filteredList[index];
              const readingYear = element._year;

              if (index === 0) finalReadings.push({ ...element, endReading: true });
              else if (startYear !== readingYear) {
                startYear = readingYear;
                finalReadings.push({ ...element, beginEndReading: true });
              } else if (index === filteredList.length - 1 && startYear === readingYear) {
                finalReadings.push({ ...element, beginningReading: true });
              } else finalReadings.push({ ...element });
            }
          } else {
            if (filteredList.length > 1) {
              filteredList[0].endReading = true;
              filteredList[filteredList.length - 1].beginningReading = true;
              if (filteredList[filteredList.length - 1]._year !== date?.year()) filteredList[filteredList.length - 1]._year = date?.year();
            } else filteredList[0].beginningReading = true;

            finalReadings.push(...filteredList);
          }
        }

        finalReadings.push(...notCollectedList);
        finalReadings.sort((a, b) => (dayjs(a.date).isBefore(dayjs(b.date)) ? 1 : -1));

        setReadings(finalReadings);
      } else setReadings([]);
    }
    setLoading(false);
  };

  const handleWellReadingSuccess = () => {
    if (wellReadingModalState.readingId) message.success("Updated reading successfully");
    else message.success("Added reading successfully");

    refreshWellReadings();

    handleWellReadingClose();

    if (refreshWellCalculations) refreshWellCalculations();
    if (refreshWellViewCalculations) refreshWellViewCalculations();
    if (refreshWaterRightViewCalculations) refreshWaterRightViewCalculations();
    if (refreshWaterRightCalculations) refreshWaterRightCalculations();
  };

  const handleEditReading = (record: any) => {
    setWellReadingModalState({ open: true, readingId: record.id });
  };

  const handleWellReadingClose = () => {
    setWellReadingModalState({
      open: false,
    });
  };

  const renderTable = (isReport: boolean, loading: boolean) => (
    <Table
      // pagination={isReport ?? false}
      rowKey={(row: any) => row.id}
      expandable={{
        expandedRowRender: (record: any) => renderExpandData(record),
      }}
      loading={loading}
      columns={readingColumns}
      dataSource={readings}
      size="small"
    />
  );

  const renderExpandData = (record: any) => {
    return (
      <div style={{ padding: 10, paddingTop: 0, paddingLeft: 50 }}>
        <Descriptions bordered size="small" column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 2 }} labelStyle={{ width: 100 }} style={{ marginBottom: 10 }}>
          <Descriptions.Item label="Validated">
            {record.validatedDate ? dayjs(record.validatedDate).format(constants.dateTimeFormat) : "-"}
            {record.validatedByDisplayName ? (
              <>
                <br />
                {record.validatedByDisplayName}
              </>
            ) : (
              ""
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Archived">{record.archivedAt ? dayjs(record.archivedAt).format(constants.dateTimeFormat) : "-"}</Descriptions.Item>
          <Descriptions.Item label="Images" span={2}>
            {record?.imageIds?.length > 0 ? (
              <Image.PreviewGroup>
                {record?.imageIds.map((imageId: any) => (
                  <Image
                    key={imageId}
                    style={{
                      textAlign: "center",
                      marginLeft: "auto",
                      marginRight: "auto",
                      alignContent: "center",
                      paddingRight: 5,
                      width: 100,
                      height: 100,
                      objectFit: "contain",
                    }}
                    src={`${constants.baseApiUrl}/well/image/${imageId}/download?maxWidth=100&maxHeight=100`}
                    preview={{
                      src: `${constants.baseApiUrl}/well/image/${imageId}/download`,
                    }}
                  />
                ))}
              </Image.PreviewGroup>
            ) : (
              ""
            )}
          </Descriptions.Item>
        </Descriptions>
        {canEdit && <AuditLogTable entityId={record.id} entityType={AuditLogType.WellReading} />}
      </div>
    );
  };

  return (
    <Card
      id="wellReadingsTable"
      title={`Well Readings`}
      extra={
        !isReport ? (
          <>
            <Space direction="horizontal" style={{ width: "100%" }}>
              <Typography style={{ paddingBottom: 3, paddingRight: 3 }}>Show Archived:</Typography>
              <Switch checked={showArchived} onChange={(checked) => setShowArchived(checked)} checkedChildren="Yes" unCheckedChildren="No" style={{ marginRight: 30 }} />
              <Typography style={{ paddingBottom: 3, paddingRight: 3 }}>Year:</Typography>
              <DatePicker
                disabledDate={(d) => d.isAfter(`${dayjs().year()}-12-31`) || d.isBefore("2020-01-01")}
                value={date}
                onChange={setDate}
                picker="year"
                allowClear
                style={{ marginRight: 30 }}
                placeholder="All Readings"
              />
              <Button icon={<ReloadOutlined />} disabled={loading} loading={loading} onClick={refreshWellReadings}>
                Refresh
              </Button>
              {canEdit && (
                <Button disabled={loading} icon={<PlusOutlined />} type="primary" onClick={() => setWellReadingModalState({ open: true })}>
                  Add Reading
                </Button>
              )}
            </Space>
          </>
        ) : (
          <></>
        )
      }
    >
      <Table
        rowKey={(row: any) => row.id}
        expandable={{
          rowExpandable: (record: any) => record.type !== "Not Collected",
          expandedRowRender: (record: any) => renderExpandData(record),
        }}
        loading={loading}
        columns={readingColumns}
        dataSource={readings}
        size="small"
        rowClassName={(record: any) => (record.archived ? "archived" : "")}
      />
      {wellReadingModalState.open && (
        <ReadingsAddEditModal
          canEdit={canEdit}
          refreshWellViewCalculations={refreshWellViewCalculations}
          readingId={wellReadingModalState.readingId}
          wellId={wellId}
          waterRightId={waterRightId}
          onSuccess={handleWellReadingSuccess}
          open={wellReadingModalState.open}
          onCancel={handleWellReadingClose}
        />
      )}
    </Card>
  );
}
