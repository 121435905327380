import { OutstandingCompanyInvites, ProfileUpdateModal } from "@/components";
import useOutstandingInvites from "@/queries/useOutstandingInvites";
import { Alert, Button, Card, Col, Empty, notification, Row, Space } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";

const ActionsDashboard = () => {
  const [api, contextHolder] = notification.useNotification();

  const { profile } = useSelector((state: any) => state.user);
  const { oustandingInvites, invalidateCompanyInvites, isFetching: loadingCompanyInvites, isFetched: companyInvitesFetched } = useOutstandingInvites();

  const [outstandingInviteModalOpen, setOutstandingInviteModalOpen] = useState(false);
  const [profileModalState, setProfileModalState] = useState<any>({
    data: undefined,
    open: false,
  });

  const handleManageProfile = () => {
    setProfileModalState({ ...profileModalState, open: true });
  };

  const openProfileUpdateCompleteNotification = (closeModal?: boolean) => {
    setProfileModalState({ ...profileModalState, open: closeModal });

    const key = `open${Date.now()}`;
    const btn = (
      <Space>
        <Button type="default" onClick={() => api.destroy(key)}>
          Close
        </Button>
      </Space>
    );
    api.success({
      message: "Update profile success",
      description: "Your profile was successfuly updated.",
      btn,
      key,
      duration: 0,
      placement: "top",
    });
  };

  const handleProfileCancel = () => {
    setProfileModalState({ ...profileModalState, open: false });
  };

  return (
    <Card title="Actions" loading={!profile || !companyInvitesFetched}>
      {contextHolder}
      <Row>
        {profile?.phoneNumberConfirmed && profile?.phoneNumber && oustandingInvites?.length === 0 && (
          <Col span={24}>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Actions" />
          </Col>
        )}
        {!profile?.phoneNumberConfirmed && (
          <Col>
            <Card
              style={{ borderColor: "red" }}
              styles={{
                body: {
                  padding: 0,
                  cursor: "pointer",
                },
              }}
              onClick={handleManageProfile}
            >
              <Alert
                type="error"
                message="Enable Login with Phone Number"
                style={{ textAlign: "center", padding: 12 }}
                description={
                  <>
                    Verify your phone number to enable login with phone number.
                    <br />
                    <small>(Click to action now)</small>
                  </>
                }
              />
            </Card>
          </Col>
        )}
        {!profile?.phoneNumber && (
          <Col>
            <Card
              style={{ borderColor: "red" }}
              styles={{
                body: {
                  padding: 0,
                  cursor: "pointer",
                },
              }}
              onClick={handleManageProfile}
            >
              <Alert
                type="error"
                message="Profile Incomplete"
                style={{ textAlign: "center", padding: 12 }}
                description={
                  <>
                    Please complete your profile by adding your phone number.
                    <br />
                    <small>(Click to action now)</small>
                  </>
                }
              />
            </Card>
          </Col>
        )}
        {oustandingInvites?.length > 0 && (
          <Col>
            <Card
              style={{ borderColor: "red" }}
              styles={{
                body: {
                  padding: 0,
                  cursor: "pointer",
                },
              }}
              onClick={() => setOutstandingInviteModalOpen(true)}
            >
              <Alert
                type="error"
                style={{ textAlign: "center", padding: 12 }}
                message="Outstanding Company Invites"
                description={
                  <>
                    <div style={{ textAlign: "center" }}>
                      {loadingCompanyInvites ? (
                        <Button type="text" loading={loadingCompanyInvites} style={{ padding: 0 }}>
                          Loading...
                        </Button>
                      ) : (
                        oustandingInvites?.length
                      )}
                    </div>
                    <small>(Click to action now)</small>
                  </>
                }
              />
            </Card>
          </Col>
        )}
      </Row>
      {outstandingInviteModalOpen && (
        <OutstandingCompanyInvites
          onClose={() => {
            setOutstandingInviteModalOpen(false);
            invalidateCompanyInvites();
          }}
        />
      )}
      {profileModalState.open && <ProfileUpdateModal open={profileModalState.open} onSuccess={openProfileUpdateCompleteNotification} onCancel={handleProfileCancel} />}
    </Card>
  );
};

export default ActionsDashboard;
