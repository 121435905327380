import { ReloadOutlined } from "@ant-design/icons";
import { Button, Card, ConfigProvider, Input, Space, Table, Tabs, Tag, message } from "antd";
import { addNotBillableEntity, updateNotBillableEntity } from "@/apis/identity.api";
import { getBillableEntities } from "@/apis/waterright.api";
import { FC, useEffect, useState } from "react";
import AdminBillingExpandRender from "./AdminBillingExpandRender";

const customizeRenderEmpty = () => <div style={{ textAlign: "center" }}>No Data</div>;

const AdminBilling: FC = () => {
  const [loadingBillableEntities, setLoadingBillableEntities] = useState<boolean>(false);

  const [billableEntities, setBillableEntities] = useState<any[]>([]);
  const [columns, setColumns] = useState<any[]>([]);
  const [searchString, setSearchString] = useState<string | undefined>(undefined);

  useEffect(() => {
    listBillableEntities();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    calculateColumns();
    // eslint-disable-next-line
  }, [billableEntities]);

  const addNotBillableEntityEvent = async (record: any) => {
    setLoadingBillableEntities(true);

    const response = await addNotBillableEntity({
      entityId: record.entityId,
      entityType: record.entityType,
    });
    if (response.ok) {
      message.success("Successfully marked entity as not billable");

      const updatedEntities = billableEntities.map((entity) => {
        if (entity.entityId === record.entityId) {
          entity.billable = false;
          entity.lastRefreshed = new Date().toISOString();
        }
        return entity;
      });

      setBillableEntities(updatedEntities);
    } else message.error("Failed to marked entity as not billable");

    setLoadingBillableEntities(false);
  };

  const updateBillableEntity = async (record: any) => {
    setLoadingBillableEntities(true);

    const response = await updateNotBillableEntity({ id: record.entityId });
    if (response.ok) {
      message.success("Successfully marked entity as billable");

      const updatedEntities = billableEntities.map((entity) => {
        if (entity.entityId === record.entityId) {
          entity.billable = true;
          entity.lastRefreshed = new Date().toISOString();
        }
        return entity;
      });

      setBillableEntities(updatedEntities);
    } else message.error("Failed to mark entity as billable");

    setLoadingBillableEntities(false);
  };

  const handleRefresh = () => {
    setSearchString(undefined);
    listBillableEntities();
  };

  const listBillableEntities = async (searchString: any = null) => {
    setLoadingBillableEntities(true);

    const request = { searchString: searchString, user: true };
    const response = await getBillableEntities(request);

    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        const refreshAt = new Date().toISOString();
        const billableEntities = data.value?.map((entity: any) => ({
          ...entity,
          lastRefreshed: refreshAt,
        }));
        setBillableEntities(billableEntities);
      }
    }

    setLoadingBillableEntities(false);
  };

  const calculateColumns = () => {
    let tempColumns = [
      {
        title: "First Name",
        key: "ownerFirstName",
        dataIndex: "ownerFirstName",
        render: (val: any, record: any) => val,
      },
      {
        title: "Last Name",
        key: "ownerLastName",
        dataIndex: "ownerLastName",
        render: (val: any, record: any) => val,
      },
      {
        title: "Email",
        key: "ownerEmail",
        dataIndex: "ownerEmail",
        render: (val: any, record: any) => val,
      },
      {
        title: "Billable",
        key: "billable",
        dataIndex: "billable",
        width: 50,
        render: (val: any, record: any) => (val === true ? <Tag color="green">Yes</Tag> : <Tag color="red">No</Tag>),
      },
      {
        title: "Actions",
        width: 75,
        render: (val: any, record: any) => (
          <Button style={{ paddingLeft: 0 }} type="link" onClick={() => (record.billable ? addNotBillableEntityEvent(record) : updateBillableEntity(record))}>
            {record.billable ? "Mark as NOT billable" : "Mark as billable"}
          </Button>
        ),
      },
    ];

    setColumns(tempColumns);
  };

  const onSearch = (searchString: any) => {
    setSearchString(searchString);
    listBillableEntities(searchString);
  };

  const onSearchChange = (searchString: any) => {
    setSearchString(searchString);
  };

  const renderTabs = () => {
    let tabs: any[] = [
      {
        label: "Billable",
        key: "billable",
        children: (
          <ConfigProvider renderEmpty={customizeRenderEmpty}>
            <Space direction="vertical" style={{ width: "100%" }}>
              <Space style={{ paddingBottom: 10 }}>
                <Input.Search
                  disabled={loadingBillableEntities}
                  placeholder="Search users"
                  onSearch={(searchString) => {
                    onSearch(searchString);
                  }}
                  onChange={(val) => onSearchChange(val.currentTarget.value)}
                  value={searchString}
                  style={{ width: 400 }}
                  allowClear
                />
                <Button disabled={loadingBillableEntities} icon={<ReloadOutlined />} onClick={handleRefresh}>
                  Refresh
                </Button>
              </Space>
            </Space>
            <Table
              rowKey={(row: any) => row.entityId}
              loading={loadingBillableEntities}
              columns={columns}
              dataSource={billableEntities ?? []}
              size="small"
              expandable={{
                expandedRowRender: (record: any) => <AdminBillingExpandRender record={record} lastRefreshed={record.lastRefreshed} />,
              }}
            />
          </ConfigProvider>
        ),
      },
    ];

    return tabs;
  };

  return (
    <Card title="Billing">
      <Tabs tabPosition="left" items={renderTabs()} />
    </Card>
  );
};

export default AdminBilling;
