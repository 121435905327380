import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getFieldsLookup } from "@/apis/field.api";
import { getWaterRightsLookup } from "@/apis/waterright.api";
import defaultQueryConfig from "@/configs/defaultQueryConfig";
import { isEqual } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CacheKeys from "@/types/CacheKeys";

export default function useWaterRightLookups(waterRightId?: string) {
  const { profile } = useSelector((state: any) => state.user);

  const queryClient = useQueryClient();
  const {
    data: waterRights,
    isFetching,
    isRefetching,
    isFetched,
    isError,
    isSuccess,
  } = useQuery({
    queryKey: [
      CacheKeys.waterRightsLookup,
      {
        profileId: profile?.id,
      },
    ],
    queryFn: async () => {
      const response = await getWaterRightsLookup({});

      if (response.ok) {
        const data = await response.json();
        if (data.isSuccess) return data.value;
      }

      throw new Error("Failed to fetch fields");
    },
    gcTime: Infinity,
    ...defaultQueryConfig,
    enabled: !!profile?.id,
    placeholderData: (currentData) => currentData || [],
  });

  const invalidateWaterRights = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: [CacheKeys.waterRightsLookup] });
  }, [queryClient]);

  const [waterRight, setWaterRight] = useState<any>(null);

  useEffect(() => {
    if (isFetched && !isError && !isFetching && !isRefetching && isSuccess && waterRightId && waterRights) {
      const tempWaterRight = waterRights.find((f: any) => f.id === waterRightId);
      if (tempWaterRight) {
        if (!isEqual(waterRight, tempWaterRight)) setWaterRight(tempWaterRight);
      } else {
        invalidateWaterRights();
      }
    }
  }, [isError, isFetched, isFetching, isRefetching, waterRightId, waterRights, invalidateWaterRights, isSuccess, waterRight]);

  return { waterRight, waterRights, invalidateWaterRights };
}
