import { CloseOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { Button, Card, Divider, Form, Select, Space } from "antd";
import { useWatch } from "antd/es/form/Form";
import { getWaterRights } from "@/apis/waterright.api";
import { WaterRightSelector } from "@/components";
import { constants } from "@/configs";
import dayjs from "dayjs";
import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import GeneratedWaterRightConfigurationReport from "./GeneratedWaterRightConfigurationReport";

const WaterRightConfigurationReport: FC = () => {
  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const { lookups } = useSelector((state: any) => state.lookups);

  const [form] = Form.useForm();

  const [loadingWaterRights, setLoadingWaterRights] = useState<boolean>(false);
  const [generatingReport, setGeneratingReport] = useState<boolean>(false);

  const [waterRights, setWaterRights] = useState<any[]>([]);

  const [reportConfig, setReportConfig] = useState<any>(undefined);

  const waterRightIds: any[] = useWatch("waterRightIds", form);
  const permitTypes: any[] = useWatch("permitTypes", form);
  const priorityTypes: any[] = useWatch("priorityTypes", form);

  useEffect(() => {
    handleReset();
    // eslint-disable-next-line
  }, [selectedCompanyId]);

  useEffect(() => {
    if (selectedCompanyId) {
      refreshWaterRights();
    }
    // eslint-disable-next-line
  }, [selectedCompanyId]);

  useEffect(() => {
    if (generatingReport) setTimeout(() => setGeneratingReport(false), 500);
    // eslint-disable-next-line
  }, [generatingReport]);

  const refreshWaterRights = async () => {
    setLoadingWaterRights(true);
    const request = { companyId: selectedCompanyId };
    const response = await getWaterRights(request);
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) setWaterRights(data.value);
    }
    setLoadingWaterRights(false);
  };

  const handleGenerateReport = () => {
    setGeneratingReport(true);

    // Water Right Ids
    const tempWRsForPermitTypes = waterRights.filter((waterRight) => permitTypes && permitTypes.includes(waterRight.permitType)).map((waterRight) => waterRight.id);
    const tempWRsForPriorityTypes = waterRights.filter((waterRight) => priorityTypes && priorityTypes.includes(waterRight.priorityType)).map((waterRight) => waterRight.id);
    const tempWRIds = [...(waterRightIds ?? [])];

    tempWRsForPermitTypes.forEach((tempWr) => {
      const wr = tempWRIds.find((waterRight) => waterRight === tempWr);
      if (!wr) tempWRIds.push(tempWr);
    });

    tempWRsForPriorityTypes.forEach((tempWr) => {
      const wr = tempWRIds.find((waterRight) => waterRight === tempWr);
      if (!wr) tempWRIds.push(tempWr);
    });

    const permitTypeLookups = lookups?.find((lookup: any) => lookup.map === "permittype");
    const priorityTypeLookups = lookups?.find((lookup: any) => lookup.map === "prioritytype");

    setReportConfig({
      waterRightIds: tempWRIds,
      waterRights: waterRights
        .filter((waterRight: any) => tempWRIds?.includes(waterRight.id))
        .map((waterRight: any) => waterRight.fileNumber)
        .join(", "),
      permitTypes: permitTypeLookups?.options
        ?.filter((option: any) => permitTypes?.includes(option.value))
        ?.sort((a: any, b: any) =>
          a?.name?.localeCompare(b?.name, "en", {
            numeric: true,
            sensitivity: "base",
          })
        )
        ?.map((option: any) => option.name)
        .join(", "),
      priorityTypes: priorityTypeLookups?.options
        ?.filter((option: any) => priorityTypes?.includes(option.value))
        ?.sort((a: any, b: any) =>
          a?.name?.localeCompare(b?.name, "en", {
            numeric: true,
            sensitivity: "base",
          })
        )
        ?.map((option: any) => option.name)
        .join(", "),
      generatedDate: dayjs().format(constants.dateTimeFormatWithSeconds),
    });
  };

  const handleReset = () => {
    form?.resetFields();
    setReportConfig(undefined);
  };

  const renderActionsButtons = () => {
    return (
      <Space>
        <Button loading={loadingWaterRights || generatingReport} disabled={loadingWaterRights || generatingReport} type="primary" icon={<ThunderboltOutlined />} onClick={handleGenerateReport}>
          Generate Report
        </Button>
        <Button loading={loadingWaterRights || generatingReport} disabled={loadingWaterRights || generatingReport} icon={<CloseOutlined />} onClick={handleReset}>
          Reset
        </Button>
      </Space>
    );
  };

  return (
    <>
      <Card title="Configuration" actions={[renderActionsButtons()]} bodyStyle={{ padding: 12, margin: 0 }}>
        <Form form={form} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }} autoComplete="off" style={{ margin: 0 }}>
          <WaterRightSelector style={{ margin: 0, marginBottom: 0 }} form={form} label={"Water Rights"} placeholder={"Select water rights"} propertyToSet={"waterRightIds"} />
          <Form.Item label="Permit Type" name="permitTypes" style={{ margin: 0, paddingTop: 10 }}>
            <Select
              allowClear
              mode="multiple"
              loading={loadingWaterRights}
              placeholder="Select permit types"
              showSearch
              optionFilterProp="label"
              maxTagCount={3}
              disabled={loadingWaterRights || generatingReport}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <Space style={{ padding: "0 8px 4px" }}>
                    <Button
                      onClick={() =>
                        form.setFieldValue(
                          "permitTypes",
                          lookups.find((lookup: any) => lookup.map === "permittype").options.map((lookup: any) => lookup.value)
                        )
                      }
                    >
                      Select All
                    </Button>
                    <Button danger onClick={() => form.setFieldValue("permitTypes", [])}>
                      Clear List
                    </Button>
                  </Space>
                </>
              )}
            >
              {lookups
                .find((lookup: any) => lookup.map === "permittype")
                .options.map((lookup: any) => {
                  return (
                    <Select.Option value={lookup.value} key={lookup.id} label={lookup.name}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        {lookup.name}
                      </div>
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item label="Priority Type" name="priorityTypes" style={{ margin: 0, paddingTop: 10 }}>
            <Select
              allowClear
              mode="multiple"
              loading={loadingWaterRights}
              placeholder="Select the priority types"
              showSearch
              optionFilterProp="label"
              maxTagCount={3}
              disabled={loadingWaterRights || generatingReport}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <Space style={{ padding: "0 8px 4px" }}>
                    <Button
                      onClick={() =>
                        form.setFieldValue(
                          "priorityTypes",
                          lookups.find((lookup: any) => lookup.map === "prioritytype").options.map((lookup: any) => lookup.value)
                        )
                      }
                    >
                      Select All
                    </Button>
                    <Button danger onClick={() => form.setFieldValue("priorityTypes", [])}>
                      Clear List
                    </Button>
                  </Space>
                </>
              )}
            >
              {lookups
                .find((lookup: any) => lookup.map === "prioritytype")
                .options.map((lookup: any) => {
                  return (
                    <Select.Option value={lookup.value} key={lookup.id} label={lookup.name}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        {lookup.name}
                      </div>
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Form>
      </Card>
      {generatingReport && (
        <Card title="Generating Report">
          <div style={{ textAlign: "center" }}>Loading...</div>
        </Card>
      )}
      {!generatingReport && reportConfig && <GeneratedWaterRightConfigurationReport reportConfig={reportConfig} />}
    </>
  );
};

export default WaterRightConfigurationReport;
