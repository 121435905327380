import { Button, Card } from "antd";
import { generateDatesReport } from "@/apis/waterright.api";
import { FC, useState } from "react";
import { useSelector } from "react-redux";
import { openReportFromGeneratedFile } from "@/services/utils";

const ExportWaterRightDateInformationReport: FC = () => {
  const { selectedCompanyId } = useSelector((state: any) => state.company);

  const [generatingDatesReport, setGeneratingDatesReport] = useState<boolean>(false);

  const downloadDatesReport = async () => {
    setGeneratingDatesReport(true);

    const request = { companyId: selectedCompanyId };

    const response = await generateDatesReport(request);
    if (response.ok) {
      const data = await response.json();
      if (data.isSuccess) {
        openReportFromGeneratedFile(data.value);
      }
    }

    setGeneratingDatesReport(false);
  };

  return (
    <Card title="Water Right Date Information Export" bodyStyle={{ padding: 12, margin: 0 }}>
      <div
        style={{
          margin: "auto",
          width: "50%",
          textAlign: "center",
        }}
      >
        <Button loading={generatingDatesReport} disabled={generatingDatesReport} type="primary" onClick={(e) => downloadDatesReport()}>
          Download
        </Button>
      </div>
    </Card>
  );
};

export default ExportWaterRightDateInformationReport;
